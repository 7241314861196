var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
var AddEnrollmentModal = /** @class */ (function (_super) {
    __extends(AddEnrollmentModal, _super);
    function AddEnrollmentModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasErrors = false;
        _this.data = {
            trials: {
                value: [],
                label: '',
                error: null,
                rule: 'required'
            }
        };
        _this.saving = false;
        _this.errorMessage = '';
        _this.loading = false;
        _this.fieldChange = function (e, field) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                fieldChange(e, field, this.data);
                this.data.trials.value = this.data.trials.value.map(function (trial) { return (__assign(__assign({}, trial), { patientNumber: trial.patientNumber || {} })); });
                return [2 /*return*/];
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split("/new")[0]);
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var patientStore, o, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        patientStore = this.props.patientStore;
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        o = this.getObjectToSave();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.saving = true;
                        return [4 /*yield*/, patientStore.enrollPatientToTrials(o)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 4:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 5:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var o = {
                trials: _this.data.trials.value.map(function (e) { return ({
                    trialId: e.value,
                    patientNumber: e.patientNumber.value
                }); })
            };
            return o;
        };
        _this.validateForm = function (validationErrors) {
            _this.data.trials.value.forEach(function (trial) {
                var _a;
                if (!trial.patientNumber.value || ((_a = trial.patientNumber.value) === null || _a === void 0 ? void 0 : _a.trim()) === '') {
                    trial.patientNumber.error = 'Required';
                }
                else {
                    trial.patientNumber.error = undefined;
                }
            });
            return (_this.data.trials.value.some(function (t) { return t.patientNumber.error; }) ||
                utilValidate(_this.data, null, validationErrors));
        };
        _this.getTrialOptions = function () {
            var _a = _this.props, trials = _a.siteStore.trials, patient = _a.patientStore.patient;
            return trials
                .filter(function (t) { return !patient.enrollments.find(function (e) { return e.trialId === t.id; }); })
                .map(function (t) { return ({
                value: t.id,
                label: t.name
            }); });
        };
        return _this;
    }
    AddEnrollmentModal.prototype.render = function () {
        var _this = this;
        var patient = this.props.patientStore.patient;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading, style: { height: '530px' } },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, "Add trial"),
            React.createElement(FlexContainer, { style: { marginTop: '10px' }, margin: 15 },
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient.fullName),
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient.id)),
            React.createElement(RdiLine, null),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent, style: {
                    height: 'calc(100% - 110px)',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement(RdiInput, { type: "select-multiple", label: "Trial", name: "takingMedicine", fullWidth: true, value: this.data.trials.value, selectedItemLabel: this.data.trials.label, errorText: this.data.trials.error, onChange: function (e) { return _this.fieldChange(e, 'trials'); }, options: this.getTrialOptions() }),
                this.data.trials.value.map(function (trial, index) {
                    var _a, _b;
                    return (React.createElement(RdiInput, { key: trial, type: "text", label: trial.label + " Patient number", value: (_a = trial.patientNumber) === null || _a === void 0 ? void 0 : _a.value, errorText: (_b = trial.patientNumber) === null || _b === void 0 ? void 0 : _b.error, fullWidth: true, onChange: function (e) { return _this.fieldChange(e, "trials." + index + ".patientNumber"); }, name: "patientNumber" }));
                }),
                React.createElement("div", { style: { flex: '1' } }),
                React.createElement(RdiLine, null),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, submit: true }, "Add")))));
    };
    __decorate([
        observable
    ], AddEnrollmentModal.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], AddEnrollmentModal.prototype, "data", void 0);
    __decorate([
        observable
    ], AddEnrollmentModal.prototype, "saving", void 0);
    __decorate([
        observable
    ], AddEnrollmentModal.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], AddEnrollmentModal.prototype, "loading", void 0);
    AddEnrollmentModal = __decorate([
        injectSiteStore,
        injectPatientStore,
        injectAccountStore,
        observer
    ], AddEnrollmentModal);
    return AddEnrollmentModal;
}(Component));
export default withRouter(AddEnrollmentModal);
