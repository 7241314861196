var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import { AppBar, CircularProgress, createStyles, Tab, Tabs, withStyles } from '@material-ui/core';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { Link as RLink } from 'react-router-dom';
import NextVisitCard from '~/components/patient/NextVisitCard';
import PatientNumberCard from '~/components/patient/PatientNumberCard';
import TrialProgressCard from '~/components/patient/TrialProgressCard';
import WearableDataCard from '~/components/patient/WearableDataCard';
import DevicesListPage from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/DevicesListPage';
import { getAdverseEventsTrialRoute, getIpDispensingLogRoute, getIpDosingLogRoute, getVisitsRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import EnrollmentStore from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
import TrialStore from '~/stores/TrialStore';
import AddUnscheduledVisit from './AddUnscheduledVisit';
import IpDispensingLogPage from './IpDispensingLogPage';
import IpDosingLogPage from './IpDosingLogPage';
import AdverseEventsPage from './patient-detail/AdverseEventsPage';
import PatientFeedbackHistory from './patient-detail/PatientFeedbackHistory';
import WearableDataPage from './patient-detail/WearableDataPage';
import RescheduleVisit from './RescheduleVisitModal';
import VisitModal from './visit/VisitModal';
import VisitListPage from './VisitListPage';
var styles = function () {
    return createStyles({
        basicInfoContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        cardContainer: {
            marginBottom: '30px',
            display: 'grid',
            gridTemplateColumns: 'repeat( auto-fit, minmax(220px, 1fr) )',
            columnGap: '20px',
            '@media (max-width: 1280px)': {
                gridTemplateColumns: '1fr 1fr'
            },
            '@media (max-width: 900px)': {
                gridTemplateColumns: '1fr'
            }
        }
    });
};
var EnrollmentPage = /** @class */ (function (_super) {
    __extends(EnrollmentPage, _super);
    function EnrollmentPage(props) {
        var _this = _super.call(this, props) || this;
        _this.tabIndex = SiteRoutes.VISITS_ROUTE;
        _this.loading = true;
        _this.tabs = [
            {
                id: 'visits',
                label: 'Visits',
                route: SiteRoutes.VISITS_ROUTE,
                url: function (siteId, patientId, trialId, enrollmentId) {
                    return getVisitsRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'ipdispensinglog',
                label: 'Ip dispensing log',
                route: SiteRoutes.IP_DISPENSING_LOG_ROUTE,
                url: function (siteId, patientId, trialId, enrollmentId) {
                    return getIpDispensingLogRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'ipdosinglog',
                label: 'Dose administration',
                route: SiteRoutes.IP_DOSING_LOG_ROUTE,
                url: function (siteId, patientId, trialId, enrollmentId) {
                    return getIpDosingLogRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'adverseevents',
                label: 'Adverse events',
                route: SiteRoutes.ADVERSE_EVENTS_TRIAL_ROUTE,
                url: function (siteId, patientId, trialId, enrollmentId) {
                    return getAdverseEventsTrialRoute(siteId, patientId, trialId, enrollmentId);
                }
            }
        ];
        _this.componentDidUpdate = function (prevProps) { return __awaiter(_this, void 0, void 0, function () {
            var enrollmentId, prevEnrollmentId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        enrollmentId = this.props.match.params.enrollmentId;
                        prevEnrollmentId = prevProps.match.params.enrollmentId;
                        if (!(prevEnrollmentId !== enrollmentId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchEnrollment()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var location;
            return __generator(this, function (_a) {
                location = this.props.location;
                this.fetchEnrollment();
                this.assignTab(location);
                return [2 /*return*/];
            });
        }); };
        _this.fetchEnrollment = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, enrollmentId, trialId, trials, enrollment;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, _b = _a.match.params, enrollmentId = _b.enrollmentId, trialId = _b.trialId, trials = _a.siteStore.trials;
                        this.loading = true;
                        return [4 /*yield*/, this.enrollmentStore.getEnrollment(enrollmentId, trialId)];
                    case 1:
                        enrollment = _c.sent();
                        this.enrollmentStore.setEnrollment(enrollment);
                        this.trialStore.trial = trials.find(function (t) { return t.id === enrollment.trialId; });
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignTab = function (location) { return __awaiter(_this, void 0, void 0, function () {
            var i, assigned, _i, _a, tab;
            return __generator(this, function (_b) {
                i = 0;
                assigned = false;
                for (_i = 0, _a = this.tabs; _i < _a.length; _i++) {
                    tab = _a[_i];
                    if (location.pathname.indexOf(this.tabs[i].id) > 0) {
                        assigned = true;
                        this.tabIndex = tab.route;
                    }
                    i += 1;
                }
                if (!assigned) {
                    this.tabIndex = this.tabs[0].route;
                }
                return [2 /*return*/];
            });
        }); };
        var _a = _this.props, siteStore = _a.siteStore, history = _a.history;
        _this.enrollmentStore = new EnrollmentStore(siteStore);
        _this.trialStore = new TrialStore(siteStore);
        history.listen(function (location) {
            _this.assignTab(location);
        });
        return _this;
    }
    EnrollmentPage.prototype.render = function () {
        var _a = this.props, classes = _a.classes, history = _a.history, _b = _a.match.params, patientId = _b.patientId, enrollmentId = _b.enrollmentId, siteId = _b.siteId, trialId = _b.trialId, location = _a.location, accountStore = _a.accountStore;
        var _c = this.enrollmentStore, enrollment = _c.enrollment, updateEnrollment = _c.updateEnrollment;
        return (React.createElement(Provider, { enrollmentStore: this.enrollmentStore, trialStore: this.trialStore },
            React.createElement(Route, { exact: true, path: SiteRoutes.PATIENT_ROUTE },
                React.createElement(Redirect, { to: getVisitsRoute(siteId, patientId, trialId, enrollmentId) })),
            this.loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null))),
            !this.loading && (React.createElement(React.Fragment, null,
                enrollment && (React.createElement("div", null,
                    React.createElement("div", { className: classes.cardContainer },
                        React.createElement(TrialProgressCard, { enrollment: enrollment, updateEnrollment: updateEnrollment }),
                        React.createElement(NextVisitCard, __assign({ enrollment: enrollment, patientId: patientId, siteId: siteId, trialId: trialId, enrollmentId: enrollmentId }, location, { history: history, checkInVisitWithConfirmation: this.enrollmentStore.visitListStore.checkInVisitWithConfirmation, aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteId); } })),
                        React.createElement(WearableDataCard, { enrollment: enrollment, siteId: siteId, trialId: trialId, enrollmentId: enrollmentId, patientId: patientId }),
                        React.createElement(PatientNumberCard, { enrollment: enrollment })),
                    React.createElement(AppBar, { position: "static", color: "default" },
                        React.createElement(Tabs, { value: this.tabIndex, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" }, this.tabs.map(function (tab) {
                            return (React.createElement(Tab, { value: tab.route, component: RLink, to: "" + tab.url(siteId, patientId, trialId, enrollmentId), key: tab.id, label: tab.label, className: tab.id }));
                        }))))),
                enrollment && (React.createElement(React.Fragment, null,
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: SiteRoutes.IP_DISPENSING_LOG_ROUTE },
                            React.createElement(IpDispensingLogPage, null)),
                        React.createElement(Route, { path: SiteRoutes.IP_DOSING_LOG_ROUTE },
                            React.createElement(IpDosingLogPage, null)),
                        React.createElement(Route, { path: SiteRoutes.ADVERSE_EVENTS_TRIAL_ROUTE },
                            React.createElement(AdverseEventsPage, null)),
                        React.createElement(Route, { path: SiteRoutes.VISITS_ROUTE },
                            React.createElement(VisitListPage, null))),
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: SiteRoutes.FEEDBACK_HISTORY_ROUTE },
                            React.createElement(PatientFeedbackHistory, null)),
                        React.createElement(Route, { path: SiteRoutes.WEARABLE_DATA_ROUTE },
                            React.createElement(WearableDataPage, null)),
                        React.createElement(Route, { path: SiteRoutes.DEVICE_LIST_ROUTE },
                            React.createElement(DevicesListPage, null)),
                        React.createElement(Route, { path: SiteRoutes.SCHEDULE_VISIT_ROUTE },
                            React.createElement(RescheduleVisit, null)),
                        React.createElement(Route, { exact: true, path: SiteRoutes.NEW_UNSCHEDULED_VISIT_ROUTE },
                            React.createElement(AddUnscheduledVisit, null)),
                        React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_UNSCHEDULED_VISIT_ROUTE },
                            React.createElement(AddUnscheduledVisit, null)),
                        React.createElement(Route, { path: SiteRoutes.VISIT_ROUTE },
                            React.createElement(VisitModal, { patient: enrollment })))))))));
    };
    __decorate([
        observable
    ], EnrollmentPage.prototype, "tabIndex", void 0);
    __decorate([
        observable
    ], EnrollmentPage.prototype, "loading", void 0);
    EnrollmentPage = __decorate([
        injectPatientStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], EnrollmentPage);
    return EnrollmentPage;
}(Component));
export default withStyles(styles)(withRouter(EnrollmentPage));
