var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { DatapointTemplateEnum } from '@cros/shared/constants/enums/DatapointTemplateEnum';
import { getDateFromTimeAndDate, parseTime, TIME_HUMAN } from '@cros/shared/utils/dateUtils';
import { format, parseISO } from 'date-fns';
import React, { Component } from 'react';
var VisitDate = /** @class */ (function (_super) {
    __extends(VisitDate, _super);
    function VisitDate(props) {
        var _this = _super.call(this, props) || this;
        _this.componentDidMount = function () {
            _this.assignInternalValues(_this.props);
        };
        _this.assignInternalValues = function (props) {
            var _a = props.dataPoint, data = _a.data, template_name = _a.template_name, errorMessage = _a.errorMessage, disabled = props.disabled;
            var parsedDate = data.value ? parseISO(data.value) : null;
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { internalDate: parsedDate, internalTime: template_name === 'time' ? parseTime(data.value) : parsedDate, internalDisabled: disabled, internalError: errorMessage })); });
        };
        _this.getJointISODateTime = function () {
            var dataPoint = _this.props.dataPoint;
            var _a = _this.state, internalDate = _a.internalDate, internalTime = _a.internalTime;
            if ((!internalTime && dataPoint.template_name !== DatapointTemplateEnum.date) ||
                !internalDate) {
                return null;
            }
            var date = null;
            // try catch because it will throw errors if someone manually input date
            try {
                date = getDateFromTimeAndDate(internalTime || new Date(), internalDate).toISOString();
            }
            catch (e) { } // eslint-disable-line
            return date;
        };
        _this.onChangeDate = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setState(function (prevState) { return (__assign(__assign({}, prevState), { internalDate: e.value })); })];
                    case 1:
                        _a.sent();
                        this.onChange();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onChangeTime = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setState(function (prevState) { return (__assign(__assign({}, prevState), { internalTime: e.value })); })];
                    case 1:
                        _a.sent();
                        this.onChange();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onChange = function () {
            var _a = _this.props, onDataPointChange = _a.onDataPointChange, dataPoint = _a.dataPoint;
            if (dataPoint.template_name === 'time') {
                var time = null;
                try {
                    time = format(_this.state.internalTime, TIME_HUMAN);
                }
                catch (e) { } // eslint-disable-line
                onDataPointChange(dataPoint, {
                    value: time
                });
            }
            var jointDate = _this.getJointISODateTime();
            // both must be defined
            if (!jointDate) {
                return;
            }
            onDataPointChange(dataPoint, {
                value: jointDate
            });
        };
        _this.state = {
            internalDate: null,
            internalTime: null,
            internalDisabled: false,
            internalError: null
        };
        return _this;
    }
    VisitDate.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var _a = this.state, internalDate = _a.internalDate, internalTime = _a.internalTime, internalDisabled = _a.internalDisabled, internalError = _a.internalError;
        if (internalDate !== nextState.internalDate || internalTime !== nextState.internalTime) {
            return true;
        }
        if (this.getJointISODateTime() === nextProps.dataPoint.data.value &&
            internalDisabled === nextProps.disabled &&
            internalError === nextProps.dataPoint.errorMessage) {
            return false;
        }
        this.assignInternalValues(nextProps);
        return true;
    };
    VisitDate.prototype.render = function () {
        var dataPoint = this.props.dataPoint;
        var _a = this.state, internalError = _a.internalError, internalDate = _a.internalDate, internalTime = _a.internalTime, internalDisabled = _a.internalDisabled;
        return (React.createElement(FlexContainer, { flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", style: { marginTop: '10px', marginRight: '20px' } },
            React.createElement(FlexContainer, { flexDirection: "row", style: { flexWrap: 'wrap', alignItems: 'flex-start' } },
                dataPoint.template_name !== DatapointTemplateEnum.time && (React.createElement(React.Fragment, null,
                    React.createElement(RdiInput, { style: { width: '180px' }, label: dataPoint.label ? dataPoint.label.replace('%s', 'Date') : '', tooltip: dataPoint.tooltip, placeholder: "Select a date...", isValid: !internalError, type: "date", value: internalDate, onChange: this.onChangeDate, disabled: internalDisabled, errorText: internalError }),
                    React.createElement("div", { style: { width: '45px' } }))),
                dataPoint.template_name !== DatapointTemplateEnum.date && (React.createElement(React.Fragment, null,
                    React.createElement(RdiInput, { style: { width: '180px' }, label: dataPoint.label ? dataPoint.label.replace('%s', 'Time') : '', tooltip: dataPoint.tooltip, type: "time", isValid: !internalError, placeholder: "Select time...", value: internalTime, onChange: this.onChangeTime, disabled: internalDisabled, errorText: internalError ? internalError.replace('Date', 'Time') : undefined }))))));
    };
    return VisitDate;
}(Component));
export default VisitDate;
