import { Tooltip } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import JsxParser from "react-jsx-parser";
import RdiLink from "s~/components/routing/RdiLink";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var RdiJsxParser = function (props) {
    var text = props.children;
    return (
    // @ts-ignore
    React.createElement(JsxParser, { renderInWrapper: false, components: {
            tooltip: function (tooltipProps) {
                return (React.createElement(Tooltip, { title: tooltipProps.title },
                    React.createElement("span", { style: { textDecoration: "underline" } }, tooltipProps.children)));
            },
            warning: function () {
                return (React.createElement(WarningIcon, { style: { color: ColorsEnum.Error, marginRight: "10px" } }));
            },
            link: function (linkProps) {
                return React.createElement(RdiLink, { to: linkProps.to }, linkProps.children);
            }
        }, jsx: text }));
};
export default RdiJsxParser;
