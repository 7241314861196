var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import BillingCodesPage from '~/pages/site/site-detail/trials/trial-detail/BillingCodesPage';
import ProtocolPage from '~/pages/site/site-detail/trials/trial-detail/ProtocolPage';
import { getTrialPatientsRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import TrialStore from '~/stores/TrialStore';
import EnrollmentListPage from './EnrollmentListPage';
import EnrollmentPage from './patient/EnrollmentPage';
var TrialDetail = /** @class */ (function (_super) {
    __extends(TrialDetail, _super);
    function TrialDetail(props) {
        var _this = _super.call(this, props) || this;
        _this.trialId = null;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.changeTrial()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.componentDidUpdate = function (prevProps) { return __awaiter(_this, void 0, void 0, function () {
            var trialId, prevTrialId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        trialId = this.props.match.params.trialId;
                        prevTrialId = prevProps.match.params.trialId;
                        if (!(prevTrialId !== trialId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.changeTrial()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.changeTrial = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, trialId, firstTrialId;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, trialId = _a.match.params.trialId, firstTrialId = _a.siteStore.firstTrialId;
                        this.trialId = trialId || firstTrialId;
                        return [4 /*yield*/, this.trialStore.getTrial(this.trialId)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var siteStore = _this.props.siteStore;
        _this.trialStore = new TrialStore(siteStore);
        return _this;
    }
    TrialDetail.prototype.render = function () {
        var _a = this.props, siteId = _a.siteStore.siteId, trialId = _a.match.params.trialId;
        return (React.createElement(Provider, { trialStore: this.trialStore },
            React.createElement(Route, { exact: true, path: SiteRoutes.PATIENTS_ROUTE }),
            this.trialStore.trialId && !this.trialStore.trialLoading && (React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: SiteRoutes.TRIAL_ROUTE },
                    React.createElement(Redirect, { to: getTrialPatientsRoute(siteId, trialId) })),
                React.createElement(Route, { path: SiteRoutes.PATIENT_ROUTE },
                    React.createElement(EnrollmentPage, null)),
                React.createElement(Route, { path: SiteRoutes.PATIENTS_ROUTE },
                    React.createElement(EnrollmentListPage, { key: trialId })),
                React.createElement(Route, { path: SiteRoutes.PROTOCOL_ROUTE },
                    React.createElement(ProtocolPage, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.BILLING_CODES_ROUTE },
                    React.createElement(BillingCodesPage, null))))));
    };
    __decorate([
        observable
    ], TrialDetail.prototype, "trialId", void 0);
    TrialDetail = __decorate([
        injectSiteStore,
        injectAccountStore,
        observer
    ], TrialDetail);
    return TrialDetail;
}(Component));
export default withRouter(TrialDetail);
