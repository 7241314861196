import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var ExpandableTableRow = function (props) {
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, { colSpan: props.colSpan, style: {
                padding: "0px",
                borderBottomWidth: "0"
            } },
            React.createElement("div", { style: {
                    maxHeight: props.expanded ? "500px" : "0px",
                    transition: "max-height .5s",
                    overflow: "hidden"
                } },
                React.createElement("div", { style: {
                        borderBottom: "1px solid " + ColorsEnum.BlackLight,
                        padding: "15px 70px"
                    } }, props.children)))));
};
export default ExpandableTableRow;
