var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { computed, observable, runInAction } from "mobx";
import { inject } from "mobx-react";
import { throwHumanlyFriendlyError } from "s~/utils/errorFactory";
var CognitoStore = /** @class */ (function () {
    function CognitoStore(errorModalStore, toastStore, confirmationModalStore, config) {
        var _this = this;
        this.errorModalStore = errorModalStore;
        this.toastStore = toastStore;
        this.confirmationModalStore = confirmationModalStore;
        this.config = config;
        this.Init = function () { return __awaiter(_this, void 0, Promise, function () {
            var Auth, currentSession;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import("@aws-amplify/auth")];
                    case 1:
                        Auth = _a.sent();
                        this.Auth = Auth.default;
                        this.Auth.configure({
                            Auth: {
                                region: this.config.cognitoPoolRegion,
                                userPoolId: this.config.cognitoPoolId,
                                userPoolWebClientId: this.config.cognitoPoolClientId
                            }
                        });
                        return [4 /*yield*/, this.currentSession()];
                    case 2:
                        currentSession = _a.sent();
                        if (currentSession && currentSession.isValid()) {
                            this._loginState = "LOGGED_IN";
                            return [2 /*return*/, true];
                        }
                        if (!currentSession) {
                            this._loginState = "LOGGED_OUT";
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, false];
                }
            });
        }); };
        this.confirmPasswordChangeParams = {};
        this.changePassword = function (oldPassword, newPassword) { return __awaiter(_this, void 0, void 0, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.Auth.currentAuthenticatedUser()];
                    case 1:
                        user = _a.sent();
                        return [4 /*yield*/, this.Auth.changePassword(user, oldPassword, newPassword)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.resetPassword = function (email) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.Auth.forgotPassword(email)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.setNewPassword = function (username, code, password) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.Auth.forgotPasswordSubmit(username, code, password)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.login = function (username, password) { return __awaiter(_this, void 0, Promise, function () {
            var _a, _b, firstName_1, lastName_1, email_1, companyName_1, error_1, code;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.Auth.signIn(username, password)];
                    case 1:
                        _a._cognitoUser = _c.sent();
                        if (this._cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                            _b = this._cognitoUser.challengeParam.userAttributes, firstName_1 = _b["custom:firstName"], lastName_1 = _b["custom:lastName"], email_1 = _b.email, companyName_1 = _b["custom:companyName"];
                            runInAction(function () {
                                _this.confirmPasswordChangeParams = {
                                    firstName: firstName_1,
                                    lastName: lastName_1,
                                    email: email_1,
                                    companyName: companyName_1
                                };
                                _this._loginState = "PASSWORD_CHANGE_REQUIRED";
                            });
                            return [2 /*return*/, false];
                        }
                        if (this._cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                            this._loginState = "PASSWORD_CHANGE_REQUIRED";
                            return [2 /*return*/, false];
                        }
                        this._loginState = "LOGGED_IN";
                        return [2 /*return*/, true];
                    case 2:
                        error_1 = _c.sent();
                        code = error_1.code;
                        if (code === "PasswordResetRequiredException" ||
                            code === "UserNotConfirmedException") {
                            throwHumanlyFriendlyError(error_1);
                        }
                        if (code === "user-not-found") {
                            this.errorModalStore.setError(error_1);
                            return [2 /*return*/, false];
                        }
                        throw new Error("Invalid username or password");
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.logout = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.Auth.signOut()];
                    case 1:
                        _a.sent();
                        this._loginState = "LOGGED_OUT";
                        return [2 /*return*/];
                }
            });
        }); };
        this.completeNewPassword = function (newPassword) { return __awaiter(_this, void 0, void 0, function () {
            var requiredAttributes, _a, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        requiredAttributes = this.cognitoUser.challengeParam.requiredAttributes;
                        _a = this;
                        return [4 /*yield*/, this.Auth.completeNewPassword(this.cognitoUser, newPassword, requiredAttributes)];
                    case 1:
                        _a._cognitoUser = _b.sent();
                        this._loginState = "LOGGED_IN";
                        return [2 /*return*/];
                    case 2:
                        error_2 = _b.sent();
                        throw throwHumanlyFriendlyError(error_2);
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.currentSession = function () { return __awaiter(_this, void 0, Promise, function () {
            var currentSession, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.Auth.currentSession()];
                    case 1:
                        currentSession = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        if (error_3.code === "NotAuthorizedException" ||
                            error_3 === "No current user") {
                            this._loginState = "LOGGED_OUT";
                            return [2 /*return*/, null];
                        }
                        this.errorModalStore.setError(error_3);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/, currentSession];
                }
            });
        }); };
        this.authToken = function () { return __awaiter(_this, void 0, Promise, function () {
            var currentSession, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.currentSession()];
                    case 1:
                        currentSession = _a.sent();
                        if (currentSession) {
                            token = currentSession.getAccessToken().getJwtToken();
                            return [2 /*return*/, token];
                        }
                        return [2 /*return*/, null];
                }
            });
        }); };
        this._loginState = "LOADING";
    }
    Object.defineProperty(CognitoStore.prototype, "cognitoUser", {
        get: function () {
            return this._cognitoUser;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CognitoStore.prototype, "LoginState", {
        get: function () {
            return this._loginState;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], CognitoStore.prototype, "confirmPasswordChangeParams", void 0);
    __decorate([
        computed
    ], CognitoStore.prototype, "cognitoUser", null);
    __decorate([
        observable
    ], CognitoStore.prototype, "_loginState", void 0);
    __decorate([
        computed
    ], CognitoStore.prototype, "LoginState", null);
    return CognitoStore;
}());
export default CognitoStore;
export var injectCognitoStore = inject("cognitoStore");
