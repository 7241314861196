import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import React from 'react';
import PatientCard from '~/components/patient/PatientCardContainer';
import { getDeviceDataRoute, getDevicesRoute } from '~/routing/Routes';
var WearableDataCard = function (props) {
    var enrollment = props.enrollment, siteId = props.siteId, trialId = props.trialId, enrollmentId = props.enrollmentId, patientId = props.patientId;
    return (React.createElement(PatientCard, null,
        React.createElement(FlexContainer, { justifyContent: "space-between", flexWrap: "nowrap" },
            React.createElement(RdiTypography, { variant: "subtitle2" }, "WEARABLE DATA"),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(RdiLink, { to: getDevicesRoute(siteId, patientId, trialId, enrollmentId), xs: true }, "Edit devices"),
            React.createElement("div", { style: { padding: '0px 10px' } }, "|"),
            React.createElement(RdiLink, { to: getDeviceDataRoute(siteId, patientId, trialId, enrollmentId), xs: true }, "View")),
        React.createElement("div", { style: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            } },
            enrollment.devices.map(function (device, index) {
                var latestDatapoint = device.latestDatapoint;
                if (!latestDatapoint)
                    return null;
                return (React.createElement("div", { key: index, style: { marginTop: '4px', marginBottom: '4px' } },
                    React.createElement(FlexContainer, { margin: 8, alignItems: "flex-start" },
                        React.createElement(RdiTypography, { variant: "body2", style: {
                                color: ColorsEnum.Blue,
                                fontWeight: 'bold',
                                minWidth: '20px'
                            } }, device.shortLabel),
                        device.schema.items
                            .filter(function (x) { return x.showOnSummary; })
                            .map(function (item, innerIndex) { return (React.createElement(FlexContainer, { key: innerIndex, alignItems: "flex-start" },
                            React.createElement("div", null,
                                React.createElement(FlexContainer, { margin: 8, alignItems: "center", flexWrap: "wrap" },
                                    React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '0px' } }, latestDatapoint.data[item.slug]),
                                    React.createElement(RdiTypography, { variant: "body2", style: {
                                            fontWeight: 'bold',
                                            color: ColorsEnum.BlackSemidark
                                        } }, item.shortLabel)),
                                React.createElement("div", null,
                                    React.createElement(RdiTypography, { variant: "body2", style: { fontSize: '11px' } }, item.unit))))); }))));
            }),
            !enrollment.latestDeviceData && (React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '0px' } }, "No data recorded"))),
        React.createElement(RdiTypography, { variant: "caption", style: { color: ColorsEnum.BlackLightToMedium } }, enrollment.wearableLastUpdatedString)));
};
export default WearableDataCard;
