var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createStyles, Modal, Paper, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import RdiButton from "s~/components/forms/RdiButton";
import RdiTypography from "s~/components/layout/RdiTypography";
import { ExceptionCodeEnum } from "s~/constants/enums/ExceptionCodeEnum";
var styles = function (theme) {
    var _a;
    return createStyles({
        paper: (_a = {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                minWidth: "300px",
                minHeight: "350px",
                padding: "20px"
            },
            _a[theme.breakpoints.down("xs")] = {
                minWidth: "100%"
            },
            _a)
    });
};
var ErrorModal = function (props) {
    var title = props.title, open = props.open, message = props.message, code = props.code, onClearError = props.onCloseClick, logout = props.logout, classes = props.classes;
    var logoutClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!logout) return [3 /*break*/, 2];
                    return [4 /*yield*/, logout()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    onClearError();
                    return [2 /*return*/];
            }
        });
    }); };
    var showLogout = function () {
        switch (code) {
            case ExceptionCodeEnum.FORBIDDEN:
            case ExceptionCodeEnum.UNAUTHORIZED:
                return true;
            default:
                return false;
        }
    };
    return (React.createElement(Modal, { open: open, style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        } },
        React.createElement(Paper, { className: classes.paper },
            React.createElement(Avatar, null,
                React.createElement(WarningIcon, null)),
            React.createElement(RdiTypography, { variant: "h5" }, title),
            React.createElement(RdiTypography, { variant: "body2" }, message && (message.detail || message.message || message.toString())),
            React.createElement(RdiButton, { onClick: onClearError, style: { marginTop: "10px" } }, "Close"),
            showLogout() && (React.createElement(RdiButton, { onClick: logoutClick, style: { marginTop: "10px" } }, "Log out")))));
};
export default withStyles(styles)(ErrorModal);
