export var MedicationRouteEnum;
(function (MedicationRouteEnum) {
    MedicationRouteEnum["ORAL"] = "oral";
    MedicationRouteEnum["TOPICAL"] = "topical";
    MedicationRouteEnum["SUBCUTANEOUS"] = "subcutaneous";
    MedicationRouteEnum["TRANSDERMAL"] = "transdermal";
    MedicationRouteEnum["INTRAMUSCULAR"] = "intramuscular";
    MedicationRouteEnum["RESPIRATORY"] = "respiratory";
    MedicationRouteEnum["NASAL"] = "nasal";
    MedicationRouteEnum["INTRAVENOUS"] = "intravenous";
    MedicationRouteEnum["OTHER"] = "other";
})(MedicationRouteEnum || (MedicationRouteEnum = {}));
export var MedicationRouteLabelsEnum;
(function (MedicationRouteLabelsEnum) {
    MedicationRouteLabelsEnum["oral"] = "Oral";
    MedicationRouteLabelsEnum["topical"] = "Topical";
    MedicationRouteLabelsEnum["subcutaneous"] = "Subcutaneous";
    MedicationRouteLabelsEnum["transdermal"] = "Transdermal";
    MedicationRouteLabelsEnum["intramuscular"] = "Intramuscular";
    MedicationRouteLabelsEnum["respiratory"] = "Inhalation";
    MedicationRouteLabelsEnum["nasal"] = "Nasal";
    MedicationRouteLabelsEnum["intravenous"] = "Intravenous";
    MedicationRouteLabelsEnum["other"] = "Other";
})(MedicationRouteLabelsEnum || (MedicationRouteLabelsEnum = {}));
