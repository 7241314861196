var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { UserStatusEnum, UserStatusLabelsEnum } from '@cros/shared/constants/enums/UserStatusEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { CircularProgress, createStyles, IconButton, Menu, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getUsersNewRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectUserStore } from '~/stores/UserStore';
var styles = function () { return createStyles({}); };
var UserListPage = /** @class */ (function (_super) {
    __extends(UserListPage, _super);
    function UserListPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.columns = [
            {
                id: 'firstName',
                label: 'First name',
                sortable: false
            },
            {
                id: 'lastName',
                label: 'Last name',
                sortable: false
            },
            {
                id: 'email',
                label: 'Email',
                sortable: false
            },
            {
                id: 'roles',
                label: 'Groups',
                sortable: false
            },
            {
                id: 'status',
                label: 'Status',
                sortable: false
            },
            {
                id: 'actions',
                label: '',
                sortable: false
            }
        ];
        _this.menuRef = null;
        _this.hasShowDelete = true;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var userStore;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userStore = this.props.userStore;
                        return [4 /*yield*/, userStore.listUsers()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, userStore.listRoles()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            _this.menuRef = null;
            history.push(url + "/edit/" + _this.userInEdit.id);
        };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            var userStore;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userStore = this.props.userStore;
                        return [4 /*yield*/, userStore.deleteUserWithConfirmation(this.userInEdit)];
                    case 1:
                        _a.sent();
                        this.menuRef = null;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onShowRestoreConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            var userStore;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userStore = this.props.userStore;
                        return [4 /*yield*/, userStore.restoreUserWithConfirmation(this.userInEdit)];
                    case 1:
                        _a.sent();
                        this.menuRef = null;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleMenuClick = function (event, user) {
            _this.menuRef = event.currentTarget;
            _this.userInEdit = user;
            var accountStore = _this.props.accountStore;
            _this.hasShowDelete = user.id !== accountStore.accountDetail.id;
        };
        _this.rolesChange = function (event, user) { return __awaiter(_this, void 0, Promise, function () {
            var userStore;
            return __generator(this, function (_a) {
                userStore = this.props.userStore;
                user.roles = event ? event.value.map(function (e) { return "" + e.value; }) : [];
                userStore.updateUser(user);
                return [2 /*return*/];
            });
        }); };
        return _this;
    }
    UserListPage.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props, siteId = _c.siteStore.siteId, _d = _c.userStore, roles = _d.roles, usersLoading = _d.usersLoading, users = _d.users, usersCount = _d.usersCount, changeUserFilters = _d.changeUserFilters, userFilters = _d.userFilters, rolesLoading = _d.rolesLoading, userFilterLabels = _d.userFilterLabels, accountStore = _c.accountStore, siteStore = _c.siteStore;
        return (React.createElement(React.Fragment, null,
            React.createElement(FlexContainer, { justifyContent: "space-between", style: { width: '100%', marginBottom: '30px', flexWrap: 'wrap' } },
                React.createElement("div", null,
                    usersCount,
                    " users"),
                React.createElement(RdiButton, { name: "new-user", to: "" + getUsersNewRoute(siteId) }, "Add user")),
            React.createElement(FlexContainer, { style: {
                    marginBottom: '20px',
                    flexWrap: 'wrap'
                }, alignItems: "flex-end", margin: 10 },
                React.createElement(RdiInput, { style: { width: '200px' }, type: "search", name: "search", placeholder: "Search", value: userFilters.search, onChange: function (e) { return changeUserFilters({ search: e.value }); } }),
                React.createElement(RdiInput, { type: "select", label: "Status", name: "filter-status", style: { width: '180px' }, value: userFilters.status, selectedItemLabel: userFilterLabels.status, onChange: function (e) {
                        return e.value
                            ? changeUserFilters({ status: e.value }, { status: e.label })
                            : changeUserFilters({ status: null }, { status: 'All' });
                    }, options: __spreadArrays([
                        { label: 'All', value: null }
                    ], Object.values(UserStatusEnum).map(function (v) { return ({
                        label: UserStatusLabelsEnum[v],
                        value: v
                    }); })) })),
            React.createElement(RdiTable, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeUserFilters, filters: userFilters })); }))),
                !usersLoading && !rolesLoading && (React.createElement(TableBody, null,
                    users.map(function (user, index) { return (React.createElement(TableRow, { key: index },
                        React.createElement(TableCell, null, user.firstName),
                        React.createElement(TableCell, null, user.lastName),
                        React.createElement(TableCell, null, user.email),
                        React.createElement(TableCell, { style: { padding: '0px', width: '200px' } },
                            React.createElement(RdiInput, { type: "select-multiple", permissions: [
                                    {
                                        aclOp: AclOpEnum.UPDATE,
                                        aclModule: AclModuleEnum.USER
                                    }
                                ], borderWidth: "0px", name: "roles", value: user.roles.map(function (r) { return ({
                                    value: r,
                                    label: r
                                }); }), onChange: function (e) { return _this.rolesChange(e, user); }, autoFocus: true, style: { marginBottom: '0px' }, options: roles.map(function (r) { return ({
                                    label: r.name,
                                    value: r.name
                                }); }), fullWidth: true, placeholder: "Select group..." })),
                        React.createElement(TableCell, null, user.disabled ? (React.createElement(RdiTypography, { variant: "body2", style: { color: ColorsEnum.Error } }, "Archived")) : (React.createElement(RdiTypography, { variant: "body2", style: { color: ColorsEnum.IconsGreen } }, "Active"))),
                        React.createElement(TableCell, { style: { padding: '0px' } },
                            React.createElement("div", { style: {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                } },
                                React.createElement(React.Fragment, null,
                                    React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, user); } },
                                        React.createElement(MoreVertIcon, null))))))); }),
                    !usersLoading && users.length === 0 && (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: 7 }, "No results")))))),
            !usersLoading && (React.createElement(RdiTablePagination, { count: usersCount, rowsPerPage: userFilters.rowsPerPage, page: userFilters.page, onChangePage: function (e, newPage) { return changeUserFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                    return changeUserFilters({
                        rowsPerPage: parseInt(e.target.value, 10),
                        page: 1
                    });
                } })),
            React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, onClose: function () {
                    _this.menuRef = null;
                }, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                        {
                            aclOp: AclOpEnum.UPDATE,
                            aclModule: AclModuleEnum.USER
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Edit")),
                this.hasShowDelete && !((_a = this.userInEdit) === null || _a === void 0 ? void 0 : _a.disabled) && (React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                        {
                            aclOp: AclOpEnum.DELETE,
                            aclModule: AclModuleEnum.USER
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Delete"))),
                ((_b = this.userInEdit) === null || _b === void 0 ? void 0 : _b.disabled) && (React.createElement(RdiMenuItem, { onClick: this.onShowRestoreConfirmation, permissions: [
                        {
                            aclOp: AclOpEnum.DELETE,
                            aclModule: AclModuleEnum.USER
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Restore")))),
            usersLoading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                React.createElement(CircularProgress, null)))));
    };
    __decorate([
        observable
    ], UserListPage.prototype, "menuRef", void 0);
    __decorate([
        observable
    ], UserListPage.prototype, "userInEdit", void 0);
    __decorate([
        observable
    ], UserListPage.prototype, "hasShowDelete", void 0);
    UserListPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectUserStore,
        observer
    ], UserListPage);
    return UserListPage;
}(Component));
export default withStyles(styles)(withRouter(UserListPage));
