var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BillStatusEnum } from '@cros/shared/constants/enums/BillStatusEnum';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { getZeroPaddedMonth } from '@cros/shared/utils/dateUtils';
import { assignFilterLabels, assignFiltersFromQueryString, getURLSearchObject } from '@cros/shared/utils/queryStringUtils';
import { getYear } from 'date-fns';
import { observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import BillService from '~/services/BillService';
var BillStore = /** @class */ (function () {
    function BillStore(siteStore, searchString, history) {
        var _this = this;
        this.siteStore = siteStore;
        this.searchString = searchString;
        this.history = history;
        this.billsCount = 0;
        this.billsCounts = {};
        this.billsLoading = true;
        this.bills = [];
        this.billFilters = {
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            orderDir: 'DESC',
            orderBy: '$metadata__txTime',
            patientId: undefined,
            trialId: undefined,
            dateFrom: getYear(new Date()) + "-" + getZeroPaddedMonth(new Date()),
            dateTo: getYear(new Date()) + "-" + getZeroPaddedMonth(new Date())
        };
        this.billFilterLabels = {
            patientId: 'All',
            trialId: 'All'
        };
        this.listBills = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, resp, _a, _b, _c, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        this.billsLoading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = BillService).listBills;
                        _c = [siteId, this.billFilters];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.bills = [];
                        this.billsCount = 0;
                        this.errorModalStore.setError(e_1);
                        this.billsLoading = false;
                        return [2 /*return*/, false];
                    case 5:
                        runInAction(function () {
                            _this.bills = resp.items;
                            _this.billsCount = resp.count;
                            _this.billsLoading = false;
                            _this.billsCounts = resp.counts;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.getBillById = function (id) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = BillService).getBillById;
                        _c = [id, siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.errorModalStore.setError(e_2);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, resp];
                }
            });
        }); };
        this.changeBillFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.billFilters = __assign(__assign({}, this.billFilters), newFilters);
                this.billFilterLabels = __assign(__assign({}, this.billFilterLabels), newLabels);
                this.listBills();
                this.history.push(getURLSearchObject(this.billFilters));
                return [2 /*return*/];
            });
        }); };
        this.listBillFilters = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_3;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = BillService).listBillFilters;
                        _c = [siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _d.sent();
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/];
                    case 5:
                        this.availableBillFilters = resp;
                        assignFilterLabels(this.billFilters, this.billFilterLabels, this.availableBillFilters);
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateBillInsurance = function (bill, insurance) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updatedBill, _b, _c, _d, e_4, removeBill;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillService).updateBillInsurance;
                        _d = [insurance,
                            siteId,
                            bill.id,
                            insurance.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updatedBill = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _e.sent();
                        if (e_4.code === 'validation-exception') {
                            throw e_4;
                        }
                        errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 5:
                        removeBill = false;
                        return [4 /*yield*/, runInAction(function () {
                                // check if we should move bill between tabs
                                if (bill.status !== updatedBill.status) {
                                    if (bill.status === BillStatusEnum.CLOSED) {
                                        removeBill = true;
                                        _this.billsCounts.closed -= 1;
                                        _this.billsCounts.pending = (_this.billsCounts.pending || 0) + 1;
                                    }
                                    else if (updatedBill.status === BillStatusEnum.CLOSED) {
                                        removeBill = true;
                                        _this.billsCounts.pending = (_this.billsCounts.pending || 0) - 1;
                                        _this.billsCounts.closed = (_this.billsCounts.closed || 0) + 1;
                                    }
                                    bill.status = updatedBill.status;
                                }
                                if (removeBill) {
                                    var existingIndex = _this.bills.findIndex(function (log) { return log.id === updatedBill.id; });
                                    if (existingIndex >= 0) {
                                        _this.bills.splice(existingIndex, 1);
                                    }
                                }
                            })];
                    case 6:
                        _e.sent();
                        toastStore.addToast(new ToastMessage("Insurance " + insurance.name + " updated"));
                        return [2 /*return*/, updatedBill];
                }
            });
        }); };
        this.updateFiles = function (bill, files) { return __awaiter(_this, void 0, Promise, function () {
            var _a, errorModalStore, toastStore, success, calls, _i, files_1, f, e_5;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.siteStore, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        success = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        calls = [];
                        for (_i = 0, files_1 = files; _i < files_1.length; _i++) {
                            f = files_1[_i];
                            // if the file is new
                            if (f.file) {
                                calls.push(this.uploadFile(f.file, bill.id));
                            }
                            // if the file has been marked for deletion
                            if (f.deleted) {
                                calls.push(this.deleteFile(f.id, bill.id));
                            }
                        }
                        return [4 /*yield*/, Promise.all(calls)];
                    case 2:
                        _b.sent();
                        success = true;
                        return [3 /*break*/, 4];
                    case 3:
                        e_5 = _b.sent();
                        if (e_5.code === 'validation-exception') {
                            throw e_5;
                        }
                        errorModalStore.setError(e_5);
                        return [2 /*return*/, false];
                    case 4:
                        runInAction(function () {
                            var existingIndex = _this.bills.findIndex(function (b) { return b.id === bill.id; });
                            if (existingIndex >= 0) {
                                _this.bills[existingIndex].records = files;
                            }
                        });
                        toastStore.addToast(new ToastMessage("Bill files updated"));
                        return [2 /*return*/, success];
                }
            });
        }); };
        this.deleteFile = function (fileId, billId) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, errorModalStore, _b, _c, _d, e_6;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillService).deleteBillFile;
                        _d = [fileId, siteId, billId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _e.sent();
                        errorModalStore.setError(e_6);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.uploadFile = function (file, billId) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, f, _b, _c, _d, e_7;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillService).uploadBillFile;
                        _d = [file, siteId, billId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        f = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_7 = _e.sent();
                        errorModalStore.setError(e_7);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, f];
                }
            });
        }); };
        assignFiltersFromQueryString(searchString, this.billFilters);
    }
    Object.defineProperty(BillStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BillStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BillStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BillStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], BillStore.prototype, "billsCount", void 0);
    __decorate([
        observable
    ], BillStore.prototype, "billsCounts", void 0);
    __decorate([
        observable
    ], BillStore.prototype, "billsLoading", void 0);
    __decorate([
        observable
    ], BillStore.prototype, "bills", void 0);
    __decorate([
        observable
    ], BillStore.prototype, "availableBillFilters", void 0);
    __decorate([
        observable
    ], BillStore.prototype, "billFilters", void 0);
    __decorate([
        observable
    ], BillStore.prototype, "billFilterLabels", void 0);
    return BillStore;
}());
export default BillStore;
export var injectBillStore = inject('billStore');
