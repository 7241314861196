export var BillInsuranceStatusEnum;
(function (BillInsuranceStatusEnum) {
    /**
     * The bill is still being compiled
     */
    BillInsuranceStatusEnum["DRAFT"] = "draft";
    /**
     * The bill was sent, can not be changed now
     */
    BillInsuranceStatusEnum["PENDING"] = "pending";
    /**
     * We got a response
     */
    BillInsuranceStatusEnum["ACCEPTED"] = "accepted";
    BillInsuranceStatusEnum["REJECTED"] = "rejected";
})(BillInsuranceStatusEnum || (BillInsuranceStatusEnum = {}));
export var BillInsuranceStatusLabelsEnum;
(function (BillInsuranceStatusLabelsEnum) {
    BillInsuranceStatusLabelsEnum["draft"] = "draft";
    BillInsuranceStatusLabelsEnum["pending"] = "pending";
    BillInsuranceStatusLabelsEnum["accepted"] = "accepted";
    BillInsuranceStatusLabelsEnum["rejected"] = "rejected";
})(BillInsuranceStatusLabelsEnum || (BillInsuranceStatusLabelsEnum = {}));
