var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import RdiFileDescription from '@cros/shared/components/misc/RdiFileDescription';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import { getDateFromTimeAndDate } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import config from '~/config';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectInsuranceStore } from '~/stores/PatientStore/InsuranceStore';
import { injectSiteStore } from '~/stores/SiteStore';
var AddInsurance = /** @class */ (function (_super) {
    __extends(AddInsurance, _super);
    function AddInsurance() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = {
            name: {
                value: '',
                error: null
            },
            startDate: {
                value: null,
                error: null,
                rule: 'required|date'
            },
            stopDate: {
                value: null,
                error: null,
                rule: 'date'
            },
            files: {
                value: null,
                error: null,
                rule: 'required|array|min:1'
            },
            medicalRecordId: {
                value: null,
                error: null
            },
            trialId: {
                value: null,
                label: '',
                error: null,
                rule: 'required'
            }
        };
        _this.saving = false;
        _this.errorMessage = '';
        _this.loading = true;
        _this.hasErrors = false;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var insuranceStore, insurance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.insuranceId) return [3 /*break*/, 2];
                        insuranceStore = this.props.insuranceStore;
                        this.loading = true;
                        return [4 /*yield*/, insuranceStore.getInsuranceById(this.insuranceId)];
                    case 1:
                        insurance = _a.sent();
                        if (!insurance) {
                            return [2 /*return*/];
                        }
                        this.assignData(insurance);
                        _a.label = 2;
                    case 2:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignData = function (insurance) {
            var _a, _b;
            var patient = _this.props.patientStore.patient;
            _this.data.name.value = insurance.name;
            _this.data.files.value = insurance.medicalRecord ? insurance.medicalRecord.records : [];
            _this.data.startDate.value = insurance.startDate;
            _this.data.medicalRecordId.value = insurance.medicalRecordId;
            _this.data.stopDate.value = insurance.stopDate;
            _this.data.trialId.value = insurance.trialId;
            _this.data.trialId.label = (_b = (_a = patient.enrollments.find(function (e) { return e.trialId === insurance.trialId; })) === null || _a === void 0 ? void 0 : _a.trial) === null || _b === void 0 ? void 0 : _b.name;
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            if (_this.isEdit) {
                history.push("" + url.split("/edit/" + _this.insuranceId)[0]);
            }
            else {
                history.push("" + url.split("/new")[0]);
            }
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var o, newInsurance, insuranceStore, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        o = this.getObjectToSave();
                        insuranceStore = this.props.insuranceStore;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        this.saving = true;
                        if (!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, insuranceStore.updateInsurance(o, this.data.files.value)];
                    case 2:
                        newInsurance = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, insuranceStore.addInsurance(o, this.data.files.value)];
                    case 4:
                        newInsurance = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        this.errorMessage = error_1.detail || error_1.message || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 8];
                    case 7:
                        this.saving = false;
                        if (!newInsurance) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var _a;
            var midnight = new Date();
            midnight.setHours(0, 0, 0, 0);
            var patient = _this.props.patientStore.patient;
            var insurance = {
                id: _this.insuranceId,
                name: _this.data.name.value,
                startDate: _this.data.startDate.value
                    ? getDateFromTimeAndDate(midnight, _this.data.startDate.value, true)
                    : undefined,
                stopDate: _this.data.stopDate.value
                    ? getDateFromTimeAndDate(midnight, _this.data.stopDate.value, true)
                    : undefined,
                enrollmentId: (_a = patient.enrollments.find(function (e) { return e.trialId === _this.data.trialId.value; })) === null || _a === void 0 ? void 0 : _a.id,
                medicalRecordId: _this.data.medicalRecordId.value
            };
            return insurance;
        };
        _this.validateForm = function (validationErrors) {
            var _a;
            var isValid = utilValidate(_this.data, _this.mapFieldToInternal, validationErrors, true);
            if (!((_a = _this.data.files.value) === null || _a === void 0 ? void 0 : _a.some(function (f) { return !f.deleted; }))) {
                _this.data.files.error = 'Required';
                return true;
            }
            return isValid;
        };
        _this.mapFieldToInternal = function (fieldName) {
            switch (fieldName) {
                case 'medicalRecordId':
                    return 'files';
                default:
                    return fieldName;
            }
        };
        _this.uploadFile = function (event) {
            var _a, _b;
            var f = event.files && event.files.length ? event.files[0] : undefined;
            if (!_this.data.files.value) {
                _this.data.files.value = [];
            }
            _this.data.files.value.push(new UploadedFile({
                originalname: f.name,
                mimetype: f.type,
                size: f.size,
                url: null,
                file: f
            }));
            if ((_b = (_a = event.event) === null || _a === void 0 ? void 0 : _a.target) === null || _b === void 0 ? void 0 : _b.value) {
                event.event.target.value = null;
            }
        };
        _this.removeFile = function (item) {
            if (_this.data.files.value) {
                _this.data.files.value.forEach(function (x) {
                    if (x.originalname === item.originalname) {
                        x.deleted = true;
                    }
                });
            }
        };
        return _this;
    }
    Object.defineProperty(AddInsurance.prototype, "isEdit", {
        get: function () {
            return !!this.insuranceId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddInsurance.prototype, "insuranceId", {
        get: function () {
            var params = this.props.match.params;
            return params.insuranceId;
        },
        enumerable: false,
        configurable: true
    });
    AddInsurance.prototype.render = function () {
        var _this = this;
        var patient = this.props.patientStore.patient;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, (this.isEdit ? 'Edit' : 'New') + " insurance"),
            React.createElement(FlexContainer, { style: { marginTop: '10px' }, margin: 15 },
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient === null || patient === void 0 ? void 0 : patient.fullName),
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
            React.createElement(RdiLine, null),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent },
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "text", label: "Name", name: "name", value: this.data.name.value, errorText: this.data.name.error, onChange: function (e) { return _this.fieldChange(e, 'name'); } })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { label: "Start date", type: "date", name: "startDate", value: this.data.startDate.value, errorText: this.data.startDate.error, onChange: function (e) { return _this.fieldChange(e, 'startDate'); }, style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(RdiInput, { label: "End date", type: "date", name: "stopDate", value: this.data.stopDate.value, errorText: this.data.stopDate.error, onChange: function (e) { return _this.fieldChange(e, 'stopDate'); }, style: { width: 'calc(50% - 15px)' } })),
                React.createElement(InputRow, null,
                    React.createElement(React.Fragment, null,
                        React.createElement(RdiInput, { type: "select", label: "Trial", name: "trialId", disabled: this.isEdit, value: this.data.trialId.value, selectedItemLabel: this.data.trialId.label, errorText: this.data.trialId.error, onChange: function (e) { return _this.fieldChange(e, 'trialId'); }, options: patient.enrollments.map(function (e) {
                                var _a;
                                return ({
                                    label: (_a = e.trial) === null || _a === void 0 ? void 0 : _a.name,
                                    value: e.trialId
                                });
                            }), style: { width: 'calc(50% - 15px)' } }),
                        React.createElement("div", { style: { width: 'calc(50% - 15px)' } }))),
                React.createElement(RdiInputLabel, null, "Files"),
                this.data.files.value &&
                    this.data.files.value
                        .filter(function (x) { return !x.deleted; })
                        .map(function (item, index) { return (React.createElement(RdiFileDescription, { config: config, key: index, file: item, onRemove: function () { return _this.removeFile(item); } })); }),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "file", label: "Upload insurance cards", name: "file", value: null, errorText: this.data.files.error, onChange: this.uploadFile, inputProps: { accept: 'image/*,application/pdf,.xls*,.doc*,.txt' }, fullWidth: true }),
                    React.createElement("div", { style: { width: '100%' } })),
                React.createElement(RdiLine, null),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, submit: true }, this.isEdit ? 'Update' : 'Add')))));
    };
    __decorate([
        observable
    ], AddInsurance.prototype, "data", void 0);
    __decorate([
        observable
    ], AddInsurance.prototype, "saving", void 0);
    __decorate([
        observable
    ], AddInsurance.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], AddInsurance.prototype, "loading", void 0);
    __decorate([
        observable
    ], AddInsurance.prototype, "hasErrors", void 0);
    __decorate([
        computed
    ], AddInsurance.prototype, "isEdit", null);
    AddInsurance = __decorate([
        injectPatientStore,
        injectInsuranceStore,
        injectSiteStore,
        observer
    ], AddInsurance);
    return AddInsurance;
}(Component));
var InputRow = function (props) { return (React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30, flexWrap: "wrap" }, props.children)); };
export default withRouter(AddInsurance);
