var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiCircle from '@cros/shared/components/misc/RdiCircle';
import RdiListItem from '@cros/shared/components/misc/RdiListItem';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import { PublicRoutes } from '@cros/shared/components/routing/PublicRoutes';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import SizingEnum from '@cros/shared/constants/styling/SizingEnum';
import { Button, List, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MaterialWarningIcon from '@material-ui/icons/Warning';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { getAuditRoute, getBillingApprovalRoute, getBillingOverviewRoute, getChangePasswordRoute, getClinicDocumentsRoute, getClinicStatisticDashboardRoute, getPatientsAllRoute, getPaymentsRoute, getPresentationLibraryRoute, getSettingsRoute, getSmsNotificationsRoute, getTasksRoute, getTrialRoute, getUsersRoute } from '~/routing/Routes';
import Accounting from '~/static/Accounting.svg';
import AuditLogIcon from '~/static/AuditLogIcon.svg';
import BillingApprovalIcon from '~/static/BillingApprovalIcon.svg';
import BillingOverviewIcon from '~/static/BillingOverviewIcon.svg';
import ClinicDocuments from '~/static/ClinicDocuments.svg';
import Management from '~/static/Management.svg';
import PatientsIcon from '~/static/PatientsIcon.svg';
import PaymentsIcon from '~/static/PaymentsIcon.svg';
import ProtocolIcon from '~/static/ProtocolIcon.svg';
import SettingsIcon from '~/static/SettingsIcon.svg';
import UsersIcon from '~/static/UsersIcon.svg';
import WarningIcon from '~/static/WarningIcon.svg';
import Logo from '~/static/logo.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: SizingEnum.drawerWidth
        },
        // necessary for content to be below app bar
        navLinkActive: {
            backgroundColor: "" + ColorsEnum.BlueDark,
            '&:hover': {
                backgroundColor: "rgba(41,96,148,0.91)"
            },
            '& span': {
                color: 'white !important'
            },
            '& .circle': {
                backgroundColor: ColorsEnum.White,
                color: ColorsEnum.BlueDark
            },
            '& img': {
                filter: 'invert(100)'
            }
        },
        accountButton: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '33px',
            padding: '0px',
            marginBottom: '0px'
        },
        names: {
            flex: '1',
            alignItems: 'flex-start',
            textAlign: 'left',
            paddingLeft: '25px',
            maxWidth: '100%',
            wordBreak: 'break-word'
        },
        moreAccountContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '50px',
            opacity: 0.25
        },
        links: {
            marginTop: '30px',
            flex: '1'
        },
        logo: {
            marginLeft: '25px',
            marginTop: '33px',
            width: '94px'
        },
        navFooter: {
            marginLeft: '25px',
            marginRight: '25px',
            marginBottom: '20px'
        },
        navFooterLinks: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px'
        },
        subNav: {
            marginLeft: '20px'
        }
    });
};
var NavigationDrawer = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(), anchorEl = _f[0], setAnchorEl = _f[1];
    var _g = useState(true), trialsExpanded = _g[0], setTrialsExpanded = _g[1];
    var _h = useState(false), accountingExanded = _h[0], setAccountingExanded = _h[1];
    var _j = useState(false), managementExpanded = _j[0], setManagementExpanded = _j[1];
    var history = useHistory();
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var logoutClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accountStore, siteStore;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accountStore = props.accountStore, siteStore = props.siteStore;
                    handleClose();
                    return [4 /*yield*/, accountStore.logout()];
                case 1:
                    _a.sent();
                    siteStore.clear();
                    return [2 /*return*/];
            }
        });
    }); };
    var accountDetail = props.accountStore.accountDetail, _k = props.siteStore, siteId = _k.siteId, trials = _k.trials, siteInfo = _k.siteInfo, classes = props.classes, accountStore = props.accountStore, siteStore = props.siteStore, handleDrawerToggle = props.handleDrawerToggle;
    var onMenuItemClick = function (link) {
        if (link) {
            history.push(link);
        }
        handleClose();
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(Link, { className: classes.logo, to: "/" },
            React.createElement("img", { alt: "img", src: Logo })),
        React.createElement(Menu, { className: "editMenuDropdown", keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, anchorEl: anchorEl, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, getContentAnchorEl: null },
            React.createElement(RdiMenuItem, { onClick: function () { return onMenuItemClick(getChangePasswordRoute(siteId)); } },
                React.createElement(RdiTypography, { variant: "body2" }, "Change password")),
            React.createElement(RdiMenuItem, { onClick: function () { return onMenuItemClick(getSmsNotificationsRoute(siteId)); } },
                React.createElement(RdiTypography, { variant: "body2" }, "SMS notifications")),
            React.createElement(RdiMenuItem, { onClick: function (e) {
                    logoutClick();
                    handleDrawerToggle(e);
                } },
                React.createElement(RdiTypography, { variant: "body2" }, "Log out"))),
        React.createElement(Button, { className: classes.accountButton, "aria-controls": "simple-menu", "aria-haspopup": "true", onClick: function (event) { return handleClick(event); }, style: { height: 'auto', minHeight: '57px' } },
            React.createElement("div", { className: classes.names },
                React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium, style: { padding: '0px' } }, accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.fullName),
                React.createElement(RdiTypography, { variant: "body2" }, (_a = accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.company) === null || _a === void 0 ? void 0 : _a.name)),
            React.createElement("div", { className: classes.moreAccountContainer },
                React.createElement(MoreVertIcon, null))),
        React.createElement(List, { className: classes.links },
            React.createElement(RdiListItem, { button: true, component: NavLink, to: getClinicStatisticDashboardRoute(siteId), activeClassName: classes.navLinkActive, id: "patients-link", onClick: handleDrawerToggle, permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.COMPANY_STATISTICS
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: AuditLogIcon })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, "Dashboard")),
            React.createElement(RdiListItem, { button: true, component: NavLink, to: getPatientsAllRoute(siteId), activeClassName: classes.navLinkActive, id: "patients-link", onClick: handleDrawerToggle },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: PatientsIcon })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, "Patients")),
            React.createElement(RdiListItem, { button: true, id: "trials-link", onClick: function () {
                    setTrialsExpanded(!trialsExpanded);
                }, permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.TRIAL
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: ProtocolIcon })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                    "Trials",
                    React.createElement(ListItemSecondaryAction, null,
                        React.createElement(RdiCircle, { radius: 20, color: "transparent", style: { width: '20px' } },
                            !trialsExpanded && React.createElement(ExpandMore, null),
                            trialsExpanded && React.createElement(ExpandLess, null))))),
            trialsExpanded &&
                trials.map(function (trial, index) { return (React.createElement(RdiListItem, { button: true, key: index, component: NavLink, to: getTrialRoute(siteId, trial.id), activeClassName: classes.navLinkActive, style: { paddingLeft: '40px' }, permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.TRIAL
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, onClick: handleDrawerToggle },
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                        React.createElement("span", { style: { fontSize: '12px' } }, trial.name)))); }),
            React.createElement(RdiListItem, { button: true, component: NavLink, to: getTasksRoute(siteId), activeClassName: classes.navLinkActive, id: "tasks-link", permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.TASK
                    },
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.PROVIDER
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: WarningIcon })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                    "Tasks",
                    React.createElement(ListItemSecondaryAction, null,
                        React.createElement(FlexContainer, { margin: 7 },
                            ((_b = siteInfo.counts) === null || _b === void 0 ? void 0 : _b.alarmsCount) > 0 && (React.createElement(MaterialWarningIcon, { style: { color: ColorsEnum.Error } })),
                            React.createElement(NavCount, { count: siteInfo.counts ? (_c = siteInfo.counts) === null || _c === void 0 ? void 0 : _c.allTasksCount : 0 }))))),
            React.createElement(RdiListItem, { button: true, component: NavLink, to: getClinicDocumentsRoute(siteId), activeClassName: classes.navLinkActive, id: "clinic-documents-link", permissions: [
                    {
                        aclOp: AclOpEnum.UPDATE,
                        aclModule: AclModuleEnum.CLINIC_DOCUMENTS
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: BillingOverviewIcon })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, "Clinic documents")),
            React.createElement(RdiListItem, { button: true, component: NavLink, to: getPresentationLibraryRoute(siteId), activeClassName: classes.navLinkActive, id: "presentation-library-link", permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.CLINIC_DOCUMENTS
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: ClinicDocuments })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, "Presentation library")),
            React.createElement(RdiListItem, { button: true, id: "trials-link", onClick: function () {
                    setAccountingExanded(!accountingExanded);
                }, permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.PAYMENT
                    },
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.CLAIM
                    },
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.BILLING
                    }
                ], aclCheck: function (permissions) {
                    return permissions.filter(function (permission) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck([permission], siteStore.siteId); }).length > 0;
                }, aclHide: true },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: Accounting })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                    "Accounting",
                    React.createElement(ListItemSecondaryAction, null,
                        React.createElement(RdiCircle, { radius: 20, color: "transparent", style: { width: '20px' } },
                            !accountingExanded && React.createElement(ExpandMore, null),
                            accountingExanded && React.createElement(ExpandLess, null))))),
            accountingExanded && (React.createElement("div", { className: classes.subNav },
                React.createElement(RdiListItem, { button: true, component: NavLink, to: getPaymentsRoute(siteId), activeClassName: classes.navLinkActive, id: "payments-link", permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.PAYMENT
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                    React.createElement(ListItemIcon, null,
                        React.createElement("img", { alt: "img", src: PaymentsIcon })),
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                        React.createElement("span", { style: { fontSize: '12px' } }, "Payments"),
                        React.createElement(ListItemSecondaryAction, null,
                            React.createElement(NavCount, { count: (_d = siteInfo.counts) === null || _d === void 0 ? void 0 : _d.paymentsCount })))),
                React.createElement(RdiListItem, { button: true, component: NavLink, to: getBillingApprovalRoute(siteId), activeClassName: classes.navLinkActive, id: "billing-approval-link", permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.PROVIDER
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                    React.createElement(ListItemIcon, null,
                        React.createElement("img", { alt: "img", src: BillingApprovalIcon })),
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                        React.createElement("span", { style: { fontSize: '12px' } }, "Billing code approval"),
                        React.createElement(ListItemSecondaryAction, null,
                            React.createElement(FlexContainer, { margin: 7 },
                                React.createElement(NavCount, { count: (_e = siteInfo.counts) === null || _e === void 0 ? void 0 : _e.billedItemsNeedsApprovalCount }))))),
                React.createElement(RdiListItem, { button: true, component: NavLink, to: getBillingOverviewRoute(siteId), activeClassName: classes.navLinkActive, id: "billing-overview-link", permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.BILLING
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                    React.createElement(ListItemIcon, null,
                        React.createElement("img", { alt: "img", src: BillingOverviewIcon })),
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                        React.createElement("span", { style: { fontSize: '12px' } }, "Billing overview"))))),
            React.createElement(RdiListItem, { button: true, id: "trials-link", onClick: function () {
                    setManagementExpanded(!managementExpanded);
                }, permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.USER
                    },
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.JOURNAL
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: Management })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                    "Management",
                    React.createElement(ListItemSecondaryAction, null,
                        React.createElement(RdiCircle, { radius: 20, color: "transparent", style: { width: '20px' } },
                            !managementExpanded && React.createElement(ExpandMore, null),
                            managementExpanded && React.createElement(ExpandLess, null))))),
            managementExpanded && (React.createElement("div", { className: classes.subNav },
                React.createElement(RdiListItem, { button: true, component: NavLink, to: getAuditRoute(siteId), activeClassName: classes.navLinkActive, id: "audit-link", permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.JOURNAL
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                    React.createElement(ListItemIcon, null,
                        React.createElement("img", { alt: "img", src: AuditLogIcon })),
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                        React.createElement("span", { style: { fontSize: '12px' } }, "Audit log"))),
                React.createElement(RdiListItem, { button: true, component: NavLink, to: getUsersRoute(siteId), activeClassName: classes.navLinkActive, id: "users-link", permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.USER_MANAGEMENT
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                    React.createElement(ListItemIcon, null,
                        React.createElement("img", { alt: "img", src: UsersIcon })),
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } },
                        React.createElement("span", { style: { fontSize: '12px' } }, "User management"))))),
            React.createElement(RdiListItem, { button: true, component: NavLink, to: getSettingsRoute(siteId), activeClassName: classes.navLinkActive, id: "settings-link", permissions: [
                    {
                        aclOp: AclOpEnum.UPDATE,
                        aclModule: AclModuleEnum.SETTINGS
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true, onClick: handleDrawerToggle },
                React.createElement(ListItemIcon, null,
                    React.createElement("img", { alt: "img", src: SettingsIcon })),
                React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, "Settings"))),
        React.createElement("div", { className: classes.navFooter },
            React.createElement(RdiTypography, { variant: "caption" }, "About CROS"),
            React.createElement("div", { className: classes.navFooterLinks },
                React.createElement(NavFooterLink, { label: "Privacy", to: PublicRoutes.PRIVACY }),
                React.createElement(NavFooterLink, { label: "Terms", to: PublicRoutes.TERMS }),
                React.createElement(NavFooterLink, { label: "Contact us", to: "#" })))));
};
var NavFooterLink = function (props) { return (React.createElement(Link, { style: {
        textDecoration: 'none'
    }, to: props.to },
    React.createElement(RdiTypography, { variant: "caption", style: { padding: '0px' } }, props.label))); };
var NavCount = function (props) {
    var count = props.count;
    return (React.createElement(RdiCircle, { radius: 20, color: ColorsEnum.Blue, fontColor: ColorsEnum.White }, count));
};
export default withStyles(styles)(injectAccountStore(injectSiteStore(observer(NavigationDrawer))));
