var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LinearProgress } from "@material-ui/core";
import React from "react";
import FlexContainer from "s~/components/misc/FlexContainer";
var ProgressWithStatus = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement(FlexContainer, { flexWrap: "wrap", margin: 10, style: { textAlign: "center" } },
        React.createElement(LinearProgress, { style: __assign({ width: "calc(100% - 45px)" }, props.progressStyle), color: props.value === 100 ? "secondary" : "primary", value: props.value, variant: "determinate" }),
        React.createElement("span", { style: { width: "35px" } },
            props.value,
            "%")))); };
export default ProgressWithStatus;
