import { FormControlLabel } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import React from "react";
import RdiJsxParser from "s~/components/layout/RdiJsxParser";
import RdiTypography from "s~/components/layout/RdiTypography";
var RdiRadio = function (props) {
    var onChange = function (event, checked) {
        props.onChange(checked);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormControlLabel, { value: "female", control: React.createElement(Radio, { style: props.radioStyle, color: "primary", value: props.value, checked: props.value, onChange: onChange, disabled: props.disabled ? props.disabled : false, name: props.name }), label: React.createElement(RdiTypography, { style: props.labelStyle, variant: "body2" },
                React.createElement(RdiJsxParser, null, props.label)) })));
};
export default RdiRadio;
