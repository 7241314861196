import { createStyles, withStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import FlexContainer from "s~/components/misc/FlexContainer";
import { PublicRoutes } from "s~/components/routing/PublicRoutes";
import RdiLink from "s~/components/routing/RdiLink";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import logo from "s~/static/logo.svg";
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        root: {
            width: "100%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: function (props) {
                return props.white ? ColorsEnum.White : undefined;
            }
        },
        inner: (_a = {
                width: "100%",
                display: "flex",
                padding: "23px"
            },
            _a[theme.breakpoints.down("xs")] = {
                paddingBottom: "7px"
            },
            _a),
        footer: {
            width: "100%",
            display: "flex",
            paddingLeft: "25px",
            paddingRight: "25px",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "20px 0px"
        },
        copyright: (_b = {},
            _b[theme.breakpoints.down("xs")] = {
                marginBottom: "20px",
                marginRight: "100px"
            },
            _b)
    });
};
var PublicPageWrapper = function (props) {
    var classes = props.classes, style = props.style;
    return (React.createElement("div", { className: classes.root, style: style },
        React.createElement("div", { className: classes.inner },
            React.createElement(Link, { to: "/" },
                React.createElement("img", { alt: "img", src: logo }))),
        React.createElement("div", { style: { flex: 1, display: "flex" } }, props.children),
        React.createElement("div", { className: classes.footer },
            React.createElement("div", { className: classes.copyright },
                React.createElement(RdiLink, { style: { color: ColorsEnum.BlackMedium }, to: "#" }, "\u00A9 2022 MediSci")),
            React.createElement(FlexContainer, { margin: 20 },
                React.createElement(RdiLink, { to: PublicRoutes.PRIVACY }, "Privacy"),
                React.createElement(RdiLink, { to: PublicRoutes.TERMS }, "Terms and conditions"),
                React.createElement(RdiLink, { to: "#" }, "Contact us")))));
};
export default withStyles(styles)(PublicPageWrapper);
