var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { getTrialRoute, SiteRoutes } from '~/routing/Routes';
import { injectSiteStore } from '~/stores/SiteStore';
import TrialDetail from './trial-detail/TrialDetail';
var TrialListPage = /** @class */ (function (_super) {
    __extends(TrialListPage, _super);
    function TrialListPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TrialListPage.prototype.render = function () {
        var _a = this.props.siteStore, firstTrialId = _a.firstTrialId, siteId = _a.siteId;
        return (React.createElement(React.Fragment, null,
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: SiteRoutes.TRIAL_LIST_ROUTE }, firstTrialId && React.createElement(Redirect, { to: getTrialRoute(siteId, firstTrialId) })),
                React.createElement(Route, { path: SiteRoutes.TRIAL_ROUTE },
                    React.createElement(TrialDetail, null)))));
    };
    TrialListPage = __decorate([
        injectSiteStore
    ], TrialListPage);
    return TrialListPage;
}(Component));
export default withRouter(TrialListPage);
