var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormHelperText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { RdiInputWrapper } from "s~/components/forms/RdiInput";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import RdiInputLabel from "./RdiInputLabel";
export var RdiSelectInput = function (props) {
    var isValid = props.isValid, label = props.label, tooltip = props.tooltip, value = props.value, selectedItemLabel = props.selectedItemLabel, placeholder = props.placeholder, disabled = props.disabled, options = props.options, borderWidth = props.borderWidth, errorText = props.errorText, onChange = props.onChange, style = props.style, name = props.name, assistiveText = props.assistiveText, permissions = props.permissions, aclCheck = props.aclCheck;
    var _a = useState(disabled), internalDisabled = _a[0], setInternalDisabled = _a[1];
    useEffect(function () {
        if ((aclCheck && !aclCheck(permissions)) || disabled) {
            setInternalDisabled(true);
        }
        else {
            setInternalDisabled(false);
        }
    }, [disabled, permissions]);
    return (React.createElement(RdiInputWrapper, __assign({}, props),
        React.createElement(RdiInputLabel, { tooltip: tooltip }, label),
        React.createElement(Select, { name: name, className: name, classNamePrefix: "react-select", value: value || selectedItemLabel
                ? { value: value || "", label: selectedItemLabel }
                : null, placeholder: placeholder, isDisabled: internalDisabled, onChange: function (e) { return onChange(e); }, options: options, menuPlacement: "auto", menuPortalTarget: document.body, theme: function (theme) { return (__assign(__assign({}, theme), { borderRadius: 0, colors: __assign(__assign({}, theme.colors), { primary25: ColorsEnum.BlackLight, primary: ColorsEnum.Blue }) })); }, styles: __assign(__assign({}, style), { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 9999 })); }, menu: function (provided) { return (__assign({}, provided)); }, option: function (provided) { return (__assign({}, provided)); }, control: function (provided, state) { return (__assign(__assign(__assign({}, provided), { minHeight: "44px", borderWidth: borderWidth, backgroundColor: state.isDisabled
                        ? ColorsEnum.BlackSuperlight
                        : "transparent" }), ((!isValid || !!errorText) && { borderColor: "red" }))); }, container: function (provided) { return (__assign(__assign({}, provided), { minHeight: "44px", width: "100%" })); }, indicatorSeparator: function (provided) { return (__assign(__assign({}, provided), { display: "none" })); }, placeholder: function (provided) { return (__assign({}, provided
                // color: 'green'
                )); } }) }),
        assistiveText && React.createElement(FormHelperText, null, assistiveText),
        !!errorText && (React.createElement(FormHelperText, { variant: "filled", error: true }, errorText))));
};
