import React from "react";
import PublicPageWrapper from "s~/components/layout/PublicPageWrapper";
import RdiTypography from "s~/components/layout/RdiTypography";
import CenteredContent from "s~/components/layout/content/CenteredContent";
var TermsPage = function () {
    return (React.createElement(PublicPageWrapper, null,
        React.createElement(CenteredContent, { style: {
                display: "flex",
                flexDirection: "column",
                maxWidth: "800px",
                margin: "0px auto",
                padding: "35px 85px",
                background: "rgba(250,250,250,0.12)",
                border: "1px solid",
                borderColor: "rgba(0,0,0,0.12)"
            } },
            React.createElement(RdiTypography, { style: { marginBottom: "36px", fontSize: "32px", fontWeight: "bold" }, variant: "h1" }, "Terms and conditions"),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: "12px", width: "100%", textAlign: "left" } }, "OVERVIEW"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "This website www.medisci.io (\u201CWebsite\u201D) and mobile application \u201CMediSci CROS\u201D (\u201CApplication\u201D or \u201CApp\u201D) is operated by MediSci Ventures, Inc. (\u201CMediSci\u201D, \"Our\" or \"We\"). Our goal is transforming healthcare through a complete digitalized patient-centric platform, bringing technology to the frontline for better health outcomes for every patient. MediSci offers its App and its Website, including all information, tools, services and mobile applications available from its site (collectively, \u201CService\u201D or \u201CServices\u201D) on the condition that the user accepts the all of the terms, conditions, policies and notices stated here. By using MediSci's App or Website, or by subscribing or making a purchase through either the App or the Website, you agree to be bound by the following terms and conditions, including those terms, conditions and policies incorporated by referenced herein (collectively, \u201CTerms of Service\u201D, \u201CTerms\u201D, or \"Agreement\"). These Terms apply to all users of the Website or App, including without limitation users who are browsers, vendors, customers, subscribers, merchants, and/or contributors of content."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "Please read these Terms of Service carefully before accessing or using our Website or App. By accessing or using any part of the Website or App, you agree to be bound by these Terms of Service. Access to the Website or the App and the use of any of the services offered therein are conditioned upon your agreement to all of the Terms of Service. Any new features or tools which are added to the Website or the App shall also be subject to the Terms of Service. MediSci reserves the right to update, change or replace any part of these Terms of Service by posting updates or changes to our website. Your continued use of or access to the Website or the App constitutes acceptance of those changes."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "AGREEMENT TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE, MEDISCI IS NOT WILLING TO PROVIDE YOU WITH ACCESS TO THE SERVICES AND YOU SHOULD IMMEDIATELY DISCONTINUE YOUR USE OF THE SERVICES."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 1 \u2013 GENERAL PROVISIONS"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "information you have provided to us is current and accurate, including without limitation your name, e-mail address, billing address, and phone number."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "reason to the extent permitted by law. MediSci will not refuse service on the basis of any illegal or discriminatory purpose."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 2 \u2013 OWNERSHIP AND LIMITATIONS ON USE"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "You acknowledge and agree that the text, information, data, graphics, images, audio, video, design, organization, compilation, metadata, look and feel, advertising and all other protectable intellectual property (the \"Content\") available on the Website, the App, or otherwise through the Services is MediSci's property, the property of our advertisers or our third party licensors or suppliers (such licensors and suppliers are referred to as \"Information Providers\"), and is protected by copyright and other intellectual property laws."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "You acknowledge and agree that MediSci's Website, App and Services are the exclusive intellectual property of MediSci. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Website, App or Service without MediSci's express written consent."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 3 \u2013 PROHIBITED USES"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "In addition to other prohibitions set forth in the Terms of Service, you are prohibited from using the Website, App or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Website, App, or Service for violating any of the prohibited uses."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "A breach or violation of any of the Terms may result in an immediate termination of your Services and MediSci pursuing any remedies against you permitted by law."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 4 \u2013 TERMINATION"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "MediSci may discontinue or change all or any of the Website, App, or Services, or their availability to you, immediately, in its sole discretion, at any time without cause."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "MediSci may terminate this Agreement and your access to and use of the Website, App, or Services, or any portion thereof, immediately, in its sole discretion, at any time without cause."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "In the event of termination, you remain bound by the obligations and liabilities set forth in this agreement, including without limitation the provisions relating to MediSci's intellectual property, indemnification, choice of law, and forum selection."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 5 \u2013 MODIFICATIONS TO SERVICES AND FEES"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "Prices for MediSci's services and/or subscription fees are subject to change without notice."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "MediSci reserves the right at any time to modify or discontinue the Service (or any part or content thereof) at any time without cause or notice. MediSci may terminate this Agreement and your access to and use of the Services, or any portion thereof, immediately, in its sole discretion, at any time without cause. MediSci shall not be liable to you or to any third-party for any modification, price change, fee, suspension or discontinuance of the Service."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 6 \u2013 OPTIONAL THIRD-PARTY TOOLS"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "We may provide you with the option to access third-party tools. You acknowledge and agree that MediSci neither monitors nor controls these third-party tools. You acknowledge and agree that the access we provide to such tools is \"AS IS\" and \"AS AVAILABLE\" without any warranties, representations, endorsement or conditions of any kind."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "We shall have no liability whatsoever arising from or relating to your use of optional third-party tools. Any use by you of optional tools offered through the Website or the App is at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s)."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 7 \u2013 THIRD-PARTY LINKS"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "Certain content, products and services available via our Website, App, or Service may include materials from third parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties. We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 8 \u2013 DISCLAIMERS"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "DISCLAIMERS OF WARRANTIES. YOU AGREE THAT YOUR ACCESS TO, AND USE OF, THE SERVICES AND THE CONTENT AVAILABLE THROUGH THE SERVICES, INCLUDING THE WEBSITE AND THE APP, IS ON AN \"AS-IS\", \"AS AVAILABLE\" BASIS AND WE AND OUR INFORMATION PROVIDERS SPECIFICALLY DISCLAIM ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. MediSci does not give medical advice or advocate for any particular treatment. You should always seek the assistance of a professional for medical advice. Certain information and data provided in connection with the Services may be delayed or inaccurate through no fault of MediSci. MediSci furnishes the information available on its Website and the App in connection with these Services without responsibility for accuracy and you agree that errors or omissions contained in such information shall not be made the basis for any claim, demand or cause of action against MediSci."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "MediSci may link to, or promote, web sites or services from other companies or offer you the ability to download software from other companies. You agree that we are not responsible for, and do not control, those web sites, services and software."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "MediSci and its subsidiaries, affiliates, and its licensors make no representations or warranties that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected except as required by law; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } },
                "LIMITATIONS ON LIABILITY. NEITHER MEDISCI NOR ANY OF ITS SUBSIDIARIES OR AFFILIATES, SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES AND LICENSORS (THE \"EXCLUDED PARTIES\") WILL BE LIABLE (JOINTLY OR SEVERALLY) TO YOU OR ANY OTHER PERSON RESULTING FROM:",
                React.createElement("ol", null,
                    React.createElement("li", null, "YOUR ACCESS TO OR USE OF WEBSITE, APP, OR SERVICES;"),
                    React.createElement("li", null, "YOUR INABILITY TO ACCESS OR USE THE WEBSITE, APP, OR SERVICES;"),
                    React.createElement("li", null, "ANY CONDUCT OR CONTENT OF ANY THIRD PARTY;"),
                    React.createElement("li", null, "ANY CONTENT OBTAINED FROM THE SERVICE;"),
                    React.createElement("li", null, "ANY UNAUTHORIZED ACCESS TO, USE OF, OR ALTERATION OF THE DATA YOU TRANSMIT OR CONTENT YOU RECEIVE, OR"),
                    React.createElement("li", null, "ANY OTHER LEGAL THEORY, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE).")),
                "NOR SHALL THE EXCLUDED PARTIES BE HELD LIABLE FOR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST SAVINGS AND LOST REVENUES WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT, OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF SUCH EXCLUDED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND IRRESPECTIVE OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, THEN THE EXCLUDED PARTIES' LIABILITY WILL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW. You understand and agree that your use of the Services is at your sole risk."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 9 \u2013 INDEMNIFICATION"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "INDEMNITY. You agree to indemnify and hold MediSci and its respective subsidiaries, affiliates, officers, agents, employees, partners, contractors, subcontractors, licensors, service providers, and suppliers from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising from your use of the Services, your violation of this Agreement, or your violation of the law or rights of third parties."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 10 - ACCURACY OF BILLING AND ACCOUNT INFORMATION"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "We reserve the right to refuse any order and/or service you place with us. We may, in our sole discretion, limit, cancel or ban quantities purchased and/or subscriptions per person, per household or per order. These restrictions may include orders placed and/or subscribed by or under the same customer and/or user account, the same credit card, and/or orders that use the same billing and/or shipping address."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. You agree to provide current, complete and accurate purchase and account information for all purchases made at our website. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed. For more details, please review our Returns Policy."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 11 \u2013 USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "For any comments or feedback that you send us, including without limitation ideas, suggestions, proposals, plans, or other materials sent unsolicited or in response to a solicitation by us, you agree that we may edit, copy, publish, translate and otherwise use such information in any way without restriction. We are under no obligation (1) to maintain any such information in confidence; (2) to pay compensation for any such information; or (3) to respond to any comments or feedback. You agree not to submit personal and protected health information in response to a solicitation for comments or feedback, and acknowledge that submitting personal and protected health information in response to a solicitation for comments or feedback may waive your privacy rights over such material."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party\u2019s intellectual property or these Terms of Service."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "You agree that you will not submit any comments that violate any right of any third party, including copyright, trademark, privacy, personality or other personal or proprietary rights. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "You may not use a false or temporary e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 12 - PERSONAL & PROTECTED HEALTH INFORMATION & APPLE HEALTH"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "As part of the Services, you may submit personal and protected health information through the Website or the App. Your submission of such information is governed by our Privacy Policy and HIPAA Privacy Practices, a copy of which have been made available to you as part of signing up for an account, are made available on our Website and which are incorporated herein by reference as if stated in full."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 13 \u2013 MOBILE APPLICATION"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "The App is used by the clinic to send you notifications for appointments, patient payment, rewards and task reminders. The App will also be used to complete health-related questionnaires and to collect your health data points from Apple Health and third-party IOT devices. To use the Services, you will need to download (free of charge) the App to your personal mobile phone."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "If you do not have a personal mobile phone or if you do not agree to use your personal mobile phone, a mobile phone may be temporarily provided to you by the clinic while you are receiving medical care or participating in a clinical study. Provision of a mobile phone is not guaranteed and is based on availability. Please check with your study doctor to see if this option is available."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "Downloading the App will require you to electronically register through the study application."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 14 \u2013 CLINICAL TRIAL PARTICIPATION"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } },
                React.createElement("u", null, "Users who are participating in a clinical trial or medical study:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "You acknowledge and agree that the clinical trial or medical study that you are participating in is being conducted, monitored, and controlled by its primary investigator (the \"Researcher\")."),
                    React.createElement("li", null, "You acknowledge and agree that MediSci is separate and distinct from the Researcher, is not responsible for the clinical trial or medical study, and neither monitors nor controls the clinical trial or medical study."),
                    React.createElement("li", null, "You acknowledge and agree that MediSci may share data it gathers with the Researcher for the purposes of health research and improving health management. Such data will not be shared for the purpose of advertising, marketing, or other use-based data mining purposes."),
                    React.createElement("li", null, "You acknowledge and agree that the Researcher has provided you with consent forms and other documentation that must be submitted as part of the clinical trial or medical study (collectively, the \"Clinical Trial Consent Forms\"), including without limitation an explanation of the (a) nature, purpose, and duration of the research; (b) procedures, risks, and benefits to the participant; (c) information about confidentiality and handling of data (including any sharing with third parties); (d) a point of contact for participant questions; and (e) the withdrawal process."),
                    React.createElement("li", null, "The Clinical Trial Consent Forms are incorporated herein by reference as if stated in their entirety. In the event of any discrepancies between the terms set forth in this Terms of Service and the Clinical Trial Consent Forms related to MediSci's services, the Clinical Trial Consent Forms shall control."))),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 15 \u2013 HEADINGS"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms"),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 16 \u2013 SEVERABILITY"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "In the event that any provision of the Terms of Service is determined to be unlawful, void or unenforceable, such a determination shall not affect the validity and enforceability of any other remaining provisions, and such unenforceable provision shall be severed from these Terms of Service and shall nevertheless be enforceable to the fullest extent permitted by law."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 17 \u2013 WAIVER, LACHES AND ESTOPPEL"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "The failure of MediSci to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision, nor shall the doctrines of laches or estoppel apply in such event."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 18 \u2013 ENTIRE AGREEMENT"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "The Terms of Service and the documents incorporated by referenced herein constitute the final and entire agreement between us regarding your use of the Website, App, and Services, and supersedes all previous and contemporaneous oral or written agreements regarding your use of the Website, App, and Services."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 19 \u2013 NO THIRD-PARTY BENEFICIARIES"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "This Agreement is personal to you, which means that you may not assign your rights or obligations under this Agreement to anyone. No third party is a beneficiary of this Agreement."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 20 \u2013 GOVERNING LAW AND FORUM SELECTION"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "You agree that this Agreement, as well as any and all claims arising from this Agreement will be governed by and construed in accordance with the laws of the State of California, United States of America applicable to contracts made entirely within California and wholly performed in California, without regard to any conflict or choice of law principles. The sole jurisdiction and venue for any litigation arising out of this Agreement will be an appropriate federal or state court located in Orange County, California."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 21 \u2013 NOT CONSTRUED AGAINST DRAFTER"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "No party shall be deemed to be the drafter of this agreement or of any particular provision or provisions, and any ambiguities in the interpretation of the Terms of Service shall not be construed against either party."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 22 \u2013 CHANGES TO TERMS OF SERVICE"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "We may modify this Agreement from time to time in our sole discretion and without notice to you. You should review this Agreement periodically to ensure familiarity with its then-current terms and conditions. Your continued use of the Services following any modification of this Agreement shall constitute your acceptance of such modifications to this Agreement."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 23 \u2013 ADDITIONAL TERMS"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "Some aspects of the Services may have additional terms, conditions and restrictions associated with them and will be provided to you as part of accessing those services. You agree that such additional terms, conditions and restrictions will apply to your use of such Services and if there is a conflict with this Agreement, such additional terms, conditions and restrictions will control."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "SECTION 24 \u2013 CONTACT INFORMATION"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "12px" } }, "Questions about the Terms of Service should be sent to us at support@medisci.io."))));
};
export default TermsPage;
