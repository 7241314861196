var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable max-classes-per-file */
import { observable } from 'mobx';
import { Model } from 's~/types/models/Model';
import UploadedFile from './UploadedFile';
var SiteRoleTrialLink = /** @class */ (function () {
    function SiteRoleTrialLink(link) {
        this.label = link.label;
        this.href = link.href;
    }
    return SiteRoleTrialLink;
}());
export { SiteRoleTrialLink };
var SiteRoleTrialContact = /** @class */ (function () {
    function SiteRoleTrialContact(contact) {
        this.name = contact.name;
        this.title = contact.title;
        this.email = contact.email;
        this.phoneNumber = contact.phoneNumber;
        this.sponsor = contact.sponsor;
    }
    return SiteRoleTrialContact;
}());
export { SiteRoleTrialContact };
var SiteRoleTrial = /** @class */ (function (_super) {
    __extends(SiteRoleTrial, _super);
    function SiteRoleTrial(p) {
        var _this = _super.call(this, p) || this;
        _this.id = p.id;
        _this.siteId = p.siteId;
        _this.trialId = p.trialId;
        _this.websiteLinks = p.websiteLinks.map(function (link) { return new SiteRoleTrialLink(link); });
        _this.contact = p.contact || [];
        _this.files = [];
        if (p.files) {
            for (var _i = 0, _a = p.files; _i < _a.length; _i++) {
                var f = _a[_i];
                _this.files.push(new UploadedFile(f));
            }
        }
        return _this;
    }
    __decorate([
        observable
    ], SiteRoleTrial.prototype, "id", void 0);
    __decorate([
        observable
    ], SiteRoleTrial.prototype, "siteId", void 0);
    __decorate([
        observable
    ], SiteRoleTrial.prototype, "trialId", void 0);
    __decorate([
        observable
    ], SiteRoleTrial.prototype, "websiteLinks", void 0);
    __decorate([
        observable
    ], SiteRoleTrial.prototype, "files", void 0);
    __decorate([
        observable
    ], SiteRoleTrial.prototype, "contact", void 0);
    return SiteRoleTrial;
}(Model));
export { SiteRoleTrial };
