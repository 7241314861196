import { TablePagination } from '@material-ui/core';
import React from 'react';
var RdiTablePagination = function (props) {
    var count = props.count, rowsPerPage = props.rowsPerPage, page = props.page, onChangePage = props.onChangePage, onChangeRowsPerPage = props.onChangeRowsPerPage;
    var getRowsPerPageOptions = function () {
        return Number(rowsPerPage) === 10 ? [5, 10, 25, 50, 100] : [5, 25, 50, 100];
    };
    return (React.createElement(React.Fragment, null, count > rowsPerPage && (React.createElement(TablePagination, { rowsPerPageOptions: getRowsPerPageOptions(), component: "div", count: count, rowsPerPage: rowsPerPage, page: page - 1, backIconButtonProps: {
            'aria-label': 'previous page',
        }, onChangePage: onChangePage, onChangeRowsPerPage: onChangeRowsPerPage, style: { minHeight: '52px', marginTop: '10px' } }))));
};
export default RdiTablePagination;
