var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, CircularProgress, createStyles, Link as MaterialLink } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import autoSaveConfig from "s~/config/autoSaveConfig";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var styles = function (theme) {
    var _a;
    return createStyles({
        routerLink: {
            textDecoration: "none",
            color: function (props) {
                return props.inverted ? ColorsEnum.Primary : ColorsEnum.White;
            },
            height: function (props) { var _a; return ((_a = props.style) === null || _a === void 0 ? void 0 : _a.height) || "43px"; },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "0px 16px"
        },
        materialLink: {
            textDecoration: "none",
            color: function (props) {
                return props.inverted ? ColorsEnum.Primary : ColorsEnum.White;
            },
            height: function (props) { var _a; return ((_a = props.style) === null || _a === void 0 ? void 0 : _a.height) || "43px"; },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "0px 16px"
        },
        progress: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        rdiButton: (_a = {
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                textTransform: "uppercase",
                margin: "0px",
                borderStyle: "solid",
                boxShadow: "none",
                letterSpacing: "normal",
                position: "relative",
                height: function (props) { var _a; return ((_a = props.style) === null || _a === void 0 ? void 0 : _a.height) || "47px"; },
                marginBottom: function (props) {
                    return props.margin === "none" ? "0px" : "15px";
                },
                color: function (props) {
                    return props.inverted ? ColorsEnum.Primary : ColorsEnum.White;
                },
                padding: function (props) { return (props.to ? "0px" : "0px 16px"); },
                backgroundColor: function (props) {
                    return props.inverted ? ColorsEnum.White : ColorsEnum.Primary;
                },
                borderColor: function (props) {
                    return props.inverted ? ColorsEnum.Primary : ColorsEnum.White;
                },
                borderWidth: function (props) { return (props.inverted ? "1px" : "0px"); },
                "&:hover": {
                    backgroundColor: ColorsEnum.BlueDark + "!important"
                },
                "&.inverted:hover": {
                    color: ColorsEnum.BlueDark + "!important",
                    backgroundColor: "white!important",
                    border: "px solid " + ColorsEnum.BlueDark + "!important"
                },
                "&.inverted:hover a": {
                    color: ColorsEnum.BlueDark + "!important"
                }
            },
            _a[theme.breakpoints.down("xs")] = {
                width: "100%",
                fontSize: function (props) {
                    return props.xsMobile ? "9px" : undefined;
                },
                height: function (props) {
                    return props.xsMobile ? "30px" : undefined;
                },
                "& a": {
                    height: function (props) {
                        return props.xsMobile ? "30px" : undefined;
                    }
                }
            },
            _a)
    });
};
var RdiButton = function (props) {
    var fullWidth = props.fullWidth, disabled = props.disabled, loading = props.loading, inverted = props.inverted, classes = props.classes, style = props.style, submit = props.submit, onClick = props.onClick, caption = props.caption, to = props.to, href = props.href, children = props.children, target = props.target, id = props.id, name = props.name, className = props.className, permissions = props.permissions, xs = props.xs, aclHide = props.aclHide, aclCheck = props.aclCheck;
    var _a = useState(loading), internalLoading = _a[0], setInternalLoading = _a[1];
    var _b = useState(disabled), internalDisabled = _b[0], setInternalDisabled = _b[1];
    var timerRef = React.useRef();
    useEffect(function () {
        if ((aclCheck && !aclCheck(permissions)) || disabled) {
            setInternalDisabled(true);
        }
        else {
            setInternalDisabled(false);
        }
        if (loading) {
            timerRef.current = setTimeout(function () {
                setInternalLoading(loading);
            }, autoSaveConfig.loadingAnimationDelay);
        }
        else {
            clearTimeout(timerRef.current);
            setInternalLoading(false);
        }
        return function () {
            clearTimeout(timerRef.current);
        };
    }, [loading, disabled]);
    if (aclHide && aclCheck && !aclCheck(permissions)) {
        return null;
    }
    var xsStyle = {
        height: "26px",
        fontSize: "10px",
        padding: "0px",
        minWidth: "110px",
        marginBottom: "0px"
    };
    return (React.createElement(Button, { fullWidth: fullWidth, variant: "contained", color: "primary", id: id, name: name, disabled: internalDisabled || loading, style: __assign(__assign({}, (xs ? xsStyle : {})), style), className: className + " " + classes.rdiButton + " " + (inverted ? "inverted" : ""), type: submit ? "submit" : "button", onClick: onClick },
        React.createElement("span", { style: {
                height: internalLoading ? "0px" : "initial",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center"
            } },
            caption,
            !to && !href && children,
            to && (React.createElement(Link, { className: classes.routerLink, to: to }, children)),
            href && (React.createElement(MaterialLink, { className: classes.materialLink, target: target, href: href }, children))),
        internalLoading && (React.createElement("span", { className: classes.progress },
            React.createElement(CircularProgress, null)))));
};
RdiButton.defaultProps = {
    fullWidth: false,
    inverted: false,
    loading: false,
    xs: false,
    permissions: null,
    aclCheck: null
};
export default withStyles(styles)(RdiButton);
