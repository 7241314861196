export var TaskStatusEnum;
(function (TaskStatusEnum) {
    TaskStatusEnum["OPEN"] = "open";
    TaskStatusEnum["PENDING"] = "pending";
    TaskStatusEnum["RESOLVED"] = "resolved";
})(TaskStatusEnum || (TaskStatusEnum = {}));
export var TaskStatusLabelsEnum;
(function (TaskStatusLabelsEnum) {
    TaskStatusLabelsEnum["open"] = "Open";
    TaskStatusLabelsEnum["pending"] = "Pending";
    TaskStatusLabelsEnum["resolved"] = "Resolved";
})(TaskStatusLabelsEnum || (TaskStatusLabelsEnum = {}));
