var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { PaymentTypeEnum, PaymentTypeLabels, VenmoPaymentIdentifier, VenmoPaymentIdentifierLabels } from '@cros/shared/constants/enums/PaymentTypeEnum';
import { Payment } from '@cros/shared/types/models/Payment';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { CircularProgress, createStyles, withStyles } from '@material-ui/core';
import { computed, observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import EnrollmentStore from '~/stores/EnrollmentStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTransactionStore } from '~/stores/TransactionStore';
import TrialStore from '~/stores/TrialStore';
var styles = function () { return createStyles({}); };
var AddPayment = /** @class */ (function (_super) {
    __extends(AddPayment, _super);
    function AddPayment(props) {
        var _this = _super.call(this, props) || this;
        _this.data = {
            enrollmentId: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            trialId: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            visitId: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            paymentType: {
                label: '',
                value: null,
                error: null,
                rule: 'required'
            },
            amount: {
                value: null,
                error: null,
                rule: 'required'
            },
            description: {
                value: null,
                error: null,
                rule: 'required_if:visitId,notConnected'
            },
            venmoComment: {
                value: null,
                error: null,
                rule: 'required_if:paymentType,other'
            },
            venmoIdentifier: {
                label: VenmoPaymentIdentifierLabels[VenmoPaymentIdentifier.VENMO],
                value: VenmoPaymentIdentifier.VENMO,
                error: null
            },
            venmoUsername: {
                value: null,
                error: null,
                rule: 'regex:/^[a-zA-Z0-9_-]{5,30}$/',
                errorMessage: 'test'
            }
        };
        _this.hasErrors = false;
        _this.saving = false;
        _this.loadingVisits = false;
        _this.loading = true;
        _this.errorMessage = '';
        _this.componentDidMount = function () { return __awaiter(_this, void 0, Promise, function () {
            var paymentStore, payment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        paymentStore = this.props.transactionStore.paymentStore;
                        if (!this.isEdit) return [3 /*break*/, 2];
                        return [4 /*yield*/, paymentStore.getPaymentById(this.paymentId)];
                    case 1:
                        payment = _a.sent();
                        this.assignData(payment);
                        _a.label = 2;
                    case 2:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.loadPatients = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.trialStore.getTrial(this.data.trialId.value)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.trialStore.changeEnrollmentFilters({ rowsPerPage: 1000 })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignData = function (payment) {
            var _a, _b, _c;
            _this.data.amount.value = payment.amount;
            _this.data.enrollmentId.value = payment.enrollmentId;
            _this.data.enrollmentId.label = ((_a = payment.patient) === null || _a === void 0 ? void 0 : _a.fullName) || '';
            _this.data.description.value = payment.description ? payment.description : undefined;
            _this.data.visitId.value = payment.visitId || 'notConnected';
            _this.data.visitId.label = ((_b = payment.visit) === null || _b === void 0 ? void 0 : _b.name) || 'Not connected to a visit';
            _this.data.trialId.value = payment.trialId;
            _this.data.trialId.label = ((_c = payment.trial) === null || _c === void 0 ? void 0 : _c.name) || '';
        };
        _this.onClose = function () {
            var _a = _this.props, onClose = _a.onClose, history = _a.history, url = _a.match.url, search = _a.location.search;
            if (onClose) {
                onClose();
            }
            if (!_this.isEdit) {
                history.push("" + url.split('/new')[0] + search);
            }
            else {
                history.push("" + url.split('/edit')[0] + search);
            }
        };
        _this.fieldChange = function (e, field) { return __awaiter(_this, void 0, Promise, function () {
            var enrollments, enrollment, patient_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fieldChange(e, field, this.data);
                        if (!(field === 'trialId')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadPatients()];
                    case 1:
                        _a.sent();
                        this.data.enrollmentId.value = '';
                        this.data.enrollmentId.label = '';
                        this.data.visitId.value = '';
                        this.data.visitId.label = '';
                        _a.label = 2;
                    case 2:
                        if (field === 'enrollmentId') {
                            this.data.venmoUsername.value = '';
                        }
                        if (this.data.paymentType.value !== PaymentTypeEnum.VENMO ||
                            this.data.venmoIdentifier.value !== VenmoPaymentIdentifier.VENMO) {
                            this.data.venmoUsername.value = '';
                        }
                        else if (this.data.paymentType.value === PaymentTypeEnum.VENMO ||
                            this.data.venmoIdentifier.value === VenmoPaymentIdentifier.VENMO) {
                            enrollments = this.trialStore.enrollments;
                            enrollment = enrollments.find(function (x) { return x.id === _this.data.enrollmentId.value; });
                            patient_1 = (enrollment || {}).patient;
                            setTimeout(function () {
                                var _a, _b;
                                return (_this.data.venmoUsername.value =
                                    ((_b = (_a = _this.data) === null || _a === void 0 ? void 0 : _a.venmoUsername) === null || _b === void 0 ? void 0 : _b.value) || (patient_1 === null || patient_1 === void 0 ? void 0 : patient_1.venmoUsername));
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, Promise, function () {
            var newPayment, payment, paymentStore, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        this.errorMessage = '';
                        payment = this.getObjectToSave();
                        paymentStore = this.props.transactionStore.paymentStore;
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, paymentStore.addPayment(payment)];
                    case 2:
                        newPayment = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, paymentStore.updatePayment(payment)];
                    case 4:
                        newPayment = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 8];
                    case 7:
                        this.saving = false;
                        if (!newPayment) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var enrollments = _this.trialStore.enrollments;
            var enrollment = enrollments.find(function (x) { return x.id === _this.data.enrollmentId.value; });
            var payment = new Payment({
                enrollmentId: _this.data.enrollmentId.value,
                patientId: enrollment === null || enrollment === void 0 ? void 0 : enrollment.patientId,
                visitId: _this.data.visitId.value !== 'notConnected' ? _this.data.visitId.value : undefined,
                trialId: _this.data.trialId.value,
                amount: parseInt(_this.data.amount.value, 10),
                description: _this.data.description.value ? _this.data.description.value : undefined,
                id: _this.paymentId,
                paymentType: _this.data.paymentType.value,
                comment: _this.data.venmoComment.value,
                venmoIdentifier: _this.data.venmoIdentifier.value,
                venmoUsername: _this.data.venmoUsername.value
            });
            return payment;
        };
        _this.getSelectedPatientContactInfo = function () {
            var enrollments = _this.trialStore.enrollments;
            var enrollment = enrollments.find(function (x) { return x.id === _this.data.enrollmentId.value; });
            var patient = (enrollment || {}).patient;
            var venmoIdentifier = _this.data.venmoIdentifier.value;
            if (!patient) {
                return null;
            }
            if (venmoIdentifier === VenmoPaymentIdentifier.EMAIL && !patient.email) {
                return "Missing patient's email";
            }
            if (venmoIdentifier === VenmoPaymentIdentifier.PHONE && !patient.phoneNumber) {
                return "Missing patient's phone number";
            }
            return "Payment will be sent to " + (venmoIdentifier === VenmoPaymentIdentifier.EMAIL
                ? patient.email
                : "" + patient.phoneNumberPrefix + patient.phoneNumber);
        };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        _this.initStorePatient = function () { return __awaiter(_this, void 0, Promise, function () {
            var enrollmentId, trialId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isEdit) return [3 /*break*/, 3];
                        this.loadingVisits = true;
                        this.data.visitId.label = '';
                        this.data.visitId.value = '';
                        enrollmentId = this.data.enrollmentId.value;
                        trialId = this.data.trialId.value;
                        return [4 /*yield*/, this.enrollmentStore.getEnrollment(enrollmentId, trialId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.enrollmentStore.visitListStore.listVisits()];
                    case 2:
                        _a.sent();
                        this.loadingVisits = false;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var siteStore = _this.props.siteStore;
        _this.trialStore = new TrialStore(siteStore);
        _this.enrollmentStore = new EnrollmentStore(siteStore);
        return _this;
    }
    Object.defineProperty(AddPayment.prototype, "paymentId", {
        get: function () {
            var params = this.props.match.params;
            return params.paymentId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddPayment.prototype, "isEdit", {
        get: function () {
            var params = this.props.match.params;
            return !!params.paymentId;
        },
        enumerable: false,
        configurable: true
    });
    AddPayment.prototype.render = function () {
        var _this = this;
        var enrollments = this.trialStore.enrollments;
        var trials = this.props.siteStore.trials;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading, style: { minHeight: '470px' } },
            React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '0px' } }, this.isEdit ? 'Edit payment' : 'New payment'),
            React.createElement(RdiLine, { style: { margin: '15px 0px' } }),
            React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '20px' } }, !this.isEdit
                ? 'Please select a patient and the amount you want to pay the patient. They will be able to claim the payment right away.'
                : 'Edit an existing unclaimed payment. The patient will be able to claim the payment via their mobile app.'),
            !this.loading && (React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent, style: { flex: '1' } },
                React.createElement("div", { style: { flex: '1' } },
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20 },
                        React.createElement(RdiInput, { type: "select", disabled: this.isEdit, label: "Trial", name: "trialId", errorText: this.data.trialId.error, value: this.data.trialId.value, selectedItemLabel: this.data.trialId.label, options: trials.map(function (item) { return ({
                                label: item.name,
                                value: item.id
                            }); }), onChange: function (e) { return _this.fieldChange(e, 'trialId'); } }),
                        React.createElement(RdiInput, { type: "select", disabled: this.isEdit, label: "Payment type", name: "paymentType", errorText: this.data.paymentType.error, value: this.data.paymentType.value, selectedItemLabel: this.data.paymentType.label, options: Object.keys(PaymentTypeLabels).map(function (item) { return ({
                                label: PaymentTypeLabels[item],
                                value: item
                            }); }), onChange: function (e) { return _this.fieldChange(e, 'paymentType'); } })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20, style: { flexWrap: 'wrap' } },
                        React.createElement(RdiInput, { type: "select", label: "Patient", name: "enrollmentId", disabled: this.isEdit, value: this.data.enrollmentId.value, selectedItemLabel: this.data.enrollmentId.label, errorText: this.data.enrollmentId.error, onChange: function (e) {
                                _this.fieldChange(e, 'enrollmentId');
                                _this.initStorePatient();
                            }, options: enrollments.map(function (item) {
                                var _a;
                                return ({
                                    label: ((_a = item.patient) === null || _a === void 0 ? void 0 : _a.fullName) || 'Unknown',
                                    value: item.id
                                });
                            }), autoFocus: true, style: { width: 'calc(50% - 10px)' } }),
                        React.createElement(Provider, { enrollmentStore: this.enrollmentStore },
                            React.createElement(RdiInput, { type: "select", label: "Visits", name: "visitId", value: this.data.visitId.value, selectedItemLabel: this.data.visitId.label, errorText: this.data.visitId.error, onChange: function (e) { return _this.fieldChange(e, 'visitId'); }, disabled: !this.enrollmentStore.enrollment || this.loadingVisits, options: __spreadArrays([
                                    {
                                        label: 'Not connected to a visit',
                                        value: 'notConnected'
                                    }
                                ], (this.enrollmentStore.visitListStore.visits
                                    ? this.enrollmentStore.visitListStore.visits.map(function (x) { return ({
                                        label: x.name,
                                        value: x.id
                                    }); })
                                    : [])), style: { width: 'calc(50% - 10px)' } }))),
                    this.data.visitId.value === 'notConnected' && (React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 },
                        React.createElement(RdiInput, { type: "text", label: "Description", name: "description", value: this.data.description.value, errorText: this.data.description.error, onChange: function (e) { return _this.fieldChange(e, 'description'); }, style: { width: 'calc(50% - 10px)' } }),
                        React.createElement("div", { style: { width: '100%' } }))),
                    this.data.paymentType.value === PaymentTypeEnum.OTHER && (React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 },
                        React.createElement(RdiInput, { type: "text", label: "Comment", name: "comment", value: this.data.venmoComment.value, errorText: this.data.venmoComment.error, onChange: function (e) { return _this.fieldChange(e, 'venmoComment'); } }),
                        React.createElement("div", { style: { width: '100%' } }))),
                    this.data.paymentType.value === PaymentTypeEnum.VENMO && (React.createElement(React.Fragment, null,
                        React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 },
                            React.createElement(RdiInput, { type: "select", label: "Venmo identifier", name: "venmoIdentifier", value: this.data.venmoIdentifier.value, selectedItemLabel: this.data.venmoIdentifier.label, errorText: this.data.venmoIdentifier.error, options: Object.keys(VenmoPaymentIdentifierLabels).map(function (item) { return ({
                                    label: VenmoPaymentIdentifierLabels[item],
                                    value: item
                                }); }), onChange: function (e) { return _this.fieldChange(e, 'venmoIdentifier'); } }),
                            this.data.venmoIdentifier.value === VenmoPaymentIdentifier.VENMO ? (React.createElement(React.Fragment, null,
                                React.createElement(RdiInput, { type: "text", label: "Venmo username", name: "venmoUsername", value: this.data.venmoUsername.value, errorText: this.data.venmoUsername.error, onChange: function (e) { return _this.fieldChange(e, 'venmoUsername'); } }))) : (React.createElement("div", { style: { width: '100%', marginTop: '28px' } }, this.getSelectedPatientContactInfo()))),
                        React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 },
                            React.createElement(RdiInput, { type: "text", label: "Venmo notification message", name: "comment", value: this.data.venmoComment.value, errorText: this.data.venmoComment.error, onChange: function (e) { return _this.fieldChange(e, 'venmoComment'); } }),
                            React.createElement("div", { style: { width: '100%' } })))),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 },
                        React.createElement(RdiInput, { type: "number", label: "Amount", name: "amount", unit: "USD", value: this.data.amount.value, errorText: this.data.amount.error, onChange: function (e) { return _this.fieldChange(e, 'amount'); } }),
                        React.createElement("div", { style: { width: '100%' } })),
                    React.createElement(RdiErrorBox, null, this.errorMessage)),
                React.createElement(RdiLine, null),
                React.createElement(FlexContainer, { justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, submit: true, margin: "none" }, this.isEdit ? 'Save' : 'Create and pay')))),
            this.loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null)))));
    };
    __decorate([
        observable
    ], AddPayment.prototype, "data", void 0);
    __decorate([
        observable
    ], AddPayment.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], AddPayment.prototype, "saving", void 0);
    __decorate([
        observable
    ], AddPayment.prototype, "loadingVisits", void 0);
    __decorate([
        observable
    ], AddPayment.prototype, "loading", void 0);
    __decorate([
        observable
    ], AddPayment.prototype, "errorMessage", void 0);
    __decorate([
        computed
    ], AddPayment.prototype, "paymentId", null);
    __decorate([
        computed
    ], AddPayment.prototype, "isEdit", null);
    AddPayment = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectTransactionStore,
        observer
    ], AddPayment);
    return AddPayment;
}(Component));
export default withStyles(styles)(withRouter(AddPayment));
