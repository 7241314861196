var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import config from '~/config';
import { injectProcedureStore } from '~/stores/PatientStore/ProcedureStore';
import { injectVisitStore } from '~/stores/PatientStore/VisitStore';
var VisitFile = /** @class */ (function (_super) {
    __extends(VisitFile, _super);
    function VisitFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.saving = false;
        _this.componentDidMount = function () {
            _this.assignInternalValues(_this.props);
        };
        _this.shouldComponentUpdate = function (nextProps) {
            var dataPoint = nextProps.dataPoint, disabled = nextProps.disabled;
            if (_this.internalValue ===
                (dataPoint.data && dataPoint.data.value ? dataPoint.data.value.url : null) &&
                _this.internalDisabled === disabled &&
                _this.internalError === dataPoint.errorMessage) {
                return false;
            }
            _this.assignInternalValues(nextProps);
            return true;
        };
        _this.assignInternalValues = function (props) {
            var dataPoint = props.dataPoint, disabled = props.disabled;
            _this.internalValue =
                dataPoint.data && dataPoint.data.value ? new UploadedFile(dataPoint.data.value) : null;
            _this.internalDisabled = disabled;
            _this.internalError = dataPoint.errorMessage;
        };
        _this.onClear = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, dataPoint, onDataPointChange;
            return __generator(this, function (_b) {
                _a = this.props, dataPoint = _a.dataPoint, onDataPointChange = _a.onDataPointChange;
                onDataPointChange(dataPoint, { value: null });
                return [2 /*return*/];
            });
        }); };
        _this.onChange = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var _a, dataPoint, onDataPointChange, uploadFile, file;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, dataPoint = _a.dataPoint, onDataPointChange = _a.onDataPointChange, uploadFile = _a.procedureStore.uploadFile;
                        this.saving = true;
                        if (!event || !event.files || !event.files[0]) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, uploadFile(event.files[0], dataPoint)];
                    case 1:
                        file = _b.sent();
                        this.saving = false;
                        if (!file) {
                            return [2 /*return*/];
                        }
                        onDataPointChange(dataPoint, { value: file });
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    VisitFile.prototype.render = function () {
        var dataPoint = this.props.dataPoint;
        return (React.createElement("span", null,
            React.createElement(RdiInput, { type: "file", label: dataPoint.label, tooltip: dataPoint.tooltip, value: this.internalValue ? this.internalValue.originalname : null, link: this.internalValue ? "" + config.apiUrl + this.internalValue.url : null, fileSize: this.internalValue ? this.internalValue.sizeString : '', disabled: this.internalDisabled, onChange: this.onChange, onClear: this.onClear, isValid: !this.internalError, errorText: this.internalError, saving: this.saving, style: { marginTop: '10px' } })));
    };
    __decorate([
        observable
    ], VisitFile.prototype, "internalDisabled", void 0);
    __decorate([
        observable
    ], VisitFile.prototype, "internalValue", void 0);
    __decorate([
        observable
    ], VisitFile.prototype, "internalError", void 0);
    __decorate([
        observable
    ], VisitFile.prototype, "saving", void 0);
    VisitFile = __decorate([
        injectProcedureStore,
        injectVisitStore,
        observer
    ], VisitFile);
    return VisitFile;
}(Component));
export default VisitFile;
