var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { formatDatetime } from '@cros/shared/utils/dateUtils';
import { CircularProgress, createStyles, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import AuditLogModal from '~/pages/site/site-detail/audit-log/AuditLogModal';
import { getPatientOverviewRoute, getPatientRoute, getTrialRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import AuditLogStore from '~/stores/AuditLogStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () { return createStyles({}); };
var AuditLogListPage = /** @class */ (function (_super) {
    __extends(AuditLogListPage, _super);
    function AuditLogListPage(props) {
        var _this = _super.call(this, props) || this;
        _this.columns = [
            {
                id: 'createdAt',
                label: 'Date',
                sortable: true,
                sortName: '$metadata__txTime'
            },
            {
                id: 'user',
                label: 'User',
                sortable: false
            },
            {
                id: 'patient',
                label: 'Patient',
                sortable: false
            },
            {
                id: 'trial',
                label: 'Trial',
                sortable: false
            },
            {
                id: 'action',
                label: 'Action',
                sortable: false
            },
            {
                id: 'actionId',
                label: 'Action ID',
                sortable: false
            }
        ];
        _this.componentDidMount = function () {
            _this.auditLogStore.changeFilters({});
            _this.auditLogStore.getAuditLogFilters();
        };
        _this.getDetailRoute = function (item) {
            var _a = _this.props.location, search = _a.search, pathname = _a.pathname;
            return pathname + "/view/" + item.id + search;
        };
        var siteStore = props.siteStore;
        _this.auditLogStore = new AuditLogStore(siteStore, _this.props.location.search, _this.props.history);
        return _this;
    }
    AuditLogListPage.prototype.render = function () {
        var _this = this;
        var auditLogStore = this.auditLogStore;
        var auditLogLoading = auditLogStore.auditLogLoading, auditLogs = auditLogStore.auditLogs, filters = auditLogStore.filters, filterLabels = auditLogStore.filterLabels, changeFilters = auditLogStore.changeFilters, auditLogCount = auditLogStore.auditLogCount, auditLogFilters = auditLogStore.auditLogFilters;
        var siteId = this.props.siteStore.siteId;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(Provider, { auditLogStore: this.auditLogStore },
                React.createElement(React.Fragment, null,
                    React.createElement(RdiTypography, { variant: "h1" }, "Audit log"),
                    React.createElement(FlexContainer, { margin: 10, style: { flexWrap: 'wrap' } },
                        React.createElement(RdiInput, { type: "date", time: "startOfDay", label: "From", style: { width: '175px' }, value: filters.fromDate, onChange: function (e) { return changeFilters({ fromDate: e.value, page: 1 }); } }),
                        React.createElement(RdiInput, { type: "date", time: "endOfDay", label: "To", value: filters.toDate, onChange: function (e) { return changeFilters({ toDate: e.value, page: 1 }); }, style: { width: '175px' } }),
                        auditLogFilters && (React.createElement(React.Fragment, null,
                            React.createElement(RdiInput, { type: "select", label: "Action", name: "filter-action", style: { width: '175px' }, value: filters.action, selectedItemLabel: filterLabels.action, onChange: function (e) {
                                    return e.value
                                        ? changeFilters({ action: e.value, page: 1 }, { action: e.label })
                                        : changeFilters({ action: null, page: 1 }, { action: 'All' });
                                }, options: __spreadArrays([{ label: 'All', slug: null }], auditLogFilters.action).map(function (action) { return ({
                                    value: action.slug,
                                    label: action.label
                                }); }) }),
                            React.createElement(RdiInput, { type: "select", label: "Patient", name: "filter-patient", style: { width: '175px' }, value: filters.patientId, selectedItemLabel: filterLabels.patientId, onChange: function (e) {
                                    return e.value
                                        ? changeFilters({ patientId: e.value, page: 1 }, { patientId: e.label })
                                        : changeFilters({ patientId: null, page: 1 }, { patientId: 'All' });
                                }, options: __spreadArrays([{ label: 'All', slug: null }], auditLogFilters.patientId).map(function (patientId) { return ({
                                    value: patientId.slug,
                                    label: patientId.label
                                }); }) }),
                            React.createElement(RdiInput, { type: "select", label: "Trial", name: "filter-trial", style: { width: '175px' }, value: filters.trialId, selectedItemLabel: filterLabels.trialId, onChange: function (e) {
                                    return e.value
                                        ? changeFilters({ trialId: e.value, page: 1 }, { trialId: e.label })
                                        : changeFilters({ trialId: null, page: 1 }, { trialId: 'All' });
                                }, options: __spreadArrays([{ label: 'All', slug: null }], auditLogFilters.trialId).map(function (trialId) { return ({
                                    value: trialId.slug,
                                    label: trialId.label
                                }); }) }),
                            React.createElement(RdiInput, { type: "select", label: "User", name: "filter-author", style: { width: '175px' }, value: filters.authorId, selectedItemLabel: filterLabels.authorId, onChange: function (e) {
                                    return e.value
                                        ? changeFilters({ authorId: e.value, page: 1 }, { authorId: e.label })
                                        : changeFilters({ authorId: null, page: 1 }, { authorId: 'All' });
                                }, options: __spreadArrays([{ label: 'All', slug: null }], auditLogFilters.authorId).map(function (authorId) { return ({
                                    value: authorId.slug,
                                    label: authorId.label
                                }); }) })))),
                    React.createElement(RdiTable, null,
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeFilters, filters: filters })); }))),
                        !auditLogLoading && (React.createElement(TableBody, null,
                            auditLogs.map(function (item, index) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
                                return (React.createElement(TableRow, { key: index },
                                    React.createElement(TableCell, null, formatDatetime(item.txTime)),
                                    React.createElement(TableCell, null, (_a = item.author) === null || _a === void 0 ? void 0 : _a.fullName),
                                    React.createElement(TableCell, null,
                                        ((_b = item.related) === null || _b === void 0 ? void 0 : _b.enrollment) && (React.createElement(RdiLink, { to: getPatientRoute(siteId, (_d = (_c = item.related) === null || _c === void 0 ? void 0 : _c.patient) === null || _d === void 0 ? void 0 : _d.id, (_f = (_e = item.related) === null || _e === void 0 ? void 0 : _e.trial) === null || _f === void 0 ? void 0 : _f.id, (_h = (_g = item.related) === null || _g === void 0 ? void 0 : _g.enrollment) === null || _h === void 0 ? void 0 : _h.id) }, (_k = (_j = item.related) === null || _j === void 0 ? void 0 : _j.patient) === null || _k === void 0 ? void 0 : _k.fullName)),
                                        ((_l = item.related) === null || _l === void 0 ? void 0 : _l.patient) && !((_m = item.related) === null || _m === void 0 ? void 0 : _m.enrollment) && (React.createElement(RdiLink, { to: getPatientOverviewRoute(siteId, (_p = (_o = item.related) === null || _o === void 0 ? void 0 : _o.patient) === null || _p === void 0 ? void 0 : _p.id) }, (_r = (_q = item.related) === null || _q === void 0 ? void 0 : _q.patient) === null || _r === void 0 ? void 0 : _r.fullName))),
                                    React.createElement(TableCell, null,
                                        React.createElement(RdiLink, { to: getTrialRoute(siteId, (_t = (_s = item.related) === null || _s === void 0 ? void 0 : _s.trial) === null || _t === void 0 ? void 0 : _t.id) }, (_v = (_u = item.related) === null || _u === void 0 ? void 0 : _u.trial) === null || _v === void 0 ? void 0 : _v.name)),
                                    React.createElement(TableCell, null, item.action),
                                    React.createElement(TableCell, null,
                                        React.createElement(RdiLink, { to: _this.getDetailRoute(item) }, item.txId))));
                            }),
                            !auditLogLoading && auditLogCount === 0 && (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 7 }, "No results")))))),
                    !auditLogLoading && (React.createElement(RdiTablePagination, { count: auditLogCount, rowsPerPage: filters.rowsPerPage, page: filters.page, onChangePage: function (e, newPage) { return changeFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                            return changeFilters({
                                rowsPerPage: parseInt(e.target.value, 10),
                                page: 1
                            });
                        } })),
                    auditLogLoading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                        React.createElement(CircularProgress, null)))),
                React.createElement(Route, { exact: true, path: SiteRoutes.VIEW_AUDIT_LOG },
                    React.createElement(AuditLogModal, null)))));
    };
    AuditLogListPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], AuditLogListPage);
    return AuditLogListPage;
}(Component));
export default withStyles(styles)(withRouter(AuditLogListPage));
