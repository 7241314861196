var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var RdiLine = function (props) {
    var margin = 25;
    if (props.xs) {
        margin = 10;
    }
    else if (props.md) {
        margin = 20;
    }
    return (React.createElement("div", { style: __assign(__assign(__assign({ width: "100%", height: "1px", backgroundColor: ColorsEnum.GreySuperlightLine }, (props.margin === "none" ? {} : { marginBottom: margin })), (props.margin === "none" ? {} : { marginTop: margin })), props.style), className: props.className }));
};
export default RdiLine;
