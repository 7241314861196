var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { createStyles, withStyles } from '@material-ui/core';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectTrialStore } from '~/stores/TrialStore';
var styles = function () { return createStyles({}); };
var WeblinkAddModal = /** @class */ (function (_super) {
    __extends(WeblinkAddModal, _super);
    function WeblinkAddModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasErrors = false;
        _this.data = {
            label: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            href: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            }
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, Promise, function () {
            var _a, _b, addTrialSiteLink, updateTrialSiteLink, isEdit, link;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        _a = this.props, _b = _a.trialStore, addTrialSiteLink = _b.addTrialSiteLink, updateTrialSiteLink = _b.updateTrialSiteLink, isEdit = _a.isEdit;
                        link = {
                            label: this.data.label.value,
                            href: this.data.href.value
                        };
                        if (!isEdit) return [3 /*break*/, 2];
                        return [4 /*yield*/, updateTrialSiteLink(this.weblinkIndex, link)];
                    case 1:
                        _c.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, addTrialSiteLink(link)];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.parentUrl = function () {
            var _a = _this.props, url = _a.match.url, isEdit = _a.isEdit;
            return url.split(isEdit ? '/edit' : '/add')[0];
        };
        _this.onClose = function () {
            var _a = _this.props, onClose = _a.onClose, history = _a.history;
            if (onClose) {
                onClose();
            }
            history.push(_this.parentUrl());
        };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        return _this;
    }
    WeblinkAddModal.prototype.componentDidMount = function () {
        var _a;
        var _b = this.props, siteRoleTrial = _b.trialStore.siteRoleTrial, isEdit = _b.isEdit;
        if (isEdit && ((_a = siteRoleTrial === null || siteRoleTrial === void 0 ? void 0 : siteRoleTrial.websiteLinks) === null || _a === void 0 ? void 0 : _a.length)) {
            this.data.label.value = siteRoleTrial.websiteLinks[this.weblinkIndex].label;
            this.data.href.value = siteRoleTrial.websiteLinks[this.weblinkIndex].href;
        }
    };
    Object.defineProperty(WeblinkAddModal.prototype, "weblinkIndex", {
        get: function () {
            var params = this.props.match.params;
            return Number(params.index);
        },
        enumerable: false,
        configurable: true
    });
    WeblinkAddModal.prototype.render = function () {
        var _this = this;
        var _a = this.props, siteRoleTrialLoading = _a.trialStore.siteRoleTrialLoading, isEdit = _a.isEdit;
        return (React.createElement(SmallModal, { loading: siteRoleTrialLoading, onClose: this.onClose },
            React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '0px' } }, isEdit ? 'Edit link' : 'New link'),
            React.createElement(RdiLine, { style: { margin: '15px 0px' } }),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent, style: { flex: '1' } },
                React.createElement("div", { style: { flex: '1' } },
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20 },
                        React.createElement(RdiInput, { type: "text", label: "Label", name: "label", value: this.data.label.value, errorText: this.data.label.error, onChange: function (e) { return fieldChange(e, 'label', _this.data); } }),
                        React.createElement(RdiInput, { type: "text", label: "Link", name: "link", placeholder: "https://example.com/", value: this.data.href.value, errorText: this.data.href.error, onChange: function (e) { return fieldChange(e, 'href', _this.data); } }))),
                React.createElement(FlexContainer, { justifyContent: "flex-end" },
                    React.createElement(RdiButton, { loading: siteRoleTrialLoading, submit: true, margin: "none" }, isEdit ? 'Save' : 'Add')))));
    };
    __decorate([
        observable
    ], WeblinkAddModal.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], WeblinkAddModal.prototype, "data", void 0);
    __decorate([
        computed
    ], WeblinkAddModal.prototype, "weblinkIndex", null);
    WeblinkAddModal = __decorate([
        injectTrialStore,
        observer
    ], WeblinkAddModal);
    return WeblinkAddModal;
}(Component));
export default withStyles(styles)(withRouter(WeblinkAddModal));
