var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { EnrollmentStatusEnum, EnrollmentStatusLabels } from '@cros/shared/constants/enums/EnrollmentStatusEnum';
import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PatientCard from '~/components/patient/PatientCardContainer';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
var TrialProgressCard = function (props) {
    var _a = props.enrollment, status = _a.status, progress = _a.progress, accountStore = props.accountStore, siteStore = props.siteStore;
    var _b = useState(status), enrollmentStatus = _b[0], setEnrollmentStatus = _b[1];
    useEffect(function () {
        setEnrollmentStatus(status);
    }, [status]);
    var updateStatus = function (newStatus) {
        props.updateEnrollment(__assign(__assign({}, props.enrollment), { status: newStatus }));
        setEnrollmentStatus(newStatus);
    };
    return (React.createElement(PatientCard, null,
        React.createElement(FlexContainer, { justifyContent: "space-between" },
            React.createElement(RdiTypography, { variant: "subtitle2" }, "TRIAL PROGRESS")),
        React.createElement("div", { style: { width: '100%' } },
            React.createElement(RdiTypography, { style: { marginBottom: '6px' }, variant: "h1" }, progress + "%"),
            React.createElement(LinearProgress, { color: progress === 100 ? 'secondary' : 'primary', value: progress, variant: "determinate", style: { height: '8px' } })),
        React.createElement("div", null,
            React.createElement(RdiInput, { style: { marginBottom: '0px', marginTop: '8px' }, type: "select", permissions: [
                    {
                        aclOp: AclOpEnum.UPDATE,
                        aclModule: AclModuleEnum.PATIENT
                    }
                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, name: "status", borderWidth: "1px", placeholder: "Select status", selectedItemLabel: EnrollmentStatusLabels[enrollmentStatus], value: enrollmentStatus, onChange: function (change) { return updateStatus(change.value); }, options: Object.values(EnrollmentStatusEnum).map(function (enumType) { return ({
                    value: enumType,
                    label: EnrollmentStatusLabels[enumType]
                }); }) }))));
};
export default injectAccountStore(injectSiteStore(TrialProgressCard));
