export var AdverseEventIpRelationEnum;
(function (AdverseEventIpRelationEnum) {
    AdverseEventIpRelationEnum["YES_DEFINITELY_RELATED"] = "yes-definitely-related";
    AdverseEventIpRelationEnum["POSSIBLY_RELATED"] = "possibly-related";
    AdverseEventIpRelationEnum["NOT_RELATED"] = "not-related";
})(AdverseEventIpRelationEnum || (AdverseEventIpRelationEnum = {}));
export var AdverseEventIpRelationLabelsEnum;
(function (AdverseEventIpRelationLabelsEnum) {
    AdverseEventIpRelationLabelsEnum["yes-definitely-related"] = "Yes definitely related";
    AdverseEventIpRelationLabelsEnum["possibly-related"] = "Possibly related";
    AdverseEventIpRelationLabelsEnum["not-related"] = "Not related";
})(AdverseEventIpRelationLabelsEnum || (AdverseEventIpRelationLabelsEnum = {}));
