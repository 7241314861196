export var AclModuleEnum;
(function (AclModuleEnum) {
    AclModuleEnum["ALL"] = "All";
    AclModuleEnum["COMPANY"] = "Company";
    AclModuleEnum["COMPANY_STATISTICS"] = "CompanyStatistics";
    AclModuleEnum["ADVERSE_EVENT"] = "AdverseEvent";
    AclModuleEnum["MEDICATION"] = "Medication";
    AclModuleEnum["INSURANCE"] = "Insurance";
    AclModuleEnum["MEDICAL_RECORD"] = "MedicalRecord";
    AclModuleEnum["IP_DISPENSING_LOG"] = "IpDispensingLog";
    AclModuleEnum["IP_DOSING_LOG"] = "IpDosingLog";
    AclModuleEnum["DEVICE"] = "Device";
    AclModuleEnum["MEDICAL_HISTORY"] = "MedicalHistory";
    AclModuleEnum["TASK"] = "Task";
    AclModuleEnum["NOTE"] = "Note";
    AclModuleEnum["PAYMENT"] = "Payment";
    AclModuleEnum["CLAIM"] = "Claim";
    AclModuleEnum["ENROLLMENT"] = "Enrollment";
    AclModuleEnum["TRIAL"] = "Trial";
    AclModuleEnum["PATIENT"] = "Patient";
    AclModuleEnum["PROTOCOL"] = "Protocol";
    AclModuleEnum["VISIT"] = "Visit";
    AclModuleEnum["PROCEDURE"] = "Procedure";
    AclModuleEnum["JOURNAL"] = "AuditLog";
    AclModuleEnum["USER"] = "User";
    AclModuleEnum["BILLING"] = "Billing";
    AclModuleEnum["PROVIDER"] = "Provider";
    AclModuleEnum["SETTINGS"] = "Settings";
    AclModuleEnum["CLINIC_DOCUMENTS"] = "ClinicDocuments";
    AclModuleEnum["PRESENTATION_LIBRARY"] = "PresentationLibrary";
    AclModuleEnum["USER_MANAGEMENT"] = "UserManagement";
})(AclModuleEnum || (AclModuleEnum = {}));
