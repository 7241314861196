var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { newFieldChange } from '@cros/shared/utils/utils';
import { newUtilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { injectAccountStore } from '~/stores/AccountStore';
import EnrollmentStore from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
import UserStore from '~/stores/UserStore';
var styles = function () { return createStyles({}); };
var ManageProviderModal = function (props) {
    var _a;
    var history = props.history, _b = props.match, url = _b.url, _c = _b.params, manageEnrollmentId = _c.manageEnrollmentId, manageTrialId = _c.manageTrialId, patient = props.patientStore.patient;
    var _d = useState({
        providerId: null
    }), data = _d[0], setData = _d[1];
    var _e = useState({
        providerId: null
    }), labels = _e[0], setLabels = _e[1];
    var _f = useState({
        providerId: null
    }), errors = _f[0], setErrors = _f[1];
    var rules = useState({
        providerId: 'required'
    })[0];
    var _g = useState(true), loading = _g[0], setLoading = _g[1];
    var _h = useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var _j = useState(false), saving = _j[0], setSaving = _j[1];
    var enrollmentStore = useState(function () { return new EnrollmentStore(props.siteStore); })[0];
    var userStore = useState(function () { return new UserStore(props.siteStore); })[0];
    var onClose = function () {
        history.push("" + url.split('/manage')[0]);
    };
    var submitEvent = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedEnrollment, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    e.stopPropagation();
                    if (newUtilValidate(data, errors, rules, null, null, true, setErrors)) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, enrollmentStore.updateEnrollment({
                            providerId: data.providerId
                        })];
                case 2:
                    updatedEnrollment = _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    // get validation errors
                    setErrorMessage(error_1.message || error_1.detail || error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setSaving(false);
                    return [7 /*endfinally*/];
                case 5:
                    // assign new data into patient enrollments array
                    patient.enrollments.map(function (en) {
                        if (en.id === updatedEnrollment.id) {
                            en.providerId = updatedEnrollment.providerId;
                            en.provider = updatedEnrollment.provider;
                        }
                        return e;
                    });
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var fieldChange = function (e, field) {
        newFieldChange(e, field, data, labels, setData, setLabels);
    };
    useEffect(function () {
        var componentDidMount = function () { return __awaiter(void 0, void 0, void 0, function () {
            var enrollment;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, enrollmentStore.getEnrollment(manageEnrollmentId, manageTrialId)];
                    case 1:
                        enrollment = _b.sent();
                        return [4 /*yield*/, userStore.listUserFilters()];
                    case 2:
                        _b.sent();
                        setData({
                            providerId: enrollment.providerId
                        });
                        setLabels({
                            providerId: (_a = enrollment.provider) === null || _a === void 0 ? void 0 : _a.fullName
                        });
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        componentDidMount();
    }, []);
    return (React.createElement(SmallModal, { loading: loading, onClose: onClose },
        React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, "Manage provider"),
        React.createElement(FlexContainer, { style: { marginTop: '10px', marginBottom: '15px' }, margin: 15 },
            React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient === null || patient === void 0 ? void 0 : patient.fullName),
            React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
        React.createElement(RdiLine, null),
        React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: submitEvent },
            React.createElement(RdiInput, { type: "select", label: "Provider", name: "providerId", value: data.providerId, errorText: errors.providerId, selectedItemLabel: labels.providerId, onChange: function (e) { return fieldChange(e, 'providerId'); }, autoFocus: true, options: ((_a = userStore.requestFilters.providerId) === null || _a === void 0 ? void 0 : _a.map(function (user) { return ({
                    value: user.slug,
                    label: user.label
                }); })) || [] }),
            React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
            React.createElement(RdiErrorBox, null, errorMessage),
            React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                React.createElement(RdiButton, { disabled: saving, loading: saving, style: { minWidth: '220px' }, fullWidth: false, submit: true }, "Save")))));
};
ManageProviderModal.defaultProps = {};
export default withRouter(withStyles(styles)(injectPatientStore(injectSiteStore(injectAccountStore(observer(ManageProviderModal))))));
