import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
var DevicesTable = function (props) {
    var columns = [
        {
            id: 'code',
            label: 'Name',
            sortable: false
        },
        {
            id: 'amount',
            label: 'Days active',
            sortable: false
        },
        {
            id: 'item',
            label: 'Last update',
            sortable: false
        }
    ];
    var devices = props.devices;
    return (React.createElement(FlexContainer, { flexDirection: "column", alignItems: "stretch", margin: 10, style: { width: '100%' } },
        React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "Devices"),
        React.createElement(RdiTable, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column })); }))),
            React.createElement(TableBody, null,
                devices.map(function (device, index) { return (React.createElement(TableRow, { key: index },
                    React.createElement(TableCell, null, device.name),
                    React.createElement(TableCell, null, device.amount),
                    React.createElement(TableCell, null, formatDate(device.lastUpdated)))); }),
                devices.length === 0 && (React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: 3 }, "No results")))))));
};
DevicesTable.defaultProps = {};
export default DevicesTable;
