var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from "mobx";
import { MedicalRecord } from "s~/types/models/MedicalRecord";
import { Model } from "s~/types/models/Model";
import { Trial } from "s~/types/models/Trial";
import { getDateFromString } from "s~/utils/dateUtils";
var Insurance = /** @class */ (function (_super) {
    __extends(Insurance, _super);
    function Insurance(i) {
        var _this = _super.call(this, i) || this;
        _this.name = i.name;
        _this.id = i.id;
        _this.createdAt = i.createdAt ? getDateFromString(i.createdAt) : null;
        _this.startDate = i.startDate ? getDateFromString(i.startDate) : undefined;
        _this.stopDate = i.stopDate ? getDateFromString(i.stopDate) : undefined;
        _this.medicalRecord = i.medicalRecord
            ? new MedicalRecord(i.medicalRecord)
            : undefined;
        _this.medicalRecordId = i.medicalRecordId;
        _this.trial = i.trial ? new Trial(i.trial) : undefined;
        _this.trialId = i.trialId;
        _this.enrollmentId = i.enrollmentId;
        _this.status = i.status;
        _this.lastUpdated = i.lastUpdated
            ? getDateFromString(i.lastUpdated)
            : undefined;
        return _this;
    }
    __decorate([
        observable
    ], Insurance.prototype, "expanded", void 0);
    return Insurance;
}(Model));
export { Insurance };
