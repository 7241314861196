var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Validator from "validatorjs";
var customMessages = {
    array: "At least one item is required",
    required: "Required",
    date: "Not a valid date format",
    numeric: "Must be a number",
    integer: "Must be a number",
    min: {
        string: "Must be at least :min characters",
        numeric: "Must be at least :min"
    },
    max: {
        string: "May not be greater than :max characters",
        numeric: "May not be greater than :max"
    },
    email: "Email format is invalid",
    boolean: "Must be true or false",
    required_with: "Required",
    required_unless: "Required",
    required_if: "Required",
    regex: "Between 5 and 30 characters (no special characters other than - and _ )"
};
// deprecated
var mapValidationErrors = function (data, validationErrors, mapApiFieldPropertyToInternal) {
    for (var _i = 0, _a = Object.keys(validationErrors); _i < _a.length; _i++) {
        var errorKey = _a[_i];
        var internalProperty = void 0;
        if (mapApiFieldPropertyToInternal) {
            internalProperty = mapApiFieldPropertyToInternal(errorKey);
        }
        else {
            internalProperty = errorKey;
        }
        var props = internalProperty.split(".");
        switch (props.length) {
            case 1:
                if (Object.keys(data).indexOf(internalProperty) + 1) {
                    data[internalProperty].error =
                        validationErrors[errorKey].message || validationErrors[errorKey][0];
                }
                break;
            case 2:
                if (Object.keys(data).indexOf(props[1]) + 1) {
                    data[props[1]].error =
                        validationErrors[errorKey].message || validationErrors[errorKey][0];
                }
                break;
            case 3:
                if (Object.keys(data).indexOf(props[0]) + 1) {
                    data[props[0]].value[parseInt(props[1], 10)][props[2]].error =
                        validationErrors[errorKey].message || validationErrors[errorKey][0];
                }
                break;
            default:
                if (Object.keys(data).indexOf(internalProperty) + 1) {
                    data[internalProperty].error = validationErrors[errorKey].message;
                }
                break;
        }
    }
};
// deprecated
export var utilValidate = function (data, mapApiFieldPropertyToInternal, validationErrors, includeFrontendValidation) {
    var hasErrors = false;
    // clear old errors, traverse nested arrays
    for (var _i = 0, _a = Object.keys(data); _i < _a.length; _i++) {
        var key = _a[_i];
        data[key].error = null;
        if (!Array.isArray(data[key].value)) {
            continue;
        }
        for (var _b = 0, _c = data[key].value; _b < _c.length; _b++) {
            var innerObj = _c[_b];
            for (var _d = 0, _e = Object.keys(innerObj); _d < _e.length; _d++) {
                var innerKey = _e[_d];
                if (typeof innerObj[innerKey] === "string" ||
                    typeof innerObj[innerKey] === "number" ||
                    typeof innerObj[innerKey] === "boolean" ||
                    !innerObj[innerKey]) {
                    continue;
                }
                innerObj[innerKey].error = null;
            }
        }
    }
    // backend validation
    if (validationErrors) {
        mapValidationErrors(data, validationErrors, mapApiFieldPropertyToInternal);
        hasErrors = true;
        return hasErrors;
    }
    if (!includeFrontendValidation) {
        return hasErrors;
    }
    var rules = {};
    var values = {};
    for (var _f = 0, _g = Object.keys(data); _f < _g.length; _f++) {
        var key = _g[_f];
        if (data[key].value) {
            values[key] = data[key].value;
        }
        if (data[key].rule) {
            rules[key] = data[key].rule;
        }
    }
    var validation = new Validator(values, rules, customMessages);
    if (!validation.passes() || validationErrors) {
        hasErrors = true;
    }
    else {
        hasErrors = false;
    }
    mapValidationErrors(data, validation.errors.errors, mapApiFieldPropertyToInternal);
    return hasErrors;
};
var newMapValidationErrors = function (errors, validationErrors, mapApiFieldPropertyToInternal, setErrors) {
    for (var _i = 0, _a = Object.keys(validationErrors); _i < _a.length; _i++) {
        var errorKey = _a[_i];
        var internalProperty = void 0;
        if (mapApiFieldPropertyToInternal) {
            internalProperty = mapApiFieldPropertyToInternal(errorKey);
        }
        else {
            internalProperty = errorKey;
        }
        errors[internalProperty] =
            validationErrors[errorKey].message || validationErrors[errorKey][0];
    }
    if (setErrors) {
        setErrors(__assign({}, errors));
    }
};
export var newUtilValidate = function (data, errors, rules, mapApiFieldPropertyToInternal, validationErrors, includeFrontendValidation, setErrors) {
    var hasErrors = false;
    // clear old errors
    for (var _i = 0, _a = Object.keys(errors); _i < _a.length; _i++) {
        var key = _a[_i];
        errors[key] = undefined;
    }
    // backend validation
    if (validationErrors) {
        newMapValidationErrors(errors, validationErrors, mapApiFieldPropertyToInternal, setErrors);
        hasErrors = true;
        return hasErrors;
    }
    if (!includeFrontendValidation) {
        return hasErrors;
    }
    var values = {};
    for (var _b = 0, _c = Object.keys(data); _b < _c.length; _b++) {
        var key = _c[_b];
        if (data[key]) {
            values[key] = data[key];
        }
    }
    var validation = new Validator(values, rules, customMessages);
    if (!validation.passes()) {
        hasErrors = true;
    }
    else {
        hasErrors = false;
    }
    newMapValidationErrors(errors, validation.errors.errors, mapApiFieldPropertyToInternal, setErrors);
    return hasErrors;
};
