var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { getDateFromTimeAndDate } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { IpDispensingStore } from '~/stores/PatientStore/IpDispensingStore';
var NewIpDosingLog = /** @class */ (function (_super) {
    __extends(NewIpDosingLog, _super);
    function NewIpDosingLog(props) {
        var _this = _super.call(this, props) || this;
        _this.data = {
            ipDispensingLogId: {
                value: '',
                label: '',
                error: null,
                rule: 'required'
            },
            dosingStaffName: {
                value: '',
                error: null,
                rule: 'required'
            },
            dosedAt: {
                value: new Date(),
                error: null,
                rule: 'required|date'
            },
            dosedAtTime: {
                value: new Date(),
                error: null,
                rule: 'required|date'
            }
        };
        _this.hasErrors = false;
        _this.errorMessage = '';
        _this.saving = false;
        _this.loading = true;
        _this.assignData = function (dosingLog) {
            _this.data.ipDispensingLogId.value = dosingLog ? dosingLog.ipDispensingLogId : '';
            _this.data.ipDispensingLogId.label = (dosingLog === null || dosingLog === void 0 ? void 0 : dosingLog.ipDispensingLog) ? dosingLog.ipDispensingLog.ipNumber.toString()
                : '';
            _this.data.dosingStaffName.value = (dosingLog === null || dosingLog === void 0 ? void 0 : dosingLog.dosingStaffName) || '';
            _this.data.dosedAt.value = (dosingLog === null || dosingLog === void 0 ? void 0 : dosingLog.dosedAt) || null;
            _this.data.dosedAtTime.value = (dosingLog === null || dosingLog === void 0 ? void 0 : dosingLog.dosedAt) || null;
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var ipDosingStore, ipLog, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ipDosingStore = this.props.enrollmentStore.ipDosingStore;
                        return [4 /*yield*/, this.ipDispensingStore.listIpDispensingLogs()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        if (!this.isEdit) return [3 /*break*/, 4];
                        return [4 /*yield*/, ipDosingStore.getIpDosignLogById(this.ipDosingLogId)];
                    case 3:
                        ipLog = _a.sent();
                        this.assignData(ipLog);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        return [3 /*break*/, 6];
                    case 6:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split((_this.isEdit ? '/editdo' : '') + "/" + (_this.ipDosingLogId || 'newdo'))[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.submit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var ipDosingStore, editLog, newLog, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ipDosingStore = this.props.enrollmentStore.ipDosingStore;
                        e.preventDefault();
                        e.stopPropagation();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        editLog = this.getObjectToSave();
                        this.saving = true;
                        this.errorMessage = '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, ipDosingStore.addIpDosingLog(editLog)];
                    case 2:
                        newLog = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, ipDosingStore.updateIdDosingLog(editLog)];
                    case 4:
                        newLog = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_2 = _a.sent();
                        this.errorMessage = error_2.message || error_2.detail || error_2;
                        this.hasErrors = this.validateForm(error_2.meta);
                        return [3 /*break*/, 8];
                    case 7:
                        this.saving = false;
                        if (!newLog) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors);
        };
        _this.getObjectToSave = function () {
            var o = {
                id: _this.ipDosingLogId,
                dosingStaffName: _this.data.dosingStaffName.value,
                ipDispensingLogId: _this.data.ipDispensingLogId.value,
                dosedAt: _this.data.dosedAtTime.value && _this.data.dosedAt.value
                    ? getDateFromTimeAndDate(_this.data.dosedAtTime.value, _this.data.dosedAt.value)
                    : null
            };
            return o;
        };
        var enrollmentStore = _this.props.enrollmentStore;
        _this.ipDispensingStore = new IpDispensingStore(enrollmentStore);
        return _this;
    }
    Object.defineProperty(NewIpDosingLog.prototype, "ipDosingLogId", {
        get: function () {
            var params = this.props.match.params;
            return params.ipDosingLogId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewIpDosingLog.prototype, "isEdit", {
        get: function () {
            return !!this.ipDosingLogId;
        },
        enumerable: false,
        configurable: true
    });
    NewIpDosingLog.prototype.render = function () {
        var _this = this;
        var patient = this.props.patientStore.patient;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, "Dose administration"),
            React.createElement(FlexContainer, { style: { marginTop: '10px', marginBottom: '15px' }, margin: 15 },
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient.fullName),
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
            React.createElement(RdiLine, null),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submit },
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "select", label: "IP log", name: "ipDispensingLogId", value: this.data.ipDispensingLogId.value, selectedItemLabel: this.data.ipDispensingLogId.label, errorText: this.data.ipDispensingLogId.error, onChange: function (e) { return _this.fieldChange(e, 'ipDispensingLogId'); }, options: this.ipDispensingStore.logs &&
                            this.ipDispensingStore.logs.map(function (x) { return ({
                                value: x.id,
                                label: x.ipNumber
                            }); }), autoFocus: true })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { type: "date", label: "Date of dose", name: "dosedAt", value: this.data.dosedAt.value, errorText: this.data.dosedAt.error, onChange: function (e) { return _this.fieldChange(e, 'dosedAt'); } }),
                    React.createElement(RdiInput, { type: "time", label: "Time of dose", name: "dosedAtTime", value: this.data.dosedAtTime.value, errorText: this.data.dosedAtTime.error, onChange: function (e) { return _this.fieldChange(e, 'dosedAtTime'); } })),
                React.createElement(FlexContainer, null,
                    React.createElement(RdiInput, { type: "text", label: "Staff dosing", name: "dosingStaffName", value: this.data.dosingStaffName.value, errorText: this.data.dosingStaffName.error, onChange: function (e) { return _this.fieldChange(e, 'dosingStaffName'); } })),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, submit: true }, this.isEdit ? 'Update' : 'Add')))));
    };
    __decorate([
        observable
    ], NewIpDosingLog.prototype, "data", void 0);
    __decorate([
        observable
    ], NewIpDosingLog.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], NewIpDosingLog.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], NewIpDosingLog.prototype, "saving", void 0);
    __decorate([
        observable
    ], NewIpDosingLog.prototype, "loading", void 0);
    __decorate([
        computed
    ], NewIpDosingLog.prototype, "ipDosingLogId", null);
    __decorate([
        computed
    ], NewIpDosingLog.prototype, "isEdit", null);
    NewIpDosingLog = __decorate([
        injectPatientStore,
        injectEnrollmentStore,
        observer
    ], NewIpDosingLog);
    return NewIpDosingLog;
}(Component));
var InputRow = function (props) {
    var children = props.children;
    return (React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 }, children));
};
export default withRouter(NewIpDosingLog);
