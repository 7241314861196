import { differenceInMinutes } from "date-fns";
import { parseTime } from "s~/utils/dateUtils";
// json logic function: calculates minutes difference between two time fields represented via type: string, template_name: time
export var timeDiff = function (a, b, ctx) {
    if (!a || !b) {
        return null;
    }
    var aVal = ctx.$procedure[a].value;
    var bVal = ctx.$procedure[b].value;
    if (!aVal || !bVal) {
        return null;
    }
    var aDate = parseTime(aVal);
    var bDate = parseTime(bVal);
    return differenceInMinutes(bDate, aDate);
};
// json logic function: rounds number to two decimal places, if needed
export var mathRoundTwoPlaces = function (a, ctx) {
    var aVal = Number(a);
    if (!aVal) {
        return null;
    }
    return Math.round((aVal + Number.EPSILON) * 100) / 100;
};
