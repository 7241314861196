var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Model } from "s~/types/models/Model";
import { getDateFromString } from "s~/utils/dateUtils";
var Transaction = /** @class */ (function (_super) {
    __extends(Transaction, _super);
    function Transaction(e) {
        var _this = _super.call(this, e) || this;
        _this.id = e.id;
        _this.patient = e.patient;
        _this.patientId = e.patientId;
        _this.trial = e.trial;
        _this.trialId = e.trialId;
        _this.status = e.status;
        _this.amount = e.amount;
        _this.currencyCode = e.currencyCode;
        _this.completionDate = e.completionDate
            ? getDateFromString(e.completionDate)
            : null;
        _this.createdAt = e.createdAt ? getDateFromString(e.createdAt) : null;
        return _this;
    }
    return Transaction;
}(Model));
export { Transaction };
