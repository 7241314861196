// Example tree data
// const treeData = [
//   {
//     key: '0-0',
//     title: 'parent 1',
//     children: [
//       {
//         key: '0-0-0',
//         title: 'parent 1-1',
//         children: [{ key: '0-0-0-0', title: 'parent 1-1-0' }],
//       },
//       {
//         key: '0-0-1',
//         title: 'parent 1-2',
//         children: [
//           { key: '0-0-1-0', title: 'parent 1-2-0' },
//         ],
//       },
//     ],
//   },
// ];
var data = [];
export var generateTreeViewFromFiles = function (files) {
    var _a;
    data = [];
    (_a = files === null || files === void 0 ? void 0 : files.slice()) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) {
        return String(a.originalname).localeCompare(String(b.originalname));
    }).forEach(function (file) { return transformFilesToTreeView(file, data); });
    return [{ key: "0", title: "Documents", isFolder: true, children: data }];
};
// Recursevly transfer files to tree view by name folder/subfolder/file.pdf becomes folder->subfolder->file.pdf
var transformFilesToTreeView = function (file, arr, level) {
    var _a;
    if (level === void 0) { level = 0; }
    var fileFolders = file.originalname.split("/");
    var title = fileFolders[level];
    var a = arr.find(function (a) { return a.title === title; });
    arr.sort(function (a, b) {
        if (a.isFolder) {
            return -1;
        }
        else if (b.isFolder) {
            return 1;
        }
        else {
            if (a.title > b.title) {
                return 1;
            }
            else if (a.title < b.title) {
                return -1;
            }
            else {
                return 0;
            }
        }
    });
    if (!a) {
        var fullname = fileFolders.slice(0, level + 1).join("/");
        var obj = {
            key: "0-" + level + "-name:" + fullname + "-" + arr.length + "-url:" + file.url,
            title: title,
            fullname: fullname,
            file: file,
        };
        if (level !== file.originalname.split("/").length - 1) {
            obj.isFolder = true;
        }
        else if (file.url === "folder" && !((_a = obj.children) === null || _a === void 0 ? void 0 : _a.length)) {
            obj.children = [{ icon: "✘" }];
            obj.isFolder = true;
        }
        arr.push(obj);
        return transformFilesToTreeView(file, arr, level);
    }
    if (fileFolders.length > level + 1) {
        if (!a.children) {
            a.children = [];
        }
        a.key = a.key.split("-url:")[0];
        return transformFilesToTreeView(file, a.children, level + 1);
    }
};
