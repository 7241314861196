var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from "mobx";
import { Model } from "s~/types/models/Model";
import { Trial } from "s~/types/models/Trial";
import { getDateFromString } from "s~/utils/dateUtils";
import UploadedFile from "./UploadedFile";
var AdverseEvent = /** @class */ (function (_super) {
    __extends(AdverseEvent, _super);
    function AdverseEvent(p) {
        var _this = _super.call(this, p) || this;
        _this.id = p.id;
        _this.text = p.text;
        _this.visit = p.visit;
        _this.startDate = getDateFromString(p.startDate);
        _this.endDate = p.endDate ? getDateFromString(p.endDate) : undefined;
        _this.sae = p.sae;
        _this.withdrawn = p.withdrawn;
        _this.outcome = p.outcome;
        _this.actionTaken = p.actionTaken;
        _this.relationshipWithIp = p.relationshipWithIp;
        _this.saeForm = p.saeForm ? new UploadedFile(p.saeForm) : undefined;
        _this.saeFormId = p.saeForm ? p.saeForm.id : undefined;
        _this.trialId = p.trialId;
        _this.trial = p.trial ? new Trial(p.trial) : undefined;
        _this.siteId = p.siteId;
        _this.patientId = p.patientId;
        _this.enrollmentId = p.enrollmentId;
        return _this;
    }
    __decorate([
        observable
    ], AdverseEvent.prototype, "expanded", void 0);
    return AdverseEvent;
}(Model));
export { AdverseEvent };
