var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
var styles = function () {
    return createStyles({
        root: {
            display: "grid",
            gridGap: "1px",
            gridTemplateColumns: function (props) {
                return "repeat(" + props.gridColumns + ", 1fr)";
            }
            // gridAutoColumns: 'min-content'
        }
    });
};
var GridContainer = function (props) {
    var className = props.className, classes = props.classes;
    return (React.createElement("div", { className: className + " " + classes.root, style: __assign({}, props.style) }, props.children));
};
GridContainer.defaultProps = {
    gridColumns: 3
};
export default withStyles(styles)(GridContainer);
