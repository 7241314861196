import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { formatDatetime } from '@cros/shared/utils/dateUtils';
import { createStyles, withStyles } from '@material-ui/core';
import { differenceInMinutes, subDays } from 'date-fns';
import React from 'react';
var styles = function () {
    return createStyles({
        container: {
            fontSize: '13px'
        },
        note: {
            fontSize: '11px',
            fontWeight: 'bold'
        }
    });
};
var VisitNotificationsInfo = function (_a) {
    var _b;
    var classes = _a.classes, reminders = _a.reminders, engagement = _a.engagement;
    if (!engagement) {
        return React.createElement(React.Fragment, null);
    }
    var initialEmailReminder = reminders.initialEmailReminder, initialSmsReminder = reminders.initialSmsReminder, secondEmailReminder = reminders.secondEmailReminder, secondSmsReminder = reminders.secondSmsReminder;
    if (!reminders || !initialEmailReminder) {
        return React.createElement("p", { className: classes.note }, "Notifications were not sent");
    }
    var now = new Date();
    var secondEmailReminderDatetime = !secondEmailReminder
        ? subDays(engagement, 2)
        : secondEmailReminder.sentAt;
    var isBookedWithin48h = differenceInMinutes(engagement, initialEmailReminder.sentAt) < 48 * 60;
    var isSecondReminderInPast = secondEmailReminderDatetime < now;
    var isBookedWithin24hFromInitial = !isBookedWithin48h &&
        differenceInMinutes(secondEmailReminderDatetime, (_b = initialEmailReminder.sentAt) !== null && _b !== void 0 ? _b : now) < 24 * 60;
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexContainer, { flexDirection: "column", alignItems: "flex-start" },
            React.createElement("div", { className: classes.container },
                React.createElement("p", null,
                    "Initial reminder sent at ",
                    formatDatetime(initialEmailReminder.sentAt),
                    !initialSmsReminder && React.createElement(React.Fragment, null, " (SMS not sent)")),
                isBookedWithin48h && (React.createElement("p", null,
                    "Second reminder ",
                    isSecondReminderInPast ? "wasn't" : "won't be",
                    " sent since the visit was/will be booked within less than 48 hours from now")),
                isBookedWithin24hFromInitial && (React.createElement("p", null, "Second reminder won't be sent since there are no at least 24 hours between the initial and second reminder")),
                !isBookedWithin48h && !isBookedWithin24hFromInitial && (React.createElement("p", null,
                    "Second reminder ",
                    secondEmailReminder ? 'sent at ' : 'scheduled for ',
                    formatDatetime(secondEmailReminderDatetime),
                    !secondEmailReminder && React.createElement("span", null, " (\u00B11 hour)"),
                    secondEmailReminder && !secondSmsReminder && React.createElement(React.Fragment, null, " (SMS not sent)")))))));
};
export default withStyles(styles)(VisitNotificationsInfo);
