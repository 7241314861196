var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { PaymentStatusEnum } from '@cros/shared/constants/enums/PaymentStatusEnum';
import { PaymentTypeEnum } from '@cros/shared/constants/enums/PaymentTypeEnum';
import { AppBar, createStyles, Tab, Tabs, withStyles } from '@material-ui/core';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { Link as RLink } from 'react-router-dom';
import CheckPaymentListPage from '~/pages/site/site-detail/payments/CheckPaymentListPage';
import ClaimedPaymentListPage from '~/pages/site/site-detail/payments/ClaimedPaymentListPage';
import PaymentListPage from '~/pages/site/site-detail/payments/PaymentListPage';
import UnclaimedPaymentListPage from '~/pages/site/site-detail/payments/UnclaimedPaymentListPage';
import { getPaymentsAllRoute, getPaymentsChequeRoute, getPaymentsClaimedRoute, getPaymentsRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { TransactionStore } from '~/stores/TransactionStore';
import AddPayment from './AddPayment';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {},
            _a),
        menuContainer: (_b = {
                marginBottom: '20px',
                marginTop: '30px',
                flexWrap: 'wrap'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginTop: '0px'
            },
            _b)
    });
};
var PaymentsIndexPage = /** @class */ (function (_super) {
    __extends(PaymentsIndexPage, _super);
    function PaymentsIndexPage(props) {
        var _this = _super.call(this, props) || this;
        _this.tabs = [
            {
                id: 'payments-cheque',
                format: function (paymentsStatusCounts) {
                    return "Create a check (" + (paymentsStatusCounts.claimpending ? paymentsStatusCounts.claimpending : 0) + ")";
                },
                filters: {
                    type: PaymentTypeEnum.CHEQUE,
                    status: PaymentStatusEnum.PENDING
                },
                route: SiteRoutes.CHECK_PAYMENT_LIST_ROUTE,
                url: function (siteId) { return getPaymentsChequeRoute(siteId); }
            },
            {
                id: 'payments-claimed',
                format: function (paymentsStatusCounts) {
                    return "Claimed (" + (paymentsStatusCounts.claimcomplete ? paymentsStatusCounts.claimcomplete : 0) + ")";
                },
                filters: {
                    status: PaymentStatusEnum.COMPLETE
                },
                route: SiteRoutes.CLAIMED_PAYMENT_LIST_ROUTE,
                url: function (siteId) { return getPaymentsClaimedRoute(siteId); }
            },
            {
                id: 'payments-all',
                format: function (paymentsStatusCounts) {
                    return "All payments (" + (paymentsStatusCounts.all ? paymentsStatusCounts.all : 0) + ")";
                },
                filters: {},
                route: SiteRoutes.PAYMENT_LIST_ROUTE,
                url: function (siteId) { return getPaymentsAllRoute(siteId); }
            }
        ];
        _this.getAddPaymentUrl = function () {
            var _a = _this.props, siteId = _a.siteStore.siteId, _b = _a.location, pathname = _b.pathname, search = _b.search;
            var tab = _this.tabs.find(function (x) { return x.url(siteId) === pathname; });
            if (!tab) {
                return '';
            }
            return tab.url(siteId) + "/new" + search;
        };
        _this.getTab = function () {
            var pathname = _this.props.location.pathname;
            var regex = new RegExp(['/new', '/edit', '/send-check'].join('|'), 'g');
            var tab = pathname.split(regex)[0];
            return tab;
        };
        var _a = _this.props, siteStore = _a.siteStore, search = _a.location.search, history = _a.history;
        _this.transactionStore = new TransactionStore(siteStore, search, history);
        return _this;
    }
    PaymentsIndexPage.prototype.render = function () {
        var _a = this.props, siteId = _a.siteStore.siteId, pathname = _a.location.pathname, accountStore = _a.accountStore, siteStore = _a.siteStore, classes = _a.classes;
        var _b = this.transactionStore, _c = _b.paymentsStatusCounts, paymentsStatusCounts = _c === void 0 ? {} : _c, filters = _b.filters, changeFilters = _b.changeFilters;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(Provider, { transactionStore: this.transactionStore },
                React.createElement(Route, { exact: true, path: SiteRoutes.PAYMENTS_INDEX_ROUTE },
                    React.createElement(Redirect, { to: "" + getPaymentsChequeRoute(siteId) })),
                React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "Payments"),
                React.createElement(FlexContainer, { className: classes.menuContainer, alignItems: "flex-end", margin: 10 },
                    React.createElement(RdiInput, { style: { width: '200px' }, type: "search", placeholder: "Search", value: filters.search, onChange: function (e) { return changeFilters({ search: e.value }); } }),
                    React.createElement(RdiInput, { type: "date", time: "startOfDay", label: "From", style: { width: '180px' }, value: filters.fromDate, onChange: function (e) { return changeFilters({ fromDate: e.value }); } }),
                    React.createElement(RdiInput, { type: "date", time: "endOfDay", label: "To", value: filters.toDate, onChange: function (e) { return changeFilters({ toDate: e.value }); }, style: { width: '180px' } }),
                    React.createElement("div", { style: { flex: 1 } }),
                    React.createElement(RdiButton, { name: "new-payment", fullWidth: false, to: this.getAddPaymentUrl, style: { marginBottom: '14px' }, permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.PAYMENT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Create a payment")),
                pathname !== getPaymentsRoute(siteId) && (React.createElement(AppBar, { position: "static", color: "default" },
                    React.createElement(Tabs, { value: this.getTab(), indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" }, this.tabs.map(function (tab) { return (React.createElement(Tab, { key: tab.id, value: tab.url(siteId), label: tab.format(paymentsStatusCounts), component: RLink, to: tab.url(siteId), id: tab.id })); })))),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: SiteRoutes.CHECK_PAYMENT_LIST_ROUTE },
                        React.createElement(CheckPaymentListPage, null)),
                    React.createElement(Route, { path: SiteRoutes.UNCLAIMED_PAYMENT_LIST_ROUTE },
                        React.createElement(UnclaimedPaymentListPage, null)),
                    React.createElement(Route, { path: SiteRoutes.CLAIMED_PAYMENT_LIST_ROUTE },
                        React.createElement(ClaimedPaymentListPage, null)),
                    React.createElement(Route, { path: SiteRoutes.PAYMENT_LIST_ROUTE },
                        React.createElement(PaymentListPage, null))),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: SiteRoutes.NEW_PAYMENT_ROUTE },
                        React.createElement(AddPayment, null)),
                    React.createElement(Route, { path: SiteRoutes.EDIT_PAYMENT_ROUTE },
                        React.createElement(AddPayment, null))))));
    };
    PaymentsIndexPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], PaymentsIndexPage);
    return PaymentsIndexPage;
}(Component));
export default withStyles(styles)(withRouter(PaymentsIndexPage));
