var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { PaymentStatusEnum } from '@cros/shared/constants/enums/PaymentStatusEnum';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { CircularProgress, createStyles, withStyles } from '@material-ui/core';
import * as telephoneData from 'country-telephone-data';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectTransactionStore } from '~/stores/TransactionStore';
var styles = function () { return createStyles({}); };
var SendCheckModal = /** @class */ (function (_super) {
    __extends(SendCheckModal, _super);
    function SendCheckModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = {
            date: {
                value: new Date(),
                error: null,
                rule: 'required'
            },
            internalCode: {
                value: '',
                error: null
            }
        };
        _this.hasErrors = false;
        _this.saving = false;
        _this.loading = false;
        _this.claim = null;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, claimStore, claimId, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, claimStore = _a.transactionStore.claimStore, claimId = _a.match.params.claimId;
                        this.loading = true;
                        _b = this;
                        return [4 /*yield*/, claimStore.getClaimById(claimId)];
                    case 1:
                        _b.claim = _c.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, onClose = _a.onClose, history = _a.history, url = _a.match.url;
            if (onClose) {
                onClose();
            }
            history.push("" + url.split('/send-check')[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var newPayment, c, claimStore, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        c = this.getObjectToSave();
                        claimStore = this.props.transactionStore.claimStore;
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, claimStore.sendCheck(c)];
                    case 2:
                        newPayment = _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        // get validation errors
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 5];
                    case 4:
                        this.saving = false;
                        if (!newPayment) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 5:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var claim = {
                completionDate: _this.data.date.value,
                status: PaymentStatusEnum.COMPLETE,
                id: _this.claim.id,
                internalCode: _this.data.internalCode.value
            };
            return claim;
        };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors);
        };
        return _this;
    }
    SendCheckModal.prototype.render = function () {
        var _this = this;
        return (React.createElement(SmallModal, { onClose: this.onClose, style: { minHeight: '450px' } },
            React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '0px' } }, "Create a check"),
            React.createElement(RdiLine, { style: { margin: '15px 0px' } }),
            this.claim && !this.loading && (React.createElement(React.Fragment, null,
                React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '30px' } }, "Use the user data below to create a check and send it to the address below:"),
                React.createElement(FlexContainer, { justifyContent: "space-between", alignItems: "flex-start" },
                    React.createElement("div", { style: { width: '50%' } },
                        React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '7px' } }, "Check for:"),
                        React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '7px' } },
                            this.claim.amount,
                            " USD"),
                        React.createElement(RdiTypography, { variant: "body2" },
                            this.claim.payments.length,
                            " payments")),
                    React.createElement("div", { style: { width: '50%' } },
                        React.createElement(RdiTypography, { variant: "body2" }, this.claim.patient.fullName),
                        React.createElement(RdiTypography, { variant: "body2" }, this.claim.patient.addressLine1),
                        this.claim.patient.addressLine2 && (React.createElement(RdiTypography, { variant: "body2" }, this.claim.patient.addressLine2)),
                        React.createElement(RdiTypography, { variant: "body2" }, this.claim.patient.city),
                        React.createElement(RdiTypography, { variant: "body2" }, this.claim.patient.zip),
                        this.claim.patient.state && (React.createElement(RdiTypography, { variant: "body2" }, this.claim.patient.state)),
                        this.claim.patient.country && (React.createElement(RdiTypography, { variant: "body2" }, telephoneData.allCountries.find(function (x) { return x.iso2 === _this.claim.patient.country; })
                            .name)))),
                React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent, style: { marginTop: '15px' } },
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 },
                        React.createElement(RdiInput, { type: "date", label: "Date", value: this.data.date.value, errorText: this.data.date.error, onChange: function (e) { return _this.fieldChange(e, 'date'); } }),
                        React.createElement(RdiInput, { type: "text", label: "Internal check code", value: this.data.internalCode.value, errorText: this.data.internalCode.error, onChange: function (e) { return _this.fieldChange(e, 'internalCode'); } })),
                    React.createElement(RdiLine, null),
                    React.createElement(RdiButton, { loading: this.saving, submit: true }, "Mark check as sent")))),
            this.loading && (React.createElement(FlexContainer, { style: { height: '100%' }, alignItems: "center", justifyContent: "center" },
                React.createElement(CircularProgress, null)))));
    };
    __decorate([
        observable
    ], SendCheckModal.prototype, "data", void 0);
    __decorate([
        observable
    ], SendCheckModal.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], SendCheckModal.prototype, "saving", void 0);
    __decorate([
        observable
    ], SendCheckModal.prototype, "loading", void 0);
    __decorate([
        observable
    ], SendCheckModal.prototype, "claim", void 0);
    SendCheckModal = __decorate([
        injectTransactionStore,
        observer
    ], SendCheckModal);
    return SendCheckModal;
}(Component));
export default withStyles(styles)(withRouter(SendCheckModal));
