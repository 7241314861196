import React from 'react';
import config from '~/config';
import useScript from '~/hooks/useScript';
var protocolConfigs = {
    nashgen2_rna: {
        p_id: config.customGptIdNashGen2,
        p_key: config.customGptKeyNashGen2
    }
};
var CustomGptWidget = function (props) {
    useScript('https://cdn.customgpt.ai/js/chat.js', function () {
        var protocolConfig = protocolConfigs[props.protocolId];
        var instance = CustomGPT === null || CustomGPT === void 0 ? void 0 : CustomGPT.getInstance();
        if (protocolConfig && !instance) {
            CustomGPT.init(protocolConfig);
        }
    }, function () {
        var protocolConfig = protocolConfigs[props.protocolId];
        var instance = CustomGPT === null || CustomGPT === void 0 ? void 0 : CustomGPT.getInstance();
        if (protocolConfig && instance) {
            CustomGPT.destroy();
        }
    });
    return React.createElement(React.Fragment, null);
};
export default CustomGptWidget;
