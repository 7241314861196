var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CircularProgress, Modal, withStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React from "react";
import CenteredContent from "s~/components/layout/content/CenteredContent";
import ModalContent from "s~/components/layout/content/ModalContent";
import ModalCloseIcon from "s~/components/misc/ModalCloseIcon";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var styles = function (theme) {
    var _a;
    return createStyles({
        modalContent: (_a = {
                maxWidth: "90%",
                width: "1100px",
                height: "85vh",
                position: "relative",
                background: ColorsEnum.White,
                padding: 0,
                display: "flex",
                flexDirection: "row"
            },
            _a[theme.breakpoints.down("xs")] = {
                height: "100%",
                width: "100%",
                maxWidth: "100%"
            },
            _a.overflow = "auto",
            _a)
    });
};
var BaseModal = function (props) {
    var onClose = props.onClose, loading = props.loading, padding = props.padding, style = props.style, children = props.children, classes = props.classes;
    return (React.createElement(Modal, { open: true, style: {
            width: "100%",
            minHeight: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }, onClose: onClose },
        React.createElement(React.Fragment, null,
            loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null))),
            !loading && (React.createElement(ModalContent, { className: classes.modalContent },
                React.createElement(ModalCloseIcon, { onClick: onClose }),
                React.createElement("div", { className: "hideScrollbar", style: __assign({ position: "relative", width: "100%", height: "100%", padding: padding }, style) }, children))))));
};
BaseModal.defaultProps = {
    padding: "25px 30px"
};
export default withStyles(styles)(BaseModal);
