export var BillingCodeStatusEnum;
(function (BillingCodeStatusEnum) {
    BillingCodeStatusEnum["OPEN"] = "open";
    BillingCodeStatusEnum["NEEDS_APPROVAL"] = "needs-approval";
    BillingCodeStatusEnum["APPROVED"] = "approved";
    BillingCodeStatusEnum["DENIED"] = "denied";
})(BillingCodeStatusEnum || (BillingCodeStatusEnum = {}));
export var BillingCodeStatusLabelsEnum;
(function (BillingCodeStatusLabelsEnum) {
    BillingCodeStatusLabelsEnum["open"] = "Open";
    BillingCodeStatusLabelsEnum["needs-approval"] = "Needs approval";
    BillingCodeStatusLabelsEnum["approved"] = "Approved";
    BillingCodeStatusLabelsEnum["denied"] = "Denied";
})(BillingCodeStatusLabelsEnum || (BillingCodeStatusLabelsEnum = {}));
