var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { DateSelectorStateEnum } from '@cros/shared/constants/enums/DateSelectorStateEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { AppBar, CircularProgress, createStyles, Tab, TableBody, TableCell, TableRow, Tabs, withStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import BillingTable from '~/components/billing/tables/BillingTable';
import DevicesTable from '~/components/billing/tables/DevicesTable';
import ICDTable from '~/components/billing/tables/ICDTable';
import InsuranceTable from '~/components/billing/tables/InsuranceTable';
import config from '~/config';
import BillFileList from '~/pages/site/site-detail/billing-overview/BillFileList';
import { getBillingOverviewClosedRoute, getBillingOverviewOpenRoute, getBillingOverviewRoute, getPatientOverviewRoute, getTrialRoute, SiteRoutes } from '~/routing/Routes';
import CalendarIconSmall from '~/static/CalendarIconSmall.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import BillStore from '~/stores/BillStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        tableCell: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
            borderRadius: '2px',
            padding: '20px',
            marginBottom: '30px',
            paddingBottom: '10px',
            '& .MuiTableCell-root': {
                paddingLeft: '0px',
                padding: '10px 5px 10px 0px',
                borderBottomWidth: '0px'
            },
            '& .MuiTableCell-head': {
                borderBottomWidth: '1px',
                '& .MuiTypography-caption': {
                    paddingRight: '0px!important'
                }
            }
        },
        heightContainer: {
            transition: 'max-height .5s cubic-bezier(0, 1, 0, 1)',
            overflow: 'hidden',
            boxSizing: 'border-box',
            marginBottom: '0px!important'
        },
        visible: {
            maxHeight: '500px',
            transition: 'max-height 1s ease-in-out'
        },
        hidden: {
            maxHeight: '0px'
        },
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {},
            _a),
        menuContainer: (_b = {
                marginBottom: '20px',
                alignItems: 'center',
                marginTop: '30px',
                flexWrap: 'wrap'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginTop: '0px'
            },
            _b)
    });
};
var BillListPage = /** @class */ (function (_super) {
    __extends(BillListPage, _super);
    function BillListPage(props) {
        var _this = _super.call(this, props) || this;
        _this.tabs = [
            {
                id: 'open',
                label: function (billingCodeCounts) {
                    return "Open (" + (((billingCodeCounts === null || billingCodeCounts === void 0 ? void 0 : billingCodeCounts.draft) || 0) + ((billingCodeCounts === null || billingCodeCounts === void 0 ? void 0 : billingCodeCounts.pending) || 0)) + ")";
                },
                url: function (siteId) { return getBillingOverviewOpenRoute(siteId); }
            },
            {
                id: 'closed',
                label: function (billingCodeCounts) { return "Closed (" + ((billingCodeCounts === null || billingCodeCounts === void 0 ? void 0 : billingCodeCounts.closed) || 0) + ")"; },
                url: function (siteId) { return getBillingOverviewClosedRoute(siteId); }
            }
        ];
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var siteId, tab;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        siteId = this.props.siteStore.siteId;
                        return [4 /*yield*/, this.billStore.listBillFilters()];
                    case 1:
                        _a.sent();
                        tab = this.tabs.find(function (t) { return t.url(siteId) === _this.getTabUrl(); });
                        if (!tab) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.changeTab(tab)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.getTabUrl = function () {
            var pathname = _this.props.location.pathname;
            var regex = new RegExp(['/new', '/edit', '/files', '/detail'].join('|'), 'g');
            var tab = pathname.split(regex)[0];
            return tab;
        };
        _this.changeTab = function (tab) { return __awaiter(_this, void 0, void 0, function () {
            var _a, history, siteId, status;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, siteId = _a.siteStore.siteId;
                        status = tab.id;
                        history.push({ pathname: "" + tab.url(siteId) });
                        return [4 /*yield*/, this.billStore.changeBillFilters({
                                status: status,
                                page: 1
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.toggleExpandCell = function (code) {
            code.expanded = !code.expanded;
        };
        var _a = _this.props, siteStore = _a.siteStore, location = _a.location, history = _a.history;
        _this.billStore = new BillStore(siteStore, location.search, history);
        return _this;
    }
    BillListPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, siteId = _a.siteStore.siteId, _b = _a.location, pathname = _b.pathname, search = _b.search, classes = _a.classes;
        var _c = this.billStore, billFilters = _c.billFilters, billFilterLabels = _c.billFilterLabels, availableBillFilters = _c.availableBillFilters, changeBillFilters = _c.changeBillFilters, billsCounts = _c.billsCounts, billsCount = _c.billsCount, billsLoading = _c.billsLoading, bills = _c.bills;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(Provider, { billStore: this.billStore },
                React.createElement(Route, { exact: true, path: SiteRoutes.BILLING_OVERVIEW_INDEX_ROUTE },
                    React.createElement(Redirect, { to: "" + getBillingOverviewOpenRoute(siteId) })),
                React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "Billing overview"),
                React.createElement(FlexContainer, { className: classes.menuContainer, margin: 10 },
                    React.createElement(RdiInput, { type: "simple-date", selectorState: DateSelectorStateEnum.month, label: "From", value: billFilters.dateFrom, style: { width: '220px' }, onChange: function (e) { return changeBillFilters({ dateFrom: e.value }); }, usePartials: true, config: config }),
                    React.createElement(RdiInput, { type: "simple-date", selectorState: DateSelectorStateEnum.month, label: "To", value: billFilters.dateTo, onChange: function (e) { return changeBillFilters({ dateTo: e.value }); }, style: { width: '220px' }, usePartials: true, config: config }),
                    !!availableBillFilters && (React.createElement(React.Fragment, null,
                        React.createElement(RdiInput, { type: "select", label: "Patient", name: "filter-patientId", style: { width: '220px' }, value: billFilters.patientId, selectedItemLabel: billFilterLabels.patientId, onChange: function (e) {
                                return e.value
                                    ? changeBillFilters({ patientId: e.value }, { patientId: e.label })
                                    : changeBillFilters({ patientId: null }, { patientId: 'All' });
                            }, options: __spreadArrays([{ label: 'All', slug: null }], availableBillFilters.patientId).map(function (patientId) { return ({
                                value: patientId.slug,
                                label: patientId.label
                            }); }) }),
                        React.createElement(RdiInput, { type: "select", label: "Trial", name: "filter-trialId", style: { width: '220px' }, value: billFilters.trialId, selectedItemLabel: billFilterLabels.trialId, onChange: function (e) {
                                return e.value
                                    ? changeBillFilters({ trialId: e.value }, { trialId: e.label })
                                    : changeBillFilters({ trialId: null }, { trialId: 'All' });
                            }, options: __spreadArrays([{ label: 'All', slug: null }], availableBillFilters.trialId).map(function (trialId) { return ({
                                value: trialId.slug,
                                label: trialId.label
                            }); }) })))),
                pathname !== getBillingOverviewRoute(siteId) && (React.createElement(AppBar, { position: "static", color: "default" },
                    React.createElement(Tabs, { value: this.getTabUrl(), indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" }, this.tabs.map(function (tab) { return (React.createElement(Tab, { key: tab.id, value: tab.url(siteId), label: "" + tab.label(billsCounts), id: tab.id, onClick: function () { return _this.changeTab(tab); } })); })))),
                React.createElement(RdiTable, null, !billsLoading && (React.createElement(TableBody, null,
                    bills.map(function (item, index) {
                        var _a, _b;
                        return (React.createElement(TableRow, { key: index },
                            React.createElement(TableCell, { style: { borderBottom: '0px', padding: '2px' } },
                                React.createElement(FlexContainer, { flexDirection: "column", alignItems: "stretch", margin: 45, className: classes.tableCell },
                                    React.createElement(FlexContainer, { justifyContent: "space-between" },
                                        React.createElement(FlexContainer, { flexDirection: "column", margin: 10, alignItems: "flex-start" },
                                            React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "Patient name"),
                                            React.createElement(RdiLink, { to: getPatientOverviewRoute(siteId, item.patientId) }, (_a = item.patient) === null || _a === void 0 ? void 0 : _a.fullName)),
                                        React.createElement(FlexContainer, { flexDirection: "column", margin: 10, alignItems: "flex-start" },
                                            React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "Trial name"),
                                            React.createElement(RdiLink, { to: getTrialRoute(siteId, item.trialId) }, (_b = item.trial) === null || _b === void 0 ? void 0 : _b.name)),
                                        React.createElement(FlexContainer, { flexDirection: "column", margin: 10, alignItems: "flex-start" },
                                            React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "Month"),
                                            React.createElement(FlexContainer, { margin: 7 },
                                                React.createElement("img", { alt: "img", src: CalendarIconSmall }),
                                                React.createElement("div", null, item.parsedDatePartial))),
                                        React.createElement(FlexContainer, { flexDirection: "column", margin: 10, alignItems: "flex-start" },
                                            React.createElement(RdiTypography, { variant: "caption" }, "Files"),
                                            React.createElement(FlexContainer, { margin: 7 },
                                                React.createElement("div", null, item.records.length > 0
                                                    ? item.records.length + " files"
                                                    : 'No files'),
                                                React.createElement("div", null,
                                                    React.createElement(RdiLink, { to: pathname + "/files/" + item.id + search }, "Manage")))),
                                        React.createElement(FlexContainer, { flexDirection: "column", margin: 10, alignItems: "flex-start" },
                                            React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "Status"),
                                            React.createElement("div", null, item.status))),
                                    React.createElement(FlexContainer, { justifyContent: "space-between", margin: 100, alignItems: "flex-start" },
                                        React.createElement(BillingTable, { billingCodes: item.billingCodes }),
                                        React.createElement(InsuranceTable, { bill: item, insurances: item.insurances })),
                                    React.createElement("div", { className: "\n                            " + classes.heightContainer + " \n                            " + (item.expanded ? classes.visible : classes.hidden) + "\n                          " },
                                        React.createElement(FlexContainer, { justifyContent: "space-between", margin: 100, alignItems: "flex-start", style: { marginBottom: '40px' } },
                                            React.createElement(ICDTable, { icdCodes: item.icd10Codes }),
                                            React.createElement(DevicesTable, { devices: item.devices }))),
                                    React.createElement("div", null,
                                        React.createElement(RdiLink, { onClick: function () { return _this.toggleExpandCell(item); }, style: {
                                                borderTop: "1px solid " + ColorsEnum.BlackLight,
                                                paddingTop: '10px',
                                                display: 'block'
                                            } },
                                            React.createElement(FlexContainer, { margin: 7, justifyContent: "center" },
                                                React.createElement("div", null, item.expanded ? 'Collapse' : 'Expand'),
                                                React.createElement("div", { style: { paddingTop: '5px' } },
                                                    !item.expanded && React.createElement(ExpandMore, null),
                                                    item.expanded && React.createElement(ExpandLess, null)))))))));
                    }),
                    !billsLoading && billsCount === 0 && (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: 8 }, "No results")))))),
                !billsLoading && (React.createElement(RdiTablePagination, { count: billsCount, rowsPerPage: billFilters.rowsPerPage, page: billFilters.page, onChangePage: function (e, newPage) { return changeBillFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                        return changeBillFilters({
                            rowsPerPage: parseInt(e.target.value, 10),
                            page: 1
                        });
                    } })),
                billsLoading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                    React.createElement(CircularProgress, null))),
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: SiteRoutes.BILLING_REPORT_FILES },
                        React.createElement(BillFileList, null))))));
    };
    __decorate([
        observable
    ], BillListPage.prototype, "billStore", void 0);
    BillListPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], BillListPage);
    return BillListPage;
}(Component));
export default withStyles(styles)(withRouter(BillListPage));
