var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { action, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import dashboardFilesService from '~/services/DashboardFilesService';
var DashboardFilesStore = /** @class */ (function () {
    function DashboardFilesStore(siteStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.dashboardFiles = [];
        this.fileSharedToPatientsList = [];
        this.isLoading = false;
        this.getDashboardFiles = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        this.isLoading = true;
                        _b = (_a = dashboardFilesService).getDashboardFiles;
                        _c = [siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        runInAction(function () {
                            _this.dashboardFiles = f;
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.errorModalStore.setError(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.getFileSharedPatientsList = function (fileId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_2;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        this.fileSharedToPatientsList = [];
                        this.isLoading = true;
                        _b = (_a = dashboardFilesService).getFileSharedPatientsList;
                        _c = [siteId, fileId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        runInAction(function () {
                            _this.fileSharedToPatientsList = f;
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.errorModalStore.setError(e_2);
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.getFilesSharedWithPatient = function (patientId) { return __awaiter(_this, void 0, Promise, function () {
            var _a, authToken, errorModalStore, siteId, f, _b, _c, _d, e_3;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.accountStore, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        siteId = this.siteStore.siteId;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        this.isLoading = true;
                        _c = (_b = dashboardFilesService).getFilesSharedWithPatient;
                        _d = [siteId,
                            patientId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        f = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _e.sent();
                        errorModalStore.setError(e_3);
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.shareFile = function (fileId, patientId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_4;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        this.isLoading = true;
                        _b = (_a = dashboardFilesService).shareFile;
                        _c = [siteId, fileId, patientId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _d.sent();
                        this.errorModalStore.setError(e_4);
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.shareFileWithEveryone = function (fileId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_5;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        this.isLoading = true;
                        _b = (_a = dashboardFilesService).shareFileWithEveryone;
                        _c = [siteId, fileId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _d.sent();
                        this.errorModalStore.setError(e_5);
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.unshareFile = function (fileId, patientId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_6;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        this.isLoading = true;
                        _b = (_a = dashboardFilesService).unshareFile;
                        _c = [siteId, fileId, patientId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _d.sent();
                        this.errorModalStore.setError(e_6);
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.unshareFileWithEveryone = function (fileId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_7;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        this.isLoading = true;
                        _b = (_a = dashboardFilesService).unshareFileWithEveryone;
                        _c = [siteId, fileId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_7 = _d.sent();
                        this.errorModalStore.setError(e_7);
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        return [2 /*return*/, f];
                }
            });
        }); };
        this.uploadFile = function (file) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, fileForUpload, _a, _b, _c, e_8;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        fileForUpload = new File([file.file], file.originalname, {
                            type: file.file.type
                        });
                        _b = (_a = dashboardFilesService).uploadDashboardFile;
                        _c = [fileForUpload, siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        this.siteStore.toastStore.addToast(new ToastMessage(file.originalname + " uploaded"));
                        runInAction(function () {
                            if (!_this.dashboardFiles) {
                                _this.dashboardFiles = [];
                            }
                            _this.dashboardFiles.push(f);
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        e_8 = _d.sent();
                        this.errorModalStore.setError(e_8);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, f];
                }
            });
        }); };
        this.uploadFiles = function (files) { return __awaiter(_this, void 0, Promise, function () {
            var chunkSize, i, chunk, e_9;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        chunkSize = 5;
                        this.isLoading = true;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 4];
                        chunk = files.slice(i, i + chunkSize);
                        // eslint-disable-next-line no-await-in-loop
                        return [4 /*yield*/, Promise.all(chunk.map(function (f) { return _this.uploadFile(f); }))];
                    case 2:
                        // eslint-disable-next-line no-await-in-loop
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i += chunkSize;
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_9 = _a.sent();
                        this.errorModalStore.setError(e_9);
                        return [3 /*break*/, 6];
                    case 6:
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateFile = function (file) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_10;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = dashboardFilesService).updateDashboardFile;
                        _c = [file.id,
                            siteId,
                            file.originalname];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_10 = _d.sent();
                        this.errorModalStore.setError(e_10);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, f];
                }
            });
        }); };
        this.updateFiles = function (files) { return __awaiter(_this, void 0, Promise, function () {
            var chunkSize, i, chunk, e_11;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        chunkSize = 5;
                        this.isLoading = true;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 4];
                        chunk = files.slice(i, i + chunkSize);
                        // eslint-disable-next-line no-await-in-loop
                        return [4 /*yield*/, Promise.all(chunk.map(function (f) { return _this.updateFile(f); }))];
                    case 2:
                        // eslint-disable-next-line no-await-in-loop
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i += chunkSize;
                        return [3 /*break*/, 1];
                    case 4:
                        this.getDashboardFiles();
                        return [3 /*break*/, 6];
                    case 5:
                        e_11 = _a.sent();
                        this.errorModalStore.setError(e_11);
                        return [3 /*break*/, 6];
                    case 6:
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.addEmptyFolder = function (file) {
            _this.dashboardFiles.push(file);
        };
        this.deleteFileWithConfirmation = function (node) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to delete " + (node.isFolder ? 'folder' : 'file') + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    var authToken, siteId, token_1, files, chunkSize, i, chunk, e_12;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                authToken = this.accountStore.authToken;
                                siteId = this.siteStore.siteId;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 7, , 8]);
                                this.isLoading = true;
                                return [4 /*yield*/, authToken()];
                            case 2:
                                token_1 = _a.sent();
                                files = [];
                                if (node.isFolder) {
                                    files = this.dashboardFiles.filter(function (f) { return f.originalname.startsWith(node.fullname) || node.key === '0'; });
                                }
                                else if (node.file) {
                                    files.push(node.file);
                                }
                                chunkSize = 10;
                                this.isLoading = true;
                                i = 0;
                                _a.label = 3;
                            case 3:
                                if (!(i < files.length)) return [3 /*break*/, 6];
                                chunk = files.slice(i, i + chunkSize);
                                // eslint-disable-next-line no-await-in-loop
                                return [4 /*yield*/, Promise.allSettled(chunk.map(function (f) { return _this.deleteFile(f.id, siteId, token_1); }))];
                            case 4:
                                // eslint-disable-next-line no-await-in-loop
                                _a.sent();
                                _a.label = 5;
                            case 5:
                                i += chunkSize;
                                return [3 /*break*/, 3];
                            case 6: return [3 /*break*/, 8];
                            case 7:
                                e_12 = _a.sent();
                                this.errorModalStore.setError(e_12);
                                return [3 /*break*/, 8];
                            case 8:
                                this.isLoading = false;
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.deleteFile = function (fileId, siteId, authToken) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, dashboardFilesService.deleteDashboardFile(fileId, siteId, authToken)];
                    case 1:
                        _a.sent();
                        runInAction(function () {
                            _this.dashboardFiles = _this.dashboardFiles.filter(function (f) { return f.id !== fileId; });
                        });
                        return [2 /*return*/];
                }
            });
        }); };
    }
    Object.defineProperty(DashboardFilesStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFilesStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFilesStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], DashboardFilesStore.prototype, "dashboardFiles", void 0);
    __decorate([
        observable
    ], DashboardFilesStore.prototype, "fileSharedToPatientsList", void 0);
    __decorate([
        observable
    ], DashboardFilesStore.prototype, "isLoading", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "getDashboardFiles", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "getFileSharedPatientsList", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "getFilesSharedWithPatient", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "shareFile", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "shareFileWithEveryone", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "unshareFile", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "unshareFileWithEveryone", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "uploadFile", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "uploadFiles", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "updateFile", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "updateFiles", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "addEmptyFolder", void 0);
    __decorate([
        action
    ], DashboardFilesStore.prototype, "deleteFileWithConfirmation", void 0);
    return DashboardFilesStore;
}());
export default DashboardFilesStore;
export var injectDashboardFilesStore = inject('dashboardFilesStore');
