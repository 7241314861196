var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiJsxParser from '@cros/shared/components/layout/RdiJsxParser';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { DatapointActionClickEnum } from '@cros/shared/constants/enums/DatapointActionClickEnum';
import { DatapointTemplateEnum } from '@cros/shared/constants/enums/DatapointTemplateEnum';
import { FormHelperText } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import React, { Component } from 'react';
import { getAdverseEventsTrialRoute, getMedicationsRoute } from '~/routing/Routes';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectVisitStore } from '~/stores/PatientStore/VisitStore';
var VisitDesign = /** @class */ (function (_super) {
    __extends(VisitDesign, _super);
    function VisitDesign() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VisitDesign.prototype.render = function () {
        var _a;
        var _b = this.props, dataPoint = _b.dataPoint, _c = _b.enrollmentStore, siteId = _c.siteId, trialId = _c.trialId, enrollmentId = _c.enrollmentId, patientId = _c.patientId, templateMeta = _b.dataPoint.template_meta, visitStore = _b.visitStore;
        var actionClick = templateMeta ? templateMeta.action_click : null;
        var url = actionClick ? actionClick.navigate_to : null;
        switch (dataPoint.template_name) {
            case DatapointTemplateEnum.hr:
                return (React.createElement(RdiLine, { style: {
                        marginTop: '10px',
                        marginBottom: '10px'
                    } }));
            case DatapointTemplateEnum.h4:
                return (React.createElement(RdiTypography, { variant: "subtitle1", style: { marginBottom: '8px', marginTop: '8px' } }, dataPoint.label));
            case DatapointTemplateEnum.h3:
                return (React.createElement(RdiTypography, { variant: "subtitle1", style: {
                        margin: (((_a = dataPoint.template_meta) === null || _a === void 0 ? void 0 : _a.margin) === 'none' ? '0px' : '8px') + " 0px"
                    }, color: TypographyColorsEnum.Black }, dataPoint.label));
            case DatapointTemplateEnum.h2:
                return (React.createElement(RdiTypography, { style: {
                        fontStyle: 'normal',
                        fontSize: '18px',
                        lineHeight: '28px',
                        letterSpacing: '0.5px',
                        color: TypographyColorsEnum.Black,
                        mixBlendMode: 'normal',
                        marginBottom: '13px'
                    }, variant: "h3" }, dataPoint.label));
            case DatapointTemplateEnum.row:
                return (React.createElement("div", { style: {
                        width: '100%',
                        height: '20px'
                    } }));
            case DatapointTemplateEnum.label:
                return (React.createElement(RdiInputLabel, { style: { marginTop: '5px' } },
                    React.createElement(RdiJsxParser, null, dataPoint.label)));
            case DatapointTemplateEnum.error:
                return (React.createElement(FormHelperText, { variant: "filled", error: true, style: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    } },
                    React.createElement(RdiJsxParser, null, dataPoint.label)));
            case DatapointTemplateEnum.button: {
                var onClick = void 0;
                var action = actionClick ? actionClick.on_click : null;
                switch (action) {
                    case DatapointActionClickEnum.adverse_events:
                        url = getAdverseEventsTrialRoute(siteId, patientId, trialId, enrollmentId);
                        break;
                    case DatapointActionClickEnum.medications:
                        url = getMedicationsRoute(siteId, patientId, trialId, enrollmentId);
                        break;
                    case DatapointActionClickEnum.add_procedure: {
                        var procedureSlug_1 = actionClick ? actionClick.procedure_slug : null;
                        onClick = function () { return visitStore.addProcedureToVisit(procedureSlug_1); };
                        break;
                    }
                    default:
                        break;
                }
                return (React.createElement(RdiButton, { inverted: true, style: { marginBottom: '15px' }, href: url, onClick: onClick, target: "_blank" }, dataPoint.label));
            }
            case DatapointTemplateEnum.link:
                return (React.createElement(RdiLink, { href: url, target: "_blank" },
                    React.createElement(FlexContainer, { margin: 7, style: { padding: '5px 0px' } },
                        React.createElement("div", null, dataPoint.label),
                        React.createElement(OpenInNew, null))));
            default:
                return null;
        }
    };
    VisitDesign = __decorate([
        injectVisitStore,
        injectEnrollmentStore
    ], VisitDesign);
    return VisitDesign;
}(Component));
export default VisitDesign;
