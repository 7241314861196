var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { VisitStatusEnum } from '@cros/shared/constants/enums/VisitStatusEnum';
import { Procedure } from '@cros/shared/types/models/Procedure';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { getTimezone } from '@cros/shared/utils/dateUtils';
import { getUrl } from '@cros/shared/utils/httpUtils';
import { computed, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import VisitService from '~/services/VisitService';
var VisitStore = /** @class */ (function () {
    function VisitStore(siteStore, enrollmentStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.enrollmentStore = enrollmentStore;
        this.shouldCheckoutWithoutDate = function () {
            var _a;
            return (_this.enrollmentStore.visitListStore.shouldCheckoutWithoutDate(_this.visit.id) ||
                ((_a = _this.visit.stage) === null || _a === void 0 ? void 0 : _a.slug) === 'off-cycle');
        };
        this.InitVisit = function (visitId) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.enrollmentStore.visitListStore.getVisitById(visitId)];
                    case 1:
                        _a.visit = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.rescheduleVisit = function (newDate) {
            _this.visit.engagement = newDate;
        };
        this.checkoutVisit = function (checkout) { return __awaiter(_this, void 0, Promise, function () {
            var _a, visitListStore, siteId, authToken, trialId, enrollmentId, v, _b, _c, _d, e_1;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, visitListStore = _a.visitListStore, siteId = _a.siteId, authToken = _a.authToken, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).checkoutVisit;
                        _d = [checkout,
                            siteId,
                            trialId,
                            enrollmentId,
                            this.visit.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        v = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        if (e_1.code === 'validation-exception') {
                            throw e_1;
                        }
                        this.errorModalStore.setError(e_1);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.visit.status = v.status;
                                        this.visit.checkedOutAt = v.checkedOutAt;
                                        // update all visits as one of them got a next date
                                        return [4 /*yield*/, visitListStore.listVisits()];
                                    case 1:
                                        // update all visits as one of them got a next date
                                        _a.sent();
                                        // because of progress & next visit change
                                        return [4 /*yield*/, this.enrollmentStore.getEnrollment(enrollmentId, trialId)];
                                    case 2:
                                        // because of progress & next visit change
                                        _a.sent();
                                        if (this.visit.billingCodes.filter(function (b) { return b.needsApproval; }).length > 0) {
                                            this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount += 1;
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/, v];
                }
            });
        }); };
        this.deleteVisitWithConfirmation = function (onDelete, onCancel) { return __awaiter(_this, void 0, void 0, function () {
            var visitListStore;
            return __generator(this, function (_a) {
                visitListStore = this.enrollmentStore.visitListStore;
                visitListStore.deleteVisitWithConfirmation(this.visit, onDelete, onCancel);
                return [2 /*return*/];
            });
        }); };
        this.checkInVisitWithConfirmation = function (onDelete, onCancel) { return __awaiter(_this, void 0, void 0, function () {
            var visitListStore;
            var _this = this;
            return __generator(this, function (_a) {
                visitListStore = this.enrollmentStore.visitListStore;
                visitListStore.checkInVisitWithConfirmation(this.visit, function () {
                    runInAction(function () {
                        _this.visit.status = VisitStatusEnum.IN_PROGRESS;
                        _this.visit.checkedInAt = new Date();
                    });
                    if (onDelete) {
                        onDelete();
                    }
                }, onCancel);
                return [2 /*return*/];
            });
        }); };
        this.addProcedureToVisit = function (procedureSlug) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, trialId, enrollmentId, procedure;
            var _this = this;
            return __generator(this, function (_b) {
                _a = this.enrollmentStore, siteId = _a.siteId, authToken = _a.authToken, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
                procedure = new Procedure({ slug: procedureSlug });
                this.confirmationModalStore.openModal("Are you sure you want to add procedure " + procedureSlug.replace('_', ' ') + " to visit?", function () { return __awaiter(_this, void 0, void 0, function () {
                    var visit, _a, _b, _c;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                _b = (_a = VisitService).patchVisit;
                                _c = [__assign(__assign({}, this.visit), { procedures: __spreadArrays(this.visit.procedures, [procedure]) }), siteId,
                                    trialId,
                                    enrollmentId,
                                    this.visit.id];
                                return [4 /*yield*/, authToken()];
                            case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                            case 2:
                                visit = _d.sent();
                                this.visit = visit;
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.getExportVisitUrl = function () {
            var _a = _this.enrollmentStore, siteId = _a.siteId, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
            return getUrl(config, "/site/" + siteId + "/trial/" + trialId + "/enrollment/" + enrollmentId + "/visit/" + _this.visit.id + "/preview", { format: 'pdf', timezone: getTimezone() });
        };
        this.updateVisit = function (visit) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, trialId, enrollmentId, authToken, updatedVisit, _b, _c, _d, oldProcedures;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, trialId = _a.trialId, enrollmentId = _a.enrollmentId, authToken = _a.authToken;
                        _c = (_b = VisitService).patchVisit;
                        _d = [visit,
                            siteId,
                            trialId,
                            enrollmentId,
                            this.visit.id];
                        return [4 /*yield*/, authToken()];
                    case 1: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 2:
                        updatedVisit = _e.sent();
                        oldProcedures = this.visit.procedures;
                        this.visit = updatedVisit;
                        this.visit.procedures = oldProcedures;
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateVisitBilling = function (visit) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, trialId, enrollmentId, authToken, updatedVisit, _b, _c, _d;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, trialId = _a.trialId, enrollmentId = _a.enrollmentId, authToken = _a.authToken;
                        _c = (_b = VisitService).patchVisitBilling;
                        _d = [visit,
                            siteId,
                            trialId,
                            enrollmentId,
                            visit.id];
                        return [4 /*yield*/, authToken()];
                    case 1: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 2:
                        updatedVisit = _e.sent();
                        return [4 /*yield*/, runInAction(function () {
                                if (_this.visit) {
                                    _this.visit.billingCodes = updatedVisit.billingCodes;
                                    _this.visit.icdCodes = updatedVisit.icdCodes;
                                    _this.visit.billingNote = updatedVisit.billingNote;
                                }
                            })];
                    case 3:
                        _e.sent();
                        this.toastStore.addToast(new ToastMessage("Billing updated"));
                        return [2 /*return*/, updatedVisit];
                }
            });
        }); };
    }
    Object.defineProperty(VisitStore.prototype, "visitId", {
        get: function () {
            return this.visit ? this.visit.id : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VisitStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.enrollmentStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VisitStore.prototype, "toastStore", {
        get: function () {
            return this.enrollmentStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VisitStore.prototype, "errorModalStore", {
        get: function () {
            return this.enrollmentStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VisitStore.prototype, "accountStore", {
        get: function () {
            return this.enrollmentStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VisitStore.prototype, "visitListStore", {
        get: function () {
            return this.enrollmentStore.visitListStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], VisitStore.prototype, "visit", void 0);
    __decorate([
        computed
    ], VisitStore.prototype, "visitId", null);
    return VisitStore;
}());
export default VisitStore;
export var injectVisitStore = inject('visitStore');
