var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Link } from "@material-ui/core";
import React from "react";
import RdiButton from "s~/components/forms/RdiButton";
import { RdiInputWrapper } from "s~/components/forms/RdiInput";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
export var RdiFileInput = function (props) {
    var disabled = props.disabled, saving = props.saving, label = props.label, value = props.value, link = props.link, onClear = props.onClear, fileSize = props.fileSize, errorText = props.errorText, onChange = props.onChange, name = props.name, inputProps = props.inputProps;
    var id = Math.random()
        .toString(16)
        .slice(-4);
    return (React.createElement(RdiInputWrapper, __assign({}, props, { style: __assign({ marginBottom: "15px" }, props.style) }),
        React.createElement("div", { style: { display: "flex", flexDirection: "row" } },
            React.createElement("label", { htmlFor: id + "file-upload", className: "custom-file-upload", style: {
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    cursor: "pointer"
                } },
                React.createElement(RdiButton, { style: {
                        pointerEvents: "none",
                        width: "auto"
                    }, inverted: true, disabled: disabled || false, loading: saving, name: name }, label)),
            value && !saving && (React.createElement("div", { style: {
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center"
                } },
                React.createElement(FlexContainer, { margin: 10 },
                    React.createElement(Link, { href: link, target: "_blank" }, value),
                    React.createElement(Link, { href: "#", onClick: onClear }, "Remove")),
                React.createElement("div", null, fileSize))),
            React.createElement("input", { style: { display: "none" }, id: id + "file-upload", type: "file", disabled: disabled || false, onChange: function (ev) { return onChange({ files: ev.target.files, event: ev }); }, accept: inputProps === null || inputProps === void 0 ? void 0 : inputProps.accept })),
        !!errorText && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.Error }, errorText))));
};
