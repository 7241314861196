export var MedicalRecordTypeEnum;
(function (MedicalRecordTypeEnum) {
    MedicalRecordTypeEnum["PROVIDER_VISIT"] = "provider-visit";
    MedicalRecordTypeEnum["CONSULTATIONS"] = "consultations";
    MedicalRecordTypeEnum["RADIOLOGY_RESULTS"] = "radiology-results";
    MedicalRecordTypeEnum["LABORATORY_RESULTS"] = "laboratory-results";
    MedicalRecordTypeEnum["HOSPITAL_RECORDS"] = "hospital-records";
    MedicalRecordTypeEnum["INSURANCE_CARDS"] = "insurance-cards";
    MedicalRecordTypeEnum["OTHER"] = "other";
})(MedicalRecordTypeEnum || (MedicalRecordTypeEnum = {}));
export var MedicalRecordTypeLabelsEnum;
(function (MedicalRecordTypeLabelsEnum) {
    MedicalRecordTypeLabelsEnum["provider-visit"] = "Provider visit";
    MedicalRecordTypeLabelsEnum["consultations"] = "Consultations";
    MedicalRecordTypeLabelsEnum["radiology-results"] = "Radiology results";
    MedicalRecordTypeLabelsEnum["laboratory-results"] = "Laboratory results";
    MedicalRecordTypeLabelsEnum["hospital-records"] = "Hospital records";
    MedicalRecordTypeLabelsEnum["insurance-cards"] = "Insurance cards";
    MedicalRecordTypeLabelsEnum["other"] = "Other";
})(MedicalRecordTypeLabelsEnum || (MedicalRecordTypeLabelsEnum = {}));
