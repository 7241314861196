var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import { IpDispensingStore } from '~/stores/PatientStore/IpDispensingStore';
import { IpDosingStore } from '~/stores/PatientStore/IpDosingStore';
import EnrollmentService from '../services/EnrollmentService';
import { VisitListStore } from './PatientStore/VisitListStore';
var EnrollmentStore = /** @class */ (function () {
    function EnrollmentStore(siteStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.authToken = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.accountStore.authToken()];
            });
        }); };
        this.setEnrollment = function (enrollment) {
            _this.enrollment = enrollment;
            _this.visitListStore.listVisits();
        };
        this.updateEnrollment = function (enr) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, enrollment, _a, _b, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _b = (_a = EnrollmentService).updateEnrollment;
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, [_c.sent(), enr,
                            this.enrollment.id,
                            this.siteId,
                            this.enrollment.patientId])];
                    case 3:
                        enrollment = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _c.sent();
                        if (e_1.code === 'validation-exception') {
                            throw e_1;
                        }
                        this.errorModalStore.setError(e_1);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, enrollment];
                }
            });
        }); };
        this.getEnrollment = function (enrollmentId, trialId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, enrollment, _a, _b, e_2;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        enrollment = null;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _b = (_a = EnrollmentService).getEnrollment;
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, [_c.sent(), this.siteId,
                            trialId,
                            enrollmentId])];
                    case 3:
                        enrollment = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _c.sent();
                        this.errorModalStore.setError(e_2);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            _this.enrollment = enrollment;
                        });
                        return [2 /*return*/, enrollment];
                }
            });
        }); };
        this.visitListStore = new VisitListStore(siteStore, this);
        this.ipDispensingStore = new IpDispensingStore(this);
        this.ipDosingStore = new IpDosingStore(this);
    }
    Object.defineProperty(EnrollmentStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnrollmentStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnrollmentStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnrollmentStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnrollmentStore.prototype, "siteId", {
        get: function () {
            return this.siteStore.siteId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnrollmentStore.prototype, "patientId", {
        get: function () {
            return this.enrollment.patientId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnrollmentStore.prototype, "enrollmentId", {
        get: function () {
            return this.enrollment.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnrollmentStore.prototype, "trialId", {
        get: function () {
            return this.enrollment.trialId;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], EnrollmentStore.prototype, "enrollment", void 0);
    __decorate([
        observable
    ], EnrollmentStore.prototype, "visitListStore", void 0);
    __decorate([
        observable
    ], EnrollmentStore.prototype, "ipDispensingStore", void 0);
    __decorate([
        observable
    ], EnrollmentStore.prototype, "ipDosingStore", void 0);
    return EnrollmentStore;
}());
export default EnrollmentStore;
export var injectEnrollmentStore = inject('enrollmentStore');
