var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import autoSaveConfig from '@cros/shared/config/autoSaveConfig';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import debounce from 'lodash/debounce';
import { action, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import MedicalRecordService from '~/services/MedicalRecordService';
var MedicalRecordStore = /** @class */ (function () {
    function MedicalRecordStore(patientStore) {
        var _this = this;
        this.patientStore = patientStore;
        this.medicalRecords = [];
        this.totalItems = 0;
        this.medicalRecordsLoading = false;
        this.medicalRecordFilters = {
            orderBy: 'createdAt',
            orderDir: 'DESC',
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            search: '',
            type: null
        };
        this.medicalRecordFilterLabels = {
            type: 'All'
        };
        this.toggleExpandCell = function (index) {
            _this.medicalRecords[index].expanded = !_this.medicalRecords[index].expanded;
        };
        this.changeMedicalRecordFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.medicalRecordFilters = __assign(__assign({}, this.medicalRecordFilters), newFilters);
                        this.medicalRecordFilterLabels = __assign(__assign({}, this.medicalRecordFilterLabels), newLabels);
                        if (!!('search' in newFilters)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.listMedicalRecords()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.searchDebounce.cancel();
                        this.searchDebounce();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.search = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listMedicalRecords()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.listMedicalRecords = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, patientId, authToken, errorModalStore, req, _b, _c, _d, e_1;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        this.medicalRecordsLoading = true;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = MedicalRecordService).listMedicalRecords;
                        _d = [siteId,
                            patientId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent(), this.medicalRecordFilters]))];
                    case 3:
                        req = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        this.medicalRecordsLoading = false;
                        errorModalStore.setError(e_1);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.medicalRecords = req.items;
                            _this.totalItems = req.count;
                            _this.medicalRecordsLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.deleteMedicalRecordWithConfirmation = function (medicalRecord) { return __awaiter(_this, void 0, Promise, function () {
            var confirmationModalStore;
            var _this = this;
            return __generator(this, function (_a) {
                confirmationModalStore = this.patientStore.confirmationModalStore;
                confirmationModalStore.openModal("Are you sure you want to delete medical record " + medicalRecord.title + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deleteMedicalRecord(medicalRecord)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.deleteMedicalRecord = function (medicalRecord) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, patientId, authToken, errorModalStore, toastStore, _b, _c, _d, e_2;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = MedicalRecordService).deleteMedicalRecord;
                        _d = [siteId,
                            patientId,
                            medicalRecord.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _e.sent();
                        errorModalStore.setError(e_2);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.medicalRecords = _this.medicalRecords.filter(function (ev) { return ev.id !== medicalRecord.id; });
                            _this.totalItems -= 1;
                        });
                        toastStore.addToast(new ToastMessage("Medical record " + medicalRecord.title + " removed"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.addMedicalRecord = function (medicalRecord, files, silent) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, toastStore, newMedicalRecord, _b, _c, _d, uploads, _i, files_1, f, rec, e_3;
            var _e;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 5, , 6]);
                        _c = (_b = MedicalRecordService).addMedicalRecord;
                        _d = [medicalRecord,
                            siteId,
                            patientId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_f.sent()]))];
                    case 3:
                        newMedicalRecord = _f.sent();
                        uploads = [];
                        for (_i = 0, files_1 = files; _i < files_1.length; _i++) {
                            f = files_1[_i];
                            if (!f.deleted) {
                                uploads.push(this.uploadFile(f.file, newMedicalRecord.id));
                            }
                        }
                        return [4 /*yield*/, Promise.all(uploads)];
                    case 4:
                        rec = _f.sent();
                        (_e = newMedicalRecord.records).push.apply(_e, rec);
                        return [3 /*break*/, 6];
                    case 5:
                        e_3 = _f.sent();
                        if (e_3.code === 'validation-exception') {
                            throw e_3;
                        }
                        errorModalStore.setError(e_3);
                        return [2 /*return*/, null];
                    case 6:
                        runInAction(function () {
                            newMedicalRecord.expanded = true;
                            _this.medicalRecords.push(newMedicalRecord);
                            _this.totalItems += 1;
                        });
                        if (!silent) {
                            toastStore.addToast(new ToastMessage("New medical record " + medicalRecord.title + " added"));
                        }
                        return [2 /*return*/, newMedicalRecord];
                }
            });
        }); };
        this.updateMedicalRecord = function (medicalRecord, files, silent) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, toastStore, updatedMedicalRecord, calls, _i, files_2, f, _b, _c, _d, e_4;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 5, , 6]);
                        calls = [];
                        for (_i = 0, files_2 = files; _i < files_2.length; _i++) {
                            f = files_2[_i];
                            // if the file is new
                            if (f.file) {
                                calls.push(this.uploadFile(f.file, medicalRecord.id));
                            }
                            // if the file has been marked for deletion
                            if (f.deleted) {
                                calls.push(this.deleteFile(f.id, medicalRecord.id));
                            }
                        }
                        return [4 /*yield*/, Promise.all(calls)];
                    case 2:
                        _e.sent();
                        _c = (_b = MedicalRecordService).updateMedicalRecord;
                        _d = [medicalRecord,
                            siteId,
                            patientId,
                            medicalRecord.id];
                        return [4 /*yield*/, authToken()];
                    case 3: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 4:
                        updatedMedicalRecord = _e.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_4 = _e.sent();
                        if (e_4.code === 'validation-exception') {
                            throw e_4;
                        }
                        errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 6:
                        runInAction(function () {
                            var existingIndex = _this.medicalRecords.findIndex(function (log) { return log.id === updatedMedicalRecord.id; });
                            if (existingIndex >= 0) {
                                _this.medicalRecords[existingIndex] = updatedMedicalRecord;
                                _this.medicalRecords[existingIndex].expanded = true;
                            }
                        });
                        if (!silent) {
                            toastStore.addToast(new ToastMessage("Medical record " + medicalRecord.title + " updated"));
                        }
                        return [2 /*return*/, updatedMedicalRecord];
                }
            });
        }); };
        this.getMedicalRecordById = function (id) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, res, _b, _c, _d, e_5;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = MedicalRecordService).getMedicalRecordById;
                        _d = [siteId,
                            patientId,
                            id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        res = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _e.sent();
                        errorModalStore.setError(e_5);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, res];
                }
            });
        }); };
        this.deleteFile = function (fileId, medicalRecordId) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, patientId, authToken, errorModalStore, _b, _c, _d, e_6;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = MedicalRecordService).deleteMedicalRecordFile;
                        _d = [fileId,
                            siteId,
                            patientId,
                            medicalRecordId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _e.sent();
                        errorModalStore.setError(e_6);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.uploadFile = function (file, medicalRecordId) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, patientId, authToken, errorModalStore, f, _b, _c, _d, e_7;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.patientStore, siteId = _a.siteId, patientId = _a.patientId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = MedicalRecordService).uploadMedicalRecordFile;
                        _d = [file,
                            siteId,
                            patientId,
                            medicalRecordId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        f = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_7 = _e.sent();
                        errorModalStore.setError(e_7);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, f];
                }
            });
        }); };
        this.searchDebounce = debounce(this.search, autoSaveConfig.autoSaveDelay);
    }
    __decorate([
        observable
    ], MedicalRecordStore.prototype, "medicalRecords", void 0);
    __decorate([
        observable
    ], MedicalRecordStore.prototype, "totalItems", void 0);
    __decorate([
        observable
    ], MedicalRecordStore.prototype, "medicalRecordsLoading", void 0);
    __decorate([
        observable
    ], MedicalRecordStore.prototype, "medicalRecordFilters", void 0);
    __decorate([
        observable
    ], MedicalRecordStore.prototype, "medicalRecordFilterLabels", void 0);
    __decorate([
        action
    ], MedicalRecordStore.prototype, "toggleExpandCell", void 0);
    return MedicalRecordStore;
}());
export { MedicalRecordStore };
export var injectMedicalRecordStore = inject('medicalRecordStore');
