var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CircularProgress, Modal } from "@material-ui/core";
import React from "react";
import CenteredContent from "s~/components/layout/content/CenteredContent";
import ModalContent from "s~/components/layout/content/ModalContent";
import ModalCloseIcon from "s~/components/misc/ModalCloseIcon";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var SmallModal = function (props) {
    var open = props.open, onClose = props.onClose, loading = props.loading, style = props.style, children = props.children, className = props.className;
    return (React.createElement(Modal, { open: open || false, onClose: onClose, className: className, style: {
            width: "100%",
            minHeight: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        } },
        React.createElement(React.Fragment, null,
            loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null))),
            !loading && (React.createElement(ModalContent, { style: __assign({ width: "580px", position: "relative", background: ColorsEnum.White, padding: "30px", overflow: "auto", margin: "0 auto" }, style) },
                React.createElement(ModalCloseIcon, { onClick: onClose }),
                children)))));
};
SmallModal.defaultProps = {
    open: true,
    loading: false
};
export default SmallModal;
