var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createStyles, withStyles } from '@material-ui/core';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import AddUser from '~/pages/site/site-detail/users/AddUser';
import UsersIndexPage from '~/pages/site/site-detail/users/UsersIndexPage';
import { SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import UserStore from '~/stores/UserStore';
var styles = function () { return createStyles({}); };
var UserManagementPage = /** @class */ (function (_super) {
    __extends(UserManagementPage, _super);
    function UserManagementPage(props) {
        var _this = _super.call(this, props) || this;
        var siteStore = props.siteStore;
        _this.userStore = new UserStore(siteStore);
        return _this;
    }
    UserManagementPage.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Provider, { userStore: this.userStore },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: SiteRoutes.ADD_USER_ROUTE },
                        React.createElement(AddUser, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_USER_ROUTE },
                        React.createElement(AddUser, null)),
                    React.createElement(Route, { path: SiteRoutes.USERS_ROUTE },
                        React.createElement(UsersIndexPage, null))))));
    };
    UserManagementPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], UserManagementPage);
    return UserManagementPage;
}(Component));
export default withStyles(styles)(withRouter(UserManagementPage));
