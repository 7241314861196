var AutoSaveConfig = /** @class */ (function () {
    function AutoSaveConfig() {
    }
    Object.defineProperty(AutoSaveConfig.prototype, "autoSaveDelay", {
        get: function () {
            return process.env.AUTOSAVE_DELAY
                ? parseInt(process.env.AUTOSAVE_DELAY, 10)
                : 1500;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoSaveConfig.prototype, "componentStateDelay", {
        get: function () {
            return process.env.COMPONENT_STATE_DELAY
                ? parseInt(process.env.COMPONENT_STATE_DELAY, 10)
                : 1500;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoSaveConfig.prototype, "loadingAnimationDelay", {
        get: function () {
            return process.env.LOADING_STATE_DELAY
                ? parseInt(process.env.LOADING_STATE_DELAY, 10)
                : 900;
        },
        enumerable: false,
        configurable: true
    });
    return AutoSaveConfig;
}());
var autoSaveConfig = new AutoSaveConfig();
export default autoSaveConfig;
