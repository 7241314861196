import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { BillInsuranceStatusEnum, BillInsuranceStatusLabelsEnum } from '@cros/shared/constants/enums/BillInsuranceStatusEnum';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { injectBillStore } from '~/stores/BillStore';
var InsuranceTable = function (props) {
    var columns = [
        {
            id: 'name',
            label: 'Name',
            sortable: false
        },
        {
            id: 'status',
            label: 'Status',
            sortable: false
        },
        {
            label: 'Last update',
            id: 'lastUpdate',
            sortable: true,
            sortName: 'startDate'
        }
    ];
    var updateBillInsurance = function (e, insurance) {
        insurance.status = e.value;
        props.billStore.updateBillInsurance(props.bill, insurance);
    };
    var insurances = props.insurances;
    var data = {
        insurances: insurances
    };
    return (React.createElement(FlexContainer, { flexDirection: "column", alignItems: "stretch", margin: 10, style: { width: '100%' } },
        React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "Insurances"),
        React.createElement(RdiTable, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column })); }))),
            React.createElement(TableBody, null,
                data.insurances.map(function (insurance, index) { return (React.createElement(TableRow, { key: index },
                    React.createElement(TableCell, null, insurance.name),
                    React.createElement(TableCell, { style: { padding: '0px' } },
                        React.createElement(RdiInput, { type: "select", options: Object.values(BillInsuranceStatusEnum).map(function (val) { return ({
                                value: val,
                                label: BillInsuranceStatusLabelsEnum[val]
                            }); }), value: insurance.status, selectedItemLabel: BillInsuranceStatusLabelsEnum[insurance.status], borderWidth: "0px", style: {
                                marginBottom: '0px',
                                position: 'relative',
                                left: '-10px'
                            }, onChange: function (e) { return updateBillInsurance(e, insurance); } })),
                    React.createElement(TableCell, null, formatDate(insurance.lastUpdated)))); }),
                insurances.length === 0 && (React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: 4 }, "No results")))))));
};
InsuranceTable.defaultProps = {};
export default injectBillStore(InsuranceTable);
