var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DeviceDataSchemaItem } from "s~/types/models/DeviceDataSchemaItem";
import { Model } from "s~/types/models/Model";
var DeviceDataSchema = /** @class */ (function (_super) {
    __extends(DeviceDataSchema, _super);
    function DeviceDataSchema(s) {
        var _this = _super.call(this, s) || this;
        _this.items = [];
        if (s.items) {
            for (var _i = 0, _a = s.items; _i < _a.length; _i++) {
                var item = _a[_i];
                _this.items.push(new DeviceDataSchemaItem(item));
            }
        }
        return _this;
    }
    return DeviceDataSchema;
}(Model));
export { DeviceDataSchema };
