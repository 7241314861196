export var EnrollmentStatusEnum;
(function (EnrollmentStatusEnum) {
    // Legacy
    // INVITED = 'invited',
    // IN_PROGRESS = 'in-progress',
    // COMPLETED = 'completed',
    // EARLY_TERMINATION = 'early-termination',
    // Current
    EnrollmentStatusEnum["INVITED"] = "invited";
    EnrollmentStatusEnum["INACTIVE"] = "inactive";
    EnrollmentStatusEnum["IN_PROGRESS"] = "in-progress";
    EnrollmentStatusEnum["SCREEN_FAILURE"] = "screen-failure";
    EnrollmentStatusEnum["RESCREEN"] = "rescreen";
    EnrollmentStatusEnum["EARLY_TERMINATION"] = "early-termination";
    EnrollmentStatusEnum["RANDOM_ACTIVE"] = "rand-active";
    EnrollmentStatusEnum["COMPLETED"] = "completed";
})(EnrollmentStatusEnum || (EnrollmentStatusEnum = {}));
export var EnrollmentStatusLabels;
(function (EnrollmentStatusLabels) {
    EnrollmentStatusLabels["invited"] = "Active";
    EnrollmentStatusLabels["in-progress"] = "Screening";
    EnrollmentStatusLabels["completed"] = "Completed";
    EnrollmentStatusLabels["early-termination"] = "Early termination";
    EnrollmentStatusLabels["active"] = "Active";
    EnrollmentStatusLabels["inactive"] = "Inactive";
    EnrollmentStatusLabels["screening"] = "Screening";
    EnrollmentStatusLabels["screen-failure"] = "Screen Failure";
    EnrollmentStatusLabels["rescreen"] = "Rescreen";
    EnrollmentStatusLabels["rand-active"] = "Rand/Active";
})(EnrollmentStatusLabels || (EnrollmentStatusLabels = {}));
