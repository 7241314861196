var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { formatDatetime } from '@cros/shared/utils/dateUtils';
import { createStyles, Typography, withStyles } from '@material-ui/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getTrialRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectAuditLogStore } from '~/stores/AuditLogStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () {
    return createStyles({
        text: {
            width: '50%',
            '& h6': {
                marginBottom: '5px'
            }
        },
        dump: {
            border: '1px solid #E4E4E4',
            padding: '0px 10px',
            '& pre': {
                whiteSpace: 'pre-wrap'
            }
        },
        closeButton: {
            margin: '0 auto',
            marginTop: '20px!important',
            width: '220px'
        }
    });
};
var AuditLogModal = /** @class */ (function (_super) {
    __extends(AuditLogModal, _super);
    function AuditLogModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = true;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, auditLogStore, params, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, auditLogStore = _a.auditLogStore, params = _a.match.params;
                        _b = this;
                        return [4 /*yield*/, auditLogStore.getAuditLogById(params.auditLogId)];
                    case 1:
                        _b.auditLog = _c.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, match = _a.match, search = _a.location.search;
            history.push("" + match.url.split('/view')[0] + search);
        };
        return _this;
    }
    AuditLogModal.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var _l = this.props, classes = _l.classes, siteId = _l.siteStore.siteId;
        var auditLog = this.auditLog;
        return (React.createElement(SmallModal, { loading: this.loading, onClose: this.onClose },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, "Audit log entry"),
            auditLog && (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { marginTop: '10px' } },
                    React.createElement(Typography, { variant: "subtitle1" }, formatDatetime(auditLog.txTime))),
                React.createElement(RdiLine, { style: { marginTop: '15px' } }),
                React.createElement(FlexContainer, { style: { marginBottom: '15px' } },
                    React.createElement("div", { className: classes.text },
                        React.createElement("div", null,
                            React.createElement(Typography, { variant: "subtitle2" }, "User")),
                        React.createElement("div", null, (_a = auditLog.author) === null || _a === void 0 ? void 0 : _a.fullName)),
                    React.createElement("div", { className: classes.text },
                        React.createElement("div", null,
                            React.createElement(Typography, { variant: "subtitle2" }, "Patient")),
                        React.createElement("div", null, ((_c = (_b = auditLog.related) === null || _b === void 0 ? void 0 : _b.patient) === null || _c === void 0 ? void 0 : _c.fullName) ? (_e = (_d = auditLog.related) === null || _d === void 0 ? void 0 : _d.patient) === null || _e === void 0 ? void 0 : _e.fullName : '/'))),
                React.createElement(FlexContainer, { style: { marginBottom: '15px' } },
                    React.createElement("div", { className: classes.text },
                        React.createElement("div", null,
                            React.createElement(Typography, { variant: "subtitle2" }, "Trial")),
                        React.createElement("div", null, ((_f = auditLog.related) === null || _f === void 0 ? void 0 : _f.trial) ? (React.createElement(RdiLink, { to: getTrialRoute(siteId, (_h = (_g = auditLog.related) === null || _g === void 0 ? void 0 : _g.trial) === null || _h === void 0 ? void 0 : _h.id) }, (_k = (_j = auditLog.related) === null || _j === void 0 ? void 0 : _j.trial) === null || _k === void 0 ? void 0 : _k.name)) : ('/'))),
                    React.createElement("div", { className: classes.text },
                        React.createElement("div", null,
                            React.createElement(Typography, { variant: "subtitle2" }, "Action")),
                        React.createElement("div", null, auditLog.action))),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "subtitle2" }, "Dump"),
                    React.createElement("div", { className: classes.dump },
                        React.createElement("pre", null, JSON.stringify(auditLog.changed, null, 2)))),
                React.createElement(RdiButton, { className: classes.closeButton, onClick: this.onClose }, "Close")))));
    };
    __decorate([
        observable
    ], AuditLogModal.prototype, "loading", void 0);
    __decorate([
        observable
    ], AuditLogModal.prototype, "auditLog", void 0);
    AuditLogModal = __decorate([
        injectSiteStore,
        injectAuditLogStore,
        injectAccountStore,
        observer
    ], AuditLogModal);
    return AuditLogModal;
}(Component));
export default withStyles(styles)(withRouter(AuditLogModal));
