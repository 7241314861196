var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import ExpandableTableRow from '@cros/shared/components/misc/ExpandableTableRow';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { MedicationFrequencyLabelsEnum } from '@cros/shared/constants/enums/MedicationFrequencyEnum';
import { MedicationRouteLabelsEnum } from '@cros/shared/constants/enums/MedicationRouteEnum';
import { MedicationUnitLabelsEnum } from '@cros/shared/constants/enums/MedicationUnitEnum';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { CircularProgress, createStyles, IconButton, Menu, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { computed, observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { getVisitDetailRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { MedicationStore } from '~/stores/PatientStore/MedicationsStore';
import { injectSiteStore } from '~/stores/SiteStore';
import AddMedication from './AddMedication';
var styles = function () { return createStyles({}); };
var MedicationListPage = /** @class */ (function (_super) {
    __extends(MedicationListPage, _super);
    function MedicationListPage(props) {
        var _this = _super.call(this, props) || this;
        _this.columns = [
            {
                id: 'expand',
                label: '',
                sortable: false,
                width: '50px'
            },
            {
                id: 'name',
                label: 'Name',
                sortable: false
            },
            {
                id: 'visit',
                label: 'Visit',
                sortable: false
            },
            {
                label: 'Start date',
                id: 'startDate',
                sortable: true,
                sortName: 'startDate'
            },
            {
                label: 'End date',
                id: 'stopDate',
                sortable: false,
                sortName: 'stopDate'
            },
            {
                label: '',
                id: 'actions',
                sortable: false,
                sortName: '',
                width: '50px'
            }
        ];
        _this.menuRef = null;
        _this.handleMenuClick = function (event, medication) {
            _this.menuRef = event.currentTarget;
            _this.medicationInEdit = medication;
        };
        _this.handleMenuClose = function () {
            _this.menuRef = null;
        };
        _this.medicationInEdit = null;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicationStore.changeMedicationsFilters({
                            orderDir: 'DESC',
                            orderBy: 'startDate'
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.handleMenuClose();
                        return [4 /*yield*/, this.medicationStore.deleteMedicationWithConfirmation(this.medicationInEdit)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            _this.handleMenuClose();
            history.push(url + "/edit/" + _this.medicationInEdit.id);
        };
        _this.getAddRoute = function () {
            var url = _this.props.match.url;
            return url + "/new";
        };
        _this.toggleExpandCell = function (index) {
            _this.medicationStore.toggleExpandCell(index);
        };
        var patientStore = _this.props.patientStore;
        _this.medicationStore = new MedicationStore(patientStore);
        return _this;
    }
    Object.defineProperty(MedicationListPage.prototype, "rowMenuOpen", {
        get: function () {
            return !!this.menuRef;
        },
        enumerable: false,
        configurable: true
    });
    MedicationListPage.prototype.render = function () {
        var _this = this;
        var _a = this.medicationStore, medications = _a.medications, totalItems = _a.totalItems, changeMedicationsFilters = _a.changeMedicationsFilters, medicationsLoading = _a.medicationsLoading, medicationFilters = _a.medicationFilters;
        var _b = this.props, disabled = _b.disabled, visitId = _b.visitId, accountStore = _b.accountStore, siteStore = _b.siteStore;
        return (React.createElement(Provider, { medicationStore: this.medicationStore },
            React.createElement("div", { style: {
                    marginBottom: 30,
                    width: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                !visitId && (React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary, style: { marginBottom: '45px' } }, "Medication change")),
                React.createElement(FlexContainer, { alignItems: "center", justifyContent: "space-between", style: { marginBottom: '50px' } },
                    React.createElement(RdiTypography, { variant: "caption", style: { padding: '0px' } }, (totalItems || 0) + " medication change" + (totalItems === 1 ? '' : 's')),
                    !disabled && (React.createElement(RdiButton, { name: "new-medication", to: this.getAddRoute, permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.MEDICATION
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "New medication change"))),
                React.createElement("div", { style: { overflowY: medicationsLoading ? 'hidden' : 'auto' } },
                    React.createElement(RdiTable, { stickyHeader: true },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeMedicationsFilters, filters: medicationFilters })); }))),
                        !medicationsLoading && (React.createElement(TableBody, null,
                            medications.map(function (item, index) {
                                return (React.createElement(React.Fragment, { key: index },
                                    React.createElement(TableRow, { key: index },
                                        React.createElement(TableCell, { style: { cursor: 'pointer' }, onClick: function () { return _this.toggleExpandCell(index); } },
                                            !item.expanded && React.createElement(KeyboardArrowDown, null),
                                            item.expanded && React.createElement(KeyboardArrowUp, null)),
                                        React.createElement(TableCell, null,
                                            React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.TrueBlack }, item.title)),
                                        React.createElement(TableCell, null, item.visit ? (React.createElement(RdiLink, { to: getVisitDetailRoute(item.visit.siteId, item.visit.patientId, item.visit.trialId, item.visit.enrollmentId, item.visit.id) }, item.visit.name)) : ('Not during visit')),
                                        React.createElement(TableCell, null, formatDate(item.startDate)),
                                        React.createElement(TableCell, null, item.stopDate && formatDate(item.stopDate)),
                                        React.createElement(TableCell, null,
                                            React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, item); } },
                                                React.createElement(MoreVertIcon, null)))),
                                    React.createElement(ExpandableTableRow, { colSpan: 5, expanded: item.expanded },
                                        React.createElement(ExpandedDetailContent, { label: "TRIAL", value: item.trial ? item.trial.name : '/' }),
                                        React.createElement(ExpandedDetailContent, { label: "UNIT", value: MedicationUnitLabelsEnum[item.unit] }),
                                        React.createElement(ExpandedDetailContent, { label: "ROUTE", value: MedicationRouteLabelsEnum[item.route] }),
                                        React.createElement(ExpandedDetailContent, { label: "FREQUENCY", value: MedicationFrequencyLabelsEnum[item.frequency] }),
                                        React.createElement(ExpandedDetailContent, { style: { marginBottom: '20px' }, label: "INDICATION", value: item.indication }))));
                            }),
                            !medicationsLoading && totalItems === 0 && (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 6 }, "No results"))))))),
                !medicationsLoading && (React.createElement(RdiTablePagination, { count: totalItems, rowsPerPage: medicationFilters.rowsPerPage, page: medicationFilters.page, onChangePage: function (e, newPage) { return changeMedicationsFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                        return changeMedicationsFilters({
                            rowsPerPage: parseInt(e.target.value, 10),
                            page: 1
                        });
                    } })),
                React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: this.rowMenuOpen, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, onClose: this.handleMenuClose },
                    React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.MEDICATION
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Edit")),
                    React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.MEDICATION
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Delete"))),
                medicationsLoading && (React.createElement(FlexContainer, { justifyContent: "center", style: { marginTop: '30px' } },
                    React.createElement(CircularProgress, null))),
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: SiteRoutes.NEW_MEDICATION_ROUTE },
                        React.createElement(AddMedication, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_MEDICATION_ROUTE },
                        React.createElement(AddMedication, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.NEW_MEDICATION_ROUTE_VISIT },
                        React.createElement(AddMedication, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_MEDICATION_ROUTE_VISIT },
                        React.createElement(AddMedication, null))))));
    };
    __decorate([
        observable
    ], MedicationListPage.prototype, "menuRef", void 0);
    __decorate([
        computed
    ], MedicationListPage.prototype, "rowMenuOpen", null);
    __decorate([
        observable
    ], MedicationListPage.prototype, "medicationInEdit", void 0);
    MedicationListPage = __decorate([
        injectPatientStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], MedicationListPage);
    return MedicationListPage;
}(Component));
var ExpandedDetailContent = function (props) { return (React.createElement(FlexContainer, { style: __assign({ padding: '7px 0px' }, props.style) },
    React.createElement(FlexContainer, { flex: 1 }, props.label),
    React.createElement(FlexContainer, { flex: 2 }, props.value))); };
export default withStyles(styles)(withRouter(MedicationListPage));
