var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
var styles = function () { return createStyles({}); };
var VerifyPhoneNumberModal = /** @class */ (function (_super) {
    __extends(VerifyPhoneNumberModal, _super);
    function VerifyPhoneNumberModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = {
            secret: {
                value: null,
                error: null,
                rule: 'required'
            }
        };
        _this.hasErrors = false;
        _this.saving = false;
        _this.parentUrl = function () {
            var url = _this.props.match.url;
            return url.split('/verify')[0];
        };
        _this.onClose = function () {
            var _a = _this.props, onClose = _a.onClose, history = _a.history;
            if (onClose) {
                onClose();
            }
            history.push(_this.parentUrl());
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.submitEvent = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var _a, history, accountStore, success, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        event.preventDefault();
                        _a = this.props, history = _a.history, accountStore = _a.accountStore;
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        this.saving = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, accountStore.sendPhoneConfirmation(String(this.data.secret.value))];
                    case 2:
                        success = _b.sent();
                        if (success) {
                            history.push(this.parentUrl());
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 5];
                    case 4:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        return _this;
    }
    VerifyPhoneNumberModal.prototype.render = function () {
        var _this = this;
        var accountDetail = this.props.accountStore.accountDetail;
        return (React.createElement(SmallModal, { onClose: this.onClose },
            React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '15px' } }, "Verify your phone number"),
            React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '0px' } },
                "To enable notifications for ",
                accountDetail.confirmationPhoneNumberPrefix,
                accountDetail.confirmationPhoneNumber,
                ", you need to confirm it by typing the code you received via SMS into the box below."),
            React.createElement(RdiLine, { style: { margin: '15px 0px' } }),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent, style: { marginTop: '15px' } },
                React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30 },
                    React.createElement(RdiInput, { type: "number", label: "Verification code", value: this.data.secret.value, errorText: this.data.secret.error, onChange: function (e) { return _this.fieldChange(e, 'secret'); }, placeholder: "6 digit code" }),
                    React.createElement("div", { style: { width: '100%' } })),
                React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: '0px' } },
                    "Didn't receive a code?",
                    ' ',
                    React.createElement(RdiLink, { to: this.parentUrl() }, "Check your number and try again")),
                React.createElement(RdiLine, null),
                React.createElement(RdiButton, { loading: this.saving, submit: true, style: { margin: '0 auto' } }, "Verify"))));
    };
    __decorate([
        observable
    ], VerifyPhoneNumberModal.prototype, "data", void 0);
    __decorate([
        observable
    ], VerifyPhoneNumberModal.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], VerifyPhoneNumberModal.prototype, "saving", void 0);
    VerifyPhoneNumberModal = __decorate([
        injectAccountStore,
        observer
    ], VerifyPhoneNumberModal);
    return VerifyPhoneNumberModal;
}(Component));
export default withStyles(styles)(withRouter(VerifyPhoneNumberModal));
