export var TaskTypeEnum;
(function (TaskTypeEnum) {
    TaskTypeEnum["ALARM"] = "alarm";
    TaskTypeEnum["TASK"] = "task";
})(TaskTypeEnum || (TaskTypeEnum = {}));
export var TaskTypeLabelsEnum;
(function (TaskTypeLabelsEnum) {
    TaskTypeLabelsEnum["alarm"] = "Alarm";
    TaskTypeLabelsEnum["task"] = "Task";
})(TaskTypeLabelsEnum || (TaskTypeLabelsEnum = {}));
