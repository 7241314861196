var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import RdiButton from "s~/components/forms/RdiButton";
import RdiCheckbox from "s~/components/forms/RdiCheckbox";
import RdiInput from "s~/components/forms/RdiInput";
import CenteredContent from "s~/components/layout/content/CenteredContent";
import ModalContent from "s~/components/layout/content/ModalContent";
import PublicPageWrapper from "s~/components/layout/PublicPageWrapper";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
import RdiErrorBox from "s~/components/misc/RdiErrorBox";
import { AccountRoutes } from "s~/components/routing/AccountRoutes";
import { PublicRoutes } from "s~/components/routing/PublicRoutes";
import RdiLink from "s~/components/routing/RdiLink";
var NewPasswordRequiredComponent = /** @class */ (function (_super) {
    __extends(NewPasswordRequiredComponent, _super);
    function NewPasswordRequiredComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.password = "";
        _this.passwordConfirmation = "";
        _this.componentDidMount = function () {
            var _a = _this.props, cognitoUser = _a.cognitoUser, history = _a.history;
            if (!cognitoUser) {
                history.push(AccountRoutes.LOGIN_ROUTE);
            }
        };
        _this.handlePasswordChange = function (e) {
            _this.password = e.value;
        };
        _this.handlePasswordConfirmationChange = function (e) {
            _this.passwordConfirmation = e.value;
        };
        _this.errorMessage = "";
        _this.confirmNewPassword = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, history, completeNewPassword, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, completeNewPassword = _a.completeNewPassword;
                        if (e) {
                            e.preventDefault();
                        }
                        if (!this.password) {
                            this.errorMessage = "Please provide the new password";
                            return [2 /*return*/];
                        }
                        if (!this.passwordConfirmation) {
                            this.errorMessage = "Please provide the password confirmation";
                            return [2 /*return*/];
                        }
                        if (this.passwordConfirmation !== this.password) {
                            this.errorMessage = "Passwords and confirmation do not match";
                            return [2 /*return*/];
                        }
                        if (!this.termsChecked) {
                            this.errorMessage = "Please confirm to the terms and conditions";
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.errorMessage = "";
                        return [4 /*yield*/, completeNewPassword(this.password)];
                    case 2:
                        _b.sent();
                        history.replace(AccountRoutes.ACCOUNT_ROUTE);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        this.errorMessage = error_1.message;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.termsChecked = false;
        _this.onTermsChange = function (checked) {
            _this.termsChecked = checked;
        };
        return _this;
    }
    NewPasswordRequiredComponent.prototype.render = function () {
        var _a = this.props.confirmPasswordChangeParams, firstName = _a.firstName, lastName = _a.lastName, email = _a.email;
        return (React.createElement(PublicPageWrapper, { white: true },
            React.createElement(CenteredContent, null,
                React.createElement(ModalContent, { style: { width: "490px" } },
                    React.createElement("form", { onSubmit: this.confirmNewPassword, style: { flex: "1" } },
                        React.createElement("div", { style: { flex: "1" } },
                            React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: "17px", marginTop: "10px" } }, "Welcome to CROS"),
                            React.createElement(RdiTypography, { style: { marginBottom: "20px" }, variant: "body2" }, "You have been invited to CROS. Create your account by confirming your\n                    credentials below and setting your password."),
                            React.createElement(RdiTypography, { variant: "body2" }, firstName + " " + lastName),
                            React.createElement(RdiTypography, { style: { marginBottom: "15px" }, variant: "body2", color: TypographyColorsEnum.BlackMedium }, email),
                            React.createElement(RdiInput, { label: "Password", type: "password", autoFocus: true, placeholder: "Please set your password", value: this.password, onChange: this.handlePasswordChange }),
                            React.createElement(RdiInput, { label: "Your password again", placeholder: "Repeat your password", type: "password", assistiveText: "Your password must contain at least 8 characters.", value: this.passwordConfirmation, onChange: this.handlePasswordConfirmationChange }),
                            React.createElement(FlexContainer, { style: { marginBottom: "22px" } },
                                React.createElement(RdiCheckbox, { onChange: this.onTermsChange, value: this.termsChecked, containerStyle: { marginBottom: 0 }, style: { marginRight: "11px" } },
                                    React.createElement(FlexContainer, { margin: 2 },
                                        React.createElement("span", null, "I agree to\u00A0"),
                                        React.createElement(RdiLink, { to: PublicRoutes.TERMS }, "terms and conditions")))),
                            React.createElement(RdiErrorBox, null, this.errorMessage)),
                        React.createElement(RdiButton, { submit: true, fullWidth: true, style: {
                                height: "57px"
                            } }, "CREATE ACCOUNT"))))));
    };
    __decorate([
        observable
    ], NewPasswordRequiredComponent.prototype, "password", void 0);
    __decorate([
        observable
    ], NewPasswordRequiredComponent.prototype, "passwordConfirmation", void 0);
    __decorate([
        observable
    ], NewPasswordRequiredComponent.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], NewPasswordRequiredComponent.prototype, "termsChecked", void 0);
    NewPasswordRequiredComponent = __decorate([
        observer
    ], NewPasswordRequiredComponent);
    return NewPasswordRequiredComponent;
}(Component));
export default withRouter(NewPasswordRequiredComponent);
