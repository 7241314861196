var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { EnrollmentStatusEnum } from '@cros/shared/constants/enums/EnrollmentStatusEnum';
import { VisitStatusEnum } from '@cros/shared/constants/enums/VisitStatusEnum';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { computed, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import VisitService from '~/services/VisitService';
var VisitListStore = /** @class */ (function () {
    function VisitListStore(siteStore, enrollmentStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.enrollmentStore = enrollmentStore;
        this.visits = [];
        this.totalItems = 0;
        this.addOrUpdateNextVisit = function (enrollment, visit) {
            if (!enrollment.nextVisit) {
                enrollment.nextVisit = visit;
            }
            else {
                var visitsSorted = _this.visits.sort(function (a, b) { return Number(a.engagement) - Number(b.engagement); });
                enrollment.nextVisit = visitsSorted[0];
            }
        };
        this.deleteVisit = function (visit) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, _b, trialId, enrollmentId, _c, _d, _e, e_1;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _b = this.enrollmentStore, trialId = _b.trialId, enrollmentId = _b.enrollmentId;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 4, , 5]);
                        _d = (_c = VisitService).deleteVisit;
                        _e = [siteId, visit.id, trialId, enrollmentId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _d.apply(_c, _e.concat([_f.sent()]))];
                    case 3:
                        _f.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _f.sent();
                        errorModalStore.setError(e_1);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.visits = _this.visits.filter(function (v) { return v.id !== visit.id; });
                            _this.totalItems -= 1;
                        });
                        // because of progress & next visit change
                        return [4 /*yield*/, this.enrollmentStore.getEnrollment(enrollmentId, trialId)];
                    case 6:
                        // because of progress & next visit change
                        _f.sent();
                        toastStore.addToast(new ToastMessage("Visit " + visit.name + " removed"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.checkInVisit = function (visit) { return __awaiter(_this, void 0, Promise, function () {
            var _a, enrollment, errorModalStore, o, v, e_2;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.enrollmentStore, enrollment = _a.enrollment, errorModalStore = _a.errorModalStore;
                        o = {
                            status: VisitStatusEnum.IN_PROGRESS,
                            id: visit.id
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.updateVisit(o, false)];
                    case 2:
                        v = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _b.sent();
                        errorModalStore.setError(e_2);
                        return [2 /*return*/, false];
                    case 4:
                        // TODO: check mutations in patient store
                        runInAction(function () {
                            var _a;
                            var visitIndex = _this.visits.findIndex(function (vis) { return vis.id === visit.id; });
                            if (visitIndex >= 0) {
                                _this.visits[visitIndex] = v;
                            }
                            if (((_a = enrollment.nextVisit) === null || _a === void 0 ? void 0 : _a.id) === visit.id) {
                                enrollment.nextVisit = v;
                            }
                            enrollment.status = EnrollmentStatusEnum.IN_PROGRESS;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.listVisits = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, errorModalStore, _b, enrollmentId, trialId, result, _c, _d, _e, e_3;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _b = this.enrollmentStore, enrollmentId = _b.enrollmentId, trialId = _b.trialId;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 4, , 5]);
                        _d = (_c = VisitService).listVisits;
                        _e = [siteId, enrollmentId, trialId, {}];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _d.apply(_c, _e.concat([_f.sent()]))];
                    case 3:
                        result = _f.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _f.sent();
                        errorModalStore.setError(e_3);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.visits = result.items;
                            _this.totalItems = result.count;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.shouldCheckoutWithoutDate = function (visitId) {
            var filteredVisits = _this.visits.filter(function (x) { var _a; return ((_a = x.stage) === null || _a === void 0 ? void 0 : _a.slug) !== 'off-cycle'; });
            if (filteredVisits.length === 0) {
                return true;
            }
            return filteredVisits[filteredVisits.length - 1].id === visitId;
        };
        this.addVisit = function (o, hasSendNotifications) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, trialId, enrollmentId, authToken, errorModalStore, toastStore, visit, _b, _c, _d, e_4;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, trialId = _a.trialId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).addNewUnscheduledVisit;
                        _d = [o,
                            siteId,
                            trialId,
                            enrollmentId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent(), hasSendNotifications]))];
                    case 3:
                        visit = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _e.sent();
                        if (e_4.code === 'validation-exception') {
                            throw e_4;
                        }
                        errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 5: return [4 /*yield*/, this.listVisits()];
                    case 6:
                        _e.sent();
                        runInAction(function () {
                            var enrollment = _this.enrollmentStore.enrollment;
                            _this.addOrUpdateNextVisit(enrollment, visit);
                        });
                        toastStore.addToast(new ToastMessage("Visit " + visit.name + " created"));
                        return [2 /*return*/, visit];
                }
            });
        }); };
        this.rescheduleVisit = function (visitId, engagement, engagementEnd, hasSendNotifications) { return __awaiter(_this, void 0, void 0, function () {
            var enrollment, o, res;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        enrollment = this.enrollmentStore.enrollment;
                        o = {
                            engagement: engagement,
                            engagementEnd: engagementEnd,
                            id: visitId
                        };
                        return [4 /*yield*/, this.scheduleVisit(o, hasSendNotifications)];
                    case 1:
                        res = _a.sent();
                        runInAction(function () {
                            var v = _this.visits.find(function (visit) { return visit.id === visitId || visit.slug === visitId; });
                            v.engagement = res.engagement;
                            v.engagementEnd = res.engagementEnd;
                            _this.visits = __spreadArrays(_this.visits);
                            _this.addOrUpdateNextVisit(enrollment, res);
                        });
                        return [2 /*return*/, res.engagement];
                }
            });
        }); };
        this.checkInVisitWithConfirmation = function (visit, onDelete, onCancel) { return __awaiter(_this, void 0, void 0, function () {
            var confirmationModalStore;
            var _this = this;
            return __generator(this, function (_a) {
                confirmationModalStore = this.enrollmentStore.confirmationModalStore;
                confirmationModalStore.openModal("Are you sure you want to check in " + visit.name + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    var success;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.checkInVisit(visit)];
                            case 1:
                                success = _a.sent();
                                if (success) {
                                    onDelete();
                                }
                                else {
                                    onCancel();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); }, onCancel);
                return [2 /*return*/];
            });
        }); };
        this.getVisitById = function (visitId) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, trialId, enrollmentId, visit, _b, _c, _d, e_5;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).getVisitDetail;
                        _d = [siteId,
                            trialId,
                            enrollmentId,
                            visitId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        visit = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _e.sent();
                        errorModalStore.setError(e_5);
                        return [2 /*return*/, null];
                    case 5:
                        // update id of visit in list, as it will be set here
                        runInAction(function () {
                            var existingVisitIndex = _this.visits.findIndex(function (ev) { return ev.id === visitId; });
                            if (existingVisitIndex >= 0) {
                                _this.visits[existingVisitIndex].id = visit.id;
                            }
                        });
                        return [2 /*return*/, visit];
                }
            });
        }); };
        this.deleteVisitWithConfirmation = function (visit, onDelete, onCancel) { return __awaiter(_this, void 0, void 0, function () {
            var confirmationModalStore;
            var _this = this;
            return __generator(this, function (_a) {
                confirmationModalStore = this.enrollmentStore.confirmationModalStore;
                confirmationModalStore.openModal("Do you really want to remove visit " + visit.name + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deleteVisit(visit)];
                            case 1:
                                _a.sent();
                                if (onDelete)
                                    onDelete();
                                return [2 /*return*/];
                        }
                    });
                }); }, onCancel);
                return [2 /*return*/];
            });
        }); };
        this.updateVisit = function (visit, hasSendNotifications) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, errorModalStore, trialId, enrollmentId, v, _b, _c, _d, e_6;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).patchVisit;
                        _d = [visit,
                            siteId,
                            trialId,
                            enrollmentId,
                            visit.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent(), hasSendNotifications]))];
                    case 3:
                        v = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _e.sent();
                        if (e_6.code === 'validation-exception') {
                            throw e_6;
                        }
                        errorModalStore.setError(e_6);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingVisitIndex = _this.visits.findIndex(function (ev) { return ev.id === visit.id; });
                            if (existingVisitIndex >= 0) {
                                _this.visits[existingVisitIndex] = v;
                            }
                            var enrollment = _this.enrollmentStore.enrollment;
                            _this.addOrUpdateNextVisit(enrollment, v);
                        });
                        return [2 /*return*/, v];
                }
            });
        }); };
        this.scheduleVisit = function (visit, hasSendNotifications) { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, errorModalStore, trialId, enrollmentId, v, _b, _c, _d, e_7;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, trialId = _a.trialId, enrollmentId = _a.enrollmentId;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).scheduleVisit;
                        _d = [visit,
                            siteId,
                            trialId,
                            enrollmentId,
                            visit.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent(), hasSendNotifications]))];
                    case 3:
                        v = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_7 = _e.sent();
                        if (e_7.code === 'validation-exception') {
                            throw e_7;
                        }
                        errorModalStore.setError(e_7);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingVisitIndex = _this.visits.findIndex(function (ev) { return ev.id === visit.id; });
                            if (existingVisitIndex >= 0) {
                                _this.visits[existingVisitIndex] = v;
                            }
                            var enrollment = _this.enrollmentStore.enrollment;
                            _this.addOrUpdateNextVisit(enrollment, v);
                        });
                        return [2 /*return*/, v];
                }
            });
        }); };
        this.listVisitFilters = function () { return __awaiter(_this, void 0, Promise, function () {
            var _a, authToken, errorModalStore, siteId, resp, _b, _c, _d, e_8;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        siteId = this.siteStore.siteId;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).listVisitFilters;
                        _d = [siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        resp = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_8 = _e.sent();
                        errorModalStore.setError(e_8);
                        return [2 /*return*/];
                    case 5:
                        this.availableVisitFilters = resp;
                        return [2 /*return*/];
                }
            });
        }); };
    }
    Object.defineProperty(VisitListStore.prototype, "visitsDisplay", {
        get: function () {
            if (!this.visits.length || !this.visits) {
                return [];
            }
            var stageName = '';
            var result = [];
            this.visits.forEach(function (currVisit) {
                if (!currVisit) {
                    return;
                }
                stageName = currVisit.stage ? currVisit.stage.name : '';
                if (result.map(function (res) { return res.stage; }).indexOf(stageName) === -1) {
                    result.push({
                        stage: stageName,
                        visits: []
                    });
                }
                var items = result.filter(function (res) { return res.stage === stageName; });
                if (items.length > 0) {
                    items[0].visits.push(currVisit);
                }
            });
            return result;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], VisitListStore.prototype, "visits", void 0);
    __decorate([
        observable
    ], VisitListStore.prototype, "totalItems", void 0);
    __decorate([
        observable
    ], VisitListStore.prototype, "availableVisitFilters", void 0);
    __decorate([
        computed
    ], VisitListStore.prototype, "visitsDisplay", null);
    return VisitListStore;
}());
export { VisitListStore };
export var injectVisitListStore = inject('visitListStore');
