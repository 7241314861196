var _a, _b, _c, _d, _e;
import { createMuiTheme, CssBaseline, MuiThemeProvider } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import React from "react";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var breakpoints = createBreakpoints({});
var theme = createMuiTheme({
    palette: {
        background: {},
        primary: {
            main: ColorsEnum.Primary
        },
        error: { main: ColorsEnum.Error },
        secondary: { main: ColorsEnum.Success },
        text: {
            primary: ColorsEnum.Black,
            secondary: ColorsEnum.BlackMedium
        }
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "::-webkit-scrollbar": {
                    width: "8px",
                    height: "3px",
                    boxSizing: "border-box"
                },
                "::-webkit-scrollbar-track": {
                    background: "#ffffff",
                    margin: "1px 0px 0px 1px"
                },
                "::-webkit-scrollbar-thumb": {
                    background: "lightgrey",
                    boxSizing: "border-box"
                },
                body: (_a = {},
                    _a[breakpoints.up("sm")] = {
                        backgroundColor: ColorsEnum.White
                    },
                    _a[breakpoints.down("xs")] = {
                        backgroundColor: ColorsEnum.BlackSuperlight
                    },
                    _a)
            }
        },
        MuiContainer: {
            root: {
                maxWidth: "100%!important",
                width: "100%!important",
                paddingLeft: "0px!important",
                paddingRight: "0px!important",
                marginLeft: "0px!important",
                marginRight: "0px!important"
                // backgroundColor: ColorsEnum.White
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: "none"
            },
            colorDefault: {
                backgroundColor: "transparent"
            }
        },
        MuiTabs: {
            root: {
                marginBottom: "20px",
                borderBottom: "1px solid " + ColorsEnum.GreyBorder
            },
            indicator: {
                transition: "none!important"
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: "40px"
            }
        },
        MuiList: {
            padding: {
            // paddingBottom: '0px'
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: "25px",
                paddingRight: "25px"
            }
        },
        MuiTab: {
            root: {
                fontFamily: "Lato",
                fontWeight: "normal",
                fontSize: "12px !important",
                lineHeight: "16px",
                borderBottom: "2px solid " + ColorsEnum.White,
                "&$disabled": {
                    color: "green"
                },
                "&:hover": {
                    color: ColorsEnum.BlueDark,
                    borderBottom: "2px solid " + ColorsEnum.BlueDark
                }
            }
        },
        MuiTableHead: {
            root: {
                backgroundColor: "white",
                fontFamily: "Lato",
                fontStyle: "normal",
                fontSize: "12px",
                lineHeight: "16px",
                color: ColorsEnum.BlackMedium
            }
        },
        MuiTableSortLabel: {
            root: {
                alignItems: "baseline",
                width: "100%"
            },
            active: {
                color: ColorsEnum.TrueBlack + "!important",
                fontWeight: "bold",
                "& svg": {
                    marginLeft: "20px"
                }
            }
        },
        MuiTableBody: {
            root: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontSize: "13px",
                lineHeight: "20px",
                color: ColorsEnum.Black
            }
        },
        MuiTablePagination: {
            toolbar: {
            // marginTop: '10px'
            }
        },
        MuiFormControl: {
            root: {
                width: "100%"
            },
            marginNormal: {
                marginTop: "0px"
            }
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: "0px"
            }
        },
        MuiTableRow: {
            root: {}
        },
        MuiTableCell: {
            root: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontSize: "13px",
                lineHeight: "20px",
                color: ColorsEnum.BlackMedium,
                padding: "12px",
                borderBottom: "1px solid " + ColorsEnum.GreyBorder
            },
            head: {
                color: "rgba(25, 25, 25, 0.38)"
            },
            stickyHeader: {
                backgroundColor: "transparent"
            }
        },
        MuiBreadcrumbs: {
            root: (_b = {
                    backgroundColor: "white",
                    marginBottom: "20px",
                    fontSize: "12px",
                    padding: "0px 90px"
                },
                _b[breakpoints.down("lg")] = {
                    padding: "0px 40px"
                },
                _b[breakpoints.down("xs")] = {
                    padding: "10px 20px",
                    marginBottom: "0px"
                },
                _b)
        },
        MuiButton: {
            root: {
                "&$disabled": {
                    backgroundColor: ColorsEnum.BlackLight + "!important"
                }
            },
            label: {
                height: "100%"
            }
        },
        MuiTouchRipple: {
            root: {
                borderRadius: "2px"
            }
        },
        MuiSvgIcon: {
            root: {
                "& path": {
                    stroke: "transparent!important"
                }
            }
        },
        MuiRadio: {
            root: {
                padding: 0,
                "&$checked": {
                    color: ColorsEnum.Primary
                }
            }
        },
        MuiInputAdornment: {
            root: {
                position: "absolute",
                right: 0
            }
        },
        MuiMenuItem: {
            root: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "20px",
                minWidth: "140px",
                color: ColorsEnum.BlackSemidark
            }
        },
        MuiInputLabel: {
            root: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "11px",
                lineHeight: "16px",
                color: ColorsEnum.Black
            }
        },
        MuiInputBase: {
            root: {
                boxSizing: "border-box",
                fontFamily: "Lato"
            }
        },
        MuiOutlinedInput: {
            input: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                color: ColorsEnum.TrueBlack,
                padding: "15px 14px",
                "&::placeholder": {
                    color: ColorsEnum.BlackMedium
                },
                "&:disabled": {
                    color: ColorsEnum.Black,
                    backgroundColor: ColorsEnum.BlackSuperlight
                }
            },
            adornedEnd: {
                paddingRight: "0px"
            },
            multiline: {
                height: "auto",
                padding: "0px",
                "& textarea": {
                    padding: "8px 15px"
                }
            },
            root: {
                height: "44px",
                boxSizing: "border-box"
            },
            notchedOutline: {
                borderRadius: "0px"
            }
        },
        MuiFormHelperText: {
            root: {
                display: "flex",
                paddingTop: "3px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                margin: 0,
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "16px",
                color: ColorsEnum.BlackSemidark,
                opacity: 0.6
            },
            contained: {
                margin: 0,
                marginLeft: "0px"
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "#FAFBFC"
            }
        },
        MuiCheckbox: {
            root: {
                padding: "0"
            }
        },
        MuiTypography: {
            h1: (_c = {
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontSize: "28px",
                    lineHeight: "34px",
                    color: ColorsEnum.TrueBlack,
                    mixBlendMode: "normal",
                    opacity: 0.87,
                    marginBottom: "30px"
                },
                _c[breakpoints.down("xs")] = {
                    fontSize: "18px",
                    fontWeight: "400",
                    marginBottom: "15px"
                },
                _c),
            h2: {
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "30px",
                lineHeight: "35px",
                letterSpacing: "-0.5px",
                color: ColorsEnum.TrueBlack,
                mixBlendMode: "normal"
            },
            h3: {
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "25px",
                color: ColorsEnum.TrueBlack
            },
            h4: (_d = {
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "15px",
                    lineHeight: "20px",
                    letterSpacing: "0.25px",
                    color: ColorsEnum.Blue,
                    mixBlendMode: "normal"
                },
                _d[breakpoints.down("xs")] = {
                    fontSize: "13px"
                },
                _d),
            h5: (_e = {
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "20px",
                    lineHeight: "29px",
                    color: ColorsEnum.Blue
                },
                _e[breakpoints.down("xs")] = {
                    fontSize: "18px"
                },
                _e),
            h6: {
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "22px",
                letterSpacing: "0.15px",
                color: ColorsEnum.TrueBlack,
                opacity: 0.87,
                marginBottom: "10px"
            },
            body1: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "18px",
                lineHeight: "28px",
                color: ColorsEnum.Black
            },
            body2: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "13px",
                lineHeight: "20px",
                color: ColorsEnum.Black
            },
            caption: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontSize: "12px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                color: ColorsEnum.Black,
                paddingTop: "0px"
            },
            subtitle1: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                display: "flex",
                alignItems: "center",
                letterSpacing: "0.15px",
                color: "#7A7A7A",
                opacity: 0.87
            },
            subtitle2: {
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "11px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                color: ColorsEnum.BlackMedium
            }
        }
    }
});
var ThemeWrapper = function (props) {
    return (React.createElement(MuiThemeProvider, { theme: theme },
        React.createElement(CssBaseline, null),
        props.children));
};
export default ThemeWrapper;
