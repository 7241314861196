var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { formatDatetimeLong } from '@cros/shared/utils/dateUtils';
import React from 'react';
import PatientCard from '~/components/patient/PatientCardContainer';
import { getVisitDetailRoute } from '~/routing/Routes';
import CalendarIconSmall from '~/static/CalendarIconSmall.svg';
var NextVisitCard = function (props) {
    var nextVisit = props.enrollment.nextVisit, siteId = props.siteId, trialId = props.trialId, enrollmentId = props.enrollmentId, pathname = props.pathname, patientId = props.patientId, aclCheck = props.aclCheck;
    var goToVisit = function (visit) { return __awaiter(void 0, void 0, void 0, function () {
        var history;
        return __generator(this, function (_a) {
            history = props.history;
            history.push(getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, visit.id));
            return [2 /*return*/];
        });
    }); };
    var checkInPatient = function (visit) { return __awaiter(void 0, void 0, void 0, function () {
        var history, checkInVisitWithConfirmation;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    history = props.history, checkInVisitWithConfirmation = props.checkInVisitWithConfirmation;
                    return [4 /*yield*/, checkInVisitWithConfirmation(visit, function () {
                            history.push(getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, visit.id));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(PatientCard, null,
        React.createElement(FlexContainer, { justifyContent: "space-between" },
            React.createElement(RdiTypography, { variant: "subtitle2" }, (nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.isInProgressAtList) ? 'VISIT IN PROGRESS' : 'NEXT VISIT'),
            React.createElement(RdiLink, { to: getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.id), disabled: !nextVisit, xs: true }, "View")),
        nextVisit && (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(FlexContainer, { style: { marginTop: '11px', marginBottom: '14px' }, flexDirection: "column", alignItems: "flex-start" },
                    React.createElement(RdiLink, { style: { marginBottom: '5px' }, to: getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.id) }, nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.name),
                    React.createElement(FlexContainer, null,
                        React.createElement("img", { alt: "img", src: CalendarIconSmall }),
                        React.createElement(RdiTypography, { style: { marginLeft: '8px' }, variant: "body2" }, formatDatetimeLong(nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.engagement))))),
            React.createElement("div", null,
                (nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.isInProgressAtList) && (React.createElement(RdiButton, { fullWidth: false, style: { width: '105px', height: '42px', marginRight: '5px' }, to: pathname + "/visits/" + (nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.id), margin: "none" }, "VIEW")),
                !(nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.isInProgressAtList) && !(nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.isDoneAtList) && (React.createElement(FlexContainer, null,
                    React.createElement(RdiButton, { fullWidth: false, style: {
                            minWidth: '105px',
                            height: '42px',
                            marginRight: '17px'
                        }, onClick: function () { return checkInPatient(nextVisit); }, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.VISIT
                            }
                        ], aclCheck: aclCheck, margin: "none" }, "CHECK IN"),
                    React.createElement(RdiLink, { to: pathname + "/schedule/" + (nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.id), permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.VISIT
                            }
                        ], xs: true, aclCheck: aclCheck }, "Reschedule"))),
                (nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.isDoneAtList) && !(nextVisit === null || nextVisit === void 0 ? void 0 : nextVisit.isCheckedOut) && (React.createElement(RdiButton, { fullWidth: false, style: {
                        minWidth: '105px',
                        height: '42px',
                        marginRight: '17px'
                    }, onClick: function () { return goToVisit(nextVisit); }, permissions: [
                        {
                            aclOp: AclOpEnum.UPDATE,
                            aclModule: AclModuleEnum.VISIT
                        }
                    ], aclCheck: aclCheck, margin: "none" }, "CHECK OUT"))))),
        !nextVisit && (React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '0px' } }, "No visit scheduled")),
        !nextVisit && React.createElement("div", null)));
};
export default NextVisitCard;
