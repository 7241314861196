var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { add_operation, apply } from 'json-logic-js';
import { computed, observable } from 'mobx';
import { DatapointEnum } from 's~/constants/enums/DatapointEnum';
import { ProcedureStatusEnum } from 's~/constants/enums/ProcedureStatusEnum';
import { ProcedureCategoryEnum, ProcedureCategorySlugEnum } from 's~/constants/enums/ProcedureCategoryEnum';
import { Model } from 's~/types/models/Model';
import { mathRoundTwoPlaces, timeDiff } from 's~/utils/jsonLogicUtils';
import { Datapoint } from './Datapoint';
var Procedure = /** @class */ (function (_super) {
    __extends(Procedure, _super);
    function Procedure(p) {
        var _this = _super.call(this, p) || this;
        _this.datapoints = [];
        _this.id = p.id;
        _this.label = p.label;
        _this.slug = p.slug;
        _this.status = p.status;
        _this.type = p.type;
        _this.category = Procedure.getCategory(p.type);
        _this.datapoints = [];
        if (p.datapoints) {
            for (var _i = 0, _a = p.datapoints; _i < _a.length; _i++) {
                var pr = _a[_i];
                // pass procedure in so we have context
                _this.datapoints.push(new Datapoint(pr, _this));
            }
        }
        return _this;
    }
    Object.defineProperty(Procedure.prototype, "jsonLogicContext", {
        get: function () {
            var vContext = {};
            // only those who have a slug
            for (var _i = 0, _a = this.datapoints.filter(function (x) { return x.slug; }); _i < _a.length; _i++) {
                var datapointLogic = _a[_i];
                vContext[datapointLogic.slug] = datapointLogic.data;
            }
            return vContext;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Procedure.prototype, "hasErrors", {
        get: function () {
            var _this = this;
            return this.datapoints.some(function (x) { return !_this.shouldSkip(x) && x.errorMessage; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Procedure.prototype, "isComplete", {
        get: function () {
            return this.status === ProcedureStatusEnum.COMPLETE || this.status === ProcedureStatusEnum.FINALIZED;
        },
        enumerable: false,
        configurable: true
    });
    // whether display of DP should be skipped
    Procedure.prototype.shouldSkip = function (datapoint) {
        var _this = this;
        var data = datapoint.data, properties = datapoint.properties, type = datapoint.type, errors = datapoint.errors;
        add_operation('time_diff', function (a, b) {
            return timeDiff(a, b, {
                $procedure: _this.jsonLogicContext,
                $self: data,
            });
        });
        add_operation('math_round', function (a) {
            return mathRoundTwoPlaces(a, {
                $procedure: _this.jsonLogicContext,
                $self: data,
            });
        });
        if (properties && properties.visible) {
            if (!apply(properties.visible, {
                $procedure: this.jsonLogicContext,
                $self: data,
            })) {
                return true;
            }
        }
        // do not show error if we didnt just finalize procedure
        // TODO: potentially find a better way of showing errors altogether
        if (type === DatapointEnum.error && !errors) {
            return true;
        }
        return false;
    };
    Procedure.getCategory = function (type) {
        var _a;
        return (_a = ProcedureCategorySlugEnum[type]) !== null && _a !== void 0 ? _a : ProcedureCategoryEnum.UNKNOWN;
    };
    __decorate([
        observable
    ], Procedure.prototype, "status", void 0);
    __decorate([
        observable
    ], Procedure.prototype, "datapoints", void 0);
    __decorate([
        computed
    ], Procedure.prototype, "jsonLogicContext", null);
    __decorate([
        computed
    ], Procedure.prototype, "hasErrors", null);
    __decorate([
        computed
    ], Procedure.prototype, "isComplete", null);
    return Procedure;
}(Model));
export { Procedure };
