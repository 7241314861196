var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { endOfDay, startOfDay } from "date-fns";
import React from "react";
import { RdiInputWrapper } from "s~/components/forms/RdiInput";
import RdiInputLabel from "./RdiInputLabel";
export var RdiDateInput = function (props) {
    var useStyles = makeStyles(function () { return ({
        datepicker: {
            "& fieldset": {
                borderWidth: function (prop) { return prop.borderWidth; }
            }
        }
    }); });
    var classes = useStyles(props);
    return (React.createElement(RdiInputWrapper, __assign({}, props),
        React.createElement(RdiInputLabel, { tooltip: props.tooltip }, props.label),
        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(KeyboardDatePicker, __assign({}, (!!props.errorText && { helperText: props.errorText }), ((!props.isValid || !!props.errorText) && { error: true }), { margin: "normal", label: "", format: "MM/dd/yyyy", placeholder: props.placeholder, invalidDateMessage: "Invalid date format", value: props.value || null, onChange: function (e) {
                    var value = e;
                    if (value instanceof Date) {
                        if (props.time === "endOfDay") {
                            value = endOfDay(e);
                        }
                        else {
                            value = startOfDay(e);
                        }
                    }
                    props.onChange({ value: value });
                }, KeyboardButtonProps: {
                    "aria-label": "change date"
                }, inputVariant: "outlined", disabled: props.disabled ? props.disabled : false, style: {
                    marginBottom: "0px",
                    width: props.fullWidth ? "100%" : "150px"
                }, InputProps: {
                    className: classes.datepicker
                }, name: props.name })))));
};
