var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import autoSaveConfig from '@cros/shared/config/autoSaveConfig';
import { BillingCodeStatusEnum } from '@cros/shared/constants/enums/BillingCodeStatusEnum';
import { Visit } from '@cros/shared/types/models/Visit';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { assignFilterLabels, assignFiltersFromQueryString, getURLSearchObject } from '@cros/shared/utils/queryStringUtils';
import { debounce } from 'lodash';
import { observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import BillingCodeService from '~/services/BilledItemService';
var BilledItemStore = /** @class */ (function () {
    function BilledItemStore(siteStore, searchString, history) {
        var _this = this;
        this.siteStore = siteStore;
        this.searchString = searchString;
        this.history = history;
        this.billedItemsCounts = {};
        this.billedItemsCount = 0;
        this.billedItemsLoading = true;
        this.billedItems = [];
        this.billingCodeFilters = {
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            orderDir: 'DESC',
            orderBy: 'createdAt',
            search: undefined,
            patientId: undefined,
            trialId: undefined,
            billingCodes__status: BillingCodeStatusEnum.OPEN
        };
        this.billingCodeFilterLabels = {
            patientId: 'All',
            trialId: 'All'
        };
        this.listBilledItems = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, resp, _a, _b, _c, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        this.billedItemsLoading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = BillingCodeService).listBilledItems;
                        _c = [siteId,
                            this.billingCodeFilters];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.billedItems = [];
                        this.billedItemsCount = 0;
                        this.errorModalStore.setError(e_1);
                        this.billedItemsLoading = false;
                        return [2 /*return*/, false];
                    case 5:
                        runInAction(function () {
                            _this.billedItems = resp.items;
                            _this.billedItemsCount = resp.count;
                            _this.billedItemsCounts = resp.counts;
                            _this.billedItemsLoading = false;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.getBilledItemById = function (id, type) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = BillingCodeService).getBilledItemById;
                        _c = [id + "--" + type, siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.errorModalStore.setError(e_2);
                        return [2 /*return*/, null];
                    case 5: return [2 /*return*/, resp];
                }
            });
        }); };
        this.changeBillingCodeFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.billingCodeFilters = __assign(__assign({}, this.billingCodeFilters), newFilters);
                        this.billingCodeFilterLabels = __assign(__assign({}, this.billingCodeFilterLabels), newLabels);
                        if (!!newFilters.search) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.listBilledItems()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.searchDebounce();
                        _a.label = 3;
                    case 3:
                        this.history.push(getURLSearchObject(this.billingCodeFilters));
                        return [2 /*return*/];
                }
            });
        }); };
        this.listBilledItemsFilters = function () { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, resp, _a, _b, _c, e_3;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = BillingCodeService).listBilledItemsFilters;
                        _c = [siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _d.sent();
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/];
                    case 5:
                        this.availableBilledItemsFilters = resp;
                        assignFilterLabels(this.billingCodeFilters, this.billingCodeFilterLabels, this.availableBilledItemsFilters);
                        return [2 /*return*/];
                }
            });
        }); };
        this.addBillingCode = function (billingItem, billingCode, silent) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, newBillingCode, _b, _c, _d, e_4;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        billingItem.billingType = billingItem instanceof Visit ? 'VisitModel' : 'TaskModel';
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).addBillingCode;
                        _d = [billingItem,
                            billingCode,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        newBillingCode = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _e.sent();
                        if (e_4.code === 'validation-exception') {
                            throw e_4;
                        }
                        errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var item = _this.billedItems.find(function (i) { return i.id === billingItem.id; });
                            if (item) {
                                item.billingCodes.push(newBillingCode);
                            }
                            // do not push it in existing here, it's updated realtime from the view
                            // billingItem.billingCodes.push(newBillingCode);
                            billingCode.id = newBillingCode.id;
                        });
                        if (!silent) {
                            toastStore.addToast(new ToastMessage("New billing code " + billingCode.code + " added"));
                        }
                        return [2 /*return*/, newBillingCode];
                }
            });
        }); };
        this.updateBillingCode = function (billingItem, billingCode, silent) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_5;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        billingItem.billingType = billingItem instanceof Visit ? 'VisitModel' : 'TaskModel';
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).updateBillingCode;
                        _d = [billingItem,
                            billingCode,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _e.sent();
                        if (e_5.code === 'validation-exception') {
                            throw e_5;
                        }
                        errorModalStore.setError(e_5);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var item = _this.billedItems.find(function (i) { return i.id === billingItem.id; });
                            if (item) {
                                var existingIndex = item.billingCodes.findIndex(function (log) { return log.id === updated.id; });
                                if (existingIndex >= 0) {
                                    item.billingCodes[existingIndex] = updated;
                                }
                            }
                            var existingIndex1 = billingItem.billingCodes.findIndex(function (log) { return log.id === updated.id; });
                            billingItem.billingCodes[existingIndex1] = updated;
                        });
                        if (!silent) {
                            toastStore.addToast(new ToastMessage("Billing code " + billingCode.code + " updated"));
                        }
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.approveCodeWithConfirmation = function (billedItem, code, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to approve code " + code.code + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.approveCode(billedItem, code)];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.bulkApproveCodesWithConfirmation = function (billedItem, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to approve codes for " + billedItem.billingName + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.bulkApproveCodes(billedItem)];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.bulkDenyCodesWithConfirmation = function (billedItem, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to deny codes for " + billedItem.billingName + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.bulkDenyCodes(billedItem)];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.bulkRevertCodesWithConfirmation = function (billedItem, tab, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to revert codes for " + billedItem.billingName + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.bulkRevertCodes(billedItem, tab)];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.denyCodeWithConfirmation = function (billedItem, code, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to deny code " + code.code + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.denyCode(billedItem, code)];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.revertWithConfirmation = function (billedItem, code, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to revert decision for " + code.code + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.revertCode(billedItem, code)];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.approveCode = function (billedItem, billingCode) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_6;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).approveBillingCode;
                        _d = [billedItem,
                            billingCode,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _e.sent();
                        errorModalStore.setError(e_6);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            billingCode.status = BillingCodeStatusEnum.APPROVED;
                            var index = _this.billedItems.findIndex(function (i) { return i.id === billedItem.id; });
                            if (index >= 0) {
                                var item = _this.billedItems[index];
                                item.billingCodes = item.billingCodes.filter(function (b) { return b.id !== billingCode.id; });
                                if (item.billingCodes.length === 0) {
                                    _this.billedItems.splice(index, 1);
                                    _this.billedItemsCounts['needs-approval'] -= 1;
                                    _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount -= 1;
                                }
                                _this.billedItemsCounts.approved = (_this.billedItemsCounts.approved || 0) + 1;
                            }
                        });
                        toastStore.addToast(new ToastMessage("Billing code " + billingCode.code + " approved"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.bulkApproveCodes = function (billedItem) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_7;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).bulkApproveBillingCodes;
                        _d = [billedItem,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_7 = _e.sent();
                        errorModalStore.setError(e_7);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var index = _this.billedItems.findIndex(function (i) { return i.id === billedItem.id; });
                            if (index >= 0) {
                                _this.billedItems.splice(index, 1);
                                _this.billedItemsCounts['needs-approval'] -= 1;
                                _this.billedItemsCounts.approved = (_this.billedItemsCounts.approved || 0) + 1;
                                _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount -= 1;
                            }
                        });
                        toastStore.addToast(new ToastMessage("Billing codes for " + updated.billingName + " approved"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.revertCode = function (billedItem, billingCode) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_8;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).revertBillingCode;
                        _d = [billedItem,
                            billingCode,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_8 = _e.sent();
                        errorModalStore.setError(e_8);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var prevStatus = billingCode.status;
                            billingCode.status = BillingCodeStatusEnum.NEEDS_APPROVAL;
                            var index = _this.billedItems.findIndex(function (i) { return i.id === billedItem.id; });
                            if (index >= 0) {
                                var item = _this.billedItems[index];
                                item.billingCodes = item.billingCodes.filter(function (b) { return b.id !== billingCode.id; });
                                if (item.billingCodes.length === 0) {
                                    _this.billedItems.splice(index, 1);
                                    _this.billedItemsCounts['needs-approval'] += 1;
                                    if (prevStatus === BillingCodeStatusEnum.APPROVED) {
                                        _this.billedItemsCounts.approved -= 1;
                                        _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount += 1;
                                    }
                                    else {
                                        _this.billedItemsCounts.denied -= 1;
                                    }
                                }
                            }
                        });
                        toastStore.addToast(new ToastMessage("Billing code " + billingCode.code + " reverted"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.bulkRevertCodes = function (billedItem, tab) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_9;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).bulkRevertBillingCodes;
                        _d = [billedItem,
                            tab,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_9 = _e.sent();
                        errorModalStore.setError(e_9);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var index = _this.billedItems.findIndex(function (i) { return i.id === billedItem.id; });
                            if (index >= 0) {
                                _this.billedItems.splice(index, 1);
                                _this.billedItemsCounts['needs-approval'] += 1;
                                if (tab === 'approved') {
                                    _this.billedItemsCounts.approved -= 1;
                                    _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount += 1;
                                }
                                else {
                                    _this.billedItemsCounts.denied -= 1;
                                }
                            }
                        });
                        toastStore.addToast(new ToastMessage("Billing codes for " + updated.billingName + " reverted"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.denyCode = function (billedItem, billingCode) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_10;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).denyBillingCode;
                        _d = [billedItem,
                            billingCode,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_10 = _e.sent();
                        errorModalStore.setError(e_10);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            billingCode.status = BillingCodeStatusEnum.DENIED;
                            var index = _this.billedItems.findIndex(function (i) { return i.id === billedItem.id; });
                            if (index >= 0) {
                                var item = _this.billedItems[index];
                                item.billingCodes = item.billingCodes.filter(function (b) { return b.id !== billingCode.id; });
                                if (item.billingCodes.length === 0) {
                                    _this.billedItems.splice(index, 1);
                                    _this.billedItemsCounts['needs-approval'] -= 1;
                                    _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount -= 1;
                                }
                                _this.billedItemsCounts.denied = (_this.billedItemsCounts.denied || 0) + 1;
                            }
                        });
                        toastStore.addToast(new ToastMessage("Billing code " + billingCode.code + " denied"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.bulkDenyCodes = function (billedItem) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_11;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).bulkDenyBillingCodes;
                        _d = [billedItem,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_11 = _e.sent();
                        errorModalStore.setError(e_11);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var index = _this.billedItems.findIndex(function (i) { return i.id === billedItem.id; });
                            if (index >= 0) {
                                _this.billedItems.splice(index, 1);
                                _this.billedItemsCounts['needs-approval'] -= 1;
                                _this.billedItemsCounts.approved = (_this.billedItemsCounts.denied || 0) + 1;
                                _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount -= 1;
                            }
                        });
                        toastStore.addToast(new ToastMessage("Billing codes for " + updated.billingName + " denied"));
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.deleteBillingCode = function (billedItem, billingCode, silent) { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteId, authToken, errorModalStore, toastStore, updated, _b, _c, _d, e_12;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        billedItem.billingType = billedItem instanceof Visit ? 'VisitModel' : 'TaskModel';
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore, toastStore = _a.toastStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = BillingCodeService).deleteBillingCode;
                        _d = [billedItem,
                            billingCode,
                            siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        updated = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_12 = _e.sent();
                        errorModalStore.setError(e_12);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var index = _this.billedItems.findIndex(function (i) { return i.id === billedItem.id; });
                            if (index >= 0) {
                                var item = _this.billedItems[index];
                                item.billingCodes = item.billingCodes.filter(function (b) { return b.id !== billingCode.id; });
                                if (item.billingCodes.length === 0) {
                                    _this.billedItems.splice(index, 1);
                                    _this.billedItemsCounts['needs-approval'] -= 1;
                                    _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount -= 1;
                                }
                            }
                            billedItem.billingCodes = billedItem.billingCodes.filter(function (b) { return b.id !== billingCode.id; });
                        });
                        if (!silent) {
                            toastStore.addToast(new ToastMessage("Billing code " + billingCode.code + " deleted"));
                        }
                        return [2 /*return*/, updated];
                }
            });
        }); };
        this.deleteBillingCodeWithConfirmation = function (item, code, onSuccess) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to delete billing code " + code.code + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deleteBillingCode(item, code)];
                            case 1:
                                _a.sent();
                                if (onSuccess)
                                    onSuccess();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.searchDebounce = debounce(this.listBilledItems, autoSaveConfig.autoSaveDelay);
        assignFiltersFromQueryString(searchString, this.billingCodeFilters);
    }
    Object.defineProperty(BilledItemStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BilledItemStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BilledItemStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BilledItemStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], BilledItemStore.prototype, "billedItemsCounts", void 0);
    __decorate([
        observable
    ], BilledItemStore.prototype, "billedItemsCount", void 0);
    __decorate([
        observable
    ], BilledItemStore.prototype, "billedItemsLoading", void 0);
    __decorate([
        observable
    ], BilledItemStore.prototype, "billedItems", void 0);
    __decorate([
        observable
    ], BilledItemStore.prototype, "availableBilledItemsFilters", void 0);
    __decorate([
        observable
    ], BilledItemStore.prototype, "billingCodeFilters", void 0);
    __decorate([
        observable
    ], BilledItemStore.prototype, "billingCodeFilterLabels", void 0);
    return BilledItemStore;
}());
export default BilledItemStore;
export var injectBilledItemStore = inject('billingCodeStore');
