var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from "mobx";
import { Model } from "s~/types/models/Model";
import { Task } from "s~/types/models/Task";
import { User } from "s~/types/models/User";
import { Visit } from "s~/types/models/Visit";
import { getDateFromString } from "s~/utils/dateUtils";
var BillingCode = /** @class */ (function (_super) {
    __extends(BillingCode, _super);
    function BillingCode(p) {
        var _this = _super.call(this, p) || this;
        _this.id = p.id;
        _this.description = p.description;
        _this.type = p.type;
        _this.code = p.code;
        _this.amount = p === null || p === void 0 ? void 0 : p.amount;
        _this.needsApproval = p === null || p === void 0 ? void 0 : p.needsApproval;
        _this.status = p.status;
        _this.trialId = p.trialId;
        _this.patientId = p.patientId;
        _this.billedItemId = p.billedItemId;
        _this.billedItemName = p.billedItemName;
        if (p.billedItem) {
            if (p.billedItem.billingType === "VisitModel") {
                _this.billedItem = new Visit(p.billedItem);
            }
            if (p.billedItem.billingType === "TaskModel") {
                _this.billedItem = new Task(p.billedItem);
            }
        }
        _this.createdAt = p.createdAt ? getDateFromString(p.createdAt) : undefined;
        _this.approvedBy = p.approvedBy ? new User(p.approvedBy) : undefined;
        _this.approvedById = p.approvedById;
        _this.deniedBy = p.deniedBy ? new User(p.deniedBy) : undefined;
        _this.deniedById = p.deniedById;
        _this.submittedBy = p.submittedBy ? new User(p.submittedBy) : undefined;
        _this.submittedById = p.submittedById;
        return _this;
    }
    __decorate([
        observable
    ], BillingCode.prototype, "id", void 0);
    __decorate([
        observable
    ], BillingCode.prototype, "code", void 0);
    __decorate([
        observable
    ], BillingCode.prototype, "amount", void 0);
    __decorate([
        observable
    ], BillingCode.prototype, "needsApproval", void 0);
    __decorate([
        observable
    ], BillingCode.prototype, "expanded", void 0);
    __decorate([
        observable
    ], BillingCode.prototype, "draft", void 0);
    __decorate([
        observable
    ], BillingCode.prototype, "deleted", void 0);
    return BillingCode;
}(Model));
export { BillingCode };
