import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { BillingTypeLabelsEnum } from '@cros/shared/constants/enums/BillingTypeEnum';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
var ICDTable = function (props) {
    var columns = [
        {
            id: 'value',
            label: 'Value',
            sortable: false
        },
        {
            id: 'source',
            label: 'Source',
            sortable: false
        }
    ];
    var icdCodes = props.icdCodes;
    return (React.createElement(FlexContainer, { flexDirection: "column", alignItems: "stretch", margin: 10, style: { width: '100%' } },
        React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "ICD 10 codes"),
        React.createElement(RdiTable, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column })); }))),
            React.createElement(TableBody, null,
                icdCodes.map(function (code, index) { return (React.createElement(TableRow, { key: index },
                    React.createElement(TableCell, null, code.name),
                    React.createElement(TableCell, null, BillingTypeLabelsEnum[code.source]))); }),
                icdCodes.length === 0 && (React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: 2 }, "No results")))))));
};
ICDTable.defaultProps = {};
export default ICDTable;
