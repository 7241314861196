var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import RdiJsxParser from "s~/components/layout/RdiJsxParser";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
import RdiInputLabel from "./RdiInputLabel";
import RdiRadio from "./RdiRadio";
var RdiRadioGroup = function (props) {
    var onRadioChange = function (selected) {
        props.onChange(selected);
    };
    return (React.createElement("div", { style: __assign({ display: "flex", 
            // marginTop: '10px',
            marginBottom: "14px", flexDirection: "column" }, props.style) },
        !!props.label && (React.createElement(RdiInputLabel, { tooltip: props.tooltip },
            React.createElement(RdiJsxParser, null, props.label))),
        !!props.options && (React.createElement("div", { style: {
                display: "flex",
                flexDirection: props.type === "radio_horizontal" ? "row" : "column",
                flexWrap: props.inline ? "nowrap" : "wrap",
                marginTop: "2px"
            } }, props.options.map(function (checkbox, index) { return (React.createElement(FlexContainer, { margin: 20, style: {
                alignItems: "center",
                marginRight: "20px",
                marginBottom: "7px",
                marginTop: "7px"
            }, key: index },
            React.createElement(RdiRadio, { radioStyle: { marginRight: "15px" }, value: checkbox.value === props.value, onChange: function (selected) {
                    if (selected)
                        onRadioChange({ value: checkbox.value });
                }, disabled: props.disabled, name: props.label, labelStyle: props.labelStyle, label: checkbox.label }))); }))),
        !!props.errorText && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.Error }, props.errorText))));
};
export default RdiRadioGroup;
