export var DatapointTemplateEnum;
(function (DatapointTemplateEnum) {
    DatapointTemplateEnum["textbox"] = "textbox";
    DatapointTemplateEnum["text"] = "text";
    DatapointTemplateEnum["hr"] = "hr";
    DatapointTemplateEnum["h4"] = "h4";
    DatapointTemplateEnum["h3"] = "h3";
    DatapointTemplateEnum["h2"] = "h2";
    DatapointTemplateEnum["row"] = "row";
    DatapointTemplateEnum["label"] = "label";
    DatapointTemplateEnum["radio"] = "radio";
    DatapointTemplateEnum["checkbox"] = "checkbox";
    DatapointTemplateEnum["error"] = "error";
    DatapointTemplateEnum["button"] = "button";
    DatapointTemplateEnum["link"] = "link";
    DatapointTemplateEnum["date"] = "date";
    DatapointTemplateEnum["time"] = "time";
})(DatapointTemplateEnum || (DatapointTemplateEnum = {}));
