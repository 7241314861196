export var MedicationFrequencyEnum;
(function (MedicationFrequencyEnum) {
    MedicationFrequencyEnum["ONCE_DAILY"] = "once-daily";
    MedicationFrequencyEnum["TWICE_DAILY"] = "twice-daily";
    MedicationFrequencyEnum["THREE_TIMES_DAILY"] = "three-times-daily";
    MedicationFrequencyEnum["FOUR_TIMES_DAILY"] = "four-times-daily";
    MedicationFrequencyEnum["WEEKLY"] = "weekly";
    MedicationFrequencyEnum["MONTHLY"] = "monthly";
    MedicationFrequencyEnum["AS_NEEDED"] = "as-needed";
    MedicationFrequencyEnum["ONCE"] = "once";
})(MedicationFrequencyEnum || (MedicationFrequencyEnum = {}));
export var MedicationFrequencyLabelsEnum;
(function (MedicationFrequencyLabelsEnum) {
    MedicationFrequencyLabelsEnum["once-daily"] = "Once daily";
    MedicationFrequencyLabelsEnum["twice-daily"] = "Twice daily";
    MedicationFrequencyLabelsEnum["three-times-daily"] = "Three times daily";
    MedicationFrequencyLabelsEnum["four-times-daily"] = "Four times daily";
    MedicationFrequencyLabelsEnum["weekly"] = "Weekly";
    MedicationFrequencyLabelsEnum["monthly"] = "Monthly";
    MedicationFrequencyLabelsEnum["as-needed"] = "As needed";
    MedicationFrequencyLabelsEnum["once"] = "Once";
})(MedicationFrequencyLabelsEnum || (MedicationFrequencyLabelsEnum = {}));
