var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Model } from "s~/types/models/Model";
import { getDateFromString } from "s~/utils/dateUtils";
var IpDosingLog = /** @class */ (function (_super) {
    __extends(IpDosingLog, _super);
    function IpDosingLog(p) {
        var _this = _super.call(this, p) || this;
        _this.ipDispensingLogId = p.ipDispensingLogId;
        _this.ipDispensingLog = p.ipDispensingLog;
        _this.dosingStaffName = p.dosingStaffName;
        _this.dosedAt = getDateFromString(p.dosedAt);
        _this.id = p.id;
        return _this;
    }
    return IpDosingLog;
}(Model));
export { IpDosingLog };
