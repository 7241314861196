import * as Sentry from '@sentry/browser';
import { CaptureConsole, RewriteFrames } from '@sentry/integrations';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-lato';
import 'typeface-montserrat';
import 'whatwg-fetch';
import config from '~/config';
import App from './App';
// microsoft edge compatibility
window.globalThis = window;
globalThis.__rootdir__ = __dirname || process.cwd();
Sentry.init({
    release: config.release,
    environment: config.environment,
    dsn: config.sentryDsn,
    integrations: [
        new RewriteFrames({
            root: globalThis.__rootdir__
        }),
        new CaptureConsole({
            levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
        })
    ],
    debug: false
});
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
// if (module.hot) {
//   module.hot.accept();
// }
