var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiCheckbox from '@cros/shared/components/forms/RdiCheckbox';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { ProcedureCategoryLabelEnum } from '@cros/shared/constants/enums/ProcedureCategoryEnum';
import { VisitTypeLabelsEnum } from '@cros/shared/constants/enums/VisitTypeEnum';
import { Procedure } from '@cros/shared/types/models/Procedure';
import { Visit } from '@cros/shared/types/models/Visit';
import { getDateFromTimeAndDate } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { Button, CircularProgress, createStyles, Typography, withStyles } from '@material-ui/core';
import { differenceInMinutes } from 'date-fns';
import { addMinutes } from 'date-fns/esm';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTrialStore } from '~/stores/TrialStore';
import VisitNotificationsInfo from './visit/VisitNotificationsInfo';
var styles = function () { return createStyles({}); };
var NewUnscheduledVisit = /** @class */ (function (_super) {
    __extends(NewUnscheduledVisit, _super);
    function NewUnscheduledVisit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errorMessage = '';
        _this.saving = false;
        _this.loading = true;
        _this.hasErrors = false;
        _this.data = {
            name: {
                value: '',
                error: null,
                rule: 'required'
            },
            description: {
                value: '',
                error: null,
                rule: 'required'
            },
            engagement: {
                value: new Date(),
                error: null,
                rule: 'required|date'
            },
            engagementDuration: {
                value: 30,
                error: null,
                rule: 'required'
            },
            type: {
                value: '',
                label: '',
                error: null,
                rule: 'required'
            },
            time: {
                value: new Date(),
                error: null,
                rule: 'required|date'
            },
            procedures: {
                error: null,
                items: []
            },
            responsiblePeople: {
                value: [],
                label: '',
                error: null
            }
        };
        _this.procedures = [];
        _this.groupedProcedures = undefined;
        _this.visitTypes = [];
        _this.visitNames = [
            'CCM',
            'Cirrhosis Gilead',
            'COPD Genentech',
            'General',
            'Genfit',
            'Hypertension',
            'Legend Inventiva',
            'Nativ3',
            'RPM',
            'Wellness',
            'Other'
        ];
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var enrollmentStore, visit;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        enrollmentStore = this.props.enrollmentStore;
                        return [4 /*yield*/, this.getAllPossibleProcedures()];
                    case 1:
                        _a.sent();
                        this.groupProceduresByCategory();
                        if (!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, enrollmentStore.visitListStore.getVisitById(this.visitId)];
                    case 2:
                        visit = _a.sent();
                        this.assignData(visit);
                        this.assignReminderData(visit);
                        _a.label = 3;
                    case 3: return [4 /*yield*/, enrollmentStore.visitListStore.listVisitFilters()];
                    case 4:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignData = function (visit) {
            _this.data.name.value = visit.name;
            _this.data.description.value = visit.description;
            _this.data.engagement.value = visit.engagement;
            _this.data.engagementDuration.value = differenceInMinutes(visit.engagementEnd, visit.engagement);
            _this.data.time.value = visit.engagement;
            _this.data.type.label = VisitTypeLabelsEnum[visit.type];
            _this.data.type.value = visit.type;
            _this.data.procedures.items = visit.procedures;
            _this.data.responsiblePeople.value = visit.responsiblePeople.map(function (e) { return ({
                label: e.fullName,
                value: e.id
            }); });
        };
        _this.assignReminderData = function (visit) {
            _this.reminders = {
                initialEmailReminder: visit.initialEmailReminder,
                initialSmsReminder: visit.initialSmsReminder,
                secondEmailReminder: visit.secondEmailReminder,
                secondSmsReminder: visit.secondSmsReminder
            };
            Object.keys(_this.reminders).forEach(function (key) {
                var _a;
                if ((_a = _this.reminders[key]) === null || _a === void 0 ? void 0 : _a.sentAt)
                    _this.reminders[key].sentAt = new Date(_this.reminders[key].sentAt);
            });
        };
        _this.getAllPossibleProcedures = function () { return __awaiter(_this, void 0, void 0, function () {
            var trialStore, protocol, _i, _a, procedure;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        trialStore = this.props.trialStore;
                        return [4 /*yield*/, trialStore.getProtocolLogic()];
                    case 1:
                        protocol = _b.sent();
                        this.procedures = (protocol === null || protocol === void 0 ? void 0 : protocol.procedures) || [];
                        for (_i = 0, _a = this.procedures; _i < _a.length; _i++) {
                            procedure = _a[_i];
                            procedure.type = procedure.slug;
                            procedure.category = Procedure.getCategory(procedure.type);
                        }
                        this.visitTypes = protocol.visitTypes;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.groupProceduresByCategory = function () {
            _this.groupedProcedures = _this.groupBy(_this.procedures.slice().sort(function (a, b) { var _a; return (_a = a.category) === null || _a === void 0 ? void 0 : _a.localeCompare(b.category); }), 'category');
        };
        _this.groupBy = function (arr, key) {
            return arr.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split('/visits')[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.visitTypeChange = function (e, field) {
            var _a;
            fieldChange(e, field, _this.data);
            _this.data.procedures.items = __spreadArrays((((_a = _this.visitTypes.find(function (v) { return v.slug === _this.data.type.value; })) === null || _a === void 0 ? void 0 : _a.procedures) || []));
        };
        _this.onSubmit = function (e, hasSendNotifications) { return __awaiter(_this, void 0, void 0, function () {
            var enrollmentStore, o, newVisit, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        enrollmentStore = this.props.enrollmentStore;
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        o = this.getObjectToSave();
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, enrollmentStore.visitListStore.addVisit(o, hasSendNotifications)];
                    case 2:
                        newVisit = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, enrollmentStore.visitListStore.updateVisit(o, hasSendNotifications)];
                    case 4:
                        newVisit = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 8];
                    case 7:
                        this.saving = false;
                        if (!newVisit) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors);
        };
        _this.getObjectToSave = function () {
            var engagement = getDateFromTimeAndDate(_this.data.time.value, _this.data.engagement.value);
            var engagementEnd = addMinutes(engagement, _this.data.engagementDuration.value);
            var o = new Visit({
                id: _this.visitId,
                name: _this.data.name.value,
                description: _this.data.description.value,
                engagement: engagement,
                engagementEnd: engagementEnd,
                procedures: _this.data.procedures.items.filter(function (p) { return !_this.isProcedureDisabled(p); }),
                type: _this.data.type.value,
                responsiblePeopleIds: _this.data.responsiblePeople.value.map(function (e) { return e.value; })
            });
            return o;
        };
        _this.selectProcedure = function (procedure) {
            var index = _this.data.procedures.items.map(function (item) { return item.slug; }).indexOf(procedure.slug);
            if (index === -1) {
                _this.data.procedures.items.push(procedure);
            }
            else {
                _this.data.procedures.items = _this.data.procedures.items.filter(function (item) { return item.label !== procedure.label; });
            }
        };
        _this.isProcedureSelected = function (procedure) {
            if (_this.data.procedures.items.map(function (item) { return item.slug; }).indexOf(procedure.slug) !== -1 ||
                _this.isProcedureDisabled(procedure)) {
                return true;
            }
            return false;
        };
        _this.isProcedureDisabled = function (procedure) {
            var _a;
            return !!((_a = _this.visitTypes
                .find(function (v) { return v.slug === _this.data.type.value; })) === null || _a === void 0 ? void 0 : _a.procedures.find(function (p) { return p.slug === procedure.slug; }));
        };
        _this.onDurationButtonClick = function (isIncrement) {
            if (isIncrement) {
                _this.data.engagementDuration.value += 30;
            }
            else if (_this.data.engagementDuration.value >= 30) {
                _this.data.engagementDuration.value -= 30;
            }
        };
        _this.onEngagementDurationChange = function (e) {
            // eslint-disable-next-line no-restricted-globals
            if (e.value >= 0 || isNaN(e.value)) {
                _this.fieldChange(e, 'engagementDuration');
            }
        };
        _this.getIndexOfProcedure = function (procedure) {
            var _a, _b, _c;
            // eslint-disable-next-line no-plusplus
            for (var i = 0; i < ((_c = (_b = (_a = _this.data) === null || _a === void 0 ? void 0 : _a.procedures) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.length); i++) {
                if (_this.data.procedures.items[i].slug === procedure.slug) {
                    return i;
                }
            }
            return -1;
        };
        return _this;
    }
    Object.defineProperty(NewUnscheduledVisit.prototype, "visitId", {
        get: function () {
            var params = this.props.match.params;
            return params.visitId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NewUnscheduledVisit.prototype, "isEdit", {
        get: function () {
            return !!this.visitId;
        },
        enumerable: false,
        configurable: true
    });
    NewUnscheduledVisit.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props, visitListStore = _c.enrollmentStore.visitListStore, patient = _c.patientStore.patient;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" },
                this.isEdit ? 'Edit' : 'Add a',
                " visit"),
            React.createElement(FlexContainer, { style: { marginTop: '10px' }, margin: 15 },
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient.fullName),
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
            React.createElement(RdiLine, null),
            React.createElement("form", { noValidate: true, autoComplete: "off" },
                React.createElement(FlexContainer, { margin: 30 },
                    React.createElement(RdiInput, { type: "text", label: "Visit name", name: "type", value: this.data.name.value, errorText: this.data.name.error, onChange: function (e) { return _this.fieldChange(e, 'name'); } })),
                React.createElement(FlexContainer, null,
                    React.createElement(RdiInput, { type: "text_multiline", name: "description", label: "Visit description", value: this.data.description.value, errorText: this.data.description.error, onChange: function (e) { return _this.fieldChange(e, 'description'); } })),
                React.createElement(FlexContainer, { margin: 30, alignItems: "flex-start", style: { flexWrap: 'wrap' } },
                    React.createElement(RdiInput, { type: "select", label: "Visit type", name: "type", value: this.data.type.value, selectedItemLabel: this.data.type.label, errorText: this.data.type.error, onChange: function (e) { return _this.visitTypeChange(e, 'type'); }, style: { width: 'calc(50% - 15px)' }, options: this.visitTypes.map(function (visitType) { return ({
                            label: visitType.name,
                            value: visitType.slug
                        }); }) }),
                    React.createElement(RdiInput, { type: "date", label: "Visit date", name: "date", value: this.data.engagement.value, errorText: this.data.engagement.error, onChange: function (e) { return _this.fieldChange(e, 'engagement'); }, style: { width: 'calc(50% - 15px)' } })),
                React.createElement(FlexContainer, { margin: 30, alignItems: "flex-start", style: { flexWrap: 'wrap' } },
                    React.createElement(RdiInput, { type: "time", label: "Visit time start", name: "time", value: this.data.time.value, errorText: this.data.time.error, onChange: function (e) { return _this.fieldChange(e, 'time'); }, style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(RdiInput, { type: "number", label: "Visit duration (minutes)", name: "engagementDuration", value: this.data.engagementDuration.value, errorText: this.data.engagementDuration.error, style: { width: 'calc(50% - 15px)' }, onChange: this.onEngagementDurationChange })),
                React.createElement(FlexContainer, { margin: 30, alignItems: "flex-start", style: { flexWrap: 'wrap' } },
                    React.createElement("div", { style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(FlexContainer, { justifyContent: "space-between", style: { width: 'calc(50% - 15px)' } },
                        React.createElement(Button, { variant: "outlined", onClick: function () { return _this.onDurationButtonClick(false); } }, "- 30 min"),
                        React.createElement(Button, { variant: "outlined", onClick: function () { return _this.onDurationButtonClick(true); } }, "+ 30 min"))),
                React.createElement(FlexContainer, null,
                    React.createElement(RdiInput, { type: "select-multiple", label: "Personnel", name: "responsiblePersonnel", value: this.data.responsiblePeople.value, errorText: this.data.responsiblePeople.error, onChange: function (e) { return _this.fieldChange(e, 'responsiblePeople'); }, placeholder: "Select responsible personnel", options: visitListStore.availableVisitFilters
                            ? visitListStore.availableVisitFilters.userId.map(function (item) { return ({
                                label: item.label,
                                value: item.slug
                            }); })
                            : [] })),
                React.createElement("div", null,
                    React.createElement(RdiInputLabel, { label: "Procedure forms", error: this.data.procedures.error }), (_a = Object.keys(this.groupedProcedures || {})) === null || _a === void 0 ? void 0 :
                    _a.map(function (key, parentIndex) { return (React.createElement(React.Fragment, { key: key },
                        React.createElement(Typography, { style: { marginBottom: '8px' } }, ProcedureCategoryLabelEnum[key]),
                        _this.groupedProcedures[key].map(function (procedure, index) { return (React.createElement(FlexContainer, { key: key + "-" + parentIndex + "-" + index },
                            _this.isProcedureSelected(procedure) && (React.createElement("div", { style: { marginBottom: '14px', marginLeft: '-8px' } }, _this.getIndexOfProcedure(procedure) + 1)),
                            React.createElement(RdiCheckbox, { onChange: function () { return _this.selectProcedure(procedure); }, value: _this.isProcedureSelected(procedure), name: procedure.slug, disabled: _this.isProcedureDisabled(procedure) }, procedure.label))); }))); }),
                    !this.loading && this.procedures.length === 0 && React.createElement("span", null, "No procedures"),
                    this.loading && React.createElement(CircularProgress, null)),
                this.isEdit && (React.createElement(React.Fragment, null,
                    React.createElement(RdiInputLabel, { label: "Notifications" }),
                    React.createElement(VisitNotificationsInfo, { reminders: this.reminders, engagement: getDateFromTimeAndDate(this.data.time.value, this.data.engagement.value) }))),
                React.createElement(RdiLine, null),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    !((_b = this.reminders) === null || _b === void 0 ? void 0 : _b.initialEmailReminder) && (React.createElement(RdiButton, { className: "submitVisit", loading: this.saving, onClick: function (e) { return _this.onSubmit(e, false); }, style: { minWidth: '220px' }, fullWidth: false, margin: "none" }, this.isEdit ? 'Save' : 'Add')),
                    React.createElement(RdiButton, { className: "submitVisit", loading: this.saving, onClick: function (e) { return _this.onSubmit(e, true); }, style: { minWidth: '220px' }, fullWidth: false, margin: "none" },
                        this.isEdit ? 'Save' : 'Add',
                        " and notify")))));
    };
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "saving", void 0);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "loading", void 0);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "data", void 0);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "procedures", void 0);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "groupedProcedures", void 0);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "visitTypes", void 0);
    __decorate([
        computed
    ], NewUnscheduledVisit.prototype, "visitId", null);
    __decorate([
        computed
    ], NewUnscheduledVisit.prototype, "isEdit", null);
    __decorate([
        observable
    ], NewUnscheduledVisit.prototype, "visitNames", void 0);
    NewUnscheduledVisit = __decorate([
        injectPatientStore,
        injectTrialStore,
        injectAccountStore,
        injectEnrollmentStore,
        injectSiteStore,
        observer
    ], NewUnscheduledVisit);
    return NewUnscheduledVisit;
}(Component));
export default withStyles(styles)(withRouter(NewUnscheduledVisit));
