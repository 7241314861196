var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { SiteRoleTrialContact } from '@cros/shared/types/models/SiteRoleTrial';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectTrialStore } from '~/stores/TrialStore';
var SponsorEnums;
(function (SponsorEnums) {
    SponsorEnums["CRO"] = "CRO";
    SponsorEnums["Sponsor"] = "Sponsor";
})(SponsorEnums || (SponsorEnums = {}));
var AddTrialContactModal = /** @class */ (function (_super) {
    __extends(AddTrialContactModal, _super);
    function AddTrialContactModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasErrors = false;
        _this.data = {
            name: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            title: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            email: {
                label: '',
                value: '',
                error: null,
                rule: 'required|email'
            },
            phoneNumber: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            sponsor: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            }
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, Promise, function () {
            var _a, addTrialSiteContact, editTrialSiteContact;
            return __generator(this, function (_b) {
                e.preventDefault();
                this.hasErrors = this.validateForm();
                if (this.hasErrors) {
                    return [2 /*return*/];
                }
                _a = this.props.trialStore, addTrialSiteContact = _a.addTrialSiteContact, editTrialSiteContact = _a.editTrialSiteContact;
                if (this.isEdit) {
                    editTrialSiteContact(this.contactIndex, this.getObject());
                }
                else {
                    addTrialSiteContact(this.getObject());
                }
                this.onClose();
                return [2 /*return*/];
            });
        }); };
        _this.handleDelete = function () { return __awaiter(_this, void 0, void 0, function () {
            var deleteTrialSiteContact;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deleteTrialSiteContact = this.props.trialStore.deleteTrialSiteContact;
                        return [4 /*yield*/, deleteTrialSiteContact(this.contactIndex)];
                    case 1:
                        _a.sent();
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObject = function () {
            return new SiteRoleTrialContact({
                name: _this.data.name.value,
                title: _this.data.title.value,
                email: _this.data.email.value,
                phoneNumber: _this.data.phoneNumber.value,
                sponsor: _this.data.sponsor.value
            });
        };
        _this.parentUrl = function () {
            var url = _this.props.match.url;
            return url.split('/contact')[0];
        };
        _this.onClose = function () {
            var _a = _this.props, onClose = _a.onClose, history = _a.history;
            if (onClose) {
                onClose();
            }
            history.push(_this.parentUrl());
        };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        return _this;
    }
    AddTrialContactModal.prototype.componentDidMount = function () {
        var _a;
        var siteRoleTrial = this.props.trialStore.siteRoleTrial;
        if (this.isEdit && ((_a = siteRoleTrial === null || siteRoleTrial === void 0 ? void 0 : siteRoleTrial.contact) === null || _a === void 0 ? void 0 : _a.length)) {
            this.data.name.value = siteRoleTrial.contact[this.contactIndex].name;
            this.data.title.value = siteRoleTrial.contact[this.contactIndex].title;
            this.data.email.value = siteRoleTrial.contact[this.contactIndex].email;
            this.data.phoneNumber.value = siteRoleTrial.contact[this.contactIndex].phoneNumber;
            this.data.sponsor.value = siteRoleTrial.contact[this.contactIndex].sponsor;
        }
    };
    Object.defineProperty(AddTrialContactModal.prototype, "contactIndex", {
        get: function () {
            var params = this.props.match.params;
            return Number(params.index);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddTrialContactModal.prototype, "isEdit", {
        get: function () {
            var url = this.props.match.url;
            return url.includes('/contact/edit');
        },
        enumerable: false,
        configurable: true
    });
    AddTrialContactModal.prototype.render = function () {
        var _this = this;
        var contactSavingLoading = this.props.trialStore.contactSavingLoading;
        return (React.createElement(SmallModal, { loading: contactSavingLoading, onClose: this.onClose },
            React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '0px' } },
                this.isEdit ? 'Edit' : 'Add',
                " contact"),
            React.createElement(RdiLine, { style: { margin: '15px 0px' } }),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent, style: { flex: '1' } },
                React.createElement("div", { style: { flex: '1' } },
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20 },
                        React.createElement(RdiInput, { type: "text", label: "Name", name: "name", value: this.data.name.value, errorText: this.data.name.error, onChange: function (e) { return fieldChange(e, 'name', _this.data); } })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20 },
                        React.createElement(RdiInput, { type: "text", label: "Title", name: "title", value: this.data.title.value, errorText: this.data.title.error, onChange: function (e) { return fieldChange(e, 'title', _this.data); } })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20 },
                        React.createElement(RdiInput, { type: "text", label: "Email", name: "email", value: this.data.email.value, errorText: this.data.email.error, onChange: function (e) { return fieldChange(e, 'email', _this.data); } })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20 },
                        React.createElement(RdiInput, { type: "text", label: "Phone number", name: "phoneNumber", value: this.data.phoneNumber.value, errorText: this.data.phoneNumber.error, onChange: function (e) { return fieldChange(e, 'phoneNumber', _this.data); } })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 20 },
                        React.createElement(RdiInput, { type: "select", label: "Sponsor", name: "sponsor", value: this.data.sponsor.value, selectedItemLabel: this.data.sponsor.value, errorText: this.data.sponsor.error, options: Object.values(SponsorEnums).map(function (val) { return ({
                                value: val,
                                label: val
                            }); }), onChange: function (e) { return fieldChange(e, 'sponsor', _this.data); } }))),
                React.createElement(FlexContainer, { justifyContent: "flex-end" },
                    this.isEdit && (React.createElement(RdiButton, { loading: contactSavingLoading, onClick: this.handleDelete, style: { marginRight: '16px' } }, "Delete")),
                    React.createElement(RdiButton, { loading: contactSavingLoading, submit: true, margin: "none" }, "Save")))));
    };
    __decorate([
        observable
    ], AddTrialContactModal.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], AddTrialContactModal.prototype, "data", void 0);
    __decorate([
        computed
    ], AddTrialContactModal.prototype, "contactIndex", null);
    AddTrialContactModal = __decorate([
        injectTrialStore,
        observer
    ], AddTrialContactModal);
    return AddTrialContactModal;
}(Component));
export default withRouter(AddTrialContactModal);
