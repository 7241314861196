var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiTypography, { h7Style } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { RdiChart } from '@cros/shared/components/misc/RdiChart';
import RdiCircle from '@cros/shared/components/misc/RdiCircle';
import RdiSquare from '@cros/shared/components/misc/RdiSquare';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import BaseModal from '@cros/shared/components/modals/BaseModal';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { DateSelectorStateEnum } from '@cros/shared/constants/enums/DateSelectorStateEnum';
import { DeviceTypeEnum } from '@cros/shared/constants/enums/DeviceTypeEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import NoMeasurements from '@cros/shared/static/NoMeasurements.svg';
import { formatDatetime } from '@cros/shared/utils/dateUtils';
import { AppBar, Button, ButtonGroup, CircularProgress, createStyles, Menu, Tab, TableBody, TableCell, TableHead, TableRow, Tabs, withStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import config from '~/config';
import { getPatientRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { DeviceDataStore } from '~/stores/PatientStore/DeviceDataStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () {
    return createStyles({
        activeButton: {
            backgroundColor: ColorsEnum.Blue,
            color: 'white',
            '&:hover': {
                backgroundColor: ColorsEnum.Blue,
                cursor: 'default'
            }
        },
        button: {}
    });
};
var WearableDataPage = /** @class */ (function (_super) {
    __extends(WearableDataPage, _super);
    function WearableDataPage(props) {
        var _this = _super.call(this, props) || this;
        _this.columns = [
            {
                id: 'timestamp',
                sortName: 'timestamp',
                sortable: true,
                label: 'Date',
                width: '180px'
            }
        ];
        _this.menuRef = null;
        _this.handleMenuClick = function (event) {
            _this.menuRef = event.currentTarget;
        };
        _this.handleMenuClose = function () {
            _this.menuRef = null;
        };
        _this.changeTab = function (e, value) { return __awaiter(_this, void 0, void 0, function () {
            var devices, device;
            return __generator(this, function (_a) {
                devices = this.props.enrollmentStore.enrollment.devices;
                device = devices.find(function (d) { return d.type === value; });
                this.deviceDataStore.setSelectedDevice(device);
                return [2 /*return*/];
            });
        }); };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.deviceDataStore.setSelectedDevice(this.devices.length > 0 ? this.devices[0] : null);
                return [2 /*return*/];
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId, history = _a.history;
            history.push(getPatientRoute(siteId, patientId, trialId, enrollmentId));
        };
        _this.export = function (average) {
            var url = _this.deviceDataStore.getExportUrl(average);
            window.open(url, '_blank');
            _this.menuRef = null;
        };
        var enrollmentStore = _this.props.enrollmentStore;
        _this.deviceDataStore = new DeviceDataStore(enrollmentStore);
        return _this;
    }
    Object.defineProperty(WearableDataPage.prototype, "devices", {
        get: function () {
            var devices = this.props.enrollmentStore.enrollment.devices;
            return devices
                .sort(function (x, y) { return (x.ordering > y.ordering ? 1 : -1); })
                .filter(function (item, pos) {
                return (devices.findIndex(function (d) { return d.type === item.type; }) === pos &&
                    item.type !== DeviceTypeEnum.generic);
            });
        },
        enumerable: false,
        configurable: true
    });
    WearableDataPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, devices = _a.enrollmentStore.enrollment.devices, classes = _a.classes, patient = _a.patientStore.patient, accountStore = _a.accountStore, siteStore = _a.siteStore;
        var _b = this.deviceDataStore, deviceData = _b.deviceData, count = _b.count, filters = _b.filters, loading = _b.loading, changeFilters = _b.changeFilters, selectedDevice = _b.selectedDevice, graphData = _b.graphData, graphDomain = _b.graphDomain, selectorState = _b.selectorState, selectedDate = _b.selectedDate, setSelectorState = _b.setSelectorState, setSelectedDate = _b.setSelectedDate, graphTickValues = _b.graphTickValues, graphTickFormat = _b.graphTickFormat;
        return (React.createElement(Provider, { deviceDataStore: this.deviceDataStore },
            React.createElement(BaseModal, { onClose: this.onClose },
                React.createElement(RdiTypography, { style: { marginBottom: '25px' }, variant: "h1" }, "Wearable data"),
                React.createElement(FlexContainer, { style: { marginBottom: '25px' } },
                    React.createElement(RdiTypography, { style: h7Style, variant: "h5", bold: true }, patient.fullName),
                    React.createElement("div", { style: { width: '45px' } }),
                    React.createElement(RdiTypography, { style: h7Style, variant: "h5" },
                        "Patient ID: ",
                        patient.id)),
                selectedDevice && (React.createElement(AppBar, { position: "static", color: "default" },
                    React.createElement(Tabs, { value: selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.type, onChange: this.changeTab, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto" }, this.devices.map(function (device) { return (React.createElement(Tab, { key: device.id, value: device.type, label: device.label })); })))),
                React.createElement("div", { style: { flex: '1', overflow: 'auto' } },
                    devices.length === 0 && React.createElement(React.Fragment, null, "No devices"),
                    selectedDevice && devices.length > 0 && (React.createElement(React.Fragment, null,
                        React.createElement(FlexContainer, { alignItems: "center", justifyContent: "space-between" },
                            React.createElement(ButtonGroup, { style: { marginRight: '20px' }, color: "primary" },
                                React.createElement(Button, { onClick: function () { return setSelectorState(DateSelectorStateEnum.day); }, className: selectorState === DateSelectorStateEnum.day
                                        ? classes.activeButton
                                        : classes.button }, "Day"),
                                React.createElement(Button, { onClick: function () { return setSelectorState(DateSelectorStateEnum.week); }, className: selectorState === DateSelectorStateEnum.week
                                        ? classes.activeButton
                                        : classes.button }, "Week"),
                                React.createElement(Button, { onClick: function () { return setSelectorState(DateSelectorStateEnum.month); }, className: selectorState === DateSelectorStateEnum.month
                                        ? classes.activeButton
                                        : classes.button }, "Month"),
                                React.createElement(Button, { onClick: function () { return setSelectorState(DateSelectorStateEnum.year); }, className: selectorState === DateSelectorStateEnum.year
                                        ? classes.activeButton
                                        : classes.button }, "Year")),
                            React.createElement(RdiInput, { type: "simple-date", borderWidth: "0px", fullWidth: false, selectorState: selectorState, onChange: function (e) {
                                    setSelectedDate(e.value);
                                }, style: { minWidth: '270px', marginLeft: '20px' }, margin: "none", value: selectedDate, showLeftRightNavigation: true, config: config }),
                            React.createElement("div", { style: {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                } },
                                React.createElement(RdiButton, { name: "editAnchor", "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", inverted: true, onClick: function (event) { return _this.handleMenuClick(event); }, style: { width: '187px' } },
                                    "Export to PDF ",
                                    React.createElement(KeyboardArrowDown, { style: { marginLeft: '15px' } })))),
                        React.createElement("div", { style: {
                                flex: 1,
                                overflowY: 'auto',
                                position: 'relative',
                                marginTop: '10px',
                                paddingTop: '5px'
                            } },
                            deviceData.length > 0 && !loading && (React.createElement(React.Fragment, null,
                                React.createElement("div", { style: { marginBottom: '15px' } },
                                    React.createElement(RdiChart, { chartType: selectedDevice.chartProperties.chartType, graphData: !loading ? graphData : [], domain: graphDomain, tickValues: graphTickValues, tickFormat: graphTickFormat })),
                                React.createElement(RdiTable, { stickyHeader: true },
                                    React.createElement(TableHead, null,
                                        React.createElement(TableRow, null,
                                            this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeFilters, filters: filters })); }),
                                            !loading &&
                                                selectedDevice &&
                                                selectedDevice.schemaAll.map(function (item, deviceIndex) { return (React.createElement(TableCell, { key: deviceIndex, style: { backgroundColor: ColorsEnum.White } },
                                                    item.shortLabel,
                                                    " ",
                                                    item.unit ? "(" + item.unit + ")" : '')); }),
                                            React.createElement(TableCell, { style: { backgroundColor: ColorsEnum.White } }, "Measurement type"))),
                                    React.createElement(TableBody, null,
                                        !loading &&
                                            deviceData.map(function (data, dataIndex) {
                                                return (React.createElement(React.Fragment, { key: dataIndex },
                                                    React.createElement(TableRow, null,
                                                        React.createElement(TableCell, null, formatDatetime(data.timestamp, filters.groupBy)),
                                                        selectedDevice &&
                                                            selectedDevice.schemaAll.map(function (item, schemaIndex) {
                                                                var i = item.options
                                                                    ? item.options.find(function (x) { return x.slug === data.fieldsAll[item.slug]; })
                                                                    : null;
                                                                return (React.createElement(TableCell, { key: schemaIndex },
                                                                    React.createElement(FlexContainer, { margin: 8 },
                                                                        i &&
                                                                            (data.isManualEntry ? (React.createElement(RdiSquare, { radius: 12, color: i.color })) : (React.createElement(RdiCircle, { radius: 12, color: i.color }))),
                                                                        React.createElement(RdiTypography, { variant: "body2", style: {
                                                                                color: i ? i.color : undefined
                                                                            } }, i ? i.shortLabel : data.fieldsAll[item.slug]))));
                                                            }),
                                                        React.createElement(TableCell, null, data.isManualEntry ? 'Manual' : 'Device')),
                                                    data.expanded && (React.createElement(TableRow, null,
                                                        React.createElement(TableCell, { colSpan: 4, style: { padding: '20px 35px' } }, "something")))));
                                            }),
                                        !loading && count === 0 && (React.createElement(TableRow, null,
                                            React.createElement(TableCell, { colSpan: 10 }, "No results"))))))),
                            deviceData.length === 0 && !loading && (React.createElement("div", { style: {
                                    margin: '0 auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '80px'
                                } },
                                React.createElement("img", { alt: "no-measurements", src: NoMeasurements }),
                                React.createElement("div", { style: { marginTop: '40px' } }, "No measurements to show"))),
                            loading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                                React.createElement(CircularProgress, null))),
                            React.createElement(Menu, { className: "exportDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, onClose: this.handleMenuClose, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                                React.createElement(RdiMenuItem, { onClick: function () { return _this.export(true); }, permissions: [
                                        {
                                            aclOp: AclOpEnum.READ,
                                            aclModule: AclModuleEnum.DEVICE
                                        }
                                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, style: { width: '187px' }, disabled: selectorState === DateSelectorStateEnum.day },
                                    React.createElement(RdiTypography, { variant: "body2" }, "Average values")),
                                React.createElement(RdiMenuItem, { onClick: function () { return _this.export(false); }, permissions: [
                                        {
                                            aclOp: AclOpEnum.READ,
                                            aclModule: AclModuleEnum.DEVICE
                                        }
                                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, style: { width: '187px' } },
                                    React.createElement(RdiTypography, { variant: "body2" }, "Exact values"))))))))));
    };
    __decorate([
        observable
    ], WearableDataPage.prototype, "menuRef", void 0);
    WearableDataPage = __decorate([
        injectPatientStore,
        injectEnrollmentStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], WearableDataPage);
    return WearableDataPage;
}(Component));
export default withStyles(styles)(withRouter(WearableDataPage));
