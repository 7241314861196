var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createStyles, Grid, withStyles } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import RdiButton from "s~/components/forms/RdiButton";
import RdiInput from "s~/components/forms/RdiInput";
import CenteredContent from "s~/components/layout/content/CenteredContent";
import FixedWidthContent from "s~/components/layout/content/FixedWidthContent";
import RdiLine from "s~/components/layout/RdiLine";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import RdiErrorBox from "s~/components/misc/RdiErrorBox";
import SuccessBox from "s~/components/misc/SuccessBox";
import { AccountRoutes } from "s~/components/routing/AccountRoutes";
import RdiLink from "s~/components/routing/RdiLink";
var styles = function (theme) {
    var _a, _b, _c, _d;
    return createStyles({
        typography: (_a = {
                paddingTop: "10px",
                display: "block",
                textAlign: "right"
            },
            _a[theme.breakpoints.down("xs")] = {
                display: "none"
            },
            _a),
        input: (_b = {},
            _b[theme.breakpoints.up("sm")] = {
                "& label": {
                    display: "none"
                }
            },
            _b),
        backLink: (_c = {},
            _c[theme.breakpoints.up("sm")] = {
                display: "none"
            },
            _c.margin = "5px 0px 20px 0px",
            _c.marginLeft = "-5px",
            _c),
        mobileHide: (_d = {},
            _d[theme.breakpoints.down("xs")] = {
                display: "none"
            },
            _d)
    });
};
var ChangePasswordComponent = /** @class */ (function (_super) {
    __extends(ChangePasswordComponent, _super);
    function ChangePasswordComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errorMessage = "";
        _this.passwordChanged = false;
        _this.visibilities = {
            oldPassword: false,
            newPassword: false,
            newPasswordConfirmation: false
        };
        _this.passwordVisibilityChange = function (field) {
            _this.visibilities[field] = !_this.visibilities[field];
        };
        _this.oldPasswordChange = function (e) {
            _this.oldPassword = e.value;
        };
        _this.newPassworChange = function (e) {
            _this.newPassword = e.value;
        };
        _this.newPasswordConfirmationChange = function (e) {
            _this.newPasswordConfirmation = e.value;
        };
        _this.changePassword = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var changePassword, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        if (!this.oldPassword) {
                            this.errorMessage = "Old password not provided";
                            return [2 /*return*/];
                        }
                        if (!this.newPassword) {
                            this.errorMessage = "New password not provided";
                            return [2 /*return*/];
                        }
                        if (!this.newPasswordConfirmation) {
                            this.errorMessage = "New password confirmation not provided";
                            return [2 /*return*/];
                        }
                        if (this.newPassword !== this.newPasswordConfirmation) {
                            this.errorMessage = "Passwords do not match";
                            return [2 /*return*/];
                        }
                        this.errorMessage = "";
                        changePassword = this.props.changePassword;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, changePassword(this.oldPassword, this.newPassword)];
                    case 2:
                        _a.sent();
                        this.passwordChanged = true;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ChangePasswordComponent.prototype.render = function () {
        var _this = this;
        var _a = this.props, classes = _a.classes, history = _a.history;
        return (React.createElement(React.Fragment, null,
            !this.passwordChanged && (React.createElement(FixedWidthContent, { style: { maxWidth: "780px" }, white: true, flex: true },
                React.createElement(React.Fragment, null,
                    React.createElement(RdiLink, { onClick: function () { return history.goBack(); }, startIcon: React.createElement(KeyboardArrowLeft, null), className: classes.backLink }, "Back"),
                    React.createElement("form", { onSubmit: this.changePassword },
                        React.createElement("div", { style: { flex: "1" } },
                            React.createElement("div", null,
                                React.createElement(RdiTypography, { variant: "h1" }, "Change your password"),
                                React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Black, style: { marginBottom: "20px" } }, "Change it by providing your current password."),
                                React.createElement(RdiLine, { xs: true, className: classes.mobileHide })),
                            React.createElement(Grid, { container: true, style: { marginTop: "30px" } },
                                React.createElement(Grid, { item: true, sm: true, style: { alignItems: "center" } },
                                    React.createElement(RdiTypography, { variant: "caption", className: classes.typography }, "Your current password")),
                                React.createElement(Grid, { item: true, xs: 12, sm: 5 },
                                    React.createElement(RdiInput, { className: classes.input, value: this.oldPassword, onChange: this.oldPasswordChange, label: "Your current password", passwordVisible: this.visibilities.oldPassword, type: this.visibilities.oldPassword ? "text" : "password", passwordVisibilityChange: function () {
                                            return _this.passwordVisibilityChange("oldPassword");
                                        } })),
                                React.createElement(Grid, { item: true, sm: true })),
                            React.createElement(RdiLine, { xs: true, className: classes.mobileHide }),
                            React.createElement(Grid, { container: true, style: { marginTop: "25px" } },
                                React.createElement(Grid, { item: true, sm: true },
                                    React.createElement(RdiTypography, { variant: "caption", className: classes.typography }, "Your new password")),
                                React.createElement(Grid, { item: true, xs: 12, sm: 5 },
                                    React.createElement(RdiInput, { className: classes.input, value: this.newPassword, onChange: this.newPassworChange, label: "Your new password", passwordVisible: this.visibilities.newPassword, type: this.visibilities.newPassword ? "text" : "password", passwordVisibilityChange: function () {
                                            return _this.passwordVisibilityChange("newPassword");
                                        } })),
                                React.createElement(Grid, { item: true, sm: true })),
                            React.createElement(Grid, { container: true },
                                React.createElement(Grid, { item: true, sm: true },
                                    React.createElement(RdiTypography, { variant: "caption", className: classes.typography }, "Your new password again")),
                                React.createElement(Grid, { item: true, xs: 12, sm: 5 },
                                    React.createElement(RdiInput, { className: classes.input, value: this.newPasswordConfirmation, onChange: this.newPasswordConfirmationChange, label: "Your new password again", passwordVisible: this.visibilities.newPasswordConfirmation, type: this.visibilities.newPasswordConfirmation
                                            ? "text"
                                            : "password", passwordVisibilityChange: function () {
                                            return _this.passwordVisibilityChange("newPasswordConfirmation");
                                        }, assistiveText: "Your password must contain at least 8 characters" })),
                                React.createElement(Grid, { item: true, sm: true })),
                            React.createElement(RdiLine, { className: classes.mobileHide }),
                            React.createElement(RdiErrorBox, { style: { width: "100%" } }, this.errorMessage)),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, sm: true }),
                            React.createElement(Grid, { item: true, xs: 12, sm: 5, style: { justifyContent: "flex-start" } },
                                React.createElement(RdiButton, { fullWidth: true, submit: true }, "Set a new password")),
                            React.createElement(Grid, { item: true, sm: true })))))),
            this.passwordChanged && (React.createElement(CenteredContent, null,
                React.createElement(SuccessBox, { text: "You have successfully changed your password.", to: AccountRoutes.ACCOUNT_ROUTE })))));
    };
    __decorate([
        observable
    ], ChangePasswordComponent.prototype, "oldPassword", void 0);
    __decorate([
        observable
    ], ChangePasswordComponent.prototype, "newPassword", void 0);
    __decorate([
        observable
    ], ChangePasswordComponent.prototype, "newPasswordConfirmation", void 0);
    __decorate([
        observable
    ], ChangePasswordComponent.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], ChangePasswordComponent.prototype, "passwordChanged", void 0);
    __decorate([
        observable
    ], ChangePasswordComponent.prototype, "visibilities", void 0);
    ChangePasswordComponent = __decorate([
        observer
    ], ChangePasswordComponent);
    return ChangePasswordComponent;
}(Component));
export default withStyles(styles)(withRouter(ChangePasswordComponent));
