var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Payment } from 's~/types/models/Payment';
import { Transaction } from 's~/types/models/Transaction';
var Claim = /** @class */ (function (_super) {
    __extends(Claim, _super);
    function Claim(e) {
        var _this = _super.call(this, e) || this;
        _this.paymentType = e.paymentType;
        _this.externalStatus = e.externalStatus;
        _this.externalErrorMessage = e.externalErrorMessage;
        _this.comment = e.comment;
        _this.internalCode = e.internalCode;
        _this.payments = e.payments ? e.payments.map(function (x) { return new Payment(x); }) : [];
        return _this;
    }
    return Claim;
}(Transaction));
export { Claim };
