var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { User } from '@cros/shared/types/models/User';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { Breadcrumbs, createStyles, withStyles } from '@material-ui/core';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getUsersRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectUserStore } from '~/stores/UserStore';
var styles = function (theme) {
    var _a, _b, _c, _d, _e;
    return createStyles({
        input: (_a = {},
            _a[theme.breakpoints.up('sm')] = {
                '& label': {
                    display: 'none'
                }
            },
            _a),
        mobileHide: (_b = {},
            _b[theme.breakpoints.down('xs')] = {
                display: 'none!important'
            },
            _b),
        threeTwoRow: {
            '& >div': {
                marginBottom: '15px',
                '& >div': {
                    flex: '0 0 33%',
                    display: 'flex',
                    boxSizing: 'border-box',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    marginBottom: '15px',
                    '&:nth-of-type(2)': (_c = {
                            minWidth: '300px'
                        },
                        _c[theme.breakpoints.down('xs')] = {
                            flex: '1',
                            minWidth: '100%'
                        },
                        _c),
                    '&:nth-of-type(1)': (_d = {
                            paddingTop: '12px'
                        },
                        _d[theme.breakpoints.down('xs')] = {
                            display: 'none'
                        },
                        _d),
                    '&:nth-of-type(3)': (_e = {},
                        _e[theme.breakpoints.down('xs')] = {
                            display: 'none'
                        },
                        _e)
                }
            }
        }
    });
};
var AddUser = /** @class */ (function (_super) {
    __extends(AddUser, _super);
    function AddUser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasErrors = false;
        _this.errorMessage = '';
        _this.saving = false;
        _this.data = {
            firstName: {
                value: null,
                error: null,
                rule: 'string'
            },
            lastName: {
                value: null,
                error: null,
                rule: 'string'
            },
            email: {
                value: null,
                error: null,
                rule: 'string'
            },
            roles: {
                value: [],
                error: null,
                rule: 'string'
            },
            trials: {
                value: [],
                error: null
            }
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var userStore, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userStore = this.props.userStore;
                        if (!this.userId) return [3 /*break*/, 2];
                        return [4 /*yield*/, userStore.getUserById(this.userId)];
                    case 1:
                        user = _a.sent();
                        if (user) {
                            this.assignData(user);
                        }
                        _a.label = 2;
                    case 2: return [4 /*yield*/, userStore.listRoles()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignData = function (user) {
            var siteStore = _this.props.siteStore;
            _this.data.firstName.value = user.firstName;
            _this.data.lastName.value = user.lastName;
            _this.data.email.value = user.email;
            _this.data.roles.value = user.roles.map(function (r) { return ({
                label: r,
                value: r
            }); });
            _this.data.trials.value = (user.trials || []).map(function (r) {
                var _a;
                return ({
                    label: (_a = siteStore.trials.find(function (t) { return t.id === r; })) === null || _a === void 0 ? void 0 : _a.name,
                    value: r
                });
            });
        };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, match = _a.match;
            var split = _this.userId ? '/edit' : '/new';
            history.push("" + match.url.split(split)[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.submit = function (e) { return __awaiter(_this, void 0, Promise, function () {
            var newUser, user, userStore, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        e.stopPropagation();
                        newUser = null;
                        this.errorMessage = '';
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        user = this.getObjectToSave();
                        this.saving = true;
                        userStore = this.props.userStore;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!this.userId) return [3 /*break*/, 3];
                        return [4 /*yield*/, userStore.updateUser(user)];
                    case 2:
                        newUser = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, userStore.addUser(user)];
                    case 4:
                        newUser = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 8];
                    case 7:
                        this.saving = false;
                        if (!newUser) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors);
        };
        _this.getObjectToSave = function () {
            return new User({
                id: _this.userId,
                firstName: _this.data.firstName.value,
                lastName: _this.data.lastName.value,
                email: _this.data.email.value,
                roles: _this.data.roles.value.map(function (r) { return r.value; }),
                trials: _this.data.trials.value.map(function (r) { return r.value; })
            });
        };
        return _this;
    }
    Object.defineProperty(AddUser.prototype, "userId", {
        get: function () {
            var params = this.props.match.params;
            return params.userId;
        },
        enumerable: false,
        configurable: true
    });
    AddUser.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props, classes = _c.classes, siteStore = _c.siteStore, roles = _c.userStore.roles;
        var isEditing = Boolean(this.userId);
        return (React.createElement("div", { style: { width: '100%' } },
            React.createElement(Breadcrumbs, { separator: "/", "aria-label": "breadcrumb" },
                React.createElement(RdiLink, { xs: true, to: getUsersRoute(siteStore.siteId) }, "User management"),
                React.createElement("span", { style: { color: 'black' } },
                    isEditing ? 'Edit' : 'Add',
                    " user")),
            React.createElement(FixedWidthContent, { white: true, xs: true },
                React.createElement(RdiTypography, { variant: "h1" },
                    isEditing ? 'Edit' : 'Add',
                    " user"),
                React.createElement(RdiLine, null),
                React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submit },
                    React.createElement("div", { className: classes.threeTwoRow },
                        React.createElement(FlexContainer, { alignItems: "flex-start" },
                            React.createElement("div", null,
                                React.createElement(RdiTypography, { variant: "caption" }, "First name")),
                            React.createElement("div", null,
                                React.createElement(RdiInput, { type: "text", name: "firstName", errorText: this.data.firstName.error, value: this.data.firstName.value, onChange: function (e) { return _this.fieldChange(e, 'firstName'); }, autoFocus: true, style: { marginBottom: '0px' }, className: classes.input, label: "First name" })),
                            React.createElement("div", null)),
                        React.createElement(FlexContainer, { alignItems: "flex-start" },
                            React.createElement("div", null,
                                React.createElement(RdiTypography, { variant: "caption" }, "Last name")),
                            React.createElement("div", null,
                                React.createElement(RdiInput, { type: "text", name: "lastName", errorText: this.data.lastName.error, value: this.data.lastName.value, onChange: function (e) { return _this.fieldChange(e, 'lastName'); }, style: { marginBottom: '0px' }, className: classes.input, label: "Last name" })),
                            React.createElement("div", null)),
                        React.createElement(FlexContainer, { alignItems: "flex-start" },
                            React.createElement("div", null,
                                React.createElement(RdiTypography, { variant: "caption" }, "Email")),
                            React.createElement("div", null,
                                React.createElement(RdiInput, { type: "email", name: "email", errorText: this.data.email.error, value: this.data.email.value, onChange: function (e) { return _this.fieldChange(e, 'email'); }, style: { marginBottom: '0px' }, className: classes.input, disabled: isEditing, label: "Email" })),
                            React.createElement("div", null)),
                        React.createElement(FlexContainer, { alignItems: "flex-start" },
                            React.createElement("div", null,
                                React.createElement(RdiTypography, { variant: "caption" }, "Groups")),
                            React.createElement("div", null,
                                React.createElement(RdiInput, { type: "select-multiple", name: "roles", errorText: this.data.roles.error, value: this.data.roles.value, onChange: function (e) { return _this.fieldChange(e, 'roles'); }, autoFocus: true, style: { marginBottom: '0px' }, options: roles.map(function (r) { return ({
                                        label: r.name,
                                        value: r.name
                                    }); }), fullWidth: true, label: "Groups", placeholder: "Select group", className: classes.input })),
                            React.createElement("div", null)),
                        ((_b = (_a = this.data.roles) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.length) === 1 &&
                            this.data.roles.value[0].value === 'auditor' && (React.createElement(FlexContainer, { alignItems: "flex-start" },
                            React.createElement("div", null,
                                React.createElement(RdiTypography, { variant: "caption" }, "Trials")),
                            React.createElement("div", null,
                                React.createElement(RdiInput, { className: classes.input, errorText: this.data.trials.error, fullWidth: true, type: "select-multiple", placeholder: "Select to add trial...", value: this.data.trials.value, onChange: function (e) { return _this.fieldChange(e, 'trials'); }, options: siteStore.trials.map(function (x) { return ({
                                        value: x.id,
                                        label: x.name
                                    }); }), name: "trials" })),
                            React.createElement("div", null)))),
                    React.createElement(RdiLine, null),
                    React.createElement(RdiErrorBox, null, this.errorMessage),
                    React.createElement("div", null,
                        React.createElement("div", null),
                        React.createElement("div", null,
                            React.createElement(RdiButton, { loading: this.saving, submit: true }, isEditing ? 'Save' : 'Add user')),
                        React.createElement("div", null))))));
    };
    __decorate([
        observable
    ], AddUser.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], AddUser.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], AddUser.prototype, "saving", void 0);
    __decorate([
        observable
    ], AddUser.prototype, "data", void 0);
    __decorate([
        computed
    ], AddUser.prototype, "userId", null);
    AddUser = __decorate([
        injectSiteStore,
        injectUserStore,
        injectAccountStore,
        observer
    ], AddUser);
    return AddUser;
}(Component));
export default withStyles(styles)(withRouter(AddUser));
