var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStyles, IconButton, InputAdornment, TextField, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { RdiInputWrapper } from 's~/components/forms/RdiInput';
import ColorsEnum from 's~/constants/styling/ColorsEnum';
import RdiInputLabel from './RdiInputLabel';
var styles = function () {
    return createStyles({
        notchedOutline: {},
        disabled: {
            '& $notchedOutline': {
                borderColor: ColorsEnum.BlackLight + "!important",
            },
        },
    });
};
var RdiTextInput = function (props) {
    var label = props.label, isValid = props.isValid, tooltip = props.tooltip, type = props.type, assistiveText = props.assistiveText, errorText = props.errorText, placeholder = props.placeholder, disabled = props.disabled, passwordVisible = props.passwordVisible, defaultValue = props.defaultValue, autoComplete = props.autoComplete, unit = props.unit, passwordVisibilityChange = props.passwordVisibilityChange, autoFocus = props.autoFocus, fullWidth = props.fullWidth, onChange = props.onChange, children = props.children, value = props.value, name = props.name, classes = props.classes, inputProps = props.inputProps;
    var invalidChars = ['e', '+', 'E'];
    var multiLineText = type === 'text_multiline';
    return (React.createElement(RdiInputWrapper, { className: props.className, fullWidth: props.fullWidth, style: props.style },
        label && React.createElement(RdiInputLabel, { tooltip: tooltip }, label),
        React.createElement("span", { style: {
                display: 'flex',
                flexDirection: 'row',
                width: fullWidth ? '100%' : undefined,
            } },
            React.createElement(TextField, { name: name, fullWidth: fullWidth, variant: "outlined", type: type, select: false, value: value !== null ? value : '', onChange: function (ev) {
                    return onChange({
                        value: type === 'number' ? parseFloat(ev.target.value) : ev.target.value,
                    });
                }, helperText: !errorText ? assistiveText : errorText, hiddenLabel: false, placeholder: placeholder, InputProps: {
                    classes: {
                        notchedOutline: classes.notchedOutline,
                        disabled: classes.disabled,
                    },
                    endAdornment: (type === 'search' && (React.createElement(InputAdornment, { position: "start" },
                        React.createElement(SearchIcon, { style: { cursor: 'pointer' } })))) ||
                        (passwordVisibilityChange && (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: passwordVisibilityChange }, passwordVisible ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null))))),
                    inputProps: __assign({}, inputProps),
                }, autoComplete: autoComplete, error: !!errorText || !isValid, disabled: !!(disabled && disabled === true), onKeyPress: function (event) {
                    if (type === 'number' && invalidChars.includes(event.key)) {
                        event.preventDefault();
                    }
                }, defaultValue: defaultValue, multiline: !!multiLineText, rows: multiLineText ? 3 : undefined, autoFocus: autoFocus, style: { width: unit ? '50%' : '100%' } }, children),
            unit && (React.createElement("div", { style: {
                    width: '50%',
                    paddingLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    height: '44px',
                } }, unit)))));
};
export default withStyles(styles)(RdiTextInput);
