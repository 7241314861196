var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var fieldChange = function (e, field, data) {
    var f = field.split('.');
    if (f.length === 1) {
        data[field] = __assign(__assign({}, data[field]), e);
        if (typeof e.value === 'string') {
            data[field].value = e.value.replace(/ +(?= )/g, ''); // Remove multiple spaces if string
        }
    }
    else {
        // e.g. trials.0.invite
        var item = data[f[0]].value[parseInt(f[1], 10)][f[2]];
        data[f[0]].value[parseInt(f[1], 10)][f[2]] = __assign(__assign({}, item), e);
    }
};
export var newFieldChange = function (e, field, data, labels, setData, setLabels) {
    var f = field.split('.');
    if (f.length === 1) {
        data[field] = typeof e.value === 'string' ? e.value.replace(/ +(?= )/g, '') : e.value; // Remove multiple spaces if string
        labels[field] = e.label;
    }
    else {
        // e.g. trials.0.invite
        data[f[0]][parseInt(f[1], 10)].draft = true;
        data[f[0]][parseInt(f[1], 10)][f[2]] = e.value;
        labels[f[0] + "." + data[f[0]][parseInt(f[1], 10)].value + "." + f[2]] = e.label;
    }
    if (setData && setLabels) {
        setData(function () { return (__assign({}, data)); });
        setLabels(function () { return (__assign({}, labels)); });
    }
};
