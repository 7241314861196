var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStyles, withStyles } from "@material-ui/core";
import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import { RdiInputWrapper } from "s~/components/forms/RdiInput";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import RdiInputLabel from "./RdiInputLabel";
var styles = function () { return createStyles({}); };
var RdiMentionsInput = function (props) {
    var _a, _b;
    var label = props.label, isValid = props.isValid, tooltip = props.tooltip, value = props.value, onChange = props.onChange, disabled = props.disabled, style = props.style, borderWidth = props.borderWidth, errorText = props.errorText, options = props.options, onMentionAdd = props.onMentionAdd, name = props.name, placeholder = props.placeholder, assistiveText = props.assistiveText;
    return (React.createElement(RdiInputWrapper, __assign({}, props),
        React.createElement(RdiInputLabel, { tooltip: tooltip }, label),
        React.createElement(MentionsInput, { placeholder: placeholder, value: value, name: name, onChange: function (e, newValue, newPlainTextValue, mentions) {
                onChange({ value: newValue, mentions: mentions });
            }, disabled: disabled, style: __assign(__assign({}, style), { input: {
                    borderWidth: borderWidth,
                    borderColor: errorText ? ColorsEnum.Error : undefined,
                    borderRadius: "3px",
                    "&::placeholder": {
                        color: ColorsEnum.BlackSuperlight + "!important",
                        backgroundColor: "green!important"
                    },
                    padding: ((_a = props.style) === null || _a === void 0 ? void 0 : _a.padding) ? (_b = props.style) === null || _b === void 0 ? void 0 : _b.padding : "8px 15px"
                }, suggestions: {
                    list: {
                        backgroundColor: "white",
                        border: "1px solid rgba(0,0,0,0.15)",
                        fontSize: 14,
                        padding: "5px 0px"
                    },
                    item: {
                        padding: "5px 15px",
                        "&focused": {
                            backgroundColor: ColorsEnum.BlackLight
                        }
                    }
                } }) },
            React.createElement(Mention, { markup: "@[__display__](id:__id__)", trigger: "@", data: options || [], renderSuggestion: function (suggestion, search, highlightedDisplay, index, focused) { return (React.createElement("div", { className: "" + (focused ? "focused" : "") },
                    "@",
                    highlightedDisplay)); }, onAdd: onMentionAdd, displayTransform: function (id, display) {
                    return "@" + display;
                }, appendSpaceOnAdd: true })),
        React.createElement(RdiInputLabel, { style: { color: ColorsEnum.BlackMedium } }, assistiveText),
        !isValid && errorText && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.Error, style: { marginTop: "3px" } }, errorText))));
};
export default withStyles(styles)(RdiMentionsInput);
