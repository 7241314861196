var OrderQueryParams = /** @class */ (function () {
    function OrderQueryParams() {
    }
    return OrderQueryParams;
}());
export { OrderQueryParams };
export var invertOrderDir = function (orderDir) {
    if (orderDir === "ASC") {
        return "DESC";
    }
    return "ASC";
};
