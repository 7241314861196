var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Table } from "@material-ui/core";
import React from "react";
var RdiTable = function (props) {
    return (React.createElement("div", { className: "rdiTable", style: __assign({ overflowX: "auto", overflowY: "visible", marginBottom: "10px" }, props.wrapperStyle) },
        React.createElement(Table, __assign({}, props), props.children)));
};
export default RdiTable;
