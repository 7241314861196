var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiCircle from '@cros/shared/components/misc/RdiCircle';
import RdiListItem from '@cros/shared/components/misc/RdiListItem';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { TaskPriorityColorsEnum, TaskPriorityEnum, TaskPriorityLabelsEnum } from '@cros/shared/constants/enums/TaskPriorityEnum';
import { TaskStatusEnum, TaskStatusLabelsEnum } from '@cros/shared/constants/enums/TaskStatusEnum';
import { TaskTypeEnum } from '@cros/shared/constants/enums/TaskTypeEnum';
import { formatDatetimeLong } from '@cros/shared/utils/dateUtils';
import { createStyles, IconButton, List, ListItemText, Menu, withStyles } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { getPatientRoute, getTasksDetailCodesRoute, getTasksDetailNotesRoute, getTrialRoute } from '~/routing/Routes';
import CalendarIconSmall from '~/static/CalendarIconSmall.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTaskStore } from '~/stores/TaskStore';
var styles = function () {
    return createStyles({
        container: {
            padding: '19px 25px 0 25px'
        }
    });
};
var TaskModalNavigation = /** @class */ (function (_super) {
    __extends(TaskModalNavigation, _super);
    function TaskModalNavigation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.menuRef = null;
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split("/detail")[0]);
        };
        _this.handleMenuClick = function (event) {
            _this.menuRef = event.currentTarget;
        };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url, task = _a.task;
            history.push(url.split('/detail')[0] + "/edit/" + task.id);
            _this.menuRef = null;
        };
        _this.onExportPdf = function () {
            var _a = _this.props, task = _a.task, getExportTaskUrl = _a.taskStore.getExportTaskUrl;
            window.open(getExportTaskUrl(task.id), '_blank');
            _this.menuRef = null;
        };
        _this.deleteTask = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, task, taskStore;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, task = _a.task, taskStore = _a.taskStore;
                        this.menuRef = null;
                        return [4 /*yield*/, taskStore.deleteTaskWithConfirmation(task, this.onClose)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    TaskModalNavigation.prototype.render = function () {
        var _this = this;
        var _a = this.props, task = _a.task, siteId = _a.siteStore.siteId, _b = _a.taskStore, changeAssignee = _b.changeAssignee, changeStatus = _b.changeStatus, changePriority = _b.changePriority, availableFilters = _b.availableFilters, params = _a.match.params, classes = _a.classes, accountStore = _a.accountStore, siteStore = _a.siteStore;
        var _c = task || {}, _d = _c.deviceDatapoint, deviceDatapoint = _d === void 0 ? null : _d, _e = _c.assignee, assignee = _e === void 0 ? null : _e, _f = _c.resolvedBy, resolvedBy = _f === void 0 ? null : _f, _g = _c.enrollment, enrollment = _g === void 0 ? null : _g, _h = _c.patient, patient = _h === void 0 ? null : _h, _j = _c.trial, trial = _j === void 0 ? null : _j;
        return (React.createElement(React.Fragment, null, task && (React.createElement("div", { style: {
                background: '#FAFAFA',
                width: '287px',
                height: '100%',
                overflow: 'auto'
            } },
            React.createElement("div", { className: classes.container },
                React.createElement(FlexContainer, { justifyContent: "space-between", alignItems: "center", style: { marginBottom: '10px' } },
                    React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.TrueBlack, style: { marginBottom: '0px' } }, task.generatedTitle),
                    task.type === TaskTypeEnum.TASK && (React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset', marginRight: '-8px' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: this.handleMenuClick },
                        React.createElement(MoreVert, null)))),
                React.createElement("div", { style: { marginBottom: '5px' } }, enrollment && patient && (React.createElement(RdiLink, { style: { justifyContent: 'flex-start' }, to: getPatientRoute(siteId, enrollment.patientId, enrollment.trialId, enrollment.id) }, patient.fullName))),
                React.createElement("div", { style: { marginBottom: '5px' } },
                    React.createElement(RdiTypography, { variant: "body2" },
                        "Patient ID: ", enrollment === null || enrollment === void 0 ? void 0 :
                        enrollment.code)),
                React.createElement("div", { style: { marginBottom: '15px' } },
                    React.createElement(RdiTypography, { variant: "body2" },
                        "Trial: ",
                        React.createElement(RdiLink, { to: getTrialRoute(siteId, trial.id) }, trial === null || trial === void 0 ? void 0 : trial.name))),
                React.createElement(FlexContainer, { style: { marginBottom: '15px' } },
                    React.createElement("img", { alt: "img", src: CalendarIconSmall }),
                    React.createElement(RdiTypography, { style: { paddingLeft: '12px' }, variant: "body2", bold: true }, formatDatetimeLong(task.createdAt)))),
            React.createElement(List, null,
                React.createElement(RdiListItem, { button: true, component: NavLink, to: "" + getTasksDetailNotesRoute(siteId, params.tabId, task.id) },
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, "Notes")),
                React.createElement(RdiListItem, { button: true, component: NavLink, to: "" + getTasksDetailCodesRoute(siteId, params.tabId, task.id) },
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, "Codes"))),
            React.createElement("div", { className: classes.container },
                React.createElement("div", null,
                    React.createElement(RdiInput, { value: task.priority, type: "select", fullWidth: true, label: "Priority", selectedItemLabel: React.createElement(React.Fragment, null,
                            React.createElement(FlexContainer, { margin: 10 },
                                React.createElement(RdiCircle, { radius: 15, color: TaskPriorityColorsEnum[task.priority] }),
                                React.createElement("span", { style: {
                                        color: TaskPriorityColorsEnum[task.priority]
                                    } }, TaskPriorityLabelsEnum[task.priority]))), onChange: function (change) { return changePriority(task, change.value); }, options: Object.values(TaskPriorityEnum).map(function (enumType) { return ({
                            value: enumType,
                            label: TaskPriorityLabelsEnum[enumType]
                        }); }) })),
                React.createElement("div", null,
                    React.createElement(RdiInput, { type: "select", placeholder: "Select assignee", label: "Assignee", selectedItemLabel: assignee ? "" + assignee.fullName : undefined, value: assignee ? assignee.id : null, onChange: function (change) { return changeAssignee(task, change.value); }, options: availableFilters.assigneeId.map(function (a) { return ({
                            label: a.label,
                            value: a.slug
                        }); }) })),
                React.createElement("div", null,
                    React.createElement(RdiInput, { style: { marginBottom: '0px' }, type: "select", placeholder: "Select status", label: "Status", selectedItemLabel: TaskStatusLabelsEnum[task.status], value: task.status, onChange: function (change) { return changeStatus(task, change.value); }, options: Object.values(TaskStatusEnum).map(function (enumType) { return ({
                            value: enumType,
                            label: TaskStatusLabelsEnum[enumType]
                        }); }) })),
                task.resolvedAt && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { marginTop: '15px' } },
                        React.createElement(RdiInputLabel, null, "Closed on"),
                        formatDatetimeLong(task.resolvedAt)),
                    React.createElement("div", { style: { marginTop: '15px' } },
                        React.createElement(RdiInputLabel, null, "Closed by"),
                        resolvedBy ? "" + resolvedBy.fullName : ''))),
                deviceDatapoint && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { marginTop: '15px' } },
                        React.createElement(RdiInputLabel, null, "Measurement"),
                        React.createElement("div", null, formatDatetimeLong(deviceDatapoint.timestamp)),
                        deviceDatapoint.device.schema.items.map(function (item, index) { return (React.createElement("div", { key: index },
                            deviceDatapoint.data[item.slug],
                            " ",
                            item.unit,
                            " ",
                            item.shortLabel)); })))),
                deviceDatapoint && deviceDatapoint.questionnaire && (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { marginTop: '15px' } },
                        React.createElement(RdiInputLabel, null, "Symptoms"),
                        Object.keys(deviceDatapoint.questionnaire.data).map(function (key, index) { return (React.createElement("div", { key: index }, deviceDatapoint.questionnaire.data[key] &&
                            deviceDatapoint.questionnaire.schema.items.find(function (s) { return s.slug === key; }) &&
                            "" + deviceDatapoint.questionnaire.schema.items.find(function (s) { return s.slug === key; })
                                .label)); }))))),
            React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, onClose: function () {
                    _this.menuRef = null;
                }, getContentAnchorEl: null, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { horizontal: 'right', vertical: 'top' } },
                React.createElement(RdiMenuItem, { onClick: this.onExportPdf, permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.TASK
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Export to PDF")),
                React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                        {
                            aclOp: AclOpEnum.UPDATE,
                            aclModule: AclModuleEnum.TASK
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Edit detail and patient")),
                React.createElement(RdiMenuItem, { onClick: this.deleteTask, permissions: [
                        {
                            aclOp: AclOpEnum.DELETE,
                            aclModule: AclModuleEnum.TASK
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Delete task")))))));
    };
    __decorate([
        observable
    ], TaskModalNavigation.prototype, "menuRef", void 0);
    TaskModalNavigation = __decorate([
        injectSiteStore,
        injectTaskStore,
        injectAccountStore,
        observer
    ], TaskModalNavigation);
    return TaskModalNavigation;
}(Component));
export default withStyles(styles)(withRouter(TaskModalNavigation));
