var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStyles, Link as MaterialLink, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var styles = function () {
    return createStyles({
        link: {
            "&:hover": {
                color: ColorsEnum.BlueDark + "!important"
            },
            cursor: "pointer",
            fontStyle: "normal",
            lineHeight: "20px",
            fontSize: "13px",
            mixBlendMode: "normal",
            color: ColorsEnum.Primary,
            textDecoration: "none",
            textTransform: function (props) {
                return props.uppercase ? "uppercase" : undefined;
            },
            fontWeight: 500,
            display: "flex",
            alignItems: "center"
        }
    });
};
var RdiLink = function (props) {
    var to = props.to, target = props.target, style = props.style, classes = props.classes, children = props.children, onClick = props.onClick, href = props.href, id = props.id, disabled = props.disabled, aclHide = props.aclHide, xs = props.xs, permissions = props.permissions, aclCheck = props.aclCheck, startIcon = props.startIcon;
    var _a = useState(disabled), internalDisabled = _a[0], setInternalDisabled = _a[1];
    useEffect(function () {
        if ((aclCheck && !aclCheck(permissions)) || disabled) {
            setInternalDisabled(true);
        }
        else {
            setInternalDisabled(false);
        }
    }, [disabled, permissions]);
    if (!props.children) {
        return null;
    }
    if (aclHide && aclCheck && !aclCheck(permissions)) {
        return null;
    }
    var internalStyle = __assign({ pointerEvents: internalDisabled ? "none" : undefined, cursor: internalDisabled ? "default" : undefined, fontSize: xs ? "12px" : undefined }, (internalDisabled ? { color: "lightgray" } : {}));
    return (React.createElement(React.Fragment, null, props.to ? (React.createElement(RouterLink, { style: __assign(__assign({}, style), internalStyle), to: to || "#", target: target, className: classes.link + " " + props.className, id: id, onClick: onClick },
        startIcon,
        children)) : (React.createElement(MaterialLink, { style: __assign(__assign({}, style), internalStyle), className: classes.link + " " + props.className, onClick: onClick, href: href, target: target, id: id },
        startIcon,
        children))));
};
RdiLink.defaultProps = {};
export default withStyles(styles)(RdiLink);
