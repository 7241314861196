var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStyles, Paper, withStyles } from "@material-ui/core";
import React from "react";
var styles = function (theme) {
    var _a;
    return createStyles({
        root: (_a = {
                padding: "35px 85px",
                background: "rgba(250,250,250,0.12)",
                border: "1px solid",
                borderColor: "rgba(0,0,0,0.12)",
                margin: "0px auto",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.024), 0px 2px 2px rgba(0, 0, 0, 0.0144), 0px 0px 2px rgba(0, 0, 0, 0.0168)",
                borderRadius: "2px",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                maxHeight: "90vh",
                maxWidth: "100%",
                "& >form, >div": {
                    display: "flex",
                    flexDirection: "column"
                }
            },
            _a[theme.breakpoints.down("xs")] = {
                border: "0",
                boxShadow: "none",
                padding: "0px 20px",
                width: "100%",
                minHeight: "100%",
                height: "100%",
                maxWidth: "100%",
                maxHeight: "100vh"
                // "& >form, >div": {
                //   justifyContent: "space-between",
                // }
            },
            _a)
    });
};
var ModalContent = function (props) {
    var classes = props.classes, className = props.className;
    return (React.createElement(Paper, { style: __assign({}, props.style), className: classes.root + " " + className }, props.children));
};
export default withStyles(styles)(ModalContent);
