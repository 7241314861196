import pickBy from "lodash/pickBy";
import queryString from "query-string";
export var assignFilterLabels = function (filters, filterLabels, availableFilters) {
    var _a;
    var _loop_1 = function (key) {
        var label = (_a = availableFilters[key].find(function (p) { return p.slug === filters[key]; })) === null || _a === void 0 ? void 0 : _a.label;
        if (label) {
            filterLabels[key] = label;
        }
    };
    for (var _i = 0, _b = Object.keys(filterLabels); _i < _b.length; _i++) {
        var key = _b[_i];
        _loop_1(key);
    }
};
export var assignFiltersFromQueryString = function (qs, filters) {
    var p = queryString.parse(qs);
    Object.assign(filters, p);
};
export var getURLSearchObject = function (filters) {
    return {
        search: "?" + new URLSearchParams(pickBy(filters, function (v) { return v !== undefined && v !== null; })).toString()
    };
};
