export var DeviceTypeEnum;
(function (DeviceTypeEnum) {
    DeviceTypeEnum["BP3L"] = "BP3L";
    // BG5S = "BG5S",
    DeviceTypeEnum["TEMPERATURE"] = "TEMPERATURE";
    DeviceTypeEnum["HEART_RATE"] = "HEART_RATE";
    DeviceTypeEnum["BODY_WEIGHT"] = "BODY_WEIGHT";
    DeviceTypeEnum["CARDIO_FITNESS"] = "CARDIO_FITNESS";
    DeviceTypeEnum["generic"] = "generic";
})(DeviceTypeEnum || (DeviceTypeEnum = {}));
export var DeviceTypeLabelsEnum;
(function (DeviceTypeLabelsEnum) {
    DeviceTypeLabelsEnum["BP3L"] = "Blood pressure";
    // BG5S = "Blood glucose",
    DeviceTypeLabelsEnum["TEMPERATURE"] = "Temperature";
    DeviceTypeLabelsEnum["HEART_RATE"] = "Heart rate";
    DeviceTypeLabelsEnum["BODY_WEIGHT"] = "Body weight";
    DeviceTypeLabelsEnum["CARDIO_FITNESS"] = "Cardio fitness";
    DeviceTypeLabelsEnum["generic"] = "Other";
})(DeviceTypeLabelsEnum || (DeviceTypeLabelsEnum = {}));
