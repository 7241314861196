var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from "mobx";
import { AclModuleEnum } from "s~/constants/enums/AclModuleEnum";
import { AclOpEnum } from "s~/constants/enums/AclOpEnum";
import { CompanyRoleEnum } from "s~/constants/enums/CompanyRoleEnum";
import { Model } from "s~/types/models/Model";
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User(u) {
        var _this = _super.call(this, u) || this;
        _this.aclCheck = function (permissions, namespaceId, namespace, objectId) {
            if (namespace === void 0) { namespace = "Company"; }
            if (!permissions) {
                return true;
            }
            for (var _i = 0, permissions_1 = permissions; _i < permissions_1.length; _i++) {
                var permission = permissions_1[_i];
                var aclOp = permission.aclOp, aclModule = permission.aclModule;
                if (_this.acl.indexOf(namespace + "-" + namespaceId + "__" + AclModuleEnum.ALL + "__" + AclOpEnum.ALL) >= 0) {
                    return true;
                }
                if (_this.acl.indexOf(namespace + "-" + namespaceId + "__" + AclModuleEnum.ALL + "__" + aclOp) >= 0) {
                    return true;
                }
                if (_this.acl.indexOf(namespace + "-" + namespaceId + "__" + aclModule + "__" + AclOpEnum.ALL) >= 0) {
                    return true;
                }
                if (_this.acl.indexOf(namespace + "-" + namespaceId + "__" + aclModule + "__" + aclOp) >= 0) {
                    return true;
                }
                if (objectId) {
                    if (_this.acl.indexOf(namespace + "-" + namespaceId + "__" + aclModule + "-" + objectId + "__" + AclOpEnum.ALL) >= 0) {
                        return true;
                    }
                    if (_this.acl.indexOf(namespace + "-" + namespaceId + "__" + module + "-" + objectId + "__" + aclOp) >= 0) {
                        return true;
                    }
                }
            }
            return false;
        };
        _this.id = u.id;
        _this.username = u.username;
        _this.email = u.email;
        _this.firstName = u.firstName;
        _this.lastName = u.lastName;
        _this.company = u.company;
        _this.roles = u.roles;
        _this.trials = u.trials;
        _this.acl = u.acl;
        _this.phoneNumber = u.phoneNumber;
        _this.phoneNumberPrefix = u.phoneNumberPrefix;
        _this.phoneNumberForSMS = !!u.phoneNumberForSMS;
        _this.confirmationPhoneNumber = u.confirmationPhoneNumber;
        _this.confirmationPhoneNumberPrefix = u.confirmationPhoneNumberPrefix;
        _this.disabled = u.disabled;
        return _this;
    }
    Object.defineProperty(User.prototype, "phoneVerified", {
        get: function () {
            if (this.phoneNumber && this.phoneNumberPrefix) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(User.prototype, "fullName", {
        get: function () {
            return this.firstName + " " + this.lastName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(User.prototype, "isProvider", {
        get: function () {
            return this.roles.indexOf(CompanyRoleEnum.PROVIDER) >= 0;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], User.prototype, "roles", void 0);
    __decorate([
        observable
    ], User.prototype, "trials", void 0);
    __decorate([
        observable
    ], User.prototype, "acl", void 0);
    __decorate([
        computed
    ], User.prototype, "phoneVerified", null);
    __decorate([
        computed
    ], User.prototype, "fullName", null);
    __decorate([
        computed
    ], User.prototype, "isProvider", null);
    return User;
}(Model));
export { User };
