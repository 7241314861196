var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from "mobx";
var SiteCountsDto = /** @class */ (function () {
    function SiteCountsDto(s) {
        this.paymentsCount = parseInt(s.paymentsCount, 10);
        this.tasksCount = parseInt(s.tasksCount, 10);
        this.alarmsCount = parseInt(s.alarmsCount, 10);
        this.billedItemsNeedsApprovalCount = parseInt(s.billedItemsNeedsApprovalCount, 10);
    }
    Object.defineProperty(SiteCountsDto.prototype, "allTasksCount", {
        get: function () {
            return this.tasksCount + this.alarmsCount;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], SiteCountsDto.prototype, "paymentsCount", void 0);
    __decorate([
        observable
    ], SiteCountsDto.prototype, "tasksCount", void 0);
    __decorate([
        observable
    ], SiteCountsDto.prototype, "alarmsCount", void 0);
    __decorate([
        observable
    ], SiteCountsDto.prototype, "billedItemsNeedsApprovalCount", void 0);
    __decorate([
        computed
    ], SiteCountsDto.prototype, "allTasksCount", null);
    return SiteCountsDto;
}());
export { SiteCountsDto };
