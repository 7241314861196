var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import smsService from '~/services/SmsService';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
export var SendTestSms = function (_a) {
    var phoneNumber = _a.phoneNumber, validatePhoneNumberFunc = _a.validatePhoneNumberFunc, authToken = _a.accountStore.authToken, siteId = _a.siteStore.siteId;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), hasShowModal = _c[0], setShowModal = _c[1];
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var hasErrors, _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    hasErrors = validatePhoneNumberFunc();
                    if (hasErrors)
                        return [2 /*return*/];
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, , 4, 5]);
                    setIsLoading(true);
                    _b = (_a = smsService).sendTestSms;
                    _c = [siteId, phoneNumber];
                    return [4 /*yield*/, authToken()];
                case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                case 3:
                    _d.sent();
                    setShowModal(true);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(RdiButton, { xs: true, loading: isLoading, onClick: onClick }, "Send test SMS"),
        React.createElement(SmallModal, { open: hasShowModal, onClose: function () { return setShowModal(function (prev) { return !prev; }); }, style: { height: '25%' } },
            React.createElement(RdiTypography, { variant: "h2" }, "Success"),
            React.createElement("br", null),
            React.createElement(RdiTypography, { variant: "body1" }, "Test SMS message sent!"),
            React.createElement(RdiTypography, { variant: "body2" }, "Check if the patient received the SMS message."))));
};
export default injectAccountStore(injectSiteStore(observer(SendTestSms)));
