var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiRadioGroup from '@cros/shared/components/forms/RdiRadioGroup';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { BillingCodeStatusEnum } from '@cros/shared/constants/enums/BillingCodeStatusEnum';
import { EditStateEnum } from '@cros/shared/constants/enums/EditStateEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { BillingCode } from '@cros/shared/types/models/BillingCode';
import { ICDCode } from '@cros/shared/types/models/ICDCode';
import { newFieldChange } from '@cros/shared/utils/utils';
import { newUtilValidate } from '@cros/shared/utils/validationUtils';
import CachedIcon from '@material-ui/icons/Cached';
import CheckmarkIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import BilledItemStore from '~/stores/BilledItemStore';
import { injectSiteStore } from '~/stores/SiteStore';
var BilledItemCodes = /** @class */ (function (_super) {
    __extends(BilledItemCodes, _super);
    function BilledItemCodes(props) {
        var _this = _super.call(this, props) || this;
        _this.saving = false;
        _this.hasErrors = false;
        _this.editState = EditStateEnum.READ;
        _this.data = {
            billingCodes: [],
            icdCodes: [],
            billingNote: ''
        };
        _this.labels = {
            billingCodes: [],
            icdCodes: [],
            billingNote: ''
        };
        _this.errors = {};
        _this.rules = {
            'billingCodes.*.amount': 'required',
            'billingCodes.*.code': 'required',
            'billingCodes.*.needsApproval': 'required'
            // billingNote: 'required'
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.assignData(this.props.item);
                return [2 /*return*/];
            });
        }); };
        _this.fieldChange = function (e, field) {
            newFieldChange(e, field, _this.data, _this.labels);
            newFieldChange(e, field, _this.props.item, _this.labels);
            _this.editState = EditStateEnum.EDIT;
        };
        _this.assignData = function (item) {
            _this.data.billingCodes = Object.assign([], item.billingCodes);
            if (item.billingCodes) {
                for (var _i = 0, _a = item.billingCodes; _i < _a.length; _i++) {
                    var b = _a[_i];
                    _this.labels["billingCodes." + b.code + ".code"] = b.code;
                }
            }
            _this.data.icdCodes = Object.assign([], item.icdCodes);
            _this.data.billingNote = item.billingNote;
        };
        _this.addBillingCode = function () {
            var bc = new BillingCode({});
            _this.data.billingCodes.push(bc);
            _this.props.item.billingCodes.push(bc);
            _this.editState = EditStateEnum.EDIT;
        };
        _this.addICDCode = function () {
            _this.data.icdCodes.push(new ICDCode({ code: '' }));
            _this.props.item.icdCodes.push(new ICDCode({ code: '' }));
            _this.editState = EditStateEnum.EDIT;
        };
        _this.removeBillingCode = function (billingCode, index) {
            // this.data.billingCodes.splice(index, 1);
            // this.props.item.billingCodes.splice(index, 1);
            _this.data.billingCodes[index].deleted = true;
            _this.editState = EditStateEnum.EDIT;
        };
        _this.removeICDCode = function (icdCode, index) {
            _this.data.icdCodes.splice(index, 1);
            _this.props.item.icdCodes.splice(index, 1);
            _this.editState = EditStateEnum.EDIT;
        };
        _this.getObjectToSave = function () {
            return {
                icdCodes: _this.data.icdCodes,
                billingNote: _this.data.billingNote
            };
        };
        _this.getBillingCodesToAdd = function () {
            return _this.data.billingCodes.filter(function (b) { return !b.id && b.draft; });
        };
        _this.getBillingCodesToUpdate = function () {
            return _this.data.billingCodes.filter(function (b) { return b.id && b.draft; });
        };
        _this.getBillingCodesToDelete = function () {
            return _this.data.billingCodes.filter(function (b) { return b.id && b.deleted; });
        };
        _this.save = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, save, item, itemBilling, calls, codesToDelete, _i, codesToDelete_1, code, codesToUpdate, _b, codesToUpdate_1, code, codesToAdd, _c, codesToAdd_1, code, error_1, error_2, _d, _e, bCode;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        e.preventDefault();
                        _a = this.props, save = _a.save, item = _a.item;
                        // clear old errors, frontend validation
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            this.editState = EditStateEnum.EDIT;
                            return [2 /*return*/];
                        }
                        itemBilling = this.getObjectToSave();
                        calls = [];
                        codesToDelete = this.getBillingCodesToDelete();
                        for (_i = 0, codesToDelete_1 = codesToDelete; _i < codesToDelete_1.length; _i++) {
                            code = codesToDelete_1[_i];
                            calls.push(this.billedItemStore.deleteBillingCode(item, code, true));
                        }
                        codesToUpdate = this.getBillingCodesToUpdate();
                        for (_b = 0, codesToUpdate_1 = codesToUpdate; _b < codesToUpdate_1.length; _b++) {
                            code = codesToUpdate_1[_b];
                            calls.push(this.billedItemStore.updateBillingCode(item, code, true));
                        }
                        codesToAdd = this.getBillingCodesToAdd();
                        for (_c = 0, codesToAdd_1 = codesToAdd; _c < codesToAdd_1.length; _c++) {
                            code = codesToAdd_1[_c];
                            calls.push(this.billedItemStore.addBillingCode(item, code, true));
                        }
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Promise.all(calls)];
                    case 2:
                        _f.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _f.sent();
                        this.editState = EditStateEnum.EDIT;
                        this.hasErrors = true;
                        return [2 /*return*/];
                    case 4:
                        this.saving = true;
                        _f.label = 5;
                    case 5:
                        _f.trys.push([5, 7, 8, 9]);
                        return [4 /*yield*/, save(__assign(__assign(__assign({}, item), itemBilling), { billingCodes: undefined }))];
                    case 6:
                        _f.sent();
                        return [3 /*break*/, 9];
                    case 7:
                        error_2 = _f.sent();
                        this.hasErrors = true;
                        this.validateForm(error_2.meta);
                        this.editState = EditStateEnum.EDIT;
                        return [2 /*return*/];
                    case 8:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 9:
                        this.editState = EditStateEnum.READ;
                        this.hasErrors = false;
                        for (_d = 0, _e = this.data.billingCodes; _d < _e.length; _d++) {
                            bCode = _e[_d];
                            bCode.draft = false;
                        }
                        this.data.billingCodes = this.data.billingCodes.filter(function (b) { return !b.deleted; });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            var res = newUtilValidate(_this.data, _this.errors, _this.rules, null, validationErrors, true);
            return res;
        };
        _this.billedItemStore = new BilledItemStore(props.siteStore, null, null);
        return _this;
    }
    BilledItemCodes.prototype.render = function () {
        var _this = this;
        var _a = this.props, siteStore = _a.siteStore, disabled = _a.disabled, trial = _a.trial, aclModule = _a.aclModule, hideBillingCodes = _a.hideBillingCodes, hideNotes = _a.hideNotes, hideTitle = _a.hideTitle, save = _a.save, accountStore = _a.accountStore;
        return (React.createElement(React.Fragment, null,
            React.createElement("form", { noValidate: true, className: "hideScrollbar", style: {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }, onSubmit: this.save },
                !hideTitle && (React.createElement("div", { style: {
                        width: '100%',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        paddingRight: '45px'
                    } },
                    React.createElement(RdiTypography, { style: { width: '100%' }, variant: "h6", color: TypographyColorsEnum.Primary }, "Codes"),
                    this.editState === EditStateEnum.SAVING && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.Success }, "Saving...")),
                    this.editState === EditStateEnum.EDIT && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium }, "Edited")))),
                React.createElement("div", { style: {
                        width: '100%',
                        paddingTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        boxSizing: 'border-box',
                        paddingRight: '20px',
                        flexFlow: 'wrap',
                        height: '100%'
                    }, className: "procedureForm" },
                    React.createElement("div", { style: { width: '100%' } },
                        !hideBillingCodes && (React.createElement(React.Fragment, null,
                            React.createElement(RdiTypography, { variant: "subtitle1", color: TypographyColorsEnum.Black }, "Billing codes"),
                            this.data.billingCodes.map(function (billingCode, index) {
                                if (billingCode.deleted) {
                                    return null;
                                }
                                return (React.createElement(FlexContainer, { key: index, alignItems: "flex-start", style: { marginTop: '8px' }, margin: 20, flexWrap: "wrap" },
                                    React.createElement(RdiInput, { type: "select", label: "" + (index === 0 ? 'Billing code' : ''), value: billingCode.code, selectedItemLabel: billingCode.code, fullWidth: true, onChange: function (e) {
                                            return _this.fieldChange(e, "billingCodes." + index + ".code");
                                        }, placeholder: "Select billing code", style: { width: '280px' }, options: trial.billingCodes.map(function (b) { return ({
                                            value: b.code,
                                            label: b.code
                                        }); }), disabled: disabled, errorText: _this.errors["billingCodes." + index + ".code"] }),
                                    React.createElement(RdiInput, { type: "number", label: "" + (index === 0 ? 'Amount' : ''), value: billingCode.amount, onChange: function (e) {
                                            return _this.fieldChange(e, "billingCodes." + index + ".amount");
                                        }, errorText: _this.errors["billingCodes." + index + ".amount"], unit: "min", style: { width: '110px' }, disabled: disabled }),
                                    disabled ? (React.createElement("div", null,
                                        index === 0 && React.createElement(RdiInputLabel, null, "Status"),
                                        React.createElement(FlexContainer, { margin: 7, alignItems: "center", justifyContent: "center", style: { height: '44px' } },
                                            (billingCode.status === BillingCodeStatusEnum.NEEDS_APPROVAL ||
                                                billingCode.status === BillingCodeStatusEnum.OPEN) && (React.createElement(React.Fragment, null,
                                                React.createElement(CachedIcon, null),
                                                React.createElement(RdiTypography, { variant: "body2" }, "Needs approval"))),
                                            billingCode.status === BillingCodeStatusEnum.APPROVED && (React.createElement(React.Fragment, null,
                                                React.createElement(CheckmarkIcon, { style: { color: ColorsEnum.IconsGreen } }),
                                                React.createElement(RdiTypography, { style: { color: ColorsEnum.IconsGreen }, variant: "body2" }, "Approved"))),
                                            billingCode.status === BillingCodeStatusEnum.DENIED && (React.createElement(React.Fragment, null,
                                                React.createElement(WarningIcon, { style: { color: ColorsEnum.Error } }),
                                                React.createElement(RdiTypography, { style: { color: ColorsEnum.Error }, variant: "body2" }, "Denied")))))) : (React.createElement(RdiRadioGroup, { options: [
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false }
                                        ], label: "" + (index === 0 ? 'Needs approval' : ''), value: billingCode.needsApproval, errorText: _this.errors["billingCodes." + index + ".needsApproval"], onChange: function (e) {
                                            return _this.fieldChange(e, "billingCodes." + index + ".needsApproval");
                                        }, type: "radio_horizontal", inline: true, style: { marginBottom: '0px' }, disabled: disabled })),
                                    React.createElement(FlexContainer, { justifyContent: "center", alignItems: "flex-end", height: "" + (index === 0 ? '55px' : '31px') }, !disabled && (React.createElement(RdiLink, { xs: true, onClick: function () { return _this.removeBillingCode(billingCode, index); }, disabled: disabled, permissions: [
                                            {
                                                aclOp: AclOpEnum.UPDATE,
                                                aclModule: aclModule
                                            }
                                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Remove")))));
                            }),
                            !disabled && (React.createElement(RdiLink, { xs: true, onClick: function () { return _this.addBillingCode(); }, style: { marginTop: '7px', display: 'block' }, disabled: disabled, permissions: [
                                    {
                                        aclOp: AclOpEnum.CREATE,
                                        aclModule: aclModule
                                    }
                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "+ Add billing code")))),
                        React.createElement(RdiTypography, { variant: "subtitle1", color: TypographyColorsEnum.Black, style: { marginTop: '20px' } }, "ICD codes"),
                        this.data.icdCodes.map(function (icdCode, index) { return (React.createElement(FlexContainer, { key: "" + index, alignItems: "flex-start", style: { marginTop: '8px' } },
                            React.createElement(RdiInput, { label: "" + (index === 0 ? 'ICD code' : ''), value: icdCode.code, errorText: _this.errors["icdCodes." + index + ".code"], fullWidth: true, onChange: function (e) { return _this.fieldChange(e, "icdCodes." + index + ".code"); }, placeholder: "Enter ICD code", style: {
                                    marginRight: '20px',
                                    marginBottom: '0px',
                                    maxWidth: '280px'
                                }, disabled: disabled }),
                            React.createElement(FlexContainer, { justifyContent: "center", alignItems: "flex-end", height: "" + (index === 0 ? '55px' : '31px') },
                                React.createElement(RdiLink, { xs: true, onClick: function () { return _this.removeICDCode(icdCode, index); }, disabled: disabled, permissions: [
                                        {
                                            aclOp: AclOpEnum.UPDATE,
                                            aclModule: aclModule
                                        }
                                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Remove")))); }),
                        !disabled && (React.createElement(RdiLink, { xs: true, onClick: function () { return _this.addICDCode(); }, style: {
                                marginTop: '7px',
                                display: 'block',
                                marginBottom: '20px'
                            }, disabled: disabled, permissions: [
                                {
                                    aclOp: AclOpEnum.CREATE,
                                    aclModule: aclModule
                                }
                            ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "+ Add ICD code")),
                        !hideNotes && (React.createElement(React.Fragment, null,
                            React.createElement(RdiLine, { md: true }),
                            React.createElement(RdiTypography, { variant: "subtitle1", style: { marginBottom: '8px' }, color: TypographyColorsEnum.Black }, "Notes"),
                            React.createElement(RdiInput, { type: "text_multiline", label: "Code notes", value: this.data.billingNote, errorText: this.errors.billingNote, fullWidth: true, onChange: function (e) { return _this.fieldChange(e, 'billingNote'); }, placeholder: disabled ? '' : 'Enter your notes', disabled: disabled }))))),
                React.createElement("div", { style: { marginRight: '25px' } }, !disabled && save && (React.createElement(React.Fragment, null,
                    React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '0px' } }),
                    React.createElement(FlexContainer, { width: "100%", margin: 30, style: { marginTop: '10px' } },
                        React.createElement(RdiButton, { fullWidth: false, submit: true, style: {
                                backgroundColor: ColorsEnum.IconsGreen,
                                height: '57px'
                            }, loading: this.saving, aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, permissions: [
                                {
                                    aclOp: AclOpEnum.UPDATE,
                                    aclModule: AclModuleEnum.BILLING
                                }
                            ] },
                            React.createElement(CheckmarkIcon, { style: { marginRight: '10px' } }),
                            React.createElement(React.Fragment, null, "Complete codes")),
                        this.hasErrors && (React.createElement(FlexContainer, { margin: 5, style: { alignItems: 'center' } },
                            React.createElement(WarningIcon, { style: { color: 'red', fontSize: '19px' } }),
                            React.createElement(RdiTypography, { variant: "body2", style: { fontSize: '12px' } },
                                React.createElement("span", { style: { color: 'red' } }, "Errors")))))))))));
    };
    __decorate([
        observable
    ], BilledItemCodes.prototype, "saving", void 0);
    __decorate([
        observable
    ], BilledItemCodes.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], BilledItemCodes.prototype, "editState", void 0);
    __decorate([
        observable
    ], BilledItemCodes.prototype, "data", void 0);
    __decorate([
        observable
    ], BilledItemCodes.prototype, "labels", void 0);
    __decorate([
        observable
    ], BilledItemCodes.prototype, "errors", void 0);
    __decorate([
        observable
    ], BilledItemCodes.prototype, "rules", void 0);
    BilledItemCodes = __decorate([
        injectSiteStore,
        injectAccountStore,
        observer
    ], BilledItemCodes);
    return BilledItemCodes;
}(Component));
export default withRouter(BilledItemCodes);
