var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiTypography, { h7Style } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import BaseModal from '@cros/shared/components/modals/BaseModal';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { DeviceTypeLabelsEnum } from '@cros/shared/constants/enums/DeviceTypeEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { CircularProgress, createStyles, IconButton, Menu, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import AddDevice from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/AddDevice';
import { getPatientRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { DeviceListStore } from '~/stores/PatientStore/DeviceListStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () {
    return createStyles({
        activeButton: {
            backgroundColor: ColorsEnum.Blue,
            color: 'white',
            '&:hover': {
                backgroundColor: ColorsEnum.Blue,
                cursor: 'default'
            }
        },
        button: {}
    });
};
var DevicesListPage = /** @class */ (function (_super) {
    __extends(DevicesListPage, _super);
    function DevicesListPage(props) {
        var _this = _super.call(this, props) || this;
        _this.columns = [
            {
                id: 'title',
                sortable: false,
                label: 'Device name'
            },
            {
                id: 'type',
                sortable: false,
                label: 'Device type'
            },
            {
                id: 'startDate',
                sortName: 'startDate',
                sortable: true,
                label: 'Start date'
            },
            {
                id: 'stopDate',
                sortName: 'stopDate',
                sortable: true,
                label: 'End date'
            },
            {
                id: 'actions',
                label: '',
                width: '50px'
            }
        ];
        _this.menuRef = null;
        _this.deviceInEdit = null;
        _this.handleMenuClick = function (event, device) {
            _this.menuRef = event.currentTarget;
            _this.deviceInEdit = device;
        };
        _this.handleMenuClose = function () {
            _this.menuRef = null;
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.deviceListStore.listDevices();
                return [2 /*return*/];
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId, history = _a.history;
            history.push(getPatientRoute(siteId, patientId, trialId, enrollmentId));
        };
        _this.getAddRoute = function () {
            var url = _this.props.match.url;
            return url + "/new";
        };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.handleMenuClose();
                        return [4 /*yield*/, this.deviceListStore.deleteDeviceWithConfirmation(this.deviceInEdit)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            _this.handleMenuClose();
            history.push(url + "/edit/" + _this.deviceInEdit.id);
        };
        var enrollmentStore = _this.props.enrollmentStore;
        _this.deviceListStore = new DeviceListStore(enrollmentStore);
        return _this;
    }
    DevicesListPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, patient = _a.patientStore.patient, siteStore = _a.siteStore, accountStore = _a.accountStore;
        var _b = this.deviceListStore, devices = _b.devices, devicesCount = _b.devicesCount, changeDeviceFilters = _b.changeDeviceFilters, deviceFilters = _b.deviceFilters, devicesLoading = _b.devicesLoading;
        return (React.createElement(Provider, { deviceListStore: this.deviceListStore },
            React.createElement(BaseModal, { onClose: this.onClose },
                React.createElement("div", null,
                    React.createElement(RdiTypography, { style: { marginBottom: '25px' }, variant: "h1" }, "Devices"),
                    React.createElement(FlexContainer, { style: { marginBottom: '25px' } },
                        React.createElement(RdiTypography, { style: h7Style, variant: "h5", bold: true }, patient.fullName),
                        React.createElement("div", { style: { width: '45px' } }),
                        React.createElement(RdiTypography, { style: h7Style, variant: "h5" },
                            "Patient ID: ",
                            patient.id))),
                React.createElement("div", { style: { flex: '1' } },
                    React.createElement(FlexContainer, { alignItems: "center", justifyContent: "space-between", style: { marginBottom: '50px' }, flexWrap: "wrap" },
                        React.createElement(RdiTypography, { variant: "caption", style: { padding: '0px' } }, (devicesCount || 0) + " device" + (devicesCount === 1 ? '' : 's')),
                        React.createElement(RdiButton, { name: "new-device", to: this.getAddRoute, permissions: [
                                {
                                    aclOp: AclOpEnum.CREATE,
                                    aclModule: AclModuleEnum.DEVICE
                                }
                            ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, inverted: true }, "New device")),
                    React.createElement(RdiTable, null,
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeDeviceFilters, filters: deviceFilters })); }))),
                        React.createElement(TableBody, null,
                            !devicesLoading &&
                                devices.map(function (device, deviceIndex) {
                                    return (React.createElement(TableRow, { key: deviceIndex },
                                        React.createElement(TableCell, null, device.title),
                                        React.createElement(TableCell, null, DeviceTypeLabelsEnum[device.type]),
                                        React.createElement(TableCell, null, formatDate(device.startDate)),
                                        React.createElement(TableCell, null, formatDate(device.stopDate)),
                                        React.createElement(TableCell, null,
                                            React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, device); } },
                                                React.createElement(MoreVertIcon, null)))));
                                }),
                            !devicesLoading && devicesCount === 0 && (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 10 }, "No results"))))),
                    !devicesLoading && (React.createElement(RdiTablePagination, { count: devicesCount, rowsPerPage: deviceFilters.rowsPerPage, page: deviceFilters.page, onChangePage: function (e, newPage) { return changeDeviceFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                            return changeDeviceFilters({
                                rowsPerPage: parseInt(e.target.value, 10),
                                page: 1
                            });
                        } })),
                    devicesLoading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                        React.createElement(CircularProgress, null)))),
                React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, onClose: this.handleMenuClose },
                    React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.DEVICE
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Edit")),
                    React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.DEVICE
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Delete")))),
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: SiteRoutes.NEW_DEVICE_ROUTE },
                    React.createElement(AddDevice, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_DEVICE_ROUTE },
                    React.createElement(AddDevice, null)))));
    };
    __decorate([
        observable
    ], DevicesListPage.prototype, "menuRef", void 0);
    __decorate([
        observable
    ], DevicesListPage.prototype, "deviceInEdit", void 0);
    DevicesListPage = __decorate([
        injectPatientStore,
        injectEnrollmentStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], DevicesListPage);
    return DevicesListPage;
}(Component));
export default withStyles(styles)(withRouter(DevicesListPage));
