var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import * as telephoneData from 'country-telephone-data';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { listTimeZones } from 'timezone-support';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import UserStore from '~/stores/UserStore';
import GCalSyncForm from './GCalSyncForm';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        inputFlex: {
            alignItems: 'flex-start',
            '& >*': {
                display: 'flex',
                boxSizing: 'border-box',
                alignItems: 'flex-start',
                justifyContent: 'flex-end'
            },
            '& >*:nth-child(1)': {
                flex: '1 1 0'
            },
            '& >*:nth-child(2)': {
                flex: '1.5 1 0'
            },
            '& >*:nth-child(3)': {
                flex: '1 1 0'
            }
        },
        input: (_a = {},
            _a[theme.breakpoints.up('sm')] = {
                '& label': {
                    display: 'none'
                }
            },
            _a),
        mobileHide: (_b = {},
            _b[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _b)
    });
};
var SiteSettingsPage = /** @class */ (function (_super) {
    __extends(SiteSettingsPage, _super);
    function SiteSettingsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.hasErrors = false;
        _this.saving = false;
        _this.errorMessage = null;
        _this.userStore = null;
        _this.data = {
            name: {
                value: '',
                error: null,
                rule: 'required|min:2'
            },
            timezone: {
                value: '',
                label: '',
                error: null,
                rule: 'required|min:2'
            },
            employeesOnCall: {
                value: [],
                label: '',
                error: null
            },
            phonePrefix: {
                label: '',
                value: '',
                error: null,
                rule: 'required'
            },
            phone: {
                value: '',
                error: null,
                rule: 'required|numeric'
            },
            email: {
                value: '',
                error: null,
                rule: 'required'
            },
            addressLine1: {
                value: '',
                error: null,
                rule: 'required'
            },
            addressLine2: {
                value: '',
                error: null,
                rule: 'required'
            },
            state: {
                value: '',
                error: null,
                rule: 'required'
            },
            city: {
                value: '',
                error: null,
                rule: 'required'
            },
            zip: {
                value: '',
                error: null,
                rule: 'required'
            },
            country: {
                value: '',
                error: null,
                label: '',
                rule: 'required'
            },
            lat: {
                value: null,
                error: null,
                rule: ''
            },
            lng: {
                value: null,
                error: null,
                label: '',
                rule: ''
            }
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var siteStore;
            return __generator(this, function (_a) {
                siteStore = this.props.siteStore;
                this.assignData(siteStore.siteInfo);
                this.userStore.changeUserFilters({ rowsPerPage: 1000 });
                return [2 /*return*/];
            });
        }); };
        _this.assignData = function (site) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            _this.data.name.value = site.name;
            _this.data.timezone.value = site.timezone;
            _this.data.timezone.label = site.timezone;
            _this.data.employeesOnCall.value = site.employeesOnCall.map(function (e) { return ({
                label: e.fullName,
                value: e.id
            }); });
            _this.data.phone.value = (_a = site.contact) === null || _a === void 0 ? void 0 : _a.phoneNumber;
            _this.data.phonePrefix.value = (_b = site.contact) === null || _b === void 0 ? void 0 : _b.phoneNumberPrefix;
            _this.data.phonePrefix.label = (_c = site.contact) === null || _c === void 0 ? void 0 : _c.phoneNumberPrefix;
            _this.data.email.value = site.email;
            _this.data.zip.value = (_d = site.location) === null || _d === void 0 ? void 0 : _d.zip;
            _this.data.city.value = (_e = site.location) === null || _e === void 0 ? void 0 : _e.city;
            _this.data.addressLine1.value = (_f = site.location) === null || _f === void 0 ? void 0 : _f.addressLine1;
            _this.data.addressLine2.value = (_g = site.location) === null || _g === void 0 ? void 0 : _g.addressLine2;
            _this.data.country.value = (_h = site.location) === null || _h === void 0 ? void 0 : _h.country;
            var country = telephoneData.allCountries.find(function (x) { var _a; return x.iso2 === ((_a = site.location) === null || _a === void 0 ? void 0 : _a.country); });
            _this.data.country.label = country ? country.name : '';
            _this.data.state.value = (_j = site.location) === null || _j === void 0 ? void 0 : _j.state;
            _this.data.lat.value = (_k = site.location) === null || _k === void 0 ? void 0 : _k.lat;
            _this.data.lng.value = (_l = site.location) === null || _l === void 0 ? void 0 : _l.lng;
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.mapFieldToInternal = function (fieldName) {
            switch (fieldName) {
                case 'employeesOnCallIds':
                    return 'employeesOnCall';
                default:
                    return fieldName;
            }
        };
        _this.submit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var siteStore, o, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        siteStore = this.props.siteStore;
                        e.preventDefault();
                        e.stopPropagation();
                        o = this.getObjectToSave();
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, siteStore.updateSite(o)];
                    case 2:
                        _a.sent();
                        this.hasErrors = this.validateForm({});
                        this.errorMessage = '';
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 4:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, _this.mapFieldToInternal, validationErrors);
        };
        _this.getObjectToSave = function () {
            var siteStore = _this.props.siteStore;
            var midnight = new Date();
            midnight.setHours(0, 0, 0, 0);
            var o = {
                name: _this.data.name.value,
                id: siteStore.siteId,
                timezone: _this.data.timezone.value,
                employeesOnCallIds: _this.data.employeesOnCall.value.map(function (e) { return e.value; }),
                contact: {
                    phoneNumber: _this.data.phone.value,
                    phoneNumberPrefix: _this.data.phonePrefix.value
                },
                email: _this.data.email.value ? _this.data.email.value : undefined,
                location: {
                    addressLine1: _this.data.addressLine1.value,
                    addressLine2: _this.data.addressLine2.value,
                    city: _this.data.city.value,
                    state: _this.data.state.value,
                    zip: _this.data.zip.value,
                    country: _this.data.country.value,
                    lat: _this.data.lat.value ? parseFloat(_this.data.lat.value) : undefined,
                    lng: _this.data.lng.value ? parseFloat(_this.data.lng.value) : undefined
                }
            };
            return o;
        };
        _this.hasPermissions = function (permissions) {
            var _a;
            var _b = _this.props, accountStore = _b.accountStore, siteStore = _b.siteStore;
            return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId);
        };
        _this.userStore = new UserStore(props.siteStore);
        return _this;
    }
    SiteSettingsPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, classes = _a.classes, accountStore = _a.accountStore, siteStore = _a.siteStore;
        var users = this.userStore.users;
        if (!this.hasPermissions([{ aclOp: AclOpEnum.CREATE, aclModule: AclModuleEnum.SETTINGS }])) {
            return null;
        }
        return (React.createElement(FixedWidthContent, { xs: true, white: true },
            React.createElement("div", null,
                React.createElement(RdiTypography, { variant: "h1" }, "Settings"),
                React.createElement(RdiLine, null),
                React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submit },
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { marginTop: '15px', marginRight: '0px' } }, "Site name"),
                        React.createElement(RdiInput, { style: { marginBottom: '0px' }, type: "text", name: "name", value: this.data.name.value, onChange: function (e) { return _this.fieldChange(e, 'name'); }, errorText: this.data.name.error, autoFocus: true, label: "Site name", className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(RdiLine, null),
                    React.createElement("div", { style: { marginBottom: '15px' } }, "Time & date settings"),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { marginTop: '15px', marginRight: '0px' } }, "Timezone"),
                        React.createElement(RdiInput, { type: "select", name: "timezone", label: "Timezone", errorText: this.data.timezone.error, value: this.data.timezone.value, selectedItemLabel: this.data.timezone.label, options: listTimeZones().map(function (item) { return ({
                                label: item,
                                value: item
                            }); }), assistiveText: "Choose either a city in the same timezone as you or a UTC timezone offset", onChange: function (e) { return _this.fieldChange(e, 'timezone'); }, className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(RdiLine, null),
                    React.createElement("div", { style: { marginBottom: '15px' } }, "Staff on call"),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { marginTop: '15px', marginRight: '0px' } }, "Employee"),
                        React.createElement(RdiInput, { type: "select-multiple", name: "employeesOnCall", label: "Employee", errorText: this.data.employeesOnCall.error, placeholder: "Select employee...", value: this.data.employeesOnCall.value, options: users
                                ? users.map(function (item) { return ({
                                    label: item.fullName,
                                    value: item.id
                                }); })
                                : [], onChange: function (e) { return _this.fieldChange(e, 'employeesOnCall'); }, className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(RdiLine, null),
                    React.createElement("div", { style: { marginBottom: '15px' } }, "Contact info"),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { marginTop: '15px', marginRight: '0px' } }, "Phone number"),
                        React.createElement(FlexContainer, { margin: 10, style: { width: '100%' } },
                            React.createElement(RdiInput, { value: this.data.phonePrefix.value, selectedItemLabel: this.data.phonePrefix.label, errorText: this.data.phonePrefix.error, placeholder: "", type: "select", style: { width: '100px', minWidth: '100px' }, label: "\u00A0", fullWidth: true, onChange: function (e) { return _this.fieldChange(e, 'phonePrefix'); }, options: telephoneData.allCountries.map(function (item) { return ({
                                    label: "+" + item.dialCode,
                                    value: "+" + item.dialCode
                                }); }), name: "phonePrefix", className: classes.input }),
                            React.createElement(RdiInput, { label: "Phone number", value: this.data.phone.value, errorText: this.data.phone.error, fullWidth: true, style: { width: 'calc(100% - 110px)', minWidth: 'calc(100% - 110px)' }, onChange: function (e) { return _this.fieldChange(e, 'phone'); }, name: "phone", className: classes.input })),
                        React.createElement("div", { className: classes.mobileHide })),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { marginTop: '15px', marginRight: '0px' } }, "Email"),
                        React.createElement(RdiInput, { label: "Email", style: { marginBottom: '0px' }, type: "text", value: this.data.email.value, onChange: function (e) { return _this.fieldChange(e, 'email'); }, errorText: this.data.email.error, name: "email", className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(RdiLine, null),
                    React.createElement("div", { style: { marginBottom: '15px' } }, "Address"),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { paddingTop: '15px', marginRight: '0px' } }, "Address line 1"),
                        React.createElement(RdiInput, { label: "Address line 1", value: this.data.addressLine1.value, errorText: this.data.addressLine1.error, fullWidth: true, type: "text", onChange: function (e) { return _this.fieldChange(e, 'addressLine1'); }, placeholder: "Street address, P.O. box, company name", name: "addressLine1", className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { paddingTop: '15px', marginRight: '0px' } }, "Address line 2"),
                        React.createElement(RdiInput, { label: "Address line 2", value: this.data.addressLine2.value, errorText: this.data.addressLine2.error, fullWidth: true, type: "text", onChange: function (e) { return _this.fieldChange(e, 'addressLine2'); }, placeholder: "Apartment, suite, unit, building, floor, etc.", name: "addressLine2", className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { paddingTop: '15px', marginRight: '0px' } }, "City"),
                        React.createElement(RdiInput, { label: "City", value: this.data.city.value, errorText: this.data.city.error, fullWidth: true, type: "text", onChange: function (e) { return _this.fieldChange(e, 'city'); }, name: "city", className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { paddingTop: '15px', marginRight: '0px' } }, "State"),
                        React.createElement(RdiInput, { label: "State", value: this.data.state.value, errorText: this.data.state.error, fullWidth: true, type: "text", onChange: function (e) { return _this.fieldChange(e, 'state'); }, name: "state", className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { paddingTop: '15px', marginRight: '0px' } }, "Zip"),
                        React.createElement("div", { className: classes.inputFlex, style: { justifyContent: 'flex-start' } },
                            React.createElement(RdiInput, { label: "Zip", value: this.data.zip.value, errorText: this.data.zip.error, fullWidth: true, type: "text", onChange: function (e) { return _this.fieldChange(e, 'zip'); }, name: "zip", className: classes.input }),
                            React.createElement("div", null)),
                        React.createElement("div", null)),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { paddingTop: '15px', marginRight: '0px' } }, "Country"),
                        React.createElement(RdiInput, { label: "Country", value: this.data.country.value, selectedItemLabel: this.data.country.label, errorText: this.data.country.error, fullWidth: true, type: "select", onChange: function (e) { return _this.fieldChange(e, 'country'); }, options: telephoneData.allCountries.map(function (item) { return ({
                                label: "" + item.name,
                                value: "" + item.iso2
                            }); }), name: "country", className: classes.input }),
                        React.createElement("div", null)),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { marginTop: '15px', marginRight: '0px' } }, "Latitude"),
                        React.createElement("div", { className: classes.inputFlex, style: { justifyContent: 'flex-start' } },
                            React.createElement(RdiInput, { label: "Latitude", value: this.data.lat.value, errorText: this.data.lat.error, fullWidth: true, onChange: function (e) { return _this.fieldChange(e, 'lat'); }, name: "lat", type: "number", className: classes.input }),
                            React.createElement("div", null)),
                        React.createElement("div", null)),
                    React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement(RdiTypography, { variant: "caption", className: classes.mobileHide, style: { marginTop: '15px', marginRight: '0px' } }, "Longitude"),
                        React.createElement("div", { className: classes.inputFlex, style: { justifyContent: 'flex-start' } },
                            React.createElement(RdiInput, { label: "Longitude", value: this.data.lng.value, errorText: this.data.lng.error, fullWidth: true, onChange: function (e) { return _this.fieldChange(e, 'lng'); }, name: "lng", type: "number", className: classes.input }),
                            React.createElement("div", null)),
                        React.createElement("div", null)),
                    this.hasErrors && React.createElement(RdiErrorBox, null, this.errorMessage),
                    React.createElement(RdiLine, null),
                    React.createElement(FlexContainer, { alignItems: "flex-start", className: classes.inputFlex },
                        React.createElement("div", { className: classes.mobileHide, style: { paddingRight: '30px' } }),
                        React.createElement("div", null,
                            React.createElement(RdiButton, { loading: this.saving, submit: true, fullWidth: true, permissions: [
                                    {
                                        aclOp: AclOpEnum.UPDATE,
                                        aclModule: AclModuleEnum.COMPANY
                                    }
                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Save changes")),
                        React.createElement("div", { className: classes.mobileHide, style: { paddingLeft: '10px' } }))),
                React.createElement(RdiLine, null),
                React.createElement(GCalSyncForm, { classes: classes, siteStore: siteStore }))));
    };
    __decorate([
        observable
    ], SiteSettingsPage.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], SiteSettingsPage.prototype, "saving", void 0);
    __decorate([
        observable
    ], SiteSettingsPage.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], SiteSettingsPage.prototype, "data", void 0);
    SiteSettingsPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], SiteSettingsPage);
    return SiteSettingsPage;
}(Component));
export default withStyles(styles)(withRouter(SiteSettingsPage));
