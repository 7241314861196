var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { computed } from "mobx";
import { DeviceData } from "s~/types/models/DeviceData";
import { DeviceDataSchema } from "s~/types/models/DeviceDataSchema";
import { Model } from "s~/types/models/Model";
import { getDateFromString } from "s~/utils/dateUtils";
var Device = /** @class */ (function (_super) {
    __extends(Device, _super);
    function Device(d) {
        var _this = _super.call(this, d) || this;
        _this.id = d.id;
        _this.mac = d.mac;
        _this.type = d.type;
        _this.name = d.name;
        _this.label = d.label;
        _this.description = d.description;
        _this.status = d.status;
        _this.shortLabel = d.shortLabel;
        _this.schema = d.schema ? new DeviceDataSchema(d.schema) : undefined;
        _this.computedFieldsSchema = d.computedFieldsSchema
            ? new DeviceDataSchema(d.computedFieldsSchema)
            : undefined;
        _this.ordering = d.ordering;
        _this.chartProperties = d.chartProperties;
        _this.categories = d.categories;
        if (d.latestDatapoint) {
            _this.latestDatapoint = new DeviceData(d.latestDatapoint);
        }
        _this.title = d.title;
        _this.startDate = d.startDate ? getDateFromString(d.startDate) : null;
        _this.stopDate = d.stopDate ? getDateFromString(d.stopDate) : null;
        _this.lastUpdated = d.lastUpdated
            ? getDateFromString(d.lastUpdated)
            : undefined;
        _this.amount = d.amount;
        return _this;
    }
    Object.defineProperty(Device.prototype, "schemaAll", {
        get: function () {
            return __spreadArrays(this.schema.items, this.computedFieldsSchema.items);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        computed
    ], Device.prototype, "schemaAll", null);
    return Device;
}(Model));
export { Device };
