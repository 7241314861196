export var ProcedureCategoryEnum;
(function (ProcedureCategoryEnum) {
    ProcedureCategoryEnum["CCM"] = "CCM";
    ProcedureCategoryEnum["SOURCE_DOCS"] = "SourceDocs";
    ProcedureCategoryEnum["WELLNESS"] = "Wellness";
    ProcedureCategoryEnum["SCREENING"] = "Screening";
    ProcedureCategoryEnum["UNKNOWN"] = "Unknown";
})(ProcedureCategoryEnum || (ProcedureCategoryEnum = {}));
export var ProcedureCategoryLabelEnum;
(function (ProcedureCategoryLabelEnum) {
    ProcedureCategoryLabelEnum["CCM"] = "CCM";
    ProcedureCategoryLabelEnum["SourceDocs"] = "Source docs";
    ProcedureCategoryLabelEnum["Wellness"] = "Wellness";
    ProcedureCategoryLabelEnum["Screening"] = "Screening";
    ProcedureCategoryLabelEnum["Unknown"] = "Unknown";
})(ProcedureCategoryLabelEnum || (ProcedureCategoryLabelEnum = {}));
export var ProcedureCategorySlugEnum;
(function (ProcedureCategorySlugEnum) {
    ProcedureCategorySlugEnum["covid_triage"] = "CCM";
    ProcedureCategorySlugEnum["hypertension"] = "CCM";
    ProcedureCategorySlugEnum["emergency_hypertension"] = "CCM";
    ProcedureCategorySlugEnum["progress_notes"] = "CCM";
    ProcedureCategorySlugEnum["liver_issues"] = "CCM";
    ProcedureCategorySlugEnum["adverse_events"] = "SourceDocs";
    ProcedureCategorySlugEnum["ecg"] = "SourceDocs";
    ProcedureCategorySlugEnum["fibroscan"] = "SourceDocs";
    ProcedureCategorySlugEnum["informed_consent"] = "SourceDocs";
    ProcedureCategorySlugEnum["investigational_product"] = "SourceDocs";
    ProcedureCategorySlugEnum["laboratory"] = "SourceDocs";
    ProcedureCategorySlugEnum["lifestyle_modification"] = "SourceDocs";
    ProcedureCategorySlugEnum["lifestyle_review"] = "SourceDocs";
    ProcedureCategorySlugEnum["medication_changes"] = "SourceDocs";
    ProcedureCategorySlugEnum["physical_assesment"] = "SourceDocs";
    ProcedureCategorySlugEnum["questionnaires"] = "SourceDocs";
    ProcedureCategorySlugEnum["reminders"] = "SourceDocs";
    ProcedureCategorySlugEnum["soap"] = "SourceDocs";
    ProcedureCategorySlugEnum["vital_signs_and_mesurements"] = "SourceDocs";
    ProcedureCategorySlugEnum["intake_male_hp"] = "Wellness";
    ProcedureCategorySlugEnum["intake_female_hp"] = "Wellness";
    ProcedureCategorySlugEnum["wheel_of_life"] = "Wellness";
    ProcedureCategorySlugEnum["vital_sign_and_measurments"] = "Wellness";
    ProcedureCategorySlugEnum["client_progress"] = "Wellness";
    ProcedureCategorySlugEnum["screening"] = "Screening";
})(ProcedureCategorySlugEnum || (ProcedureCategorySlugEnum = {}));
