import React from "react";
import FlexContainer from "s~/components/misc/FlexContainer";
import CalendarIconSmall from "s~/static/CalendarIconSmall.svg";
import { formatDatetime, formatDatetimeLong, getDateFromString } from "s~/utils/dateUtils";
var DateWithIcon = function (props) {
    var value = props.value, displayType = props.displayType, additionalText = props.additionalText, stringValue = props.stringValue, addition = props.addition;
    var mom = value;
    if (typeof mom === "string") {
        mom = getDateFromString(mom);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexContainer, null,
            React.createElement("div", { style: { marginRight: "9px" } },
                React.createElement("img", { alt: "img", src: CalendarIconSmall })),
            value && displayType === "default date" && (React.createElement("span", null, formatDatetime(mom))),
            value && displayType === "humanized date and time" && (React.createElement("div", { style: { display: "flex", flexDirection: "row", flexWrap: "wrap" } },
                React.createElement("span", null, (formatDatetimeLong(mom) + " " + (additionalText || "")).trim()),
                !!addition && addition)),
            !value && stringValue && React.createElement("span", null, stringValue))));
};
DateWithIcon.defaultProps = {
    displayType: "default date"
};
export default DateWithIcon;
