var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { format, setMonth, setYear } from "date-fns";
import { observable } from "mobx";
import { BillingCode } from "s~/types/models/BillingCode";
import { Device } from "s~/types/models/Device";
import { ICDCode } from "s~/types/models/ICDCode";
import { Insurance } from "s~/types/models/Insurance";
import { Model } from "s~/types/models/Model";
import { Patient } from "s~/types/models/Patient";
import { Trial } from "s~/types/models/Trial";
import UploadedFile from "s~/types/models/UploadedFile";
import { getDateFromString } from "s~/utils/dateUtils";
var Bill = /** @class */ (function (_super) {
    __extends(Bill, _super);
    function Bill(p) {
        var _this = _super.call(this, p) || this;
        _this.id = p.id;
        _this.patientId = p.patientId;
        _this.patient = p.patient ? new Patient(p.patient) : undefined;
        _this.siteId = p.siteId;
        _this.createdAt = p.createdAt ? getDateFromString(p.createdAt) : undefined;
        _this.trialId = p.trialId;
        _this.trial = p.trial ? new Trial(p.trial) : undefined;
        _this.date = p.date;
        _this.status = p.status;
        _this.billingCodes = p.billingCodes
            ? p.billingCodes.map(function (b) { return new BillingCode(b); })
            : [];
        _this.insurances = p.insurances
            ? p.insurances.map(function (b) { return new Insurance(b); })
            : [];
        _this.icd10Codes = p.icd10Codes ? p.icd10Codes.map(function (b) { return new ICDCode(b); }) : [];
        _this.devices = p.devices ? p.devices.map(function (b) { return new Device(b); }) : [];
        _this.datePartial = p.datePartial;
        _this.records = [];
        if (p.records) {
            for (var _i = 0, _a = p.records; _i < _a.length; _i++) {
                var rec = _a[_i];
                _this.records.push(new UploadedFile(rec));
            }
        }
        return _this;
    }
    Object.defineProperty(Bill.prototype, "parsedDatePartial", {
        get: function () {
            var datePartialRegex = /^(?<year>[0-9]{4})(-((?<month>[0-9]{2}(-(?<day>[0-9]{2}))?)|(W(?<week>[0-9]{2}))))?$/;
            var match = datePartialRegex.exec(this.datePartial);
            var _a = match.groups, month = _a.month, year = _a.year;
            var date = new Date();
            date = setMonth(date, parseInt(month, 10) - 1);
            date = setYear(date, parseInt(year, 10));
            return format(date, "MMMM yyyy");
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], Bill.prototype, "expanded", void 0);
    return Bill;
}(Model));
export { Bill };
