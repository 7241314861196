export var DatapointEnum;
(function (DatapointEnum) {
    DatapointEnum["boolean"] = "boolean";
    DatapointEnum["radio"] = "radio";
    DatapointEnum["textbox"] = "textbox";
    DatapointEnum["upload"] = "upload";
    DatapointEnum["numeric"] = "numeric";
    DatapointEnum["adverse_events_form"] = "adverse_events_form";
    DatapointEnum["medication_changes_form"] = "medication_changes_form";
    DatapointEnum["investigation_product_dispensing_form"] = "investigation_product_dispensing_form";
    DatapointEnum["investigation_product_dosing_form"] = "investigation_product_dosing_form";
    DatapointEnum["datetime"] = "datetime";
    DatapointEnum["text"] = "text";
    DatapointEnum["design"] = "design";
    DatapointEnum["computed"] = "computed";
    DatapointEnum["error"] = "error";
})(DatapointEnum || (DatapointEnum = {}));
