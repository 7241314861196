var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import CheckmarkIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import * as telephoneData from 'country-telephone-data';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import VerifyPhoneNumberModal from '~/pages/account/VerifyPhoneNumberModal';
import { AccountRoutes, getSettingsRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function (theme) {
    var _a, _b, _c;
    return createStyles({
        inputFlex: {
            flexGrow: 1,
            flexBasis: 0,
            display: 'flex',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            '&:nth-of-type(2)': (_a = {
                    minWidth: '300px'
                },
                _a[theme.breakpoints.down('xs')] = {
                    minWidth: '100%'
                },
                _a),
            '&:nth-of-type(1)': {
                maxWidth: '180px'
            }
        },
        mobileHide: (_b = {},
            _b[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _b),
        input: (_c = {},
            _c[theme.breakpoints.up('sm')] = {
                '& label': {
                    display: 'none'
                }
            },
            _c)
    });
};
var SmsNotificationsPage = /** @class */ (function (_super) {
    __extends(SmsNotificationsPage, _super);
    function SmsNotificationsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = {
            phoneNumberPrefix: {
                label: '',
                value: '',
                error: null,
                rule: 'required',
                disabled: false
            },
            phoneNumber: {
                value: '',
                error: null,
                rule: 'required|numeric',
                disabled: false
            }
        };
        _this.hasErrors = false;
        _this.errorMessage = '';
        _this.saving = false;
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.componentDidMount = function () {
            _this.updateNumbers();
            var history = _this.props.history;
            history.listen(function () {
                _this.updateNumbers();
            });
        };
        _this.updateNumbers = function () {
            var accountDetail = _this.props.accountStore.accountDetail;
            if (!accountDetail) {
                return;
            }
            if (accountDetail.phoneNumber && accountDetail.phoneNumberPrefix) {
                _this.data.phoneNumberPrefix.value = accountDetail.phoneNumberPrefix;
                _this.data.phoneNumberPrefix.label = accountDetail.phoneNumberPrefix;
                _this.data.phoneNumber.value = accountDetail.phoneNumber;
                _this.data.phoneNumber.disabled = true;
                _this.data.phoneNumberPrefix.disabled = true;
            }
            else if (accountDetail.confirmationPhoneNumber &&
                accountDetail.confirmationPhoneNumberPrefix) {
                _this.data.phoneNumberPrefix.value = accountDetail.confirmationPhoneNumberPrefix;
                _this.data.phoneNumberPrefix.label = accountDetail.confirmationPhoneNumberPrefix;
                _this.data.phoneNumber.value = accountDetail.confirmationPhoneNumber;
                _this.data.phoneNumber.disabled = false;
                _this.data.phoneNumberPrefix.disabled = false;
            }
            else {
                _this.data.phoneNumberPrefix.value = '+1';
                _this.data.phoneNumberPrefix.label = '+1';
                _this.data.phoneNumber.value = '';
                _this.data.phoneNumber.disabled = false;
                _this.data.phoneNumberPrefix.disabled = false;
            }
        };
        _this.verifyNumber = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, history, url, accountStore, user, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, url = _a.match.url, accountStore = _a.accountStore;
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        this.saving = true;
                        this.errorMessage = '';
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, accountStore.updateUserContact({
                                phoneNumber: String(this.data.phoneNumber.value),
                                phoneNumberPrefix: this.data.phoneNumberPrefix.value,
                                phoneNumberForSMS: true
                            })];
                    case 2:
                        user = _b.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 5];
                    case 4:
                        this.saving = false;
                        if (!user) {
                            return [2 /*return*/];
                        }
                        history.push(url + "/verify");
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.enableNotificationsWithConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            var accountStore, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accountStore = this.props.accountStore;
                        this.errorMessage = '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, accountStore.enableNotificationsWithConfirmation()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        this.errorMessage = error_2.message || error_2.detail || error_2;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.disableNotificationsWithConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            var accountStore, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accountStore = this.props.accountStore;
                        this.errorMessage = '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, accountStore.disableNotificationsWithConfirmation()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        this.errorMessage = error_3.message || error_3.detail || error_3;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.deletePhoneNumberWithConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            var accountStore, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accountStore = this.props.accountStore;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, accountStore.deletePhoneNumberWithConfirmation(this.updateNumbers)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        this.errorMessage = error_4.message || error_4.detail || error_4;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.submit = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var accountDetail;
            return __generator(this, function (_a) {
                event.preventDefault();
                accountDetail = this.props.accountStore.accountDetail;
                if (accountDetail.phoneNumberForSMS) {
                    this.disableNotificationsWithConfirmation();
                }
                else if (accountDetail.phoneVerified) {
                    this.enableNotificationsWithConfirmation();
                }
                else {
                    this.verifyNumber();
                }
                return [2 /*return*/];
            });
        }); };
        _this.validateForm = function (validationErrors) {
            var invalid = utilValidate(_this.data, null, validationErrors);
            return invalid;
        };
        return _this;
    }
    SmsNotificationsPage.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, classes = _b.classes, accountDetail = _b.accountStore.accountDetail, _c = _b.siteStore, siteId = _c.siteId, siteInfo = _c.siteInfo, accountStore = _b.accountStore, siteStore = _b.siteStore;
        return (React.createElement(FixedWidthContent, { style: { maxWidth: '780px' } },
            React.createElement("form", { onSubmit: this.submit, style: {
                    width: '100%'
                } },
                React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '35px' } }, "SMS notifications"),
                !accountDetail.phoneVerified && (React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Black }, "Enable notifications for your phone number.")),
                React.createElement(RdiLine, null),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: classes.inputFlex + " " + classes.mobileHide },
                        React.createElement(RdiTypography, { variant: "caption", style: { paddingTop: '15px' } }, "Phone number")),
                    React.createElement(FlexContainer, { className: classes.inputFlex, margin: 10 },
                        React.createElement(RdiInput, { value: this.data.phoneNumberPrefix.value, disabled: this.data.phoneNumberPrefix.disabled, selectedItemLabel: this.data.phoneNumberPrefix.label, errorText: this.data.phoneNumberPrefix.error, placeholder: "", type: "select", style: { minWidth: '100px' }, fullWidth: true, onChange: function (e) { return _this.fieldChange(e, 'phoneNumberPrefix'); }, options: telephoneData.allCountries.map(function (item) { return ({
                                label: "+" + item.dialCode,
                                value: "+" + item.dialCode
                            }); }), name: "phoneNumberPrefix", className: classes.input, label: "\u00A0" }),
                        React.createElement(RdiInput, { type: "number", value: this.data.phoneNumber.value, disabled: this.data.phoneNumber.disabled, errorText: this.data.phoneNumber.error, fullWidth: true, onChange: function (e) { return _this.fieldChange(e, 'phoneNumber'); }, name: "phoneNumber", label: "Phone number", className: classes.input, style: { minWidth: 'calc(100% - 110px)' } })),
                    React.createElement("div", { className: classes.inputFlex })),
                !accountDetail.phoneVerified && (React.createElement("div", { className: "" + classes.inputFlex, style: { flexDirection: 'column', alignItems: 'center' } },
                    React.createElement(RdiTypography, { style: { color: ColorsEnum.Error }, variant: "body2" }, "Phone number is not verified"))),
                accountDetail.phoneVerified && (React.createElement("div", { className: "" + classes.inputFlex },
                    React.createElement("div", { className: classes.inputFlex }),
                    React.createElement("div", { className: classes.inputFlex, style: { flexDirection: 'column' } },
                        React.createElement(RdiLink, { onClick: this.deletePhoneNumberWithConfirmation, style: { marginBottom: '20px' } }, "Change phone number")),
                    React.createElement("div", { className: classes.inputFlex }))),
                accountDetail.phoneVerified && accountDetail.phoneNumberForSMS && (React.createElement("div", { className: "" + classes.inputFlex },
                    React.createElement("div", { className: classes.inputFlex }),
                    React.createElement("div", { className: classes.inputFlex, style: { flexDirection: 'column' } },
                        React.createElement(FlexContainer, { margin: 10, alignItems: "flex-start" },
                            React.createElement(CheckmarkIcon, { style: { color: ColorsEnum.IconsGreen } }),
                            React.createElement(RdiTypography, { style: { color: ColorsEnum.IconsGreen }, variant: "body2" },
                                "Phone number ",
                                accountDetail.phoneNumberPrefix,
                                accountDetail.phoneNumber,
                                " is verified and can receive SMS notifications.")),
                        ((_a = siteInfo.employeesOnCallIds) === null || _a === void 0 ? void 0 : _a.indexOf(accountDetail.id)) < 0 ||
                            !siteInfo.employeesOnCallIds ? (React.createElement(React.Fragment, null,
                            React.createElement(FlexContainer, { margin: 10, alignItems: "flex-start" },
                                React.createElement(CloseIcon, { style: { color: ColorsEnum.Error } }),
                                React.createElement(RdiTypography, { style: { color: ColorsEnum.Error }, variant: "body2" }, "To start receiving notifications, you need to be added to the list of staff on call by your site administrator.")),
                            React.createElement(RdiLink, { style: { marginTop: '20px' }, to: getSettingsRoute(siteId), permissions: [
                                    {
                                        aclOp: AclOpEnum.UPDATE,
                                        aclModule: AclModuleEnum.COMPANY
                                    }
                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true }, "Manage staff on call"))) : (React.createElement(FlexContainer, { margin: 10, alignItems: "flex-start" },
                            React.createElement(CheckmarkIcon, { style: { color: ColorsEnum.IconsGreen } }),
                            React.createElement(RdiTypography, { style: { color: ColorsEnum.IconsGreen }, variant: "body2" }, "You are added to staff on call and will receive alerts.")))))),
                React.createElement(RdiErrorBox, { style: { width: '100%' } }, this.errorMessage),
                React.createElement(RdiLine, null),
                React.createElement(FlexContainer, { alignItems: "flex-start" },
                    React.createElement("div", { className: classes.inputFlex }),
                    React.createElement("div", { className: classes.inputFlex, style: { justifyContent: 'flex-start' } },
                        React.createElement(RdiButton, { submit: true, loading: this.saving, fullWidth: true, inverted: accountDetail.phoneNumberForSMS }, accountDetail.phoneNumberForSMS ? 'Disable notifications' : 'Enable notifications')),
                    React.createElement("div", { className: classes.inputFlex }))),
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: AccountRoutes.VERIFY_PHONE_NUMBER_ROUTE },
                    React.createElement(VerifyPhoneNumberModal, null)))));
    };
    __decorate([
        observable
    ], SmsNotificationsPage.prototype, "data", void 0);
    __decorate([
        observable
    ], SmsNotificationsPage.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], SmsNotificationsPage.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], SmsNotificationsPage.prototype, "saving", void 0);
    SmsNotificationsPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], SmsNotificationsPage);
    return SmsNotificationsPage;
}(Component));
export default withStyles(styles)(withRouter(SmsNotificationsPage));
