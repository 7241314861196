var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Model } from "s~/types/models/Model";
var DeviceDataSchemaItem = /** @class */ (function (_super) {
    __extends(DeviceDataSchemaItem, _super);
    function DeviceDataSchemaItem(i) {
        var _this = _super.call(this, i) || this;
        _this.label = i.label;
        _this.shortLabel = i.shortLabel;
        _this.slug = i.slug;
        _this.unit = i.unit;
        _this.options = i.options;
        _this.color = i.color;
        _this.showOnSummary = i.showOnSummary;
        return _this;
    }
    return DeviceDataSchemaItem;
}(Model));
export { DeviceDataSchemaItem };
