var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import { DatapointEnum } from '@cros/shared/constants/enums/DatapointEnum';
import React, { Component } from 'react';
var VisitNumeric = /** @class */ (function (_super) {
    __extends(VisitNumeric, _super);
    function VisitNumeric(props) {
        var _this = _super.call(this, props) || this;
        _this.assignInternalValues = function (props) {
            var dataPoint = props.dataPoint, disabled = props.disabled;
            _this.setState(function (prevState) {
                var _a, _b, _c, _d, _e;
                return (__assign(__assign({}, prevState), { disabled: disabled || dataPoint.type === DatapointEnum.computed, 
                    // default unit with unit options
                    properties: dataPoint.properties || { unit: '', units: [] }, internalValue: {
                        value: (_a = dataPoint.data) === null || _a === void 0 ? void 0 : _a.value,
                        unit: (_b = dataPoint.data) === null || _b === void 0 ? void 0 : _b.unit
                    }, internalError: dataPoint.errorMessage, selectedUnitLabel: (_e = (_d = (_c = dataPoint.properties) === null || _c === void 0 ? void 0 : _c.units) === null || _d === void 0 ? void 0 : _d.find(function (x) { return x.slug === dataPoint.data.unit; })) === null || _e === void 0 ? void 0 : _e.label }));
            });
        };
        _this.onChangeValue = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, dataPoint, onDataPointChange, val, changeEventvalue;
            return __generator(this, function (_b) {
                _a = this.props, dataPoint = _a.dataPoint, onDataPointChange = _a.onDataPointChange;
                val = null;
                try {
                    val = parseFloat(e.value);
                }
                catch (err) { } // eslint-disable-line no-empty
                if (Number.isNaN(val)) {
                    val = null;
                }
                changeEventvalue = {
                    unit: this.state.internalValue.unit
                        ? this.state.internalValue.unit
                        : this.state.properties.unit,
                    value: val
                };
                this.setState(function (prevState) { return (__assign(__assign({}, prevState), { internalValue: changeEventvalue })); });
                onDataPointChange(dataPoint, changeEventvalue);
                return [2 /*return*/];
            });
        }); };
        _this.onChangeUnit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, dataPoint, onDataPointChange, changeEventvalue;
            return __generator(this, function (_b) {
                _a = this.props, dataPoint = _a.dataPoint, onDataPointChange = _a.onDataPointChange;
                changeEventvalue = {
                    unit: e.value,
                    value: this.state.internalValue.value
                };
                this.setState(function (prevState) { return (__assign(__assign({}, prevState), { internalValue: changeEventvalue, selectedUnitLabel: e.label })); });
                onDataPointChange(dataPoint, changeEventvalue);
                return [2 /*return*/];
            });
        }); };
        _this.state = {
            properties: {
                unit: null,
                units: []
            },
            internalValue: { value: null, unit: null },
            selectedUnitLabel: null,
            disabled: false,
            internalError: null
        };
        return _this;
    }
    VisitNumeric.prototype.componentDidMount = function () {
        this.assignInternalValues(this.props);
    };
    VisitNumeric.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var dataPoint = nextProps.dataPoint;
        // TODO: optimize
        if (dataPoint.type === DatapointEnum.computed) {
            return true;
        }
        if (!dataPoint.data) {
            return false;
        }
        if (this.state.internalValue.value !== nextState.internalValue.value ||
            this.state.internalValue.unit !== nextState.internalValue.unit) {
            return true;
        }
        if (this.state.internalValue.value === dataPoint.data.value &&
            this.state.internalValue.unit === dataPoint.data.unit &&
            this.state.internalError === dataPoint.errorMessage) {
            return false;
        }
        this.assignInternalValues(nextProps);
        return true;
    };
    VisitNumeric.prototype.render = function () {
        var _a, _b, _c;
        var _d = this.props, dataPoint = _d.dataPoint, disabled = _d.disabled;
        var _e = this.state, internalValue = _e.internalValue, internalError = _e.internalError, properties = _e.properties, selectedUnitLabel = _e.selectedUnitLabel;
        return (React.createElement("div", { key: dataPoint.slug, style: {
                fontStyle: 'normal',
                fontSize: '12px',
                maxWidth: '180px',
                marginTop: '5px',
                marginRight: '20px'
            } },
            React.createElement(RdiInputLabel, { tooltip: dataPoint.tooltip }, dataPoint.label || ''),
            React.createElement("span", { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    flexBasis: 0,
                    maxWidth: '100%',
                    flexWrap: 'wrap'
                } },
                React.createElement(RdiInput, { type: "number", onChange: this.onChangeValue, placeholder: "Amount", isValid: !internalError, value: dataPoint.calculatedFormula || internalValue.value, disabled: disabled || !!((_a = dataPoint.properties) === null || _a === void 0 ? void 0 : _a.formula), style: { width: '85px', minWidth: '85px' } }),
                ((_b = properties === null || properties === void 0 ? void 0 : properties.units) === null || _b === void 0 ? void 0 : _b.length) === 1 && (React.createElement("div", { style: {
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '14px'
                    } }, selectedUnitLabel)),
                ((_c = properties === null || properties === void 0 ? void 0 : properties.units) === null || _c === void 0 ? void 0 : _c.length) > 1 && (React.createElement(RdiInput, { type: "select", selectedItemLabel: selectedUnitLabel, onChange: this.onChangeUnit, value: internalValue.unit, isValid: !internalError, disabled: disabled, options: properties.units.map(function (item) { return ({
                        value: item.slug,
                        label: item.label || item.slug
                    }); }), style: { marginLeft: '10px', marginBottom: '-14px', width: '85px', minWidth: '85px' } }))),
            !!internalError && (React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.Error }, internalError))));
    };
    return VisitNumeric;
}(Component));
export default VisitNumeric;
