export var RaceEnum;
(function (RaceEnum) {
    RaceEnum["AI_OR_AN"] = "ai-or-an";
    RaceEnum["ASIAN"] = "asian";
    RaceEnum["BLACK"] = "black";
    RaceEnum["HISPANIC"] = "hispanic";
    RaceEnum["NA_OR_AN"] = "na-or-an";
    RaceEnum["NH_OR_PI"] = "nh-or-pi";
    RaceEnum["WHITE"] = "white";
    RaceEnum["MIDDLE_EASTERN"] = "middle-eastern";
    RaceEnum["OTHER"] = "other";
})(RaceEnum || (RaceEnum = {}));
export var RaceLabelsEnum;
(function (RaceLabelsEnum) {
    RaceLabelsEnum["ai-or-an"] = "American Indian or Alaska Native";
    RaceLabelsEnum["asian"] = "Asian";
    RaceLabelsEnum["black"] = "Black or African American";
    RaceLabelsEnum["hispanic"] = "Hispanic or Latino";
    RaceLabelsEnum["na-or-an"] = "Native American or Alaskan Native";
    RaceLabelsEnum["nh-or-pi"] = "Native Hawaiian or Pacific Islander";
    RaceLabelsEnum["white"] = "White or European";
    RaceLabelsEnum["middle-eastern"] = "Middle Easterner and North African";
    RaceLabelsEnum["other"] = "Others";
})(RaceLabelsEnum || (RaceLabelsEnum = {}));
