var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiCircle from '@cros/shared/components/misc/RdiCircle';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { TaskPriorityColorsEnum, TaskPriorityEnum, TaskPriorityLabelsEnum } from '@cros/shared/constants/enums/TaskPriorityEnum';
import { TaskStatusEnum, TaskStatusLabelsEnum } from '@cros/shared/constants/enums/TaskStatusEnum';
import { TaskTypeEnum } from '@cros/shared/constants/enums/TaskTypeEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { formatDatetime } from '@cros/shared/utils/dateUtils';
import { CircularProgress, createStyles, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getPatientRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTaskStore } from '~/stores/TaskStore';
var styles = function () { return createStyles({}); };
var AlarmListPage = /** @class */ (function (_super) {
    __extends(AlarmListPage, _super);
    function AlarmListPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.columns = [
            {
                id: 'title',
                label: 'Alert detail',
                sortable: false,
                width: '20%'
            },
            {
                id: 'patient',
                label: 'Patient',
                sortable: false
            },
            {
                id: 'createdAt',
                label: 'Created',
                sortable: true,
                sortName: 'createdAt'
            },
            {
                id: 'priority',
                label: 'Priority',
                sortable: false
            },
            {
                id: 'asignee',
                label: 'Assignee',
                sortable: false,
                width: '15%'
            },
            {
                id: 'status',
                label: 'Status',
                sortable: false
            }
        ];
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var taskStore;
            return __generator(this, function (_a) {
                taskStore = this.props.taskStore;
                taskStore.changeFilters({
                    type: TaskTypeEnum.ALARM,
                    resolvedById__isnull: true,
                    status: undefined
                });
                return [2 /*return*/];
            });
        }); };
        _this.getNotesRoute = function (taskId) {
            var _a = _this.props, url = _a.match.url, search = _a.location.search;
            return url + "/detail/" + taskId + search;
        };
        return _this;
    }
    AlarmListPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, siteId = _a.siteStore.siteId, _b = _a.taskStore, tasks = _b.tasks, totalItems = _b.totalItems, loading = _b.loading, filters = _b.filters, changeFilters = _b.changeFilters, changeAssignee = _b.changeAssignee, changeStatus = _b.changeStatus, changePriority = _b.changePriority, availableFilters = _b.availableFilters;
        return (React.createElement(React.Fragment, null,
            React.createElement(RdiTable, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeFilters, filters: filters })); }))),
                !loading && (React.createElement(TableBody, null,
                    tasks.map(function (item, index) {
                        var _a, _b;
                        return (React.createElement(TableRow, { key: index, style: {
                                backgroundColor: item.status === TaskStatusEnum.PENDING ? ColorsEnum.BlueSuperlight : undefined
                            } },
                            React.createElement(TableCell, null,
                                React.createElement(FlexContainer, { margin: 10 },
                                    React.createElement(WarningIcon, { style: { color: ColorsEnum.Error, fontSize: '19px' } }),
                                    React.createElement(RdiLink, { to: _this.getNotesRoute(item.id), style: { justifyContent: 'flex-start' } }, item.generatedTitle),
                                    item.unreadNotesCount > 0 && (React.createElement(RdiCircle, { radius: 20, color: ColorsEnum.Blue, fontColor: ColorsEnum.White }, item.unreadNotesCount)))),
                            React.createElement(TableCell, null, item.enrollment && (React.createElement(RdiLink, { style: { justifyContent: 'flex-start' }, to: getPatientRoute(siteId, item.patientId, item.trialId, item.enrollmentId) }, (_a = item.patient) === null || _a === void 0 ? void 0 : _a.fullName))),
                            React.createElement(TableCell, null, formatDatetime(item.createdAt)),
                            React.createElement(TableCell, { style: { padding: '0px' } },
                                React.createElement(RdiInput, { style: { marginBottom: '0px' }, type: "select", permissions: [
                                        {
                                            aclOp: AclOpEnum.UPDATE,
                                            aclModule: AclModuleEnum.TASK
                                        }
                                    ], borderWidth: "0px", placeholder: "Select priority", selectedItemLabel: React.createElement(React.Fragment, null,
                                        React.createElement(FlexContainer, { margin: 10 },
                                            React.createElement(RdiCircle, { radius: 15, color: TaskPriorityColorsEnum[item.priority] }),
                                            React.createElement("span", { style: {
                                                    color: TaskPriorityColorsEnum[item.priority]
                                                } }, TaskPriorityLabelsEnum[item.priority]))), value: item.priority, onChange: function (change) { return changePriority(item, change.value); }, options: Object.values(TaskPriorityEnum).map(function (enumType) { return ({
                                        value: enumType,
                                        label: TaskPriorityLabelsEnum[enumType]
                                    }); }) })),
                            React.createElement(TableCell, { style: { padding: '0px' } },
                                React.createElement(RdiInput, { style: { marginBottom: '0px' }, type: "select", permissions: [
                                        {
                                            aclOp: AclOpEnum.UPDATE,
                                            aclModule: AclModuleEnum.TASK
                                        }
                                    ], name: "assigneeId", borderWidth: "0px", placeholder: "Select assignee", selectedItemLabel: (_b = item.assignee) === null || _b === void 0 ? void 0 : _b.fullName, value: item.assignee ? item.assignee.id : null, onChange: function (change) { return changeAssignee(item, change.value); }, options: availableFilters
                                        ? availableFilters.assigneeId.map(function (a) { return ({
                                            label: a.label,
                                            value: a.slug
                                        }); })
                                        : [] })),
                            React.createElement(TableCell, { style: { padding: '0px' } },
                                React.createElement(RdiInput, { style: { marginBottom: '0px' }, type: "select", permissions: [
                                        {
                                            aclOp: AclOpEnum.UPDATE,
                                            aclModule: AclModuleEnum.TASK
                                        }
                                    ], name: "status", borderWidth: "0px", placeholder: "Select status", selectedItemLabel: TaskStatusLabelsEnum[item.status], value: item.status, onChange: function (change) { return changeStatus(item, change.value); }, options: Object.values(TaskStatusEnum).map(function (enumType) { return ({
                                        value: enumType,
                                        label: TaskStatusLabelsEnum[enumType]
                                    }); }) }))));
                    }),
                    !loading && totalItems === 0 && (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: 7 }, "No results")))))),
            !loading && (React.createElement(RdiTablePagination, { count: totalItems, rowsPerPage: filters.rowsPerPage, page: filters.page, onChangePage: function (e, newPage) { return changeFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                    return changeFilters({
                        rowsPerPage: parseInt(e.target.value, 10),
                        page: 1
                    });
                } })),
            loading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                React.createElement(CircularProgress, null)))));
    };
    AlarmListPage = __decorate([
        injectAccountStore,
        injectTaskStore,
        injectSiteStore,
        observer
    ], AlarmListPage);
    return AlarmListPage;
}(Component));
export default withStyles(styles)(withRouter(AlarmListPage));
