var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AppBar, createStyles, CssBaseline, Drawer, Hidden, Toolbar, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import SizingEnum from "s~/constants/styling/SizingEnum";
import Logo from "s~/static/logo.svg";
var styles = function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return createStyles({
        wrapper: (_a = {},
            _a[theme.breakpoints.up("md")] = {
                minWidth: function (props) {
                    return props.width || SizingEnum.drawerWidth;
                },
                display: "flex"
            },
            _a.overflowY = "auto",
            _a),
        appBar: (_b = {},
            _b[theme.breakpoints.up("md")] = {
                width: function (props) {
                    return "calc(100% - " + (props.width || SizingEnum.drawerWidth) + "px)";
                },
                marginLeft: SizingEnum.drawerWidth
            },
            _b.height = function (props) {
                return props.appBarAnchor === "left" ? "100%" : undefined;
            },
            _b.width = function (props) {
                return props.appBarAnchor === "left" ? "56px" : undefined;
            },
            _b.left = function (props) {
                return props.appBarAnchor === "left" ? "0px" : undefined;
            },
            _b.right = function (props) {
                return props.appBarAnchor === "left" ? "auto" : undefined;
            },
            _b),
        menuButton: (_c = {},
            _c[theme.breakpoints.up("md")] = {
                display: "none"
            },
            _c),
        drawer: (_d = {},
            _d[theme.breakpoints.up("md")] = {
                // width: (props: NavigationDrawerWrapperProps) =>
                //   props.width || SizingEnum.drawerWidth,
                flexShrink: 0,
                flex: "1"
            },
            _d),
        drawerPaper: (_e = {},
            // width: (props: NavigationDrawerWrapperProps) =>
            //   props.width || SizingEnum.drawerWidth,
            _e[theme.breakpoints.up("md")] = {
                position: function (props) {
                    return props.detached ? "relative" : undefined;
                }
            },
            _e.boxSizing = "content-box",
            _e.overflowX = "hidden",
            _e),
        toolbar: (_f = {},
            _f[theme.breakpoints.up("sm")] = {
                backgroundColor: ColorsEnum.BlackSuperlight
            },
            _f[theme.breakpoints.down("xs")] = {
                backgroundColor: ColorsEnum.White
            },
            _f.borderBottom = function (props) {
                return props.appBarAnchor !== "left"
                    ? "1px solid rgba(0, 0, 0, 0.12)"
                    : undefined;
            },
            _f.borderRight = function (props) {
                return props.appBarAnchor === "left"
                    ? "1px solid rgba(0, 0, 0, 0.12)"
                    : undefined;
            },
            _f.display = "flex",
            _f.justifyContent = function (props) {
                return props.appBarAnchor === "left" ? "center" : "space-between";
            },
            _f.padding = function (props) {
                return props.appBarAnchor === "left" ? "20px 0px" : "0px 20px";
            },
            _f.height = "100%",
            _f.alignItems = function (props) {
                return props.appBarAnchor === "left" ? "flex-start" : undefined;
            },
            _f),
        logo: {
            marginTop: "4px",
            width: "94px"
        }
    });
};
var NavigationDrawerWrapper = /** @class */ (function (_super) {
    __extends(NavigationDrawerWrapper, _super);
    function NavigationDrawerWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mobileOpen = false;
        _this.handleDrawerToggle = function () {
            _this.mobileOpen = !_this.mobileOpen;
        };
        return _this;
    }
    NavigationDrawerWrapper.prototype.render = function () {
        var _a = this.props, classes = _a.classes, NavigationDrawer = _a.NavigationDrawer, anchor = _a.anchor, appBarAnchor = _a.appBarAnchor, drawerProps = _a.drawerProps;
        return (React.createElement("div", { className: classes.wrapper },
            React.createElement(CssBaseline, null),
            React.createElement(Hidden, { smDown: true },
                React.createElement(Drawer, { variant: "permanent", anchor: anchor || "left", className: "" + classes.drawer, classes: {
                        paper: classes.drawerPaper
                    } },
                    React.createElement(NavigationDrawer, __assign({ handleDrawerToggle: this.handleDrawerToggle }, drawerProps)))),
            React.createElement(Hidden, { mdUp: true },
                React.createElement(AppBar, { position: "fixed", className: classes.appBar, color: "default" },
                    React.createElement(Toolbar, { className: classes.toolbar },
                        appBarAnchor !== "left" && (React.createElement(Link, { to: "/", className: classes.logo },
                            React.createElement("img", { alt: "img", src: Logo }))),
                        React.createElement(IconButton, { color: "inherit", "aria-label": "open drawer", edge: "start", onClick: this.handleDrawerToggle, className: classes.menuButton, size: "small" },
                            React.createElement(MenuIcon, null)))),
                React.createElement(Drawer, { variant: "temporary", anchor: anchor || "right", open: this.mobileOpen, onClose: this.handleDrawerToggle, classes: {
                        paper: classes.drawerPaper
                    }, ModalProps: {
                        keepMounted: true // Better open performance on mobile.
                    } },
                    React.createElement(NavigationDrawer, __assign({ handleDrawerToggle: this.handleDrawerToggle }, drawerProps))))));
    };
    __decorate([
        observable
    ], NavigationDrawerWrapper.prototype, "mobileOpen", void 0);
    NavigationDrawerWrapper = __decorate([
        observer
    ], NavigationDrawerWrapper);
    return NavigationDrawerWrapper;
}(Component));
export default withStyles(styles)(NavigationDrawerWrapper);
