export var BillStatusEnum;
(function (BillStatusEnum) {
    BillStatusEnum["OPEN"] = "open";
    BillStatusEnum["CLOSED"] = "closed";
})(BillStatusEnum || (BillStatusEnum = {}));
export var BillStatusLabelsEnum;
(function (BillStatusLabelsEnum) {
    BillStatusLabelsEnum["open"] = "Open";
    BillStatusLabelsEnum["closed"] = "Closed";
})(BillStatusLabelsEnum || (BillStatusLabelsEnum = {}));
