var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import RdiTypography from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
var checkBoxLabelStyle = {
    lineHeight: "24px"
};
var RdiCheckbox = function (props) {
    var onChange = function (event, checked) {
        if (props.onChange) {
            props.onChange(checked);
        }
    };
    return (React.createElement(FlexContainer, { style: __assign({ marginBottom: "16px" }, props.containerStyle), alignItems: "flex-start" },
        React.createElement(FormControlLabel, { disabled: props.disabled, control: React.createElement(Checkbox, { style: __assign(__assign({}, props.style), { marginRight: "11px" }), value: props.value, color: "primary", checked: props.value, onChange: onChange, disabled: props.disabled, name: props.name }), label: React.createElement(RdiTypography, { variant: "body2", style: checkBoxLabelStyle }, props.children) })));
};
RdiCheckbox.defaultProps = { value: false };
export default RdiCheckbox;
