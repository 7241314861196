var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { Card, CardContent } from '@material-ui/core';
import React from 'react';
var PatientCardContainer = function (props) { return (React.createElement(Card, { style: __assign({ minHeight: '154px', marginTop: '20px', backgroundColor: ColorsEnum.White, boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.2), 0px 1px 5px 0px rgba(0,0,0,0.02), 0px 1px 3px 0px rgba(0,0,0,0.02)' }, props.style) },
    React.createElement(CardContent, { style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '18px',
            height: '100%',
            minHeight: '154px'
        } }, props.children))); };
export default PatientCardContainer;
