import { uuid } from "s~/utils/uuid";
export var DEFAULT_TOAST_TIMEOUT = 6000;
var ToastMessage = /** @class */ (function () {
    function ToastMessage(message, toastTimeout, toastType) {
        if (toastTimeout === void 0) { toastTimeout = DEFAULT_TOAST_TIMEOUT; }
        if (toastType === void 0) { toastType = "success"; }
        this.message = message;
        this.toastTimeout = toastTimeout;
        this.toastType = toastType;
        this.id = uuid();
    }
    return ToastMessage;
}());
export { ToastMessage };
