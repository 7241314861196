import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import React from 'react';
import PatientCard from '~/components/patient/PatientCardContainer';
var PatientNumberCard = function (props) {
    var enrollment = props.enrollment;
    return (React.createElement(PatientCard, null,
        React.createElement(FlexContainer, { justifyContent: "space-between" },
            React.createElement(RdiTypography, { variant: "subtitle2" }, "PATIENT NUMBER")),
        React.createElement("div", { style: {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            } },
            React.createElement(RdiTypography, { style: { marginBottom: '0px', wordWrap: 'break-word' }, variant: "h1" }, enrollment.patientNumber || 'Patient number missing'))));
};
export default PatientNumberCard;
