var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import autoSaveConfig from '@cros/shared/config/autoSaveConfig';
import debounce from 'lodash/debounce';
import { observable } from 'mobx';
import { inject } from 'mobx-react';
import { ClaimStore } from '~/stores/ClaimStore';
import { PaymentStore } from '~/stores/PaymentStore';
var TransactionStore = /** @class */ (function () {
    function TransactionStore(siteStore, searchString, history) {
        var _this = this;
        this.siteStore = siteStore;
        this.searchString = searchString;
        this.history = history;
        this.transactions = [];
        this.paymentsCount = 0;
        this.paymentsStatusCounts = {};
        this.filters = {
            search: '',
            fromDate: null,
            toDate: null
        };
        this.filterLabels = {};
        this.loading = false;
        this.changeFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.filters = __assign(__assign({}, this.filters), newFilters);
                        this.filterLabels = __assign(__assign({}, this.filterLabels), newLabels);
                        if (!!('search' in newFilters)) return [3 /*break*/, 3];
                        if (!((this.filters.fromDate && this.filters.toDate) ||
                            (!this.filters.fromDate && !this.filters.toDate))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.changeChildFilters()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        this.searchDebounce.cancel();
                        this.searchDebounce();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.changeChildFilters = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.paymentStore.changePatientPaymentFilters(this.filters)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.paymentStore.changePaymentFilters(this.filters)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.claimStore.changeFilters(this.filters)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.searchDebounce = debounce(this.changeChildFilters, autoSaveConfig.autoSaveDelay);
        this.paymentStore = new PaymentStore(this, this.siteStore, searchString, history);
        this.claimStore = new ClaimStore(this, this.siteStore, searchString, history);
    }
    __decorate([
        observable
    ], TransactionStore.prototype, "paymentStore", void 0);
    __decorate([
        observable
    ], TransactionStore.prototype, "claimStore", void 0);
    __decorate([
        observable
    ], TransactionStore.prototype, "transactions", void 0);
    __decorate([
        observable
    ], TransactionStore.prototype, "paymentsCount", void 0);
    __decorate([
        observable
    ], TransactionStore.prototype, "paymentsStatusCounts", void 0);
    __decorate([
        observable
    ], TransactionStore.prototype, "filters", void 0);
    __decorate([
        observable
    ], TransactionStore.prototype, "filterLabels", void 0);
    __decorate([
        observable
    ], TransactionStore.prototype, "loading", void 0);
    return TransactionStore;
}());
export { TransactionStore };
export var injectTransactionStore = inject('transactionStore');
