var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import { DatapointTemplateEnum } from '@cros/shared/constants/enums/DatapointTemplateEnum';
import { DatapointWrapTypeEnum } from '@cros/shared/constants/enums/DatapointWrapTypeEnum';
import React, { Component } from 'react';
var VisitNote = /** @class */ (function (_super) {
    __extends(VisitNote, _super);
    function VisitNote(props) {
        var _this = _super.call(this, props) || this;
        _this.assignInternalValues = function (props) {
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { internalValue: props.dataPoint.data.value, internalDisabled: props.disabled, internalError: props.dataPoint.errorMessage })); });
        };
        _this.internalChange = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onDataPointChange, dataPoint, val;
            return __generator(this, function (_b) {
                _a = this.props, onDataPointChange = _a.onDataPointChange, dataPoint = _a.dataPoint;
                val = e.value;
                this.setState(function (prevState) { return (__assign(__assign({}, prevState), { internalValue: val })); });
                onDataPointChange(dataPoint, { value: val });
                return [2 /*return*/];
            });
        }); };
        _this.state = {
            internalValue: '',
            internalDisabled: false,
            internalError: null
        };
        return _this;
    }
    VisitNote.prototype.componentDidMount = function () {
        this.assignInternalValues(this.props);
    };
    VisitNote.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        if (nextState.internalValue !== this.state.internalValue) {
            return true;
        }
        if (this.state.internalValue === nextProps.dataPoint.data.value &&
            this.state.internalDisabled === nextProps.disabled &&
            this.state.internalError === nextProps.dataPoint.errorMessage) {
            return false;
        }
        this.assignInternalValues(nextProps);
        return true;
    };
    VisitNote.prototype.render = function () {
        var _a = this.props, dataPoint = _a.dataPoint, assistiveText = _a.assistiveText;
        var _b = this.state, internalValue = _b.internalValue, internalError = _b.internalError, internalDisabled = _b.internalDisabled;
        return (React.createElement(RdiInput, { type: dataPoint.template_name === DatapointTemplateEnum.textbox ? 'text_multiline' : 'text', label: dataPoint.label, tooltip: dataPoint.tooltip, value: internalValue, fullWidth: dataPoint.template_name === DatapointTemplateEnum.textbox, assistiveText: assistiveText, errorText: internalError, isValid: !internalError, onChange: this.internalChange, disabled: internalDisabled, placeholder: dataPoint.template_meta ? dataPoint.template_meta.placeholder : undefined, style: {
                marginTop: '10px',
                marginRight: dataPoint.template_meta &&
                    dataPoint.template_meta.global_wrap === DatapointWrapTypeEnum.nowrap
                    ? '20px'
                    : '0px'
            } }));
    };
    return VisitNote;
}(Component));
export default VisitNote;
