export var ChildbearingPotentialEnum;
(function (ChildbearingPotentialEnum) {
    ChildbearingPotentialEnum["YES"] = "yes";
    ChildbearingPotentialEnum["NO"] = "no";
})(ChildbearingPotentialEnum || (ChildbearingPotentialEnum = {}));
export var ChildbearingPotentialLabelsEnum;
(function (ChildbearingPotentialLabelsEnum) {
    ChildbearingPotentialLabelsEnum["yes"] = "Yes";
    ChildbearingPotentialLabelsEnum["no"] = "No";
})(ChildbearingPotentialLabelsEnum || (ChildbearingPotentialLabelsEnum = {}));
