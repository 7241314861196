export var PhonePlatformEnum;
(function (PhonePlatformEnum) {
    PhonePlatformEnum["ANDROID"] = "android";
    PhonePlatformEnum["IOS"] = "ios";
    PhonePlatformEnum["OTHER"] = "other";
})(PhonePlatformEnum || (PhonePlatformEnum = {}));
export var PhonePlatformLabelsEnum;
(function (PhonePlatformLabelsEnum) {
    PhonePlatformLabelsEnum["android"] = "Android";
    PhonePlatformLabelsEnum["ios"] = "iOS";
    PhonePlatformLabelsEnum["other"] = "Other";
})(PhonePlatformLabelsEnum || (PhonePlatformLabelsEnum = {}));
