var ColorsEnum;
(function (ColorsEnum) {
    ColorsEnum["Primary"] = "#46AAC8";
    ColorsEnum["BlackLight"] = "#E4E4E4";
    ColorsEnum["BlackLightToMedium"] = "#ccc";
    ColorsEnum["BlackMedium"] = "#9F9F9F";
    ColorsEnum["Black"] = "#7A7A7A";
    ColorsEnum["BlackSemidark"] = "rgba(0, 0, 0, 0.6)";
    ColorsEnum["Error"] = "#FF3061";
    ColorsEnum["Success"] = "#B0D990";
    ColorsEnum["BlackSuperlight"] = "#F8F8F8";
    ColorsEnum["White"] = "#FFFFFF";
    ColorsEnum["BlackGrey"] = "#888888";
    ColorsEnum["InputBorder"] = "rgba(25, 25, 25, 0.15)";
    ColorsEnum["TrueBlack"] = "rgba(0, 0, 0, 0.87)";
    ColorsEnum["IconsGreen"] = "#B0D991";
    ColorsEnum["PaperBackground"] = "#FAFAFA";
    ColorsEnum["Blue"] = "rgba(70,170,200,0.85)";
    ColorsEnum["BlueSuperlight"] = "rgb(236, 247, 249)";
    ColorsEnum["BlueDark"] = "#296094";
    ColorsEnum["Placeholder"] = "#BDBDBD";
    ColorsEnum["GreyBorder"] = "rgba(224, 224, 224, 1)";
    ColorsEnum["GreyBG"] = "#FAFBFC";
    ColorsEnum["GreySuperlightLine"] = "#e4e4e4";
})(ColorsEnum || (ColorsEnum = {}));
export default ColorsEnum;
