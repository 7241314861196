var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { CircularProgress, IconButton, Menu, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { getPatientOverviewRoute, getTrialRoute } from '~/routing/Routes';
import DashboardFilesStore from '~/stores/DashboardFilesStore';
import PatientListStore from '~/stores/PatientListStore';
import { injectSiteStore } from '~/stores/SiteStore';
var PatientsTable = function (_a) {
    var fileId = _a.fileId, sharedList = _a.sharedList, onUpdate = _a.onUpdate, siteStore = _a.siteStore;
    var columns = [
        {
            id: 'fullName',
            label: 'Patient',
            sortable: false
        },
        {
            id: 'email',
            label: 'Email',
            sortable: false
        },
        {
            id: 'trials',
            label: 'Active trials',
            sortable: false
        },
        {
            id: 'shared',
            label: 'Shared',
            sortable: false
        }
    ];
    var siteId = siteStore.siteId, trials = siteStore.trials, trialsLoading = siteStore.trialsLoading;
    var patientListStore = useState(new PatientListStore(siteStore))[0];
    var dashboardFileStore = useState(new DashboardFilesStore(siteStore))[0];
    var _b = useState(null), menuRef = _b[0], setMenuRef = _b[1];
    var _c = useState(null), currentPatient = _c[0], setCurrentPatient = _c[1];
    var patients = patientListStore.patients, changePatientFilters = patientListStore.changePatientFilters, patientsLoading = patientListStore.patientsLoading, patientFilters = patientListStore.patientFilters, patientsCount = patientListStore.patientsCount;
    var handleMenuClick = function (event, patient) {
        setMenuRef(event.currentTarget);
        setCurrentPatient(patient);
    };
    var fetchTrialPatients = function () { return __awaiter(void 0, void 0, void 0, function () {
        var getPatients, getPatientFilters;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    getPatients = patientListStore.getPatients, getPatientFilters = patientListStore.getPatientFilters;
                    return [4 /*yield*/, getPatients()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getPatientFilters()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onShareClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!sharedList.includes(currentPatient === null || currentPatient === void 0 ? void 0 : currentPatient.id)) return [3 /*break*/, 2];
                    return [4 /*yield*/, dashboardFileStore.shareFile(fileId, currentPatient.id)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, dashboardFileStore.unshareFile(fileId, currentPatient.id)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, onUpdate()];
                case 5:
                    _a.sent();
                    setMenuRef(null);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchTrialPatients();
    }, []);
    var changeSearchString = function (e) {
        changePatientFilters({
            page: 1,
            search: e.value
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(RdiInput, { type: "search", placeholder: "Search patients", value: patientFilters.search, onChange: changeSearchString }),
        React.createElement("br", null),
        React.createElement(RdiTable, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column })); }))),
            !trialsLoading && (React.createElement(TableBody, null, patients.map(function (row, index) {
                return (React.createElement(TableRow, { hover: true, tabIndex: -1, key: index },
                    React.createElement(TableCell, null,
                        React.createElement(RdiLink, { to: getPatientOverviewRoute(siteId, row.id) }, row.fullName)),
                    React.createElement(TableCell, null, row.email),
                    React.createElement(TableCell, null, (trials === null || trials === void 0 ? void 0 : trials.length) > 0 &&
                        row.enrollments.map(function (e, innerIndex) {
                            var _a, _b, _c;
                            return ((_b = (_a = trials.find(function (t) { return t.id === e.trialId; })) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.trim()) && (React.createElement(RdiLink, { key: innerIndex, to: getTrialRoute(e.siteId, e.trialId) }, (_c = trials.find(function (t) { return t.id === e.trialId; })) === null || _c === void 0 ? void 0 :
                                _c.name,
                                row.enrollments.length === innerIndex + 1 ? '' : ', '));
                        })),
                    React.createElement(TableCell, null, sharedList.includes(row.id) ? 'Yes' : 'No'),
                    React.createElement(TableCell, { style: { padding: '0px' } },
                        React.createElement("div", { style: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            } },
                            React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return handleMenuClick(event, row); } },
                                React.createElement(MoreVertIcon, null))))));
            }))),
            !patientsLoading && patientsCount === 0 && (React.createElement(TableRow, null,
                React.createElement(TableCell, { colSpan: 5 }, "No results")))),
        trialsLoading && (React.createElement(FlexContainer, { style: { marginTop: '45px' }, alignItems: "center", justifyContent: "center" },
            React.createElement(CircularProgress, null))),
        React.createElement(Menu, { className: "editMenuDropdown", anchorEl: menuRef, keepMounted: true, open: !!menuRef, onClose: function () {
                setMenuRef(null);
            }, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
            React.createElement(RdiMenuItem, { onClick: onShareClick },
                React.createElement(RdiTypography, { variant: "body2" }, sharedList.includes(currentPatient === null || currentPatient === void 0 ? void 0 : currentPatient.id) ? 'Unshare' : 'Share')))));
};
export default injectSiteStore(observer(PatientsTable));
