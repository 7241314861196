var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormHelperText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { RdiInputWrapper } from "s~/components/forms/RdiInput";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import RdiInputLabel from "./RdiInputLabel";
export var RdiMultipleChoiceInput = function (props) {
    var isValid = props.isValid, label = props.label, tooltip = props.tooltip, value = props.value, placeholder = props.placeholder, disabled = props.disabled, options = props.options, borderWidth = props.borderWidth, errorText = props.errorText, onChange = props.onChange, style = props.style, name = props.name, permissions = props.permissions, aclCheck = props.aclCheck;
    var _a = useState(disabled), internalDisabled = _a[0], setInternalDisabled = _a[1];
    useEffect(function () {
        if ((aclCheck && !aclCheck(permissions)) || disabled) {
            setInternalDisabled(true);
        }
        else {
            setInternalDisabled(false);
        }
    }, [disabled, permissions]);
    return (React.createElement(RdiInputWrapper, __assign({}, props),
        React.createElement(RdiInputLabel, { tooltip: tooltip }, label),
        React.createElement(Select, { isMulti: true, name: name, className: "" + name, classNamePrefix: "react-select", value: value, placeholder: placeholder, isDisabled: internalDisabled, onChange: function (e) { return onChange({ value: e || [] }); }, multiValueRemove: function (e) {
                return onChange(__assign(__assign({}, e), { remove: true }));
            }, options: options, menuPlacement: "auto", menuPortalTarget: document.body, components: { ClearIndicator: function () { return null; } }, theme: function (theme) { return (__assign(__assign({}, theme), { borderRadius: 0, colors: __assign(__assign({}, theme.colors), { primary25: ColorsEnum.BlackLight, primary: ColorsEnum.Blue }) })); }, styles: __assign(__assign({}, style), { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 9999 })); }, menu: function (provided) { return (__assign({}, provided)); }, option: function (provided) { return (__assign({}, provided)); }, control: function (provided) { return (__assign(__assign(__assign({}, provided), { minHeight: "44px", borderWidth: borderWidth, backgroundColor: "transparent" }), ((!isValid || !!errorText) && { borderColor: "red" }))); }, container: function (provided) { return (__assign(__assign({}, provided), { minHeight: "44px", width: "100%" })); }, indicatorSeparator: function () { return ({
                    display: "none"
                }); } }) }),
        !!errorText && (React.createElement(FormHelperText, { variant: "filled", error: true }, errorText))));
};
