import React from "react";
import RdiButton from "s~/components/forms/RdiButton";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import SmallModal from "./SmallModal";
var ConfirmationModal = function (props) {
    return (React.createElement(SmallModal, { onClose: props.onClose, open: props.open, className: "confirmationModal" },
        React.createElement(RdiTypography, { variant: "h2", style: { marginBottom: "25px" }, color: TypographyColorsEnum.Primary }, props.title),
        React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: "40px" } }, props.content),
        React.createElement(FlexContainer, { justifyContent: "space-between", margin: 30 },
            React.createElement(RdiButton, { onClick: props.onClose, fullWidth: true, name: "no", margin: "none" }, "NO"),
            React.createElement(RdiButton, { onClick: props.onConfirm, loading: props.loading, fullWidth: true, style: {
                    backgroundColor: ColorsEnum.IconsGreen
                }, name: "yes", margin: "none" }, "YES"))));
};
ConfirmationModal.defaultProps = {
    title: "Confirmation required"
};
export default ConfirmationModal;
