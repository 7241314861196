var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import DateFnsUtils from "@date-io/date-fns";
import { IconButton, makeStyles } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import { addDays, addMonths, addWeeks, addYears, endOfWeek, format, getYear, isSameDay, isValid, isWithinInterval, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import React, { useEffect, useState } from "react";
import { RdiInputWrapper } from "s~/components/forms/RdiInput";
import FlexContainer from "s~/components/misc/FlexContainer";
import { DateSelectorStateEnum } from "s~/constants/enums/DateSelectorStateEnum";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import { formatDate, formatMonthYear, getZeroPaddedMonth, parsePartialDate } from "s~/utils/dateUtils";
import RdiInputLabel from "./RdiInputLabel";
var styles = makeStyles(function (theme) { return ({
    datepicker: {
        minWidth: "215px",
        marginBottom: function (prop) {
            return prop.margin === "none" ? undefined : "14px";
        },
        "& fieldset": {
            borderWidth: function (prop) {
                return prop.borderWidth + "!important";
            }
        },
        "& input": {
            textAlign: function (prop) {
                return prop.showLeftRightNavigation ? "center" : "left";
            },
            cursor: "pointer",
            "&:hover": {
                color: ColorsEnum.Blue
            }
        }
    },
    dayWrapper: {
        position: "relative"
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit"
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: "1px solid " + theme.palette.secondary.main,
        borderRadius: "50%"
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled
    },
    highlightNonCurrentMonthDay: {
        color: "#676767"
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%"
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%"
    }
}); });
export var RdiDateSelectorInput = function (props) {
    var value = props.value, config = props.config;
    var _a = useState(new Date()), internalValue = _a[0], setInternalValue = _a[1];
    useEffect(function () {
        if (props.usePartials) {
            if (!value) {
                return;
            }
            var d = new Date();
            var _a = parsePartialDate(value), month = _a.month, year = _a.year;
            switch (props.selectorState) {
                case DateSelectorStateEnum.month:
                    d.setFullYear(year);
                    d.setMonth(month - 1);
                    setInternalValue(d);
                    break;
                default:
                    break;
            }
        }
        setInternalValue(value);
    }, [props.selectorState, props.usePartials, value]);
    var useStyles = styles;
    var classes = useStyles(props);
    var renderLabel = function (date, invalidLabel) {
        if (!isValid(date)) {
            return invalidLabel;
        }
        switch (props.selectorState) {
            case DateSelectorStateEnum.day:
                return "" + formatDate(date);
            case DateSelectorStateEnum.month:
                return "" + formatMonthYear(startOfMonth(date));
            case DateSelectorStateEnum.week:
                return "Week of " + formatDate(startOfWeek(date, { weekStartsOn: config === null || config === void 0 ? void 0 : config.weekStartsOn }));
            case DateSelectorStateEnum.year:
                return "" + format(startOfYear(date), "yyyy");
            default:
                return "";
        }
    };
    var renderDate = function (date, selectedDate, dayInCurrentMonth) {
        var _a, _b;
        var dateClone = new Date(date);
        var selectedDateClone = new Date(selectedDate);
        var start;
        var end;
        switch (props.selectorState) {
            case DateSelectorStateEnum.day:
            case DateSelectorStateEnum.month:
            case DateSelectorStateEnum.year:
                start = selectedDateClone;
                end = selectedDateClone;
                break;
            case DateSelectorStateEnum.week:
                start = startOfWeek(selectedDateClone, {
                    weekStartsOn: config === null || config === void 0 ? void 0 : config.weekStartsOn
                });
                end = endOfWeek(selectedDateClone, {
                    weekStartsOn: config === null || config === void 0 ? void 0 : config.weekStartsOn
                });
                break;
            default:
                break;
        }
        var dayIsBetween = isWithinInterval(dateClone, { start: start, end: end });
        var isFirstDay = isSameDay(dateClone, start);
        var isLastDay = isSameDay(dateClone, end);
        var wrapperClassName = clsx((_a = {},
            _a[classes.highlight] = dayIsBetween,
            _a[classes.firstHighlight] = isFirstDay,
            _a[classes.endHighlight] = isLastDay,
            _a));
        var dayClassName = clsx(classes.day, (_b = {},
            _b[classes.nonCurrentMonthDay] = !dayInCurrentMonth,
            _b[classes.highlightNonCurrentMonthDay] = !dayInCurrentMonth && dayIsBetween,
            _b));
        return (React.createElement("div", { className: wrapperClassName },
            React.createElement(IconButton, { className: dayClassName },
                React.createElement("span", null,
                    " ",
                    format(dateClone, "d"),
                    " "))));
    };
    var getView = function () {
        switch (props.selectorState) {
            case DateSelectorStateEnum.day:
                return ["year", "month", "date"];
            case DateSelectorStateEnum.month:
                return ["month", "year"];
            case DateSelectorStateEnum.week:
                return ["year", "month", "date"];
            case DateSelectorStateEnum.year:
                return ["year"];
            default:
                return ["year", "month", "date"];
        }
    };
    var onChange = function (ret) {
        if (props.usePartials) {
            switch (props.selectorState) {
                case DateSelectorStateEnum.month:
                    props.onChange({
                        value: getYear(new Date(ret.value)) + "-" + getZeroPaddedMonth(new Date(ret.value))
                    });
                    return;
                default:
                    break;
            }
        }
        props.onChange(ret);
    };
    var changeDate = function (multiplier) {
        var date = internalValue;
        switch (props.selectorState) {
            case DateSelectorStateEnum.day:
                date = addDays(date, multiplier * 1);
                break;
            case DateSelectorStateEnum.month:
                date = addMonths(date, multiplier * 1);
                break;
            case DateSelectorStateEnum.week:
                date = addWeeks(date, multiplier * 1);
                break;
            case DateSelectorStateEnum.year:
                date = addYears(date, multiplier * 1);
                break;
            default:
                break;
        }
        onChange({ value: date });
    };
    return (React.createElement(RdiInputWrapper, __assign({}, props, { style: __assign({ marginBottom: "0px" }, props.style) }),
        React.createElement(RdiInputLabel, null, props.label),
        React.createElement(FlexContainer, { justifyContent: "center", alignItems: "center" },
            props.showLeftRightNavigation && (React.createElement(ArrowBack, { style: { cursor: "pointer" }, onClick: function () { return changeDate(-1); } })),
            React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                React.createElement(DatePicker
                // firstDayOfWeek={1}
                , { 
                    // firstDayOfWeek={1}
                    views: getView(), margin: "normal", label: "", format: "MM/dd/yyyy", value: internalValue, onChange: function (e) { return onChange({ value: e }); }, inputVariant: "outlined", disabled: props.disabled ? props.disabled : false, style: {
                        marginBottom: "0px",
                        width: props.fullWidth ? "100%" : "fit-content"
                    }, InputProps: {
                        className: classes.datepicker
                    }, renderDay: renderDate, labelFunc: renderLabel })),
            props.showLeftRightNavigation && (React.createElement(ArrowForward, { style: { cursor: "pointer" }, onClick: function () { return changeDate(1); } })))));
};
