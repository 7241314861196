var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { getDateFromTimeAndDate } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { Button, createStyles, withStyles } from '@material-ui/core';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
import VisitNotificationsInfo from './visit/VisitNotificationsInfo';
var styles = function () { return createStyles({}); };
var RescheduleVisitModal = /** @class */ (function (_super) {
    __extends(RescheduleVisitModal, _super);
    function RescheduleVisitModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errorMessage = '';
        _this.hasErrors = false;
        _this.saving = false;
        _this.visit = null;
        _this.reminders = null;
        _this.data = {
            date: {
                value: null,
                error: null,
                rule: 'required|date'
            },
            time: {
                value: null,
                error: null,
                rule: 'required|date'
            },
            engagementDuration: {
                value: 30,
                error: null,
                rule: 'required'
            }
        };
        _this.assignReminderData = function (visit) {
            _this.reminders = {
                initialEmailReminder: visit.initialEmailReminder,
                initialSmsReminder: visit.initialSmsReminder,
                secondEmailReminder: visit.secondEmailReminder,
                secondSmsReminder: visit.secondSmsReminder
            };
            // convert date strings to Date objects
            Object.keys(_this.reminders).forEach(function (key) {
                var _a;
                if ((_a = _this.reminders[key]) === null || _a === void 0 ? void 0 : _a.sentAt)
                    _this.reminders[key].sentAt = new Date(_this.reminders[key].sentAt);
            });
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var enrollmentStore, visitId, visit;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        enrollmentStore = this.props.enrollmentStore;
                        visitId = this.visitId;
                        return [4 /*yield*/, enrollmentStore.visitListStore.getVisitById(visitId)];
                    case 1:
                        visit = _a.sent();
                        if (!visit) {
                            return [2 /*return*/];
                        }
                        this.assignReminderData(visit);
                        this.visit = visit;
                        this.data.date.value = visit.engagement;
                        this.data.time.value = visit.engagement;
                        this.data.engagementDuration.value = differenceInMinutes(visit.engagementEnd, visit.engagement);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, onClose = _a.onClose, url = _a.match.url;
            if (onClose) {
                onClose();
            }
            history.push("" + url.split('/schedule')[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.onSubmit = function (e, hasSendNotifications) { return __awaiter(_this, void 0, void 0, function () {
            var _a, enrollmentStore, visitStore, visitId, engagement, engagementEnd, newDate, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, enrollmentStore = _a.enrollmentStore, visitStore = _a.visitStore, visitId = _a.match.params.visitId;
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        this.saving = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        engagement = getDateFromTimeAndDate(this.data.time.value, this.data.date.value);
                        engagementEnd = addMinutes(engagement, this.data.engagementDuration.value);
                        return [4 /*yield*/, enrollmentStore.visitListStore.rescheduleVisit(visitId, engagement, engagementEnd, hasSendNotifications)];
                    case 2:
                        newDate = _b.sent();
                        // visit store will be defined only if we're opening this modal inside visit modal
                        if (visitStore) {
                            visitStore.rescheduleVisit(newDate);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 4:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 5:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, _this.mapFieldToInternal, validationErrors, true);
        };
        _this.mapFieldToInternal = function (fieldName) {
            switch (fieldName) {
                case 'engagement':
                    return 'date';
                default:
                    return fieldName;
            }
        };
        _this.onEngagementDurationChange = function (e) {
            // eslint-disable-next-line no-restricted-globals
            if (e.value >= 0 || isNaN(e.value)) {
                _this.fieldChange(e, 'engagementDuration');
            }
        };
        _this.onDurationButtonClick = function (isIncrement) {
            if (isIncrement) {
                _this.data.engagementDuration.value += 30;
            }
            else if (_this.data.engagementDuration.value >= 30) {
                _this.data.engagementDuration.value -= 30;
            }
        };
        return _this;
    }
    Object.defineProperty(RescheduleVisitModal.prototype, "visitId", {
        get: function () {
            var visitId = this.props.match.params.visitId;
            return visitId;
        },
        enumerable: false,
        configurable: true
    });
    RescheduleVisitModal.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var patient = this.props.patientStore.patient;
        return (React.createElement(SmallModal, { onClose: this.onClose },
            React.createElement("form", { noValidate: true, autoComplete: "off" },
                React.createElement("div", null,
                    React.createElement(RdiTypography, { variant: "h1" }, (((_a = this.visit) === null || _a === void 0 ? void 0 : _a.engagement) ? 'Reschedule' : 'Schedule') + " visit " + ((_b = this.visit) === null || _b === void 0 ? void 0 : _b.name)),
                    React.createElement(FlexContainer, { margin: 30, style: { marginBottom: '30px' } },
                        React.createElement(RdiTypography, { variant: "caption" }, patient.fullName),
                        React.createElement(RdiTypography, { variant: "caption" }, "Patient ID: " + patient.id))),
                React.createElement(FlexContainer, { margin: 30, style: { alignItems: 'flex-start' }, flexWrap: "wrap" },
                    React.createElement(RdiInput, { type: "date", label: "Date", value: this.data.date.value, errorText: this.data.date.error, onChange: function (e) { return _this.fieldChange(e, 'date'); } })),
                React.createElement(FlexContainer, { margin: 30, style: { alignItems: 'flex-start' }, flexWrap: "wrap" },
                    React.createElement(RdiInput, { type: "time", label: "Time", value: this.data.time.value, errorText: this.data.time.error, onChange: function (e) { return _this.fieldChange(e, 'time'); }, style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(RdiInput, { type: "number", label: "Visit duration (minutes)", name: "engagementDuration", value: this.data.engagementDuration.value, errorText: this.data.engagementDuration.error, style: { width: 'calc(50% - 15px)' }, onChange: this.onEngagementDurationChange })),
                React.createElement(FlexContainer, { margin: 30, alignItems: "flex-start", style: { flexWrap: 'wrap' } },
                    React.createElement("div", { style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(FlexContainer, { justifyContent: "space-between", style: { width: 'calc(50% - 15px)' } },
                        React.createElement(Button, { variant: "outlined", onClick: function () { return _this.onDurationButtonClick(false); } }, "- 30 min"),
                        React.createElement(Button, { variant: "outlined", onClick: function () { return _this.onDurationButtonClick(true); } }, "+ 30 min"))),
                React.createElement(RdiInputLabel, { label: "Notifications" }),
                React.createElement(VisitNotificationsInfo, { reminders: this.reminders, engagement: getDateFromTimeAndDate(this.data.time.value, this.data.date.value) }),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement("div", null,
                    React.createElement(RdiLine, null),
                    React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                        !((_c = this.reminders) === null || _c === void 0 ? void 0 : _c.initialEmailReminder) && (React.createElement(RdiButton, { onClick: function (e) { return _this.onSubmit(e, false); }, loading: this.saving, margin: "none" }, "Save")),
                        React.createElement(RdiButton, { onClick: function (e) { return _this.onSubmit(e, true); }, loading: this.saving, margin: "none" }, "Save and notify"))))));
    };
    __decorate([
        observable
    ], RescheduleVisitModal.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], RescheduleVisitModal.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], RescheduleVisitModal.prototype, "saving", void 0);
    __decorate([
        observable
    ], RescheduleVisitModal.prototype, "visit", void 0);
    __decorate([
        observable
    ], RescheduleVisitModal.prototype, "data", void 0);
    RescheduleVisitModal = __decorate([
        injectPatientStore,
        injectEnrollmentStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], RescheduleVisitModal);
    return RescheduleVisitModal;
}(Component));
export default withStyles(styles)(withRouter(RescheduleVisitModal));
