var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Link } from "@material-ui/core";
import React from "react";
import FlexContainer from "s~/components/misc/FlexContainer";
var RdiFileDescription = function (props) {
    var _a;
    return (React.createElement(FlexContainer, { margin: 10, style: __assign({ width: "100%", padding: "0px 0px 10px 0px" }, (props.style ? props.style : undefined)) },
        React.createElement(Link, { target: "_blank", href: props.file.url ? "" + ((_a = props.config) === null || _a === void 0 ? void 0 : _a.apiUrl) + props.file.url : null, style: { maxWidth: "70%", wordBreak: "break-all" } }, props.file.originalname),
        React.createElement("span", null, props.file.sizeString),
        props.onRemove && (React.createElement(Link, { onClick: props.onRemove, style: { cursor: "pointer" } }, "Remove"))));
};
export default RdiFileDescription;
