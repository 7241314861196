import { IconButton } from "@material-ui/core";
import React from "react";
import CloseIcon from "s~/static/CloseIcon.svg";
var ModalCloseIcon = function (props) {
    var onClick = props.onClick;
    return (React.createElement(IconButton, { onClick: onClick, tabIndex: 0, className: "close-modal", style: {
            zIndex: 66,
            position: "absolute",
            right: "20px",
            top: "20px",
            width: "26px",
            height: "26px",
            display: "flex",
            padding: "5px"
        } },
        React.createElement("img", { alt: "img", src: CloseIcon })));
};
export default ModalCloseIcon;
