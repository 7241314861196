import { differenceInDays, differenceInYears, endOfDay, endOfWeek, format, getDate, getDay, getHours, getMinutes, getMonth, getSeconds, getYear, parseISO, setDate, setHours, setMilliseconds, setMinutes, setMonth, setSeconds, setYear, startOfWeek, } from 'date-fns';
import { DeviceDataGroupByEnum } from 's~/constants/enums/DeviceDataGroupByEnum';
export var DATETIME_HUMAN = 'MMMM d, yyyy HH:mm'; // August 31, 2018 22:01
export var DATETIME_LONG = 'EEEE, MMMM d, yyyy HH:mm'; // Friday, August 31, 2018 22:01
export var DATE_HUMAN = 'MMMM d, yyyy'; // August 31, 2018
export var DATE_ISO = 'yyyy-MM-dd'; // August 31, 2018
export var DATE_LONG = 'EE, MMMM d, yyyy'; // Friday, August 31, 2018
export var TIME_HUMAN = 'HH:mm'; // August 31, 2018 22:01
export var MONTH_YEAR_HUMAN = 'MMMM yyyy'; // August 31, 2018
// old default human date: 'E, MMM d, yyyy - hh:mm a'
// export const DATETIME_HUMAN = 'dd/MM/yyyy hh:mma';
// export const DATE_HUMAN = 'dd/MM/yyyy';
export var getDaysAgo = function (date) {
    return differenceInDays(endOfDay(new Date()), date);
};
export var getYearsAgo = function (date) {
    return differenceInYears(date, new Date());
};
export var getDateFromTimeAndDate = function (tObj, dObj, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
utc) {
    if (!tObj || !dObj) {
        return null;
    }
    var d = new Date();
    d = setYear(d, getYear(dObj));
    d = setMonth(d, getMonth(dObj));
    d = setDate(d, getDate(dObj));
    d = setHours(d, getHours(tObj));
    d = setMinutes(d, getMinutes(tObj));
    d = setSeconds(d, getSeconds(tObj));
    d = setMilliseconds(d, 0);
    // TODO: UTC??
    return d;
};
export var formatDatetimeLong = function (date) {
    if (!date)
        return '';
    return format(date, DATETIME_LONG);
};
export var formatISODate = function (date) {
    if (!date)
        return '';
    return format(date, DATE_ISO);
};
export var formatDatetime = function (date, groupedBy) {
    if (!date)
        return '';
    if (groupedBy) {
        switch (groupedBy) {
            case DeviceDataGroupByEnum.DAY:
                return format(date, DATE_HUMAN);
            case DeviceDataGroupByEnum.WEEK:
                return format(startOfWeek(date), DATE_HUMAN) + " - " + format(endOfWeek(date), DATE_HUMAN);
            case DeviceDataGroupByEnum.MONTH:
                return format(date, 'MMMM');
            default:
                return '';
        }
    }
    return format(date, DATETIME_HUMAN);
};
export var formatTime = function (date) {
    if (!date)
        return '';
    return format(date, TIME_HUMAN);
};
export var formatDate = function (date) {
    if (!date)
        return '';
    return format(date, DATE_HUMAN);
};
export var formatDateLong = function (date) {
    if (!date)
        return '';
    return format(date, DATE_LONG);
};
export var formatMonthYear = function (date) {
    if (!date)
        return '';
    return format(date, MONTH_YEAR_HUMAN);
};
export var getDateFromString = function (dateString) {
    if (dateString instanceof Date) {
        return dateString;
    }
    var result = parseISO(dateString);
    return result;
};
export var getTimezone = function () {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export var getZeroPaddedMonth = function (date) {
    return "" + (getMonth(date) + 1 < 10 ? '0' : '') + (getMonth(date) + 1);
};
export var getZeroPaddedDay = function (date) {
    return "" + (getDay(date) + 1 < 10 ? '0' : '') + (getDay(date) + 1);
};
export var datePartialRegex = /^(?<year>[0-9]{4})(-((?<month>[0-9]{2}(-(?<day>[0-9]{2}))?)|(W(?<week>[0-9]{2}))))?$/;
export function parsePartialDate(partial) {
    var match = datePartialRegex.exec(partial);
    var _a = match.groups, month = _a.month, day = _a.day, week = _a.week, year = _a.year;
    return {
        year: year ? parseInt(year, 10) : null,
        month: month ? parseInt(month, 10) : null,
        week: week ? parseInt(week, 10) : null,
        day: day ? parseInt(day, 10) : null,
    };
}
export function parseTime(time) {
    if (!time) {
        return null;
    }
    var regex = /^(?<hour>[0-9]{2}):(?<minute>[0-9]{2})$/;
    var match = regex.exec(time);
    var _a = match.groups, minute = _a.minute, hour = _a.hour;
    if (!minute || !hour) {
        return null;
    }
    var aDate = new Date();
    aDate.setHours(parseInt(hour, 10));
    aDate.setMinutes(parseInt(minute, 10));
    return aDate;
}
