var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { IconButton, Paper } from "@material-ui/core";
import CheckmarkIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import RdiTypography from "s~/components/layout/RdiTypography";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var RdiToast = /** @class */ (function (_super) {
    __extends(RdiToast, _super);
    function RdiToast() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RdiToast.prototype.render = function () {
        var _a = this.props, onClick = _a.onClick, index = _a.index, children = _a.children;
        return (React.createElement(Paper, { style: {
                position: "fixed",
                top: 20 + index * 60 + "px",
                right: "20px",
                backgroundColor: ColorsEnum.PaperBackground,
                display: "flex",
                flexDirection: "row",
                padding: "10px",
                alignItems: "center",
                zIndex: 6000
            }, className: "rdiToast" },
            React.createElement(CheckmarkIcon, { style: { color: ColorsEnum.IconsGreen, marginRight: "10px" } }),
            React.createElement(RdiTypography, { variant: "body2" }, children),
            React.createElement(IconButton, { style: {
                    marginLeft: "10px",
                    padding: "0px"
                }, name: "toast-message-close", onClick: onClick },
                React.createElement(CloseIcon, { style: { color: ColorsEnum.BlackMedium } }))));
    };
    return RdiToast;
}(React.Component));
export default RdiToast;
