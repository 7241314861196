import React from "react";
import PublicPageWrapper from "s~/components/layout/PublicPageWrapper";
import RdiTypography from "s~/components/layout/RdiTypography";
import CenteredContent from "s~/components/layout/content/CenteredContent";
var PrivacyPage = function () {
    return (React.createElement(PublicPageWrapper, null,
        React.createElement(CenteredContent, { style: {
                display: "flex",
                flexDirection: "column",
                maxWidth: "800px",
                margin: "0px auto",
                padding: "35px 85px",
                background: "rgba(250,250,250,0.12)",
                border: "1px solid",
                borderColor: "rgba(0,0,0,0.12)"
            } },
            React.createElement(RdiTypography, { style: { marginBottom: "36px", fontSize: "32px", fontWeight: "bold" }, variant: "h1" }, "PRIVACY POLICY"),
            React.createElement(RdiTypography, { variant: "body1", style: { marginBottom: "14px" } },
                "MediSci Ventures, Inc. (\u201CMediSci\u201D, \"Our\" or \"We\") operates the website www.medisci.io (\u201CWebsite\u201D) and mobile application \u201CMediSci CROS\u201D (\u201CApplication\u201D or \u201CApp\u201D). We recognize the concerns that you may have about protecting the privacy of information provided by the users of our Website and App. We have created this Privacy Policy to explain the way we collect, use, protect, share, disclose and process your personal information.",
                " "),
            React.createElement(RdiTypography, { variant: "h3", style: { marginBottom: "14px", width: "100%", textAlign: "left" } }, "1. How We Collect Information"),
            React.createElement(RdiTypography, { variant: "body1" },
                "MediSci collects information through your online and offline general interaction with MediSci, its Website, and App, such as:",
                React.createElement("ul", null,
                    React.createElement("li", null, "Logging when you access our Website or use our App through Apple's \u201CHealth\u201D app;"),
                    React.createElement("li", null, "Collecting data that you voluntarily input on our Website or App, or through other IOT devices or voice assistants such as Apple Watch, Amazon Alexa, Google Assistant or Apple Siri;"),
                    React.createElement("li", null, "Signing up for an email newsletter;"),
                    React.createElement("li", null, "Contacting MediSci customer service or support, or providing feedback;"),
                    React.createElement("li", null, "Purchasing or subscribing to services with MediSci or through MediSci;"),
                    React.createElement("li", null, "Registering for an online account;"),
                    React.createElement("li", null, "Transacting business with MediSci;"),
                    React.createElement("li", null, "Using the \"chat\" feature on our Website or App;"),
                    React.createElement("li", null, "Collecting your mobile device's Mobile Advertising ID, if not disabled by user;"),
                    React.createElement("li", null, "Visiting or browsing the Website, App and/or third party websites or apps."))),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "2. What Information We Collect"),
            React.createElement(RdiTypography, { variant: "body1" }, "To perform our service, we may collect your information either directly or automated such as Personal Information, Log Files."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "2.1. Personal Information"),
            React.createElement(RdiTypography, { variant: "body1" }, "MediSci may collect the personally identifiable information that you give to us in connection with your general interactions with MediSci."),
            React.createElement(RdiTypography, { variant: "body1" },
                React.createElement("ul", null,
                    React.createElement("li", null, "Your name, home address, email address, and phone number and comments;"),
                    React.createElement("li", null, "Order history and preferences for services and product offerings;"),
                    React.createElement("li", null, "Employer name and work address;"),
                    React.createElement("li", null, "Financial information;"),
                    React.createElement("li", null, "Social Security number;"),
                    React.createElement("li", null, "Date of Birth;"),
                    React.createElement("li", null, "Payment information, such credit or debit card number or other payment method;"),
                    React.createElement("li", null, "Billing and shipping information;"),
                    React.createElement("li", null, "Passwords.")),
                "We also may collect or acquire non-personally identifiable information, such as:",
                React.createElement("ul", null,
                    React.createElement("li", null, "IP address, general geographic location such as the city from which your computer or device is connecting to the Internet, web browser type and version, operating system, the type of 2 computer or device you use to connect to the Website or App, or other technical information about your visit to the Website or App; links clicked, webpages requested and visited, timestamps, or other navigational information about visits to the Website or App such as your activities while visiting the Website or App and the content you access or view, websites you visit prior to and after visiting the Website or App, and information regarding your Internet location when you see Website or App ads and whether you click on such ads, and information regarding your age, gender and interests with respect to products and services.")),
                "If you provide personally identifiable information to MediSci through the Website or the App, we may be able to connect non-personally identifiable information such as your IP address to you."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "3. How We Use Information We Collect"),
            React.createElement(RdiTypography, { variant: "body1" },
                "MediSci may use personal information as described in this Privacy Policy. MediSci primarily uses nonmedical personal information to provide services to you, for example:",
                React.createElement("ul", null,
                    React.createElement("li", null, "Providing you with access to your online account;"),
                    React.createElement("li", null, "Improving your web experience;"),
                    React.createElement("li", null, "Completing purchases of or registering your participation in community education classes and other events;"),
                    React.createElement("li", null, "Emailing you to confirm or remind you of purchases or participation;"),
                    React.createElement("li", null, "Sending you information via e-mail or postal mail about our services, appointment reminders, offers and news that may be of interest to you;"),
                    React.createElement("li", null, "Respond to your communications, communicate with you about our services, provide you with customer service and support, and attend to tasks relating to your transactions with MediSci;"),
                    React.createElement("li", null, "Sharing your medical records;"),
                    React.createElement("li", null, "Processing payments for clinical trials;"),
                    React.createElement("li", null, "Tracking participation and wellness goals;"),
                    React.createElement("li", null, "Providing just-in-time health services recommended by your doctors;"),
                    React.createElement("li", null, "Improving and personalizing our Website or App."))),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "4. Cookies and Other Collection Technology"),
            React.createElement(RdiTypography, { variant: "body1" }, "A cookie is a piece of data stored on your computer. When you visit the Sites, your web browser may store cookies on your computer or device, or your computer or device may be exposed to other technologies such as web beacons, pixels, gifs or tags (\u201CCollection Technology\u201D), which will better enable you to navigate the Website or App upon return and will collect certain information about you. You may use the Website or App even if you choose to reject or delete the cookies or other Collection Technology; however, some features of the Website or App may not work properly with cookies or other Collection Technology disabled. Cookies or Other Collection Technology also may be used by any third party websites for which a link is placed on our Website or App."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "14px" } }, "We may collect information in your Apple \u201CHealth\u201D app or similar app, or in IOT medical devices. We also may collect the Mobile Advertising ID provided by your mobile device."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "14px" } }, "We may work with other companies who use tracking technologies, including cookies or other Collection Technology, to serve ads on our behalf across the internet. These companies may collect non-personally identifiable information about your visit to the Website or App and about your interaction with our communications, including advertising and emails."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "5. Your Opt-Out Choices"),
            React.createElement(RdiTypography, { variant: "body1" }, "If you subscribe to our email newsletter, you may opt-out by following the instructions contained within the email or by contacting support@medisci.io."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "14px" } }, "If you would like to update your mailing information or want your name and address removed from MediSci's marketing direct mailing list you can contact support@medisci.io."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "6. Data Security"),
            React.createElement(RdiTypography, { variant: "body1" }, "We have taken reasonable technical and operational precautions in order to protect personal information stored by MediSci from unauthorized access, misuse or loss. Our security procedures are monitored regularly and updated in line with technological progress."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "14px" } }, "No transmission of data over the internet is guaranteed to be completely secure. It may be possible for third parties not under the control of MediSci to intercept or access transmissions or private communications unlawfully. While we strive to protect personal information, neither MediSci nor our service providers can ensure or warrant the security of any information you transmit to us over the internet. Any such transmission is at your own risk."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "14px" } }, "MediSci is not responsible for the acts and omissions of any third parties."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "7. Sharing and Disclosure of Your Information"),
            React.createElement(RdiTypography, { variant: "body1" }, "MediSci does not share, sell or rent any personal information that you provide to us under this Privacy Policy to third parties, except with your consent or as described in this Privacy Policy. We do not share, sell or rent personal information to third parties for their direct marketing purposes."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "14px" } },
                "We may share personal information with our affiliates and third parties such as our service providers whom we engage to perform services on our behalf, such as:",
                React.createElement("ul", null,
                    React.createElement("li", null, "Shipping or delivery services"),
                    React.createElement("li", null, "Payment processors or billing services"),
                    React.createElement("li", null, "Consultants or affiliates.")),
                "We also may share personal information:",
                React.createElement("ul", null,
                    React.createElement("li", null, "As required by law or to comply with law enforcement or other government agencies"),
                    React.createElement("li", null, "To protect the rights, safety, or property of MediSci or others"),
                    React.createElement("li", null, "To enforce the Terms of Use"),
                    React.createElement("li", null, "To establish or exercise our legal rights or defend against legal claims"),
                    React.createElement("li", null, "To protect against threats, illegal activities or fraud"),
                    React.createElement("li", null, "With a third party or successor in the event of any reorganization, merger, sale, joint venture, assignment, or disposition of all or any portion of our business or operations.")),
                "MediSci may use and share non-personally identifiable information with third parties for a variety of purposes including, without limitation, marketing and promotional services for MediSci; website evaluation; remarketing, data analysis; and/or to perform the other tasks described above. We also may de-identify personally identifiable information (remove information that could be used to identify a person) and share it in aggregated form with third parties, advertisers and business partners to analyze usage, demographics or interests, improve our products or services, improve user experience, or for similar purposes. We will not re-identify such data and we will ask our contracting parties to agree to keep the data in its de-identified form."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "8. Do Not Track Disclosure"),
            React.createElement(RdiTypography, { variant: "body1" }, "At this time, MediSci is unable to universally respond to a \u201CDo Not Track\u201D or other \u201Copt-out\u201D mechanism that has been activated by a user through their web browser and, except as otherwise provided herein, does not alter its policies if a user activates same."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "9. California Consumer Privacy Act (California Residents Only)"),
            React.createElement(RdiTypography, { variant: "body1" },
                "Under the California Consumer Privacy Act (\"CCPA\"), among other rights, California residents have the right to ask businesses to disclose what personal information they have about you and what they do with that information, to delete your personal information, to direct businesses not to sell or share your personal information, to correct inaccurate information that they have about you, and to limit businesses\u2019 use and disclosure of your sensitive personal information:",
                React.createElement("ul", null,
                    React.createElement("li", null, "Right to know: You can request that a business disclose to you: (1) the categories and/or specific pieces of personal information they have collected about you, (2) the categories of sources for that personal information, (3) the purposes for which the business uses that information, (4) the categories of third parties with whom the business discloses the information, and (5) the categories of information that the business sells or discloses to third parties. You can make a request to know up to twice a year, free of charge."),
                    React.createElement("li", null, "Right to delete: You can request that businesses delete personal information they collected from you and tell their service providers to do the same, subject to certain exceptions (such as if the business is legally required to keep the information)."),
                    React.createElement("li", null, "Right to opt-out of sale or sharing: You may request that businesses stop selling or sharing your personal information (\u201Copt-out\u201D), including via a user-enabled global privacy control. Businesses cannot sell or share your personal information after they receive your opt-out request unless you later authorize them to do so again."),
                    React.createElement("li", null, "Right to correct: You may ask businesses to correct inaccurate information that they have about you."),
                    React.createElement("li", null, "Right to limit use and disclosure of sensitive personal information: You can direct businesses to only use your sensitive personal information (for example, your social security number, financial account information, your precise geolocation data, or your genetic data) for limited purposes, such as providing you with the services you requested."))),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "10. General Data Protection Regulation (European Union residents only)"),
            React.createElement(RdiTypography, { variant: "body1" },
                "Residents of the European Economic Area (\"EEA\")'s data is protected under the General Data Protection Regulation (\"GDPR\"). EEA residents have the following rights:",
                React.createElement("ul", null,
                    React.createElement("li", null, "Right to be informed (GDPR Articles 12 to 14): Data subjects have the right to be informed about the collection and use of their personal data."),
                    React.createElement("li", null, "Right to access (GDPR Article 15): Data subjects have the right to view and request copies of their personal data."),
                    React.createElement("li", null, "Right to rectification (GDPR Article 16): Data subjects have the right to request inaccurate or outdated personal information be updated or corrected."),
                    React.createElement("li", null, "Right to be forgotten/Right to erasure (GDPR Article 17): Data subjects have the right to request their personal data be deleted. Note that this is not an absolute right and may be subject to exemptions based on certain laws."),
                    React.createElement("li", null, "Right to data portability (GDPR Article 20): Data subjects have the right to ask for their data to be transferred to another controller or provided to them. The data must be provided in a machinereadable electronic format."),
                    React.createElement("li", null, "Right to restrict processing (Article 18): Data subjects have the right to request the restriction or suppression of their personal data."),
                    React.createElement("li", null, "Right to withdraw consent (GDPR Article 7): Data subjects have the right to withdraw previously given consent to process their personal data."),
                    React.createElement("li", null, "Right to object (GDPR Article 21): Data subjects have the right to object to the processing of their personal data."),
                    React.createElement("li", null, "Right to object to automated processing (GDPR Article 22): Data subjects have the right to object to decisions being made with their data solely based on automated decision making or profiling.")),
                "MediSci will respond to requests within one month of receiving the request, but MediSci's time to respond to requests may take longer than one month if the request is complex or if you submit multiple requests. Requests should be submitted to support@medisci.io."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "11. Email Marketing"),
            React.createElement(RdiTypography, { variant: "body1" }, "MediSci's marketing emails contain an unsubscribe option. If you do not wish to receive any of our marketing emails, you may unsubscribe at anytime by clicking the unsubscribe option in the email."),
            React.createElement(RdiTypography, { variant: "body1", style: { marginTop: "14px" } }, "Please note that it might take as many as 15 days for MediSci to process your email marketing opt-out request and, during this period, you may receive our promotional email. Opting out of marketing emails does not prevent you from receiving transaction alerts or other updates."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "12. Mobile Device Permission"),
            React.createElement(RdiTypography, { variant: "body1" }, "Mobile device users who are accessing the App may be asked to give the App permission to use certain features, such as data and microphone. It is your choice whether to allow such access. If you do not grant access, it is possible that some App features will be disabled or not work properly."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "13. Uninstalling the App"),
            React.createElement(RdiTypography, { variant: "body1" }, "Mobile device users can delete or uninstall the App. Uninstalling the App alone does not affect monthly subscription fees, which you must separately unsubscribe from to avoid."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "14. Changes to Privacy Policy"),
            React.createElement(RdiTypography, { variant: "body1" }, "MediSci reserves the right to update, change or replace any part of this Privacy Policy by posting updates and/or changes to our website. Your continued use of or access to the Website and/or the App constitutes acceptance of those changes."),
            React.createElement(RdiTypography, { variant: "h3", style: {
                    marginBottom: "12px",
                    marginTop: "24px",
                    width: "100%",
                    textAlign: "left"
                } }, "15. Contact Us"),
            React.createElement(RdiTypography, { variant: "body1" }, "For more information about our Privacy Policy, please contact us via email at support@medisci.io"))));
};
export default PrivacyPage;
