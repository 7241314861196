var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import RdiToast from '@cros/shared/components/misc/RdiToast';
import ConfirmationModal from '@cros/shared/components/modals/ConfirmationModal';
import ErrorModal from '@cros/shared/components/modals/ErrorModal';
import { PublicRoutes } from '@cros/shared/components/routing/PublicRoutes';
import { injectConfirmationModalStore } from '@cros/shared/stores/ConfirmationModalStore';
import { injectErrorModalStore } from '@cros/shared/stores/ErrorModalStore';
import { injectToastStore } from '@cros/shared/stores/ToastStore';
import TermsPage from '@cros/shared/components/public/TermsPage';
import PrivacyPage from '@cros/shared/components/public/PrivacyPage';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AccountRoutes, SiteRoutes } from '../routing/Routes';
import { injectAccountStore } from '../stores/AccountStore';
import AccountPage from './account/AccountPage';
import SitePage from './site/SitePage';
var styles = function () {
    return createStyles({
        '@global': {
            'body, html, #root': {
                width: '100%',
                height: '100%',
                fontFamily: 'Lato'
            },
            '.MuiSelect-root .hide': {
                display: 'none!important'
            },
            '.hideScrollbar::-webkit-scrollbar': {
                display: 'none'
            },
            'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    });
};
var Pages = /** @class */ (function (_super) {
    __extends(Pages, _super);
    function Pages() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onErrorModalCloseClick = function () {
            var clearError = _this.props.errorModalStore.clearError;
            clearError();
        };
        return _this;
    }
    Pages.prototype.render = function () {
        var _a = this.props, _b = _a.accountStore, LoginState = _b.LoginState, logout = _b.logout, error = _a.errorModalStore.error, _c = _a.toastStore, toastMessages = _c.toastMessages, removeToastMessage = _c.removeToastMessage, _d = _a.confirmationModalStore, confirmation = _d.confirmation, title = _d.title, containerConfirm = _d.containerConfirm, containerClose = _d.containerClose, loading = _d.loading;
        return (React.createElement(React.Fragment, null,
            toastMessages.map(function (toast, index) { return (React.createElement(RdiToast, { key: toast.id, index: index, onClick: function () { return removeToastMessage(toast.id); } }, toast.message)); }),
            LoginState !== 'LOADING' && (React.createElement(Switch, null,
                React.createElement(Route, { path: PublicRoutes.TERMS, exact: true },
                    React.createElement(TermsPage, null)),
                React.createElement(Route, { path: PublicRoutes.PRIVACY, exact: true },
                    React.createElement(PrivacyPage, null)),
                React.createElement(Route, { path: AccountRoutes.ACCOUNT_ROUTE },
                    React.createElement(AccountPage, null)),
                LoginState === 'LOGGED_IN' && (React.createElement(Route, { exact: true, path: "/" },
                    React.createElement(Redirect, { to: AccountRoutes.ACCOUNT_ROUTE }))),
                LoginState === 'LOGGED_OUT' && React.createElement(Redirect, { to: AccountRoutes.LOGIN_ROUTE }),
                React.createElement(Route, { path: SiteRoutes.SITE_ROOT_ROUTE },
                    React.createElement(SitePage, null)))),
            React.createElement(ErrorModal, { open: !!error, title: error ? error.title || error.code || 'Error' : 'Error', code: error === null || error === void 0 ? void 0 : error.code, message: error ? error.message || error.detail : error, onCloseClick: this.onErrorModalCloseClick, logout: logout }),
            React.createElement(ConfirmationModal, { open: !!confirmation, title: title, content: confirmation, onConfirm: containerConfirm, onClose: containerClose, loading: loading })));
    };
    Pages = __decorate([
        injectErrorModalStore,
        injectAccountStore,
        injectToastStore,
        injectConfirmationModalStore,
        observer
    ], Pages);
    return Pages;
}(Component));
export default withStyles(styles)(Pages);
