export var GenderEnum;
(function (GenderEnum) {
    GenderEnum["OTHER"] = "other";
    GenderEnum["FEMALE"] = "female";
    GenderEnum["MALE"] = "male";
})(GenderEnum || (GenderEnum = {}));
export var GenderLabelsEnum;
(function (GenderLabelsEnum) {
    GenderLabelsEnum["other"] = "Other";
    GenderLabelsEnum["female"] = "Female";
    GenderLabelsEnum["male"] = "Male";
})(GenderLabelsEnum || (GenderLabelsEnum = {}));
