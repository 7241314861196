var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { newFieldChange } from '@cros/shared/utils/utils';
import { newUtilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectBilledItemStore } from '~/stores/BilledItemStore';
import { VisitListStore } from '~/stores/PatientStore/VisitListStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { TaskStore } from '~/stores/TaskStore';
import TrialStore from '~/stores/TrialStore';
var styles = function () { return createStyles({}); };
var AddBillingCode = /** @class */ (function (_super) {
    __extends(AddBillingCode, _super);
    function AddBillingCode(props) {
        var _this = _super.call(this, props) || this;
        _this.hasErrors = false;
        _this.data = {
            visitId: null,
            taskId: null,
            trialId: null,
            enrollmentId: null,
            billingCode: null,
            amount: null,
            codeFor: null
        };
        _this.labels = {
            visitId: '',
            taskId: '',
            trialId: '',
            enrollmentId: '',
            billingCode: '',
            codeFor: ''
        };
        _this.rules = {
            visitId: 'required_if:codeFor,visit',
            taskId: 'required_if:codeFor,task',
            trialId: 'required',
            enrollmentId: 'required',
            billingCode: 'required',
            amount: 'required',
            codeFor: 'required'
        };
        _this.errors = {};
        _this.loading = true;
        _this.saving = false;
        _this.errorMessage = '';
        _this.loadPatients = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.trialStore.getTrial(this.data.trialId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.trialStore.getEnrollments()];
                    case 2:
                        _a.sent();
                        this.data.visitId = undefined;
                        this.labels.visitId = '';
                        this.data.taskId = undefined;
                        this.labels.taskId = '';
                        this.data.billingCode = undefined;
                        this.labels.billingCode = '';
                        this.data.enrollmentId = undefined;
                        this.labels.enrollmentId = '';
                        return [2 /*return*/];
                }
            });
        }); };
        _this.loadVisits = function () { return __awaiter(_this, void 0, Promise, function () {
            var siteStore;
            return __generator(this, function (_a) {
                siteStore = this.props.siteStore;
                this.visitListStore = new VisitListStore(siteStore, {
                    trialId: this.data.trialId,
                    enrollmentId: this.data.enrollmentId
                });
                this.visitListStore.listVisits();
                return [2 /*return*/];
            });
        }); };
        _this.loadTasks = function () { return __awaiter(_this, void 0, Promise, function () {
            var _a, siteStore, history;
            return __generator(this, function (_b) {
                _a = this.props, siteStore = _a.siteStore, history = _a.history;
                this.taskStore = new TaskStore(siteStore, '', history);
                this.taskStore.listTasks();
                return [2 /*return*/];
            });
        }); };
        _this.assignData = function (billingItem, billingCode) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.data.trialId = billingItem.trialId;
                        this.labels.trialId = (_a = billingItem.trial) === null || _a === void 0 ? void 0 : _a.name;
                        return [4 /*yield*/, this.loadPatients()];
                    case 1:
                        _c.sent();
                        this.data.billingCode = billingCode.code;
                        this.labels.billingCode = billingCode.code;
                        this.data.amount = billingCode.amount;
                        this.data.visitId = billingItem.id;
                        this.labels.visitId = billingItem.billingName;
                        this.data.taskId = billingItem.id;
                        this.labels.taskId = billingItem.billingName;
                        this.data.enrollmentId = billingItem.enrollmentId;
                        this.labels.enrollmentId = (_b = billingItem.patient) === null || _b === void 0 ? void 0 : _b.fullName;
                        this.data.codeFor = billingItem.billingType === 'VisitModel' ? 'visit' : 'task';
                        this.labels.codeFor = billingItem.billingType === 'VisitModel' ? 'visit' : 'task';
                        // make sure we can use it when submitting
                        return [4 /*yield*/, this.loadVisits()];
                    case 2:
                        // make sure we can use it when submitting
                        _c.sent();
                        return [4 /*yield*/, this.loadTasks()];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var billingCodeStore, billingItem, billingCode;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        billingCodeStore = this.props.billingCodeStore;
                        if (!this.isEdit) return [3 /*break*/, 2];
                        return [4 /*yield*/, billingCodeStore.getBilledItemById(this.billingItemId, this.billingItemType)];
                    case 1:
                        billingItem = _a.sent();
                        if (!billingItem) {
                            return [2 /*return*/];
                        }
                        billingCode = billingItem.billingCodes.find(function (b) { return b.id === _this.billingCodeId; });
                        if (billingCode) {
                            this.assignData(billingItem, billingCode);
                        }
                        _a.label = 2;
                    case 2:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function (hasNoUpdate) {
            if (hasNoUpdate === void 0) { hasNoUpdate = true; }
            var _a = _this.props, history = _a.history, url = _a.match.url, search = _a.location.search;
            var onUpdate = _this.props.onUpdate;
            if (!hasNoUpdate) {
                onUpdate();
            }
            if (!_this.isEdit) {
                history.push("" + url.split('/new')[0] + search);
            }
            else {
                history.push("" + url.split('/edit')[0] + search);
            }
        };
        _this.fieldChange = function (e, field) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newFieldChange(e, field, this.data, this.labels);
                        if (!(field === 'trialId')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadPatients()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 2:
                        if (!(field === 'enrollmentId')) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.loadVisits()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.loadTasks()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var o, visits, tasks, item, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        e.stopPropagation();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        o = this.getObjectToSave();
                        this.saving = true;
                        visits = (this.visitListStore || { visits: [] }).visits;
                        tasks = (this.taskStore || { tasks: [] }).tasks;
                        item = (visits.find(function (v) { return v.id === _this.data.visitId; }) ||
                            tasks.find(function (v) { return v.id === _this.data.taskId; }));
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.props.billingCodeStore.addBillingCode(item, o)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.props.billingCodeStore.updateBillingCode(item, o)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 7:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose(false);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var midnight = new Date();
            midnight.setHours(0, 0, 0, 0);
            var o = {
                id: _this.billingCodeId,
                code: _this.data.billingCode,
                amount: _this.data.amount,
                needsApproval: true
            };
            return o;
        };
        _this.validateForm = function (validationErrors) {
            return newUtilValidate(_this.data, _this.errors, _this.rules, null, validationErrors, true);
        };
        var siteStore = _this.props.siteStore;
        _this.trialStore = new TrialStore(siteStore);
        return _this;
    }
    Object.defineProperty(AddBillingCode.prototype, "isEdit", {
        get: function () {
            var params = this.props.match.params;
            return !!params.billingCodeId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddBillingCode.prototype, "billingCodeId", {
        get: function () {
            var params = this.props.match.params;
            return params.billingCodeId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddBillingCode.prototype, "billingItemId", {
        get: function () {
            var params = this.props.match.params;
            return params.billingItemId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddBillingCode.prototype, "billingItemType", {
        get: function () {
            var params = this.props.match.params;
            return params.billingItemType;
        },
        enumerable: false,
        configurable: true
    });
    AddBillingCode.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.trialStore, enrollments = _b.enrollments, trial = _b.trial;
        var visits = (this.visitListStore || {}).visits;
        var tasks = (this.taskStore || {}).tasks;
        var _c = this.props, siteStore = _c.siteStore, accountStore = _c.accountStore;
        var trials = this.props.siteStore.trials;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading },
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent, style: { flex: '1' } },
                React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, (this.isEdit ? 'Edit' : 'Add a new') + " code"),
                React.createElement(RdiLine, null),
                React.createElement("div", { style: { flex: 1 } },
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30, style: { flexWrap: 'wrap' } },
                        React.createElement(RdiInput, { type: "select", label: "Trial", name: "trialId", value: this.data.trialId, selectedItemLabel: this.labels.trialId, errorText: this.errors.trialId, onChange: function (e) { return _this.fieldChange(e, 'trialId'); }, options: trials.map(function (t) { return ({
                                label: t.name,
                                value: t.id
                            }); }), fullWidth: true, disabled: this.isEdit, style: { width: 'calc(50% - 15px)' } }),
                        React.createElement(RdiInput, { type: "select", label: "Patient", name: "enrollmentId", value: this.data.enrollmentId, selectedItemLabel: this.labels.enrollmentId, errorText: this.errors.enrollmentId, onChange: function (e) { return _this.fieldChange(e, 'enrollmentId'); }, options: enrollments.map(function (enrollment) {
                                var _a;
                                return ({
                                    label: (_a = enrollment.patient) === null || _a === void 0 ? void 0 : _a.fullName,
                                    value: enrollment.id
                                });
                            }), fullWidth: true, disabled: this.isEdit, style: { width: 'calc(50% - 15px)' } })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30, style: { flexWrap: 'wrap' } },
                        React.createElement(RdiInput, { type: "select", label: "Code for", name: "codeFor", value: this.data.codeFor, selectedItemLabel: this.labels.codeFor, errorText: this.errors.codeFor, onChange: function (e) { return _this.fieldChange(e, 'codeFor'); }, options: [
                                {
                                    value: 'visit',
                                    label: 'Visit'
                                },
                                {
                                    value: 'task',
                                    label: 'Task'
                                }
                            ], fullWidth: true, disabled: this.isEdit, style: { width: 'calc(50% - 15px)' } }),
                        this.data.codeFor === 'visit' && (React.createElement(RdiInput, { type: "select", label: "Visit", name: "visitId", value: this.data.visitId, selectedItemLabel: this.labels.visitId, errorText: this.errors.visitId, onChange: function (e) { return _this.fieldChange(e, 'visitId'); }, options: visits === null || visits === void 0 ? void 0 : visits.map(function (visit) { return ({
                                label: visit.name,
                                value: visit.id
                            }); }), disabled: this.isEdit, style: { width: 'calc(50% - 15px)' } })),
                        this.data.codeFor === 'task' && (React.createElement(RdiInput, { type: "select", label: "Task", name: "taskId", value: this.data.taskId, selectedItemLabel: this.labels.taskId, errorText: this.errors.taskId, onChange: function (e) { return _this.fieldChange(e, 'taskId'); }, options: tasks === null || tasks === void 0 ? void 0 : tasks.filter(function (task) { return task.enrollmentId === _this.data.enrollmentId; }).map(function (task) { return ({
                                label: task.title,
                                value: task.id
                            }); }), disabled: this.isEdit, style: { width: 'calc(50% - 15px)' } })),
                        !this.data.codeFor && React.createElement("div", { style: { width: '100%' } })),
                    React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30, style: { flexWrap: 'wrap' } },
                        React.createElement(RdiInput, { type: "select", label: "Billing code", name: "billingCode", value: this.data.billingCode, selectedItemLabel: this.labels.billingCode, errorText: this.errors.billingCode, onChange: function (e) { return _this.fieldChange(e, 'billingCode'); }, options: (_a = trial === null || trial === void 0 ? void 0 : trial.billingCodes) === null || _a === void 0 ? void 0 : _a.map(function (b) { return ({
                                label: b.code,
                                value: b.code
                            }); }), style: { width: 'calc(50% - 15px)' } }),
                        React.createElement(RdiInput, { type: "number", label: "Amount", unit: "min", name: "amount", value: this.data.amount, errorText: this.errors.amount, onChange: function (e) { return _this.fieldChange(e, 'amount'); }, style: { width: 'calc(50% - 15px)' } })),
                    React.createElement(RdiErrorBox, null, this.errorMessage)),
                React.createElement(RdiLine, null),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, margin: "none", submit: true, permissions: [
                            {
                                aclOp: this.isEdit ? AclOpEnum.UPDATE : AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.BILLING
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, this.isEdit ? 'Update' : 'Add')))));
    };
    __decorate([
        observable
    ], AddBillingCode.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], AddBillingCode.prototype, "data", void 0);
    __decorate([
        observable
    ], AddBillingCode.prototype, "labels", void 0);
    __decorate([
        observable
    ], AddBillingCode.prototype, "rules", void 0);
    __decorate([
        observable
    ], AddBillingCode.prototype, "errors", void 0);
    __decorate([
        observable
    ], AddBillingCode.prototype, "loading", void 0);
    __decorate([
        observable
    ], AddBillingCode.prototype, "saving", void 0);
    __decorate([
        observable
    ], AddBillingCode.prototype, "errorMessage", void 0);
    __decorate([
        computed
    ], AddBillingCode.prototype, "isEdit", null);
    __decorate([
        computed
    ], AddBillingCode.prototype, "billingCodeId", null);
    __decorate([
        computed
    ], AddBillingCode.prototype, "billingItemId", null);
    __decorate([
        computed
    ], AddBillingCode.prototype, "billingItemType", null);
    AddBillingCode = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectBilledItemStore,
        observer
    ], AddBillingCode);
    return AddBillingCode;
}(Component));
export default withStyles(styles)(withRouter(AddBillingCode));
