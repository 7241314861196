var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import UploadedFile from 's~/types/models/UploadedFile';
import { Model } from 's~/types/models/Model';
import { User } from 's~/types/models/User';
import { getDateFromString } from 's~/utils/dateUtils';
export var NoteTypeEnum;
(function (NoteTypeEnum) {
    NoteTypeEnum["Note"] = "note";
    NoteTypeEnum["Message"] = "message";
})(NoteTypeEnum || (NoteTypeEnum = {}));
var Note = /** @class */ (function (_super) {
    __extends(Note, _super);
    function Note(n) {
        var _this = _super.call(this, n) || this;
        _this.id = n.id;
        _this.note = n.note;
        _this.taskId = n.taskId;
        _this.ownerId = n.ownerId;
        _this.owner = n.owner ? new User(n.owner) : null;
        _this.mentions = n.mentions;
        _this.createdAt = n.createdAt ? getDateFromString(n.createdAt) : null;
        _this.isSystemGenerated = n.isSystemGenerated;
        _this.type = n.type;
        _this.attachments = [];
        if (n.attachments) {
            for (var _i = 0, _a = n.attachments; _i < _a.length; _i++) {
                var rec = _a[_i];
                _this.attachments.push(new UploadedFile(rec));
            }
        }
        return _this;
    }
    return Note;
}(Model));
export { Note };
