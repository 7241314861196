import { createStyles, withStyles } from "@material-ui/core";
import React from "react";
var styles = function (theme) {
    var _a;
    return createStyles({
        content: (_a = {
                width: "100%",
                display: "flex",
                padding: "80px 0px",
                minHeight: "100%"
            },
            _a[theme.breakpoints.up("md")] = {
                padding: "30px 0px"
            },
            _a.flexDirection = "row",
            _a.boxSizing = "border-box",
            _a[theme.breakpoints.down("md")] = {
                paddingBottom: "20px"
            },
            _a[theme.breakpoints.down("xs")] = {
                paddingBottom: "0px",
                paddingTop: "56px"
            },
            _a)
    });
};
var PageContent = function (props) {
    var classes = props.classes;
    return React.createElement("div", { className: classes.content }, props.children);
};
export default withStyles(styles)(PageContent);
