var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from "mobx";
import { Model } from "s~/types/models/Model";
import { getDateFromString } from "s~/utils/dateUtils";
import UploadedFile from "./UploadedFile";
var MedicalRecord = /** @class */ (function (_super) {
    __extends(MedicalRecord, _super);
    function MedicalRecord(r) {
        var _this = _super.call(this, r) || this;
        _this.title = r.title;
        _this.type = r.type;
        _this.id = r.id;
        _this.createdAt = r.createdAt ? getDateFromString(r.createdAt) : null;
        _this.records = [];
        if (r.records) {
            for (var _i = 0, _a = r.records; _i < _a.length; _i++) {
                var rec = _a[_i];
                _this.records.push(new UploadedFile(rec));
            }
        }
        _this.sharedToPatient = r.sharedToPatient;
        _this.sharedToPatientAt = r.sharedToPatientAt
            ? getDateFromString(r.sharedToPatientAt)
            : null;
        return _this;
    }
    __decorate([
        observable
    ], MedicalRecord.prototype, "expanded", void 0);
    return MedicalRecord;
}(Model));
export { MedicalRecord };
