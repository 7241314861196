var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import RdiFileDescription from '@cros/shared/components/misc/RdiFileDescription';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { AdverseEventIpActionEnum, AdverseEventIpActionLabelsEnum } from '@cros/shared/constants/enums/AdverseEventIpActionEnum';
import { AdverseEventIpRelationEnum, AdverseEventIpRelationLabelsEnum } from '@cros/shared/constants/enums/AdverseEventIpRelationEnum';
import { AdverseEventOutcomeEnum, AdverseEventOutcomeLabelsEnum } from '@cros/shared/constants/enums/AdverseEventOutcomeEnum';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import config from '~/config';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectAdverseEventsStore } from '~/stores/PatientStore/AdverseEventsStore';
var notDuringVisit = {
    id: 'NotDurringVisit',
    name: 'Not during visit'
};
var AddAdverseEvent = /** @class */ (function (_super) {
    __extends(AddAdverseEvent, _super);
    function AddAdverseEvent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasErrors = false;
        _this.data = {
            text: {
                value: '',
                error: null,
                rule: 'required|min:2'
            },
            visitId: {
                value: notDuringVisit.id,
                label: 'Not during visit',
                error: null,
                rule: 'required'
            },
            startDate: {
                value: new Date(),
                error: null,
                rule: 'required|date'
            },
            endDate: {
                value: null,
                error: null,
                rule: "date|required_unless:outcome," + AdverseEventOutcomeEnum.NOT_RECOVERED
            },
            sae: {
                value: false,
                label: 'No',
                error: null,
                rule: 'required'
            },
            withdrawn: {
                value: false,
                label: 'No',
                error: null,
                rule: 'required'
            },
            outcome: {
                value: AdverseEventOutcomeEnum.NOT_RECOVERED,
                label: AdverseEventOutcomeLabelsEnum[AdverseEventOutcomeEnum.NOT_RECOVERED],
                error: null,
                rule: 'required'
            },
            actionTaken: {
                value: AdverseEventIpActionEnum.NOT_APPLICABLE,
                label: AdverseEventIpActionLabelsEnum[AdverseEventIpActionEnum.NOT_APPLICABLE],
                error: null,
                rule: 'required'
            },
            relationshipWithIp: {
                value: AdverseEventIpRelationEnum.NOT_RELATED,
                label: AdverseEventIpRelationLabelsEnum[AdverseEventIpRelationEnum.NOT_RELATED],
                error: null,
                rule: 'required'
            },
            files: {
                items: [],
                error: null
            },
            trialId: {
                value: null,
                label: '',
                error: null,
                rule: 'required'
            }
        };
        _this.assignEditData = function (event) {
            var _a, _b, _c;
            var patient = _this.props.patientStore.patient;
            _this.data.visitId.value = event.visitId || notDuringVisit.id;
            _this.data.visitId.label = ((_a = event.visit) === null || _a === void 0 ? void 0 : _a.name) || notDuringVisit.name;
            _this.data.text.value = event.text;
            _this.data.startDate.value = event.startDate;
            _this.data.endDate.value = event.endDate ? event.endDate : undefined;
            _this.data.sae.value = event.sae;
            _this.data.sae.label = event.sae ? 'Yes' : 'No';
            _this.data.withdrawn.value = event.withdrawn;
            _this.data.withdrawn.label = event.withdrawn ? 'Yes' : 'No';
            _this.data.outcome.value = event.outcome;
            _this.data.outcome.label = AdverseEventOutcomeLabelsEnum[event.outcome];
            _this.data.actionTaken.value = event.actionTaken;
            _this.data.actionTaken.label = AdverseEventIpActionLabelsEnum[event.actionTaken];
            _this.data.relationshipWithIp.value = event.relationshipWithIp;
            _this.data.relationshipWithIp.label = AdverseEventIpRelationLabelsEnum[event.relationshipWithIp];
            _this.data.trialId.value = event.trialId;
            _this.data.trialId.label = (_c = (_b = patient.enrollments.find(function (e) { return e.trialId === event.trialId; })) === null || _b === void 0 ? void 0 : _b.trial) === null || _c === void 0 ? void 0 : _c.name;
            _this.data.files.items = event.saeForm ? [event.saeForm] : [];
        };
        _this.errorMessage = '';
        _this.loading = true;
        _this.saving = false;
        _this.saeFormValid = true;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, adverseEventsStore, patientStore, editEvent, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, adverseEventsStore = _a.adverseEventsStore, patientStore = _a.patientStore;
                        return [4 /*yield*/, patientStore.listVisits()];
                    case 1:
                        _b.sent();
                        if (!this.isEdit) return [3 /*break*/, 6];
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, adverseEventsStore.getAdverseEvent(this.adverseEventId)];
                    case 3:
                        editEvent = _b.sent();
                        this.assignEditData(editEvent);
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        this.errorMessage = e_1.detail || e_1.description || e_1.message || e_1;
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        if (this.visitId) {
                            this.data.visitId.value = this.visitId;
                        }
                        _b.label = 7;
                    case 7:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split((_this.isEdit ? '/edit' : '') + "/" + (_this.adverseEventId || 'new'))[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
            // if we unselect sae and we had files, delete them
            if (field === 'sae' && !_this.data[field].value) {
                for (var _i = 0, _a = _this.data.files.items; _i < _a.length; _i++) {
                    var file = _a[_i];
                    file.deleted = true;
                }
            }
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var adverseEventsStore, newAdverseEvent, adverseEvent, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        adverseEventsStore = this.props.adverseEventsStore;
                        e.preventDefault();
                        e.stopPropagation();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        adverseEvent = this.getObjectToSave();
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, adverseEventsStore.addAdverseEvent(adverseEvent, this.data.files.items.length > 0 ? this.data.files.items[0] : null, this.trialId)];
                    case 2:
                        newAdverseEvent = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, adverseEventsStore.updateAdverseEvent(adverseEvent, false, this.data.files.items, this.trialId)];
                    case 4:
                        newAdverseEvent = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 8];
                    case 7:
                        this.saving = false;
                        if (!newAdverseEvent) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 8:
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var _a;
            var nonDeletedFiles = _this.data.files.items.filter(function (x) { return !x.deleted; });
            var adverseEvent = {
                id: _this.adverseEventId,
                text: _this.data.text.value,
                visitId: _this.data.visitId.value === notDuringVisit.id ? undefined : _this.data.visitId.value,
                startDate: _this.data.startDate.value,
                endDate: _this.data.endDate.value ? _this.data.endDate.value : undefined,
                sae: _this.data.sae.value,
                withdrawn: _this.data.withdrawn.value,
                outcome: _this.data.outcome.value,
                actionTaken: _this.data.actionTaken.value,
                relationshipWithIp: _this.data.relationshipWithIp.value,
                saeFormId: nonDeletedFiles.length > 0 ? nonDeletedFiles[0].id : undefined,
                trialId: ((_a = _this.data.trialId) === null || _a === void 0 ? void 0 : _a.value) || _this.trialId
            };
            return adverseEvent;
        };
        _this.validateForm = function (validationErrors) {
            var invalid = utilValidate(_this.data, null, validationErrors);
            return invalid;
        };
        _this.onSelectSaeForm = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var f, _i, _a, file;
            return __generator(this, function (_b) {
                f = event.files && event.files.length ? event.files[0] : undefined;
                if (!f) {
                    return [2 /*return*/];
                }
                // if we have new files, replace it
                this.data.files.items = this.data.files.items.filter(function (x) { return !x.file; });
                // if we have old files, mark them for deletion
                for (_i = 0, _a = this.data.files.items; _i < _a.length; _i++) {
                    file = _a[_i];
                    file.deleted = true;
                }
                this.data.files.items.push(new UploadedFile({
                    originalname: f.name,
                    mimetype: f.type,
                    size: f.size,
                    url: null,
                    file: f
                }));
                return [2 /*return*/];
            });
        }); };
        _this.removeFile = function (item) {
            var index = _this.data.files.items.findIndex(function (x) { return x.originalname === item.originalname; });
            // file is new
            if (item.file) {
                _this.data.files.items.splice(index);
            }
            else {
                _this.data.files.items[index].deleted = true;
            }
        };
        return _this;
    }
    Object.defineProperty(AddAdverseEvent.prototype, "adverseEventId", {
        get: function () {
            var params = this.props.match.params;
            return params.adverseEventId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddAdverseEvent.prototype, "trialId", {
        get: function () {
            var params = this.props.match.params;
            return this.isDetailsModal ? undefined : params.trialId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddAdverseEvent.prototype, "isEdit", {
        get: function () {
            return !!this.adverseEventId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddAdverseEvent.prototype, "visitId", {
        get: function () {
            var params = this.props.match.params;
            return params.visitId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddAdverseEvent.prototype, "saeUploadEnabled", {
        get: function () {
            return this.data.sae.value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddAdverseEvent.prototype, "endDateEnabled", {
        get: function () {
            return this.data.outcome.value !== AdverseEventOutcomeEnum.NOT_RECOVERED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddAdverseEvent.prototype, "visits", {
        get: function () {
            var patientStore = this.props.patientStore;
            return __spreadArrays([notDuringVisit], (patientStore.visits || []));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddAdverseEvent.prototype, "isDetailsModal", {
        get: function () {
            var url = this.props.match.url;
            return (url.endsWith('/details/adverseevents/new') || url.includes('/details/adverseevents/edit/'));
        },
        enumerable: false,
        configurable: true
    });
    AddAdverseEvent.prototype.render = function () {
        var _this = this;
        var _a = this.props, visitId = _a.match.params.visitId, patient = _a.patientStore.patient;
        return (React.createElement(SmallModal, { loading: this.loading, onClose: this.onClose },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, (this.isEdit ? 'Edit' : 'New') + " adverse event"),
            React.createElement(FlexContainer, { style: { marginTop: '10px', marginBottom: '15px' }, margin: 15 },
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient === null || patient === void 0 ? void 0 : patient.fullName),
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
            React.createElement(RdiLine, null),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent },
                React.createElement(RdiInput, { type: "text", label: "Adverse event", name: "text", value: this.data.text.value, errorText: this.data.text.error, onChange: function (e) { return _this.fieldChange(e, 'text'); }, autoFocus: true }),
                React.createElement(FlexContainer, { margin: 30, alignItems: "flex-start", flexWrap: "wrap" },
                    !visitId && (React.createElement(RdiInput, { type: "select", label: "Visit", name: "visitId", value: this.data.visitId.value, selectedItemLabel: this.data.visitId.label, errorText: this.data.visitId.error, onChange: function (e) { return _this.fieldChange(e, 'visitId'); }, options: this.visits.map(function (visit) { return ({
                            label: visit.name,
                            value: visit.id
                        }); }), style: { width: 'calc(50% - 15px)' } })),
                    React.createElement(RdiInput, { type: "date", label: "Start date", name: "startDate", value: this.data.startDate.value, errorText: this.data.startDate.error, onChange: function (e) { return _this.fieldChange(e, 'startDate'); }, style: { width: 'calc(50% - 15px)' } }),
                    visitId && React.createElement("div", { style: { width: 'calc(50% - 15px)' } })),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(FlexContainer, { margin: 30, alignItems: "flex-start" },
                    React.createElement(RdiInput, { type: "select", label: "SAE", name: "sae", value: this.data.sae.value, selectedItemLabel: this.data.sae.label, errorText: this.data.sae.error, onChange: function (e) { return _this.fieldChange(e, 'sae'); }, options: [
                            { label: 'No', value: false },
                            { label: 'Yes', value: true }
                        ] }),
                    this.isDetailsModal && (React.createElement(RdiInput, { type: "select", label: "Trial", name: "trialId", value: this.data.trialId.value, selectedItemLabel: this.data.trialId.label, errorText: this.data.trialId.error, onChange: function (e) { return _this.fieldChange(e, 'trialId'); }, options: patient.enrollments.map(function (e) {
                            var _a;
                            return ({
                                label: (_a = e.trial) === null || _a === void 0 ? void 0 : _a.name,
                                value: e.trialId
                            });
                        }), style: { width: 'calc(50% - 15px)' } }))),
                this.saeUploadEnabled && (React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start" },
                    React.createElement(RdiInput, { type: "file", label: "UPLOAD SAE FORM", name: "saeForm", errorText: !this.saeFormValid ? 'Required' : undefined, value: null, link: null, onChange: this.onSelectSaeForm, fullWidth: false, style: { minWidth: '180px' } }),
                    this.data.files.items
                        .filter(function (x) { return !x.deleted; })
                        .map(function (item, index) { return (React.createElement(RdiFileDescription, { config: config, style: {
                            minHeight: '47px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '0px'
                        }, key: index, file: item, onRemove: function () { return _this.removeFile(item); } })); }))),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(FlexContainer, { margin: 30, alignItems: "flex-start", flexWrap: "wrap" },
                    React.createElement(RdiInput, { type: "select", label: "Outcome", name: "outcome", value: this.data.outcome.value, selectedItemLabel: this.data.outcome.label, errorText: this.data.outcome.error, onChange: function (e) { return _this.fieldChange(e, 'outcome'); }, options: Object.values(AdverseEventOutcomeEnum).map(function (enumType) { return ({
                            value: enumType,
                            label: AdverseEventOutcomeLabelsEnum[enumType]
                        }); }), style: { width: 'calc(50% - 15px)' } }),
                    this.endDateEnabled && (React.createElement(RdiInput, { type: "date", label: "End date", name: "endDate", value: this.data.endDate.value, errorText: this.data.endDate.error, onChange: function (e) { return _this.fieldChange(e, 'endDate'); }, style: { width: 'calc(50% - 15px)' } })),
                    !this.endDateEnabled && React.createElement("div", { style: { width: '100%' } })),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(FlexContainer, { margin: 30, flexWrap: "wrap" },
                    React.createElement(RdiInput, { type: "select", label: "Relationship with IP", name: "relationshipWithIp", value: this.data.relationshipWithIp.value, selectedItemLabel: this.data.relationshipWithIp.label, errorText: this.data.relationshipWithIp.error, onChange: function (e) { return _this.fieldChange(e, 'relationshipWithIp'); }, options: Object.values(AdverseEventIpRelationEnum).map(function (enumType) { return ({
                            label: AdverseEventIpRelationLabelsEnum[enumType],
                            value: enumType
                        }); }), style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(RdiInput, { type: "select", label: "Action taken", name: "actionTaken", value: this.data.actionTaken.value, selectedItemLabel: this.data.actionTaken.label, errorText: this.data.actionTaken.error, onChange: function (e) { return _this.fieldChange(e, 'actionTaken'); }, options: Object.values(AdverseEventIpActionEnum).map(function (enumType) { return ({
                            label: AdverseEventIpActionLabelsEnum[enumType],
                            value: enumType
                        }); }), style: { width: 'calc(50% - 15px)' } })),
                React.createElement(FlexContainer, { margin: 30, flexWrap: "wrap" },
                    React.createElement(RdiInput, { type: "select", label: "Subject withdrawn?", name: "withdrawn", value: this.data.withdrawn.value, selectedItemLabel: this.data.withdrawn.label, errorText: this.data.withdrawn.error, onChange: function (e) { return _this.fieldChange(e, 'withdrawn'); }, options: [
                            { label: 'No', value: false },
                            { label: 'Yes', value: true }
                        ], style: { width: 'calc(50% - 15px)' } }),
                    React.createElement("div", { style: { width: 'calc(50% - 15px)' } })),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { disabled: this.saving, loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, submit: true, margin: "none" }, this.isEdit ? 'Update' : 'Add')))));
    };
    __decorate([
        observable
    ], AddAdverseEvent.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], AddAdverseEvent.prototype, "data", void 0);
    __decorate([
        observable
    ], AddAdverseEvent.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], AddAdverseEvent.prototype, "loading", void 0);
    __decorate([
        observable
    ], AddAdverseEvent.prototype, "saving", void 0);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "adverseEventId", null);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "trialId", null);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "isEdit", null);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "visitId", null);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "saeUploadEnabled", null);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "endDateEnabled", null);
    __decorate([
        observable
    ], AddAdverseEvent.prototype, "saeFormValid", void 0);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "visits", null);
    __decorate([
        computed
    ], AddAdverseEvent.prototype, "isDetailsModal", null);
    AddAdverseEvent = __decorate([
        injectPatientStore,
        injectAdverseEventsStore,
        observer
    ], AddAdverseEvent);
    return AddAdverseEvent;
}(Component));
export default withRouter(AddAdverseEvent);
