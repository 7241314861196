var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import NavigationModal from '@cros/shared/components/modals/NavigationModal';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { createStyles, withStyles } from '@material-ui/core';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import BilledItemCodes from '~/components/billing/BilledItemCodes';
import { getVisitDetailRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import VisitStore from '~/stores/PatientStore/VisitStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTrialStore } from '~/stores/TrialStore';
import ProceduresNavigation from './ProceduresNavigation';
import VisitProcedure from './VisitProcedure';
var styles = function () { return createStyles({}); };
var VisitModal = /** @class */ (function (_super) {
    __extends(VisitModal, _super);
    function VisitModal(props) {
        var _this = _super.call(this, props) || this;
        _this.loading = true;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var history;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        history = this.props.history;
                        this.locationChangeHandle = history.listen(function () {
                            _this.rePopulateVisit();
                        });
                        return [4 /*yield*/, this.rePopulateVisit()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.componentWillUnmount = function () {
            // remove listener
            _this.locationChangeHandle();
        };
        _this.rePopulateVisit = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, visitId, history, _b, siteId, trialId, enrollmentId, patientId;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.props, visitId = _a.match.params.visitId, history = _a.history, _b = _a.enrollmentStore, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId;
                        if (!(visitId && this.visitStore.visitId !== visitId && ((_c = this.visitStore.visit) === null || _c === void 0 ? void 0 : _c.slug) !== visitId)) return [3 /*break*/, 2];
                        this.loading = true;
                        return [4 /*yield*/, this.visitStore.InitVisit(visitId)];
                    case 1:
                        _d.sent();
                        this.loading = false;
                        // check if id changed (meaning it materialized) and reload page,
                        // as the active links in procedure navigation will not work with slug
                        if (this.visitStore.visit.id !== visitId) {
                            history.push(getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, this.visitStore.visit.id));
                        }
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            history.push("" + url.split("/visits")[0]);
        };
        var _a = _this.props, enrollmentStore = _a.enrollmentStore, siteStore = _a.siteStore;
        _this.visitStore = new VisitStore(siteStore, enrollmentStore);
        return _this;
    }
    VisitModal.prototype.render = function () {
        var _a = this.props, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId, visitId = _b.visitId, tab = _b.tab, trial = _a.trialStore.trial;
        var visit = this.visitStore.visit;
        return (React.createElement(Provider, { visitStore: this.visitStore },
            React.createElement(NavigationModal, { navigation: !this.loading && React.createElement(ProceduresNavigation, null), loading: this.loading, onClose: this.onClose, NavigationDrawer: ProceduresNavigation }, !this.loading && visit && (React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: SiteRoutes.VISIT_ROUTE }, visit.procedures.length > 0 && (React.createElement(Redirect, { to: getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, visitId, tab) + "/" + visit.procedures[0].slug }))),
                visit.procedures.map(function (procedure) { return (React.createElement(Route, { key: procedure.slug, path: SiteRoutes.VISIT_ROUTE + "/" + procedure.slug },
                    React.createElement(VisitProcedure, { procedure: procedure }))); }),
                React.createElement(Route, { path: SiteRoutes.VISIT_CODES_ROUTE },
                    React.createElement(BilledItemCodes, { disabled: visit.isCheckedOut, item: visit, trial: trial, save: this.visitStore.updateVisitBilling, aclModule: AclModuleEnum.VISIT })))))));
    };
    __decorate([
        observable
    ], VisitModal.prototype, "loading", void 0);
    VisitModal = __decorate([
        injectEnrollmentStore,
        injectAccountStore,
        injectSiteStore,
        injectTrialStore,
        observer
    ], VisitModal);
    return VisitModal;
}(Component));
export default withStyles(styles)(withRouter(VisitModal));
