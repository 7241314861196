var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AccountRoutes as SharedAccountRoutes } from '@cros/shared/components/routing/AccountRoutes';
import { PublicRoutes as SharedPublicRoutes } from '@cros/shared/components/routing/PublicRoutes';
export var AccountRoutes = __assign(__assign({}, SharedAccountRoutes), { ACCOUNT_ROUTE: '/account', REGISTER_COMPANY: '/account/register-company', SMS_TASKS_INDEX_ROUTE: '/site/:siteId/account/sms-notifications', VERIFY_PHONE_NUMBER_ROUTE: '/site/:siteId/account/sms-notifications/verify', CHANGE_PASSWORD_ROUTE: '/site/:siteId/account/change-password' });
export var PublicRoutes = __assign({}, SharedPublicRoutes);
export var SiteRoutes;
(function (SiteRoutes) {
    SiteRoutes["SITE_ROOT_ROUTE"] = "/site";
    SiteRoutes["SITE_ROUTE"] = "/site/:siteId";
    SiteRoutes["TRIAL_LIST_ROUTE"] = "/site/:siteId/trials";
    SiteRoutes["TRIAL_ROUTE"] = "/site/:siteId/trials/:trialId";
    SiteRoutes["PATIENTS_ROUTE"] = "/site/:siteId/trials/:trialId/patients";
    SiteRoutes["NEW_PATIENT_ROUTE"] = "/site/:siteId/patients/new";
    SiteRoutes["PATIENT_ROUTE"] = "/site/:siteId/patients/:patientId/:trials?/:trialId?/:enrollments?/:enrollmentId?";
    SiteRoutes["PATIENT_TRIAL_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId";
    SiteRoutes["SCHEDULE_VISIT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/:tab?/schedule/:visitId";
    SiteRoutes["RESCHEDULE_VISIT_VISIT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/:procedureSlug/schedule";
    SiteRoutes["VISITS_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId";
    SiteRoutes["VISIT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/:tab?/visits/:visitId";
    SiteRoutes["VISIT_CODES_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/:tab?/visits/:visitId/codes";
    SiteRoutes["CHECKOUT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/:procedureSlug?/checkout";
    SiteRoutes["COMPLETED_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/:procedureSlug?/completed";
    SiteRoutes["NEW_UNSCHEDULED_VISIT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/new";
    SiteRoutes["EDIT_UNSCHEDULED_VISIT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/edit/:visitId";
    SiteRoutes["IP_DISPENSING_LOG_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/ipdispensinglog";
    SiteRoutes["IP_DISPENSING_LOG_DETAILS_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/ipdispensinglog/details/:ipDispensingLogId";
    SiteRoutes["NEW_IP_DISPENSING_LOG_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/ipdispensinglog/newdi";
    SiteRoutes["NEW_IP_DISPENSING_LOG_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/investigational_product/newdi";
    SiteRoutes["EDIT_IP_DISPENSING_LOG_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/ipdispensinglog/editdi/:ipDispensingLogId";
    SiteRoutes["EDIT_IP_DISPENSING_LOG_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/investigational_product/editdi/:ipDispensingLogId";
    SiteRoutes["IP_DOSING_LOG_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/ipdosinglog";
    SiteRoutes["NEW_IP_DOSING_LOG_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/ipdosinglog/newdo";
    SiteRoutes["EDIT_IP_DOSING_LOG_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/ipdosinglog/editdo/:ipDosingLogId";
    SiteRoutes["NEW_IP_DOSING_LOG_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/investigational_product/newdo";
    SiteRoutes["EDIT_IP_DOSING_LOG_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/investigational_product/editdo/:ipDosingLogId";
    SiteRoutes["FEEDBACK_HISTORY_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/feedback-history";
    SiteRoutes["WEARABLE_DATA_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/device-data";
    SiteRoutes["DEVICE_LIST_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/devices";
    SiteRoutes["PROTOCOL_ROUTE"] = "/site/:siteId/trials/:trialId/protocol";
    SiteRoutes["ADD_SITE_TRIAL_LINK"] = "/site/:siteId/trials/:trialId/protocol/add-link";
    SiteRoutes["EDIT_SITE_TRIAL_LINK"] = "/site/:siteId/trials/:trialId/protocol/edit-link/:index";
    SiteRoutes["EDIT_SITE_TRIAL_FILE"] = "/site/:siteId/trials/:trialId/protocol/edit-file";
    SiteRoutes["ADD_SITE_TRIAL_EMPTY_FOLDER"] = "/site/:siteId/trials/:trialId/protocol/add-folder";
    SiteRoutes["ADD_SITE_CONTACT"] = "/site/:siteId/trials/:trialId/protocol/contact/add";
    SiteRoutes["EDIT_SITE_CONTACT"] = "/site/:siteId/trials/:trialId/protocol/contact/edit/:index";
    SiteRoutes["BILLING_CODES_ROUTE"] = "/site/:siteId/trials/:trialId/billing-codes";
    SiteRoutes["SITE_SETTINGS_ROUTE"] = "/site/:siteId/settings";
    SiteRoutes["BILLING_APPROVAL_INDEX_ROUTE"] = "/site/:siteId/billing-approval";
    SiteRoutes["BILLING_APPROVAL_ROUTE"] = "/site/:siteId/billing-approval/:tabId?";
    SiteRoutes["BILLING_APPROVAL_NEEDS_APPROVAL_ROUTE"] = "/site/:siteId/billing-approval/needs-approval";
    SiteRoutes["BILLING_APPROVAL_APPROVED_ROUTE"] = "/site/:siteId/billing-approval/approved";
    SiteRoutes["BILLING_APPROVAL_DENIED_ROUTE"] = "/site/:siteId/billing-approval/denied";
    SiteRoutes["BILLING_OVERVIEW_INDEX_ROUTE"] = "/site/:siteId/billing-overview";
    SiteRoutes["NEW_BILLING_CODE_ROUTE"] = "/site/:siteId/billing-approval/:tabId?/new";
    SiteRoutes["EDIT_BILLING_CODE_ROUTE"] = "/site/:siteId/billing-approval/:tabId?/edit/:billingItemId--:billingItemType--:billingCodeId";
    SiteRoutes["BILLING_OVERVIEW_ROUTE"] = "/site/:siteId/billing-overview";
    SiteRoutes["BILLING_REPORT_FILES"] = "/site/:siteId/billing-overview/:tabId?/files/:billingReportId";
    SiteRoutes["PAYMENTS_INDEX_ROUTE"] = "/site/:siteId/payments";
    SiteRoutes["CHECK_PAYMENT_LIST_ROUTE"] = "/site/:siteId/payments/check";
    SiteRoutes["SEND_CHECK_ROUTE"] = "/site/:siteId/payments/check/send-check/:claimId";
    SiteRoutes["UNCLAIMED_PAYMENT_LIST_ROUTE"] = "/site/:siteId/payments/unclaimed";
    SiteRoutes["CLAIMED_PAYMENT_LIST_ROUTE"] = "/site/:siteId/payments/claimed";
    SiteRoutes["PAYMENT_LIST_ROUTE"] = "/site/:siteId/payments/all";
    SiteRoutes["NEW_PAYMENT_ROUTE"] = "/site/:siteId/payments/:tabId?/new";
    SiteRoutes["EDIT_PAYMENT_ROUTE"] = "/site/:siteId/payments/:tabId?/edit/:paymentId";
    SiteRoutes["PATIENT_LIST_ROUTE"] = "/site/:siteId/patients";
    SiteRoutes["TASKS_INDEX_ROUTE"] = "/site/:siteId/tasks/:tabId?";
    SiteRoutes["TASKS_NOTAB_ROUTE"] = "/site/:siteId/tasks";
    SiteRoutes["ALARM_LIST_ROUTE"] = "/site/:siteId/tasks/alarm";
    SiteRoutes["TASK_LIST_ROUTE"] = "/site/:siteId/tasks/task";
    SiteRoutes["RESOLVED_TASK_LIST_ROUTE"] = "/site/:siteId/tasks/resolved";
    SiteRoutes["TASK_MODAL_ROUTE"] = "/site/:siteId/tasks/:tabId?/detail/:taskId";
    SiteRoutes["TASK_MODAL_NOTES_ROUTE"] = "/site/:siteId/tasks/:tabId?/detail/:taskId/notes";
    SiteRoutes["TASK_MODAL_CODES_ROUTE"] = "/site/:siteId/tasks/:tabId?/detail/:taskId/codes";
    SiteRoutes["ASSIGN_TASK_ROUTE"] = "/site/:siteId/tasks/:tabId?/assign/:taskId";
    SiteRoutes["NEW_TASK_ROUTE"] = "/site/:siteId/tasks/:tabId?/new";
    SiteRoutes["EDIT_TASK_ROUTE"] = "/site/:siteId/tasks/:tabId?/edit/:taskId?";
    SiteRoutes["PATIENT_DETAIL_MODAL_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details";
    SiteRoutes["USER_DETAILS_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/userdetails";
    SiteRoutes["MEDICATIONS_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medications";
    SiteRoutes["DEMOGRAPHICS_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/demographics";
    SiteRoutes["INSURANCES_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/insurances";
    SiteRoutes["ADVERSE_EVENTS_PATIENT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/adverseevents";
    SiteRoutes["ENROLLMENTS_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/trials";
    SiteRoutes["PHONE_INFO_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/phone-info";
    SiteRoutes["NEW_ADVERSE_EVENT_PATIENT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/adverseevents/new";
    SiteRoutes["NEW_ADVERSE_EVENT_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/adverse_events/new";
    SiteRoutes["EDIT_ADVERSE_EVENT_PATIENT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/adverseevents/edit/:adverseEventId";
    SiteRoutes["EDIT_ADVERSE_EVENT_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/adverse_events/edit/:adverseEventId";
    SiteRoutes["MEDICAL_HISTORY_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medicalhistory";
    SiteRoutes["MEDICAL_RECORDS_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medicalrecords";
    SiteRoutes["NEW_MEDICAL_HISTORY_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medicalhistory/new";
    SiteRoutes["NEW_MEDICAL_RECORD_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medicalrecords/new";
    SiteRoutes["EDIT_MEDICAL_HISTORY_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medicalhistory/edit/:medicalHistoryId";
    SiteRoutes["EDIT_MEDICAL_RECORD_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medicalrecords/edit/:medicalRecordId";
    SiteRoutes["NEW_MEDICAL_HISTORY_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/medical_history/new";
    SiteRoutes["NEW_MEDICATION_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medications/new";
    SiteRoutes["EDIT_MEDICATION_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/medications/edit/:medicationId";
    SiteRoutes["NEW_DEVICE_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/devices/new";
    SiteRoutes["EDIT_DEVICE_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/devices/edit/:deviceId";
    SiteRoutes["NEW_INSURANCE_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/insurances/new";
    SiteRoutes["EDIT_INSURANCE_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/insurances/edit/:insuranceId";
    SiteRoutes["NEW_MEDICATION_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/medication_changes/new";
    SiteRoutes["EDIT_MEDICATION_ROUTE_VISIT"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/visits/:visitId/medication_changes/edit/:medicationId";
    SiteRoutes["ADD_ENROLLMENT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/trials/new";
    SiteRoutes["MANAGE_ENROLLMENT_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/trials/manage/:manageTrialId--:manageEnrollmentId";
    SiteRoutes["PATIENT_SHARED_FILES"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/details/shared-files";
    SiteRoutes["AUDIT_LOG_LIST_ROUTE"] = "/site/:siteId/audit-log";
    SiteRoutes["VIEW_AUDIT_LOG"] = "/site/:siteId/audit-log/view/:auditLogId";
    SiteRoutes["USERS_ROUTE"] = "/site/:siteId/users";
    SiteRoutes["USERS_ALL_ROUTE"] = "/site/:siteId/users/all";
    SiteRoutes["USERS_GROUPS_ROUTE"] = "/site/:siteId/users/groups";
    SiteRoutes["USERS_PERMISSIONS_ROUTE"] = "/site/:siteId/users/permissions";
    SiteRoutes["ADD_USER_ROUTE"] = "/site/:siteId/users/new";
    SiteRoutes["EDIT_USER_ROUTE"] = "/site/:siteId/users/all/edit/:userId";
    SiteRoutes["CLINIC_DOCUMNETS_ROUTE"] = "/site/:siteId/clinic-documents";
    SiteRoutes["PRESENTATION_LIBRARY_ROUTE"] = "/site/:siteId/presentation-library";
    SiteRoutes["CLINIC_DASHBOARD_STATISTIC"] = "/site/:siteId/dashboard";
    SiteRoutes["ADVERSE_EVENTS_TRIAL_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/adverseevents";
    SiteRoutes["NEW_ADVERSE_EVENT_TRIAL_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/adverseevents/new";
    SiteRoutes["EDIT_ADVERSE_EVENT_TRIAL_ROUTE"] = "/site/:siteId/patients/:patientId/trials/:trialId/enrollments/:enrollmentId/adverseevents/edit/:adverseEventId";
})(SiteRoutes || (SiteRoutes = {}));
export var getSiteRoute = function (siteId) { return "/site/" + siteId; };
export var getSettingsRoute = function (siteId) { return "/site/" + siteId + "/settings"; };
export var getTrialsRoute = function (siteId) { return "/site/" + siteId + "/trials"; };
export var getTrialRoute = function (siteId, trialId) { return getTrialsRoute(siteId) + "/" + trialId; };
export var getTrialPatientsRoute = function (siteId, trialId) {
    return getTrialRoute(siteId, trialId) + "/patients";
};
export var getPatientsAllRoute = function (siteId) { return "/site/" + siteId + "/patients"; };
export var getNewPatientRoute = function (siteId) { return getPatientsAllRoute(siteId) + "/new"; };
export var getChangePasswordRoute = function (siteId) { return "/site/" + siteId + "/account/change-password"; };
export var getSmsNotificationsRoute = function (siteId) { return "/site/" + siteId + "/account/sms-notifications"; };
export var getVerifyPhoneNumberRoute = function (siteId) {
    return "/site/" + siteId + "/account/sms-notifications/verify";
};
export var getPatientRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientsAllRoute(siteId) + "/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId;
};
export var getPatientOverviewRoute = function (siteId, patientId) {
    return getPatientsAllRoute(siteId) + "/" + patientId;
};
export var getScheduleRoute = function (siteId, patientId, trialId, enrollmentId, visitId) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/schedule/" + visitId;
};
export var getPatientDetailRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/details";
};
export var getVisitsRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "" + getPatientRoute(siteId, patientId, trialId, enrollmentId);
};
export var getNewUnscheduledVisitRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/visits/new";
};
export var getVisitEditRoute = function (siteId, patientId, trialId, enrollmentId, visitId) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/visits/edit/" + visitId;
};
export var getVisitDetailRoute = function (siteId, patientId, trialId, enrollmentId, visitId, tab) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/" + (tab ? tab + "/" : '') + "visits/" + visitId;
};
export var getVisitCodesRoute = function (siteId, patientId, trialId, enrollmentId, visitId, tab) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/" + (tab ? tab + "/" : '') + "visits/" + visitId + "/codes";
};
export var getCheckoutRoute = function (siteId, trialId, enrollmentId, visitId, tab) {
    return getVisitDetailRoute(siteId, trialId, enrollmentId, visitId, tab) + "/checkout";
};
export var getIpDispensingLogRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/ipdispensinglog";
};
export var getIpDosingLogRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientRoute(siteId, patientId, trialId, enrollmentId) + "/ipdosinglog";
};
export var getProtocolRoute = function (siteId, trialId) { return "/site/" + siteId + "/trials/" + trialId + "/protocol"; };
export var getBillingCodesRoute = function (siteId, trialId) {
    return "/site/" + siteId + "/trials/" + trialId + "/billing-codes";
};
export var getPaymentsRoute = function (siteId) { return "/site/" + siteId + "/payments"; };
export var getPaymentsChequeRoute = function (siteId) { return getPaymentsRoute(siteId) + "/check"; };
export var getPaymentsUnclaimedRoute = function (siteId) { return getPaymentsRoute(siteId) + "/unclaimed"; };
export var getPaymentsClaimedRoute = function (siteId) { return getPaymentsRoute(siteId) + "/claimed"; };
export var getPaymentsAllRoute = function (siteId) { return getPaymentsRoute(siteId) + "/all"; };
export var getBillingOverviewRoute = function (siteId) { return getSiteRoute(siteId) + "/billing-overview"; };
export var getBillingReportFilesRoute = function (siteId, billingReportId) {
    return getBillingOverviewRoute(siteId) + "/files/" + billingReportId;
};
export var getBillingOverviewOpenRoute = function (siteId) {
    return getSiteRoute(siteId) + "/billing-overview/open";
};
export var getBillingOverviewClosedRoute = function (siteId) {
    return getSiteRoute(siteId) + "/billing-overview/closed";
};
export var getBillingApprovalRoute = function (siteId) { return getSiteRoute(siteId) + "/billing-approval"; };
export var getBillingNeedsApprovalRoute = function (siteId) {
    return getBillingApprovalRoute(siteId) + "/needs-approval";
};
export var getBillingOpenRoute = function (siteId) { return getBillingApprovalRoute(siteId) + "/open"; };
export var getBillingApprovedRoute = function (siteId) { return getBillingApprovalRoute(siteId) + "/approved"; };
export var getBillingDeniedRoute = function (siteId) { return getBillingApprovalRoute(siteId) + "/denied"; };
export var getTasksRoute = function (siteId) { return getSiteRoute(siteId) + "/tasks"; };
export var getTasksAlarmRoute = function (siteId) { return getTasksRoute(siteId) + "/alarm"; };
export var getTasksTaskRoute = function (siteId) { return getTasksRoute(siteId) + "/task"; };
export var getTasksResolvedRoute = function (siteId) { return getTasksRoute(siteId) + "/resolved"; };
export var getTaskDetailRoute = function (siteId, taskId, tabId) {
    return "/site/" + siteId + "/tasks/" + (tabId ? tabId + "/" : '') + "detail/" + taskId;
};
export var getTasksDetailCodesRoute = function (siteId, tabId, taskId) {
    return getTaskDetailRoute(siteId, taskId, tabId) + "/codes";
};
export var getTasksDetailNotesRoute = function (siteId, tabId, taskId) {
    return getTaskDetailRoute(siteId, taskId, tabId) + "/notes";
};
export var getTasksDetailEditRoute = function (siteId, taskId, tabId) {
    return "/site/" + siteId + "/tasks/" + (tabId ? tabId + "/" : '') + "edit/" + taskId;
};
export var getDemographicsRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/demographics";
};
export var getUserDetailsRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/userdetails";
};
export var getAdverseEventsTrialRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/adverseevents";
};
export var getAdverseEventsPatientRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/adverseevents";
};
export var getMedicationsRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/medications";
};
export var getMedicalHistoryRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/medicalhistory";
};
export var getInsurancesRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/insurances";
};
export var getMedicalRecordsRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/medicalrecords";
};
export var getNewMedicationRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/medications/new";
};
export var getEditMedicationsRoute = function (siteId, patientId, trialId, enrollmentId, medicationId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/medications/edit/" + medicationId;
};
export var getPatientSharedFilesRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/details/shared-files";
};
export var getPatientFeedbackHistoryRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/feedback-history";
};
export var getDeviceDataRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/device-data";
};
export var getDevicesRoute = function (siteId, patientId, trialId, enrollmentId) {
    return "/site/" + siteId + "/patients/" + patientId + "/trials/" + trialId + "/enrollments/" + enrollmentId + "/devices";
};
export var getAuditRoute = function (siteId) { return "/site/" + siteId + "/audit-log"; };
export var getEnrollmentsRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientDetailRoute(siteId, patientId, trialId, enrollmentId) + "/trials";
};
export var getPhoneInfoRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientDetailRoute(siteId, patientId, trialId, enrollmentId) + "/phone-info";
};
export var getAddEnrollmentRoute = function (siteId, patientId, trialId, enrollmentId) {
    return getPatientDetailRoute(siteId, patientId, trialId, enrollmentId) + "/trials/new";
};
export var getUsersRoute = function (siteId) { return "/site/" + siteId + "/users"; };
export var getUsersNewRoute = function (siteId) { return "/site/" + siteId + "/users/new"; };
export var getUsersAllRoute = function (siteId) { return "/site/" + siteId + "/users/all"; };
export var getUsersGroupsRoute = function (siteId) { return "/site/" + siteId + "/users/groups"; };
export var getUsersPermissionsRoute = function (siteId) { return "/site/" + siteId + "/users/permissions"; };
export var getClinicDocumentsRoute = function (siteId) { return "/site/" + siteId + "/clinic-documents"; };
export var getClinicStatisticDashboardRoute = function (siteId) { return "/site/" + siteId + "/dashboard"; };
export var getPresentationLibraryRoute = function (siteId) { return "/site/" + siteId + "/presentation-library"; };
