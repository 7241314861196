var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { compareAsc, compareDesc } from 'date-fns';
import { computed, observable } from 'mobx';
import { inject } from 'mobx-react';
import FeedbackService from '~/services/FeedbackService';
var FeedbackStore = /** @class */ (function () {
    function FeedbackStore(enrollmentStore) {
        var _this = this;
        this.enrollmentStore = enrollmentStore;
        this.patientFeedback = [];
        this.patientFeedbackCount = 0;
        this.sortDirection = 'DESC';
        this.getFeedback = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, trialId, enrollmentId, authToken, errorModalStore, f, _b, _c, _d, e_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.enrollmentStore, siteId = _a.siteId, trialId = _a.trialId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = FeedbackService).getPatientFeedbackHistory;
                        _d = [siteId,
                            trialId,
                            enrollmentId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        f = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        errorModalStore.setError(e_1);
                        return [2 /*return*/];
                    case 5:
                        this.patientFeedback = f.items;
                        this.patientFeedbackCount = f.count;
                        return [2 /*return*/];
                }
            });
        }); };
    }
    Object.defineProperty(FeedbackStore.prototype, "sortedFeedback", {
        get: function () {
            switch (this.sortDirection) {
                case 'DESC':
                    return this.patientFeedback
                        .slice()
                        .sort(function (a, b) { return compareDesc(a.feedbackAdded, b.feedbackAdded); });
                case 'ASC':
                    return this.patientFeedback
                        .slice()
                        .sort(function (a, b) { return compareAsc(a.feedbackAdded, b.feedbackAdded); });
                default:
                    return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], FeedbackStore.prototype, "patientFeedback", void 0);
    __decorate([
        observable
    ], FeedbackStore.prototype, "patientFeedbackCount", void 0);
    __decorate([
        observable
    ], FeedbackStore.prototype, "sortDirection", void 0);
    __decorate([
        computed
    ], FeedbackStore.prototype, "sortedFeedback", null);
    return FeedbackStore;
}());
export { FeedbackStore };
export var injectFeedbackStore = inject('feedbackStore');
