var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import ExpandableTableRow from '@cros/shared/components/misc/ExpandableTableRow';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiFileDescription from '@cros/shared/components/misc/RdiFileDescription';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { MedicalRecordTypeEnum, MedicalRecordTypeLabelsEnum } from '@cros/shared/constants/enums/MedicalRecordTypeEnum';
import { formatDatetime, formatDatetimeLong } from '@cros/shared/utils/dateUtils';
import { CircularProgress, IconButton, Menu, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PeopleIcon from '@material-ui/icons/People';
import { createStyles, withStyles } from '@material-ui/styles';
import { computed, observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import config from '~/config';
import { SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { MedicalRecordStore } from '~/stores/PatientStore/MedicalRecordStore';
import { injectSiteStore } from '~/stores/SiteStore';
import AddMedicalRecord from './AddMedicalRecord';
var styles = function () {
    return createStyles({
        heightContainer: {
            transition: 'max-height .5s',
            overflow: 'hidden'
        }
    });
};
var MedicalRecordsPage = /** @class */ (function (_super) {
    __extends(MedicalRecordsPage, _super);
    function MedicalRecordsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.columns = [
            {
                id: 'expandcell',
                label: '',
                width: '30px'
            },
            {
                id: 'title',
                label: 'Title',
                sortable: false,
                sortName: 'title'
            },
            {
                id: 'type',
                label: 'Type',
                sortable: false,
                sortName: 'type'
            },
            {
                id: 'createdAt',
                label: 'Entered',
                sortable: true,
                sortName: 'createdAt'
            },
            {
                id: 'shareColumn',
                label: '',
                sortable: false,
                sortName: ''
            },
            {
                id: 'actionColumn',
                label: '',
                sortable: false,
                sortName: ''
            }
        ];
        _this.menuRef = null;
        _this.handleMenuClick = function (event, medicalRecord) {
            _this.menuRef = event.currentTarget;
            _this.medicalRecordInEdit = medicalRecord;
        };
        _this.handleMenuClose = function () {
            _this.menuRef = null;
        };
        _this.medicalRecordInEdit = null;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicalRecordStore.listMedicalRecords()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            _this.handleMenuClose();
            history.push(url + "/edit/" + _this.medicalRecordInEdit.id);
        };
        _this.getAddRoute = function () {
            var url = _this.props.match.url;
            return url + "/new";
        };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.medicalRecordStore.deleteMedicalRecordWithConfirmation(this.medicalRecordInEdit)];
                    case 1:
                        _a.sent();
                        this.medicalRecordInEdit = null;
                        this.handleMenuClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.toggleExpandCell = function (index) {
            _this.medicalRecordStore.toggleExpandCell(index);
        };
        var patientStore = _this.props.patientStore;
        _this.medicalRecordStore = new MedicalRecordStore(patientStore);
        return _this;
    }
    Object.defineProperty(MedicalRecordsPage.prototype, "rowMenuOpen", {
        get: function () {
            return !!this.menuRef;
        },
        enumerable: false,
        configurable: true
    });
    MedicalRecordsPage.prototype.render = function () {
        var _this = this;
        var _a = this.medicalRecordStore, medicalRecords = _a.medicalRecords, totalItems = _a.totalItems, changeMedicalRecordFilters = _a.changeMedicalRecordFilters, medicalRecordFilters = _a.medicalRecordFilters, medicalRecordFilterLabels = _a.medicalRecordFilterLabels, medicalRecordsLoading = _a.medicalRecordsLoading;
        var _b = this.props, accountStore = _b.accountStore, siteStore = _b.siteStore;
        return (React.createElement(Provider, { medicalRecordStore: this.medicalRecordStore },
            React.createElement("div", { style: {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement(RdiTypography, { style: { marginBottom: '30px' }, variant: "h6", color: TypographyColorsEnum.Primary }, "Medical records"),
                React.createElement(FlexContainer, { style: { marginBottom: '35px' }, alignItems: "flex-end", justifyContent: "space-between", flexWrap: "wrap", margin: 15 },
                    React.createElement(RdiTypography, { variant: "caption", style: {
                            padding: '0px',
                            marginBottom: '25px',
                            minWidth: '100px'
                        } }, (medicalRecordsLoading ? '' : totalItems) + " medical record" + (totalItems === 1 ? '' : 's')),
                    React.createElement(RdiInput, { style: { width: '200px' }, type: "search", name: "search", placeholder: "Search", value: medicalRecordFilters.search, onChange: function (e) { return changeMedicalRecordFilters({ search: e.value }); } }),
                    React.createElement(RdiInput, { style: { width: '200px' }, type: "select", label: "Type", name: "filter-type", value: medicalRecordFilters.type, selectedItemLabel: medicalRecordFilterLabels.type, onChange: function (e) {
                            return changeMedicalRecordFilters({ type: e.value !== 'all' ? e.value : null }, { type: e.label });
                        }, options: __spreadArrays(['all'], Object.values(MedicalRecordTypeEnum)).map(function (enumType) { return ({
                            value: enumType,
                            label: MedicalRecordTypeLabelsEnum[enumType] || 'All'
                        }); }) }),
                    React.createElement(RdiButton, { name: "new-medical-record", to: this.getAddRoute, permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.MEDICAL_RECORD
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "New medical record")),
                React.createElement("div", { style: { overflowY: medicalRecordsLoading ? 'hidden' : 'auto' } },
                    React.createElement(RdiTable, { stickyHeader: true },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeMedicalRecordFilters, filters: medicalRecordFilters })); }))),
                        !medicalRecordsLoading && (React.createElement(TableBody, null,
                            medicalRecords.map(function (item, index) {
                                return (React.createElement(React.Fragment, { key: index },
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, { style: { cursor: 'pointer' }, onClick: function () { return _this.toggleExpandCell(index); } },
                                            !item.expanded && React.createElement(ExpandMore, null),
                                            item.expanded && React.createElement(ExpandLess, null)),
                                        React.createElement(TableCell, null, item.title),
                                        React.createElement(TableCell, null, MedicalRecordTypeLabelsEnum[item.type]),
                                        React.createElement(TableCell, null, formatDatetimeLong(item.createdAt)),
                                        React.createElement(TableCell, null, item.sharedToPatient && (React.createElement("div", { style: {
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            } },
                                            React.createElement(Tooltip, { title: "Shared on " + formatDatetime(item.sharedToPatientAt) },
                                                React.createElement(PeopleIcon, null))))),
                                        React.createElement(TableCell, null,
                                            React.createElement("div", { style: {
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                } },
                                                React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, item); } },
                                                    React.createElement(MoreVertIcon, null))))),
                                    React.createElement(ExpandableTableRow, { colSpan: 6, expanded: item.expanded },
                                        React.createElement(RdiTypography, { variant: "body2", style: { padding: '10px 0px' } }, "Files"),
                                        item.records
                                            .filter(function (x) { return !x.deleted; })
                                            .map(function (file, fileIndex) { return (React.createElement(RdiFileDescription, { config: config, key: fileIndex, file: file })); }))));
                            }),
                            !medicalRecordsLoading && totalItems === 0 && (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 6 }, "No results"))))))),
                !medicalRecordsLoading && (React.createElement(RdiTablePagination, { count: totalItems, rowsPerPage: medicalRecordFilters.rowsPerPage, page: medicalRecordFilters.page, onChangePage: function (e, newPage) { return changeMedicalRecordFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                        return changeMedicalRecordFilters({
                            rowsPerPage: parseInt(e.target.value, 10),
                            page: 1
                        });
                    } })),
                React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: this.rowMenuOpen, onClose: this.handleMenuClose },
                    React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.MEDICAL_RECORD
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Edit")),
                    React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.MEDICAL_RECORD
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Delete"))),
                medicalRecordsLoading && (React.createElement(FlexContainer, { style: { marginTop: '30px' }, justifyContent: "center" },
                    React.createElement(CircularProgress, null))),
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: SiteRoutes.NEW_MEDICAL_RECORD_ROUTE },
                        React.createElement(AddMedicalRecord, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_MEDICAL_RECORD_ROUTE },
                        React.createElement(AddMedicalRecord, null))))));
    };
    __decorate([
        observable
    ], MedicalRecordsPage.prototype, "menuRef", void 0);
    __decorate([
        computed
    ], MedicalRecordsPage.prototype, "rowMenuOpen", null);
    __decorate([
        observable
    ], MedicalRecordsPage.prototype, "medicalRecordInEdit", void 0);
    MedicalRecordsPage = __decorate([
        injectPatientStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], MedicalRecordsPage);
    return MedicalRecordsPage;
}(Component));
export default withStyles(styles)(withRouter(MedicalRecordsPage));
