import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import React from 'react';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { Typography } from '@material-ui/core';
var PresentationLibraryPage = function () {
    var videoUrls = [
        {
            title: 'MediSci Apple Health',
            url: 'https://drive.google.com/file/d/1tODa9zkcSSlZITMBAnlf2b4mp_JGiMwy/preview'
        },
        {
            title: 'MediSci Demo - Chatbot',
            url: 'https://drive.google.com/file/d/1cTgIS122i4tW1yO89LMxZXA3IYsgyLYa/preview'
        },
        {
            title: 'MediSci DEMO - resend invite e-mail',
            url: 'https://drive.google.com/file/d/1Bm8kkwvGVo2wgmxAYSdD5sz0sQZCVEWK/preview'
        },
        {
            title: 'MediSci_Demo_Final_Version',
            url: 'https://drive.google.com/file/d/1JygqrU13RWChXmSaYS2LH4_SCTRd7z6i/preview'
        }
    ];
    return (React.createElement(FixedWidthContent, { style: { maxWidth: '780px' }, white: true },
        React.createElement(RdiTypography, { variant: "h1", style: { color: TypographyColorsEnum.Primary } }, "Presentation Library"),
        React.createElement(RdiLine, null),
        React.createElement(FlexContainer, { flexDirection: "row", flexWrap: "wrap" }, videoUrls.map(function (file) { return (React.createElement(FlexContainer, { flexDirection: "column", style: { margin: '12px' } },
            React.createElement(Typography, null, file.title),
            React.createElement("iframe", { title: file.title, src: file.url, width: "320", height: "240", allow: "autoplay;fullscreen" }))); }))));
};
export default PresentationLibraryPage;
