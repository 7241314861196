var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { createStyles, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () { return createStyles({}); };
var PhoneInfoPage = /** @class */ (function (_super) {
    __extends(PhoneInfoPage, _super);
    function PhoneInfoPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PhoneInfoPage.prototype.render = function () {
        var patient = this.props.patientStore.patient;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: {
                    marginBottom: 30,
                    width: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                } },
                React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary, style: { marginBottom: '45px' } }, "Smartphone info"),
                React.createElement(FlexContainer, { flexDirection: "column", margin: 20, alignItems: "flex-start" },
                    React.createElement(FlexContainer, { margin: 7 },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "App version:"),
                        React.createElement("div", null, patient.phoneAppVersion || '[unknown]')),
                    React.createElement(FlexContainer, { margin: 7 },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "Operating system version:"),
                        React.createElement("div", null, patient.phoneOsVersion || '[unknown]')),
                    React.createElement(FlexContainer, { margin: 7 },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "Platform:"),
                        React.createElement("div", null, patient.phonePlatform || '[unknown]')),
                    React.createElement(FlexContainer, { margin: 7 },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "Notifications enabled:"),
                        React.createElement("div", null,
                            patient.phoneNotificationsEnabled === true && 'true',
                            patient.phoneNotificationsEnabled === false && 'false',
                            !(patient.phoneNotificationsEnabled === true ||
                                patient.phoneNotificationsEnabled === false) && '[unknown]'))))));
    };
    PhoneInfoPage = __decorate([
        injectPatientStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], PhoneInfoPage);
    return PhoneInfoPage;
}(Component));
export default withStyles(styles)(withRouter(PhoneInfoPage));
