var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DateSelectorStateEnum } from '@cros/shared/constants/enums/DateSelectorStateEnum';
import { DeviceDataGroupByEnum } from '@cros/shared/constants/enums/DeviceDataGroupByEnum';
import { getTimezone } from '@cros/shared/utils/dateUtils';
import { getUrl } from '@cros/shared/utils/httpUtils';
import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, endOfYear, format, getDate, getDay, getDaysInMonth, getHours, getMinutes, getMonth, getWeek, getYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import { computed, observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import DeviceService from '~/services/DeviceService';
var DeviceDataStore = /** @class */ (function () {
    function DeviceDataStore(enrollmentStore) {
        var _this = this;
        this.enrollmentStore = enrollmentStore;
        this.deviceData = [];
        this.count = 0;
        this.filters = {
            page: 1,
            rowsPerPage: 10000,
            orderBy: 'timestamp',
            orderDir: 'ASC',
            timestampFrom: undefined,
            timestampTo: undefined,
            range: undefined,
            groupBy: undefined,
            timezone: getTimezone()
        };
        this.selectorState = DateSelectorStateEnum.month;
        this.selectedDate = new Date();
        this.loading = false;
        this.setSelectorState = function (newState) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.selectorState = newState;
                        return [4 /*yield*/, this.changeDateFilters()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.setSelectedDate = function (newState) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.selectedDate = newState;
                        return [4 /*yield*/, this.changeDateFilters()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.setSelectedDevice = function (selectedDevice) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.selectedDevice = selectedDevice;
                        return [4 /*yield*/, this.setSelectedDate(this.selectedDate ? this.selectedDate : new Date())];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.changeDateFilters = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.selectorState;
                        switch (_a) {
                            case DateSelectorStateEnum.day: return [3 /*break*/, 1];
                            case DateSelectorStateEnum.week: return [3 /*break*/, 3];
                            case DateSelectorStateEnum.month: return [3 /*break*/, 5];
                            case DateSelectorStateEnum.year: return [3 /*break*/, 7];
                        }
                        return [3 /*break*/, 9];
                    case 1: return [4 /*yield*/, this.changeFilters({
                            timestampFrom: startOfDay(this.selectedDate),
                            timestampTo: endOfDay(this.selectedDate),
                            groupBy: undefined
                        })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 10];
                    case 3: return [4 /*yield*/, this.changeFilters({
                            timestampFrom: startOfDay(startOfWeek(this.selectedDate, {
                                weekStartsOn: config.weekStartsOn
                            })),
                            timestampTo: endOfDay(endOfWeek(this.selectedDate, { weekStartsOn: config.weekStartsOn })),
                            groupBy: DeviceDataGroupByEnum.DAY
                        })];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 10];
                    case 5: return [4 /*yield*/, this.changeFilters({
                            timestampFrom: startOfDay(startOfMonth(this.selectedDate)),
                            timestampTo: endOfDay(endOfMonth(this.selectedDate)),
                            groupBy: DeviceDataGroupByEnum.DAY
                        })];
                    case 6:
                        _b.sent();
                        return [3 /*break*/, 10];
                    case 7: return [4 /*yield*/, this.changeFilters({
                            timestampFrom: startOfDay(startOfYear(this.selectedDate)),
                            timestampTo: endOfDay(endOfYear(this.selectedDate)),
                            groupBy: DeviceDataGroupByEnum.MONTH
                        })];
                    case 8:
                        _b.sent();
                        return [3 /*break*/, 10];
                    case 9: return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        }); };
        this.changeFilters = function (newFilters) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.filters = __assign(__assign({}, this.filters), newFilters);
                        return [4 /*yield*/, this.getDeviceData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.getDeviceData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, enrollmentId, authToken, errorModalStore, res, _b, _c, _d, e_1;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        // some enrollments have no devices
                        if (!this.selectedDevice) {
                            return [2 /*return*/];
                        }
                        _a = this.enrollmentStore, siteId = _a.siteId, enrollmentId = _a.enrollmentId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        this.loading = true;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = DeviceService).getDeviceData;
                        _d = [siteId,
                            enrollmentId,
                            this.selectedDevice.type,
                            this.filters];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        res = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        errorModalStore.setError(e_1);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.deviceData = res.items;
                            _this.count = res.count;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.getExportUrl = function (average) {
            var _a = _this.enrollmentStore, siteId = _a.siteId, enrollmentId = _a.enrollmentId;
            var exportFilters = __assign({}, _this.filters);
            var date = exportFilters.timestampFrom;
            var groupBy = exportFilters.groupBy;
            delete exportFilters.groupBy;
            delete exportFilters.timestampFrom;
            delete exportFilters.timestampTo;
            var year = getYear(date);
            var month = "" + (getMonth(date) + 1 < 10 ? '0' : '') + (getMonth(date) + 1);
            var week = "" + (getWeek(date) < 10 ? '0' : '') + getWeek(date);
            var day = "" + (getDate(date) < 10 ? '0' : '') + getDate(date);
            // construct regex /^(?<year>[0-9]{4})(-((?<month>[0-9]{2}(-(?<day>[0-9]{2}))?)|(W(?<week>[0-9]{2}))))?$/
            var range = "" + year + (_this.selectorState === DateSelectorStateEnum.day ||
                _this.selectorState === DateSelectorStateEnum.month
                ? "-" + month
                : '') + (_this.selectorState === DateSelectorStateEnum.week ? "-W" + week : '') + (_this.selectorState === DateSelectorStateEnum.day ? "-" + day : '');
            var url = getUrl(config, "/site/" + siteId + "/enrollment/" + enrollmentId + "/device/" + _this.selectedDevice.type + "/export", __assign(__assign(__assign({}, exportFilters), { range: range }), (average ? { groupBy: groupBy } : {})));
            return url;
        };
    }
    Object.defineProperty(DeviceDataStore.prototype, "graphData", {
        get: function () {
            var _this = this;
            if (!this.selectedDevice) {
                return [];
            }
            var allLinesData = [];
            var _loop_1 = function (chartLine) {
                var data = void 0;
                var key = chartLine.key, color = chartLine.color;
                switch (this_1.selectorState) {
                    case DateSelectorStateEnum.day:
                        data = this_1.deviceData.map(function (x) { return ({
                            x: Math.floor(getHours(x.timestamp)) + Math.floor(getMinutes(x.timestamp)) / 60,
                            y: x.data[key],
                            color: x.getCategory(_this.selectedDevice)
                                ? x.getCategory(_this.selectedDevice).color
                                : 'black',
                            symbol: x.isManualEntry ? 'square' : undefined
                        }); });
                        break;
                    case DateSelectorStateEnum.week:
                        data = this_1.deviceData.map(function (x) { return ({
                            x: (getDay(x.timestamp) - 1) % 7,
                            y: x.data[key],
                            color: x.getCategory(_this.selectedDevice)
                                ? x.getCategory(_this.selectedDevice).color
                                : 'black',
                            symbol: x.isManualEntry ? 'square' : undefined
                        }); });
                        break;
                    case DateSelectorStateEnum.month:
                        data = this_1.deviceData.map(function (x) { return ({
                            x: getDate(x.timestamp) - 1,
                            y: x.data[key],
                            color: x.getCategory(_this.selectedDevice)
                                ? x.getCategory(_this.selectedDevice).color
                                : 'black',
                            symbol: x.isManualEntry ? 'square' : undefined
                        }); });
                        break;
                    case DateSelectorStateEnum.year:
                        data = this_1.deviceData.map(function (x) { return ({
                            x: getMonth(x.timestamp),
                            y: x.data[key],
                            color: x.getCategory(_this.selectedDevice)
                                ? x.getCategory(_this.selectedDevice).color
                                : 'black',
                            symbol: x.isManualEntry ? 'square' : undefined
                        }); });
                        break;
                    default:
                        break;
                }
                // // remove duplicates, temporary
                // data = data.filter(
                //   (item: RdiGraphData, index: number, list: RdiGraphData[]) =>
                //     list.findIndex(dataItem => item.x === dataItem.x) === index
                // );
                allLinesData.push({
                    data: data,
                    color: color
                });
            };
            var this_1 = this;
            for (var _i = 0, _a = this.selectedDevice.chartProperties.chartKeys; _i < _a.length; _i++) {
                var chartLine = _a[_i];
                _loop_1(chartLine);
            }
            return allLinesData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeviceDataStore.prototype, "graphDomain", {
        get: function () {
            if (!this.selectedDevice) {
                return null;
            }
            var xMax = 0;
            var xMin = 0;
            var yMax = this.selectedDevice.chartProperties.yMax;
            var yMin = this.selectedDevice.chartProperties.yMin;
            switch (this.selectorState) {
                case DateSelectorStateEnum.day:
                    xMax = 24;
                    break;
                case DateSelectorStateEnum.week:
                    xMax = 6;
                    break;
                case DateSelectorStateEnum.month:
                    xMax = getDaysInMonth(this.selectedDate) - 1;
                    break;
                case DateSelectorStateEnum.year:
                    xMax = 11;
                    break;
                default:
                    break;
            }
            return { xMin: xMin, xMax: xMax, yMax: yMax, yMin: yMin };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeviceDataStore.prototype, "graphTickValues", {
        get: function () {
            if (!this.selectedDevice) {
                return null;
            }
            var y = this.selectedDevice.chartProperties.yTickValues;
            var x;
            switch (this.selectorState) {
                case DateSelectorStateEnum.day:
                    x = Array.from({ length: 24 }, function (data, i) { return (i % 2 === 0 ? i : undefined); });
                    break;
                case DateSelectorStateEnum.week:
                    x = Array.from({ length: 7 }, function (data, i) { return i; });
                    break;
                case DateSelectorStateEnum.month:
                    x = Array.from({ length: getDaysInMonth(this.selectedDate) + 1 }, function (data, i) { return i; });
                    break;
                case DateSelectorStateEnum.year:
                    x = Array.from({ length: 12 }, function (data, i) { return i; });
                    break;
                default:
                    break;
            }
            return { x: x, y: y };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeviceDataStore.prototype, "graphTickFormat", {
        get: function () {
            var _this = this;
            switch (this.selectorState) {
                case DateSelectorStateEnum.day:
                    return { x: function (d) { return d + "h"; } };
                case DateSelectorStateEnum.week:
                    return {
                        x: function (d) {
                            return "" + format(addDays(startOfWeek(_this.selectedDate, {
                                weekStartsOn: config.weekStartsOn
                            }), d), 'EE');
                        }
                    };
                case DateSelectorStateEnum.month:
                    return { x: function (d) { return "" + (d + 1); } };
                case DateSelectorStateEnum.year:
                    return {
                        x: function (d) { return "" + format(addMonths(startOfYear(_this.selectedDate), d), 'LLL'); }
                    };
                default:
                    return {};
            }
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], DeviceDataStore.prototype, "selectedDevice", void 0);
    __decorate([
        observable
    ], DeviceDataStore.prototype, "deviceData", void 0);
    __decorate([
        observable
    ], DeviceDataStore.prototype, "count", void 0);
    __decorate([
        observable
    ], DeviceDataStore.prototype, "filters", void 0);
    __decorate([
        observable
    ], DeviceDataStore.prototype, "selectorState", void 0);
    __decorate([
        observable
    ], DeviceDataStore.prototype, "selectedDate", void 0);
    __decorate([
        observable
    ], DeviceDataStore.prototype, "loading", void 0);
    __decorate([
        computed
    ], DeviceDataStore.prototype, "graphData", null);
    __decorate([
        computed
    ], DeviceDataStore.prototype, "graphDomain", null);
    __decorate([
        computed
    ], DeviceDataStore.prototype, "graphTickValues", null);
    __decorate([
        computed
    ], DeviceDataStore.prototype, "graphTickFormat", null);
    return DeviceDataStore;
}());
export { DeviceDataStore };
export var injectDeviceDataStore = inject('deviceDataStore');
