var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Enrollment } from "s~/types/models/Enrollment";
import { Model } from "s~/types/models/Model";
import { Patient } from "s~/types/models/Patient";
import { Trial } from "s~/types/models/Trial";
import { User } from "s~/types/models/User";
var AuditLog = /** @class */ (function (_super) {
    __extends(AuditLog, _super);
    function AuditLog(p) {
        var _this = _super.call(this, p) || this;
        _this.id = p.id;
        _this.action = p.action;
        _this.author = p.author ? new User(p.author) : undefined;
        _this.authorId = p.authorId;
        _this.authorCompanyId = p.authorCompanyId;
        _this.authorType = p.authorType;
        _this.changed = p.changed;
        _this.txTime = p.txTime ? new Date(p.txTime) : undefined;
        _this.txId = p.txId;
        _this.related = p.related
            ? {
                trial: p.related.trial ? new Trial(p.related.trial) : undefined,
                enrollment: p.related.enrollment
                    ? new Enrollment(p.related.enrollment)
                    : undefined,
                patient: p.related.patient
                    ? new Patient(p.related.patient)
                    : undefined
            }
            : undefined;
        return _this;
    }
    return AuditLog;
}(Model));
export { AuditLog };
