var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { AppBar, createStyles, Tab, Tabs, withStyles } from '@material-ui/core';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { Link as RLink } from 'react-router-dom';
import AddTask from '~/pages/site/site-detail/tasks/AddTask';
import AlarmListPage from '~/pages/site/site-detail/tasks/AlarmListPage';
import ResolvedTaskListPage from '~/pages/site/site-detail/tasks/ResolvedTaskListPage';
import TaskListPage from '~/pages/site/site-detail/tasks/TaskListPage';
import { getTasksAlarmRoute, getTasksResolvedRoute, getTasksRoute, getTasksTaskRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { TaskStore } from '~/stores/TaskStore';
import TaskModal from './TaskModal';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {},
            _a),
        menuContainer: (_b = {
                marginBottom: '20px',
                alignItems: 'center',
                marginTop: '30px',
                flexWrap: 'wrap'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginTop: '0px'
            },
            _b)
    });
};
var TasksIndexPage = /** @class */ (function (_super) {
    __extends(TasksIndexPage, _super);
    function TasksIndexPage(props) {
        var _this = _super.call(this, props) || this;
        _this.tabs = [
            {
                id: 'alarms',
                label: function (taskCounts) { return "Alarm (" + (taskCounts && taskCounts.alarm ? taskCounts.alarm : 0) + ")"; },
                url: function (siteId) { return getTasksAlarmRoute(siteId); }
            },
            {
                id: 'tasks',
                label: function (taskCounts) { return "Task (" + (taskCounts && taskCounts.task ? taskCounts.task : 0) + ")"; },
                url: function (siteId) { return getTasksTaskRoute(siteId); }
            },
            {
                id: 'resolved',
                label: function (taskCounts) {
                    return "Resolved (" + (taskCounts && taskCounts.resolved ? taskCounts.resolved : 0) + ")";
                },
                url: function (siteId) { return getTasksResolvedRoute(siteId); }
            }
        ];
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.taskStore.getAvailableTaskFilters()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getAddTaskUrl = function () {
            var _a = _this.props, url = _a.match.url, search = _a.location.search;
            return url + "/new" + search;
        };
        _this.getTab = function () {
            var pathname = _this.props.location.pathname;
            var regex = new RegExp(['/new', '/edit', '/assign', '/detail'].join('|'), 'g');
            var tab = pathname.split(regex)[0];
            return tab;
        };
        var _a = _this.props, siteStore = _a.siteStore, location = _a.location, history = _a.history;
        _this.taskStore = new TaskStore(siteStore, location.search, history);
        return _this;
    }
    TasksIndexPage.prototype.render = function () {
        var _a = this.props, siteId = _a.siteStore.siteId, pathname = _a.location.pathname, siteStore = _a.siteStore, accountStore = _a.accountStore, classes = _a.classes;
        var _b = this.taskStore, taskCounts = _b.taskCounts, filters = _b.filters, filterLabels = _b.filterLabels, availableFilters = _b.availableFilters, changeFilters = _b.changeFilters;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(Provider, { taskStore: this.taskStore },
                React.createElement(Route, { exact: true, path: SiteRoutes.TASKS_NOTAB_ROUTE },
                    React.createElement(Redirect, { to: "" + getTasksAlarmRoute(siteId) })),
                React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "Tasks"),
                React.createElement(FlexContainer, { className: classes.menuContainer, alignItems: "flex-end", margin: 10 },
                    React.createElement(RdiInput, { style: { width: '200px' }, type: "search", name: "search", placeholder: "Search", value: filters.search, onChange: function (e) { return changeFilters({ search: e.value }); } }),
                    !!availableFilters && (React.createElement(React.Fragment, null,
                        React.createElement(RdiInput, { type: "select", label: "Patient", name: "filter-patientId", style: { width: '180px' }, value: filters.patientId, selectedItemLabel: filterLabels.patientId, onChange: function (e) {
                                return e.value
                                    ? changeFilters({ patientId: e.value }, { patientId: e.label })
                                    : changeFilters({ patientId: null }, { patientId: 'All' });
                            }, options: __spreadArrays([{ label: 'All', slug: null }], availableFilters.patientId).map(function (patientId) { return ({
                                value: patientId.slug,
                                label: patientId.label
                            }); }) }),
                        React.createElement(RdiInput, { type: "select", label: "Trial", name: "filter-trialId", style: { width: '180px' }, value: filters.trialId, selectedItemLabel: filterLabels.trialId, onChange: function (e) {
                                return e.value
                                    ? changeFilters({ trialId: e.value }, { trialId: e.label })
                                    : changeFilters({ trialId: null }, { trialId: 'All' });
                            }, options: __spreadArrays([{ label: 'All', slug: null }], availableFilters.trialId).map(function (trialId) { return ({
                                value: trialId.slug,
                                label: trialId.label
                            }); }) }),
                        React.createElement(RdiInput, { type: "select", label: "Assignee", name: "filter-assigneeId", style: { width: '180px' }, value: filters.assigneeId, selectedItemLabel: filterLabels.assigneeId, onChange: function (e) {
                                return e.value
                                    ? changeFilters({ assigneeId: e.value }, { assigneeId: e.label })
                                    : changeFilters({ assigneeId: null }, { assigneeId: 'All' });
                            }, options: __spreadArrays([{ label: 'All', slug: null }], availableFilters.assigneeId).map(function (assigneeId) { return ({
                                value: assigneeId.slug,
                                label: assigneeId.label
                            }); }) }))),
                    React.createElement("div", { style: { flex: 1 } }),
                    React.createElement(RdiButton, { name: "new-task", to: this.getAddTaskUrl, permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.TASK
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Create a task")),
                pathname !== getTasksRoute(siteId) && (React.createElement(AppBar, { position: "static", color: "default" },
                    React.createElement(Tabs, { value: this.getTab(), indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" }, this.tabs.map(function (tab) { return (React.createElement(Tab, { key: tab.id, value: tab.url(siteId), component: RLink, to: "" + tab.url(siteId), label: "" + tab.label(taskCounts), id: tab.id })); })))),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: SiteRoutes.ALARM_LIST_ROUTE },
                        React.createElement(AlarmListPage, null)),
                    React.createElement(Route, { path: SiteRoutes.TASK_LIST_ROUTE },
                        React.createElement(TaskListPage, null)),
                    React.createElement(Route, { path: SiteRoutes.RESOLVED_TASK_LIST_ROUTE },
                        React.createElement(ResolvedTaskListPage, null))),
                React.createElement(Route, { path: SiteRoutes.TASK_MODAL_ROUTE },
                    React.createElement(TaskModal, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.NEW_TASK_ROUTE },
                    React.createElement(AddTask, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_TASK_ROUTE },
                    React.createElement(AddTask, null)))));
    };
    TasksIndexPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], TasksIndexPage);
    return TasksIndexPage;
}(Component));
export default withStyles(styles)(withRouter(TasksIndexPage));
