export function usernameFromEmail(email) {
    return email
        .replace(/[`~!#$%^&*()|=?;:'",<>{}[\]\\/]/gi, "")
        .replace(/[@\-+_.]/g, "-");
}
export function toLowercase(str) {
    if (!str) {
        return str;
    }
    return str.toLowerCase();
}
