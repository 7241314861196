var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import autoSaveConfig from '@cros/shared/config/autoSaveConfig';
import { TaskStatusEnum } from '@cros/shared/constants/enums/TaskStatusEnum';
import { TaskTypeEnum } from '@cros/shared/constants/enums/TaskTypeEnum';
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { getUrl } from '@cros/shared/utils/httpUtils';
import { assignFilterLabels, assignFiltersFromQueryString, getURLSearchObject } from '@cros/shared/utils/queryStringUtils';
import debounce from 'lodash/debounce';
import { observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import config from '~/config';
import TaskService from '~/services/TaskService';
var TaskStore = /** @class */ (function () {
    function TaskStore(siteStore, searchString, history) {
        var _this = this;
        this.siteStore = siteStore;
        this.searchString = searchString;
        this.history = history;
        this.tasks = [];
        this.totalItems = 0;
        this.taskCounts = {};
        this.filters = {
            page: 1,
            rowsPerPage: config.defaultRowsPerPage,
            orderDir: 'DESC',
            orderBy: 'createdAt',
            assigneeId: undefined,
            patientId: undefined,
            trialId: undefined,
            type: undefined,
            status: undefined,
            priority: undefined,
            search: undefined
        };
        this.filterLabels = {
            assigneeId: 'All',
            patientId: 'All',
            trialId: 'All'
        };
        this.loading = false;
        this.changeFilters = function (newFilters, newLabels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.filters = __assign(__assign({}, this.filters), newFilters);
                        this.filterLabels = __assign(__assign({}, this.filterLabels), newLabels);
                        if (!!newFilters.search) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.listTasks()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.searchDebounce();
                        _a.label = 3;
                    case 3:
                        this.history.push(getURLSearchObject(this.filters));
                        return [2 /*return*/];
                }
            });
        }); };
        this.listTasks = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, resp, _a, _b, _c, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        this.loading = true;
                        this.searchDebounce.cancel();
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).listTasks;
                        _c = [siteId, this.filters];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.tasks = [];
                        this.totalItems = 0;
                        this.errorModalStore.setError(e_1);
                        this.loading = false;
                        return [2 /*return*/, false];
                    case 5:
                        runInAction(function () {
                            _this.tasks = resp.items;
                            _this.totalItems = resp.count;
                            _this.taskCounts = resp.counts ? resp.counts : {};
                            _this.loading = false;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.getTaskById = function (id) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, e_2, task;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).getTask;
                        _c = [siteId, id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        this.errorModalStore.setError(e_2);
                        return [2 /*return*/, null];
                    case 5:
                        task = this.tasks.find(function (x) { return x.id === resp.id; });
                        if (task) {
                            task.unreadNotesCount = 0;
                        }
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.changeStatus = function (task, status) { return __awaiter(_this, void 0, Promise, function () {
            var prevStatus, n;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        prevStatus = task.status;
                        task.status = status;
                        if (!(task.status === TaskStatusEnum.RESOLVED && prevStatus !== TaskStatusEnum.RESOLVED)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.resolveTask(task)];
                    case 1:
                        n = _a.sent();
                        if (n.type === TaskTypeEnum.ALARM) {
                            this.siteStore.siteInfo.counts.alarmsCount -= 1;
                        }
                        else {
                            this.siteStore.siteInfo.counts.tasksCount -= 1;
                        }
                        return [2 /*return*/, n];
                    case 2:
                        if (!(task.status !== TaskStatusEnum.RESOLVED && prevStatus === TaskStatusEnum.RESOLVED)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.unresolveTask(task)];
                    case 3:
                        n = _a.sent();
                        if (n.type === TaskTypeEnum.ALARM) {
                            this.siteStore.siteInfo.counts.alarmsCount += 1;
                        }
                        else {
                            this.siteStore.siteInfo.counts.tasksCount += 1;
                        }
                        return [2 /*return*/, n];
                    case 4: return [4 /*yield*/, this.changeTaskStatus(task)];
                    case 5:
                        n = _a.sent();
                        return [2 /*return*/, n];
                }
            });
        }); };
        this.changePriority = function (task, priority) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                task.priority = priority;
                return [2 /*return*/, this.updateTask(task)];
            });
        }); };
        this.resolveTask = function (task) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, e_3;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).changeTaskStatus;
                        _c = [siteId, task.id, task];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _d.sent();
                        if (e_3.code === 'validation-exception') {
                            throw e_3;
                        }
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this.tasks.findIndex(function (ev) { return ev.id === resp.id; });
                            if (existingIndex >= 0) {
                                _this.tasks.splice(existingIndex, 1);
                            }
                            _this.totalItems -= 1;
                            if (task.type === TaskTypeEnum.TASK) {
                                _this.taskCounts.task -= 1;
                            }
                            else {
                                _this.taskCounts.alarm -= 1;
                            }
                            _this.taskCounts.resolved = _this.taskCounts.resolved ? _this.taskCounts.resolved + 1 : 1;
                            task.status = resp.status;
                            task.resolvedAt = resp.resolvedAt;
                            task.resolvedBy = resp.resolvedBy;
                            task.resolvedById = resp.resolvedById;
                            if (task.billingCodes.filter(function (b) { return b.needsApproval; }).length > 0) {
                                _this.siteStore.siteInfo.counts.billedItemsNeedsApprovalCount += 1;
                            }
                        });
                        this.toastStore.addToast(new ToastMessage("Task " + resp.title + " successfully resolved."));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.changeAssignee = function (task, assigneeId) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, e_4;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (task.assigneeId === assigneeId) {
                            return [2 /*return*/, null];
                        }
                        task.assigneeId = assigneeId;
                        task.note = '';
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).changeAssignee;
                        _c = [siteId, task.id, task];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _d.sent();
                        if (e_4.code === 'validation-exception') {
                            throw e_4;
                        }
                        this.errorModalStore.setError(e_4);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this.tasks.findIndex(function (ev) { return ev.id === resp.id; });
                            if (existingIndex >= 0) {
                                _this.tasks[existingIndex].assigneeId = resp.assigneeId;
                                _this.tasks[existingIndex].assignee = resp.assignee;
                            }
                        });
                        this.toastStore.addToast(new ToastMessage("Task " + resp.generatedTitle + " assignee successfully updated."));
                        task.assignee = resp.assignee;
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.unresolveTask = function (task) { return __awaiter(_this, void 0, Promise, function () {
            var n;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        task.resolvedAt = undefined;
                        task.status = TaskStatusEnum.OPEN;
                        task.onResolveNote = '';
                        task.resolvedById = undefined;
                        return [4 /*yield*/, this.changeTaskStatus(task)];
                    case 1:
                        n = _a.sent();
                        runInAction(function () {
                            var existingIndex = _this.tasks.findIndex(function (ev) { return ev.id === task.id; });
                            if (existingIndex >= 0) {
                                _this.tasks.splice(existingIndex, 1);
                            }
                            if (task.type === TaskTypeEnum.TASK) {
                                _this.taskCounts.task = _this.taskCounts.task ? _this.taskCounts.task + 1 : 1;
                            }
                            else {
                                _this.taskCounts.alarm = _this.taskCounts.alarm ? _this.taskCounts.alarm + 1 : 1;
                            }
                            _this.taskCounts.resolved -= 1;
                        });
                        return [2 /*return*/, n];
                }
            });
        }); };
        this.deleteTask = function (task) { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, _a, _b, _c, e_5;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).deleteTask;
                        _c = [siteId, task.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_5 = _d.sent();
                        this.errorModalStore.setError(e_5);
                        return [2 /*return*/];
                    case 5:
                        if (task.type === TaskTypeEnum.ALARM) {
                            this.siteStore.siteInfo.counts.alarmsCount -= 1;
                        }
                        else {
                            this.siteStore.siteInfo.counts.tasksCount -= 1;
                        }
                        runInAction(function () {
                            var existingIndex = _this.tasks.findIndex(function (ev) { return ev.id === task.id; });
                            if (existingIndex >= 0) {
                                _this.tasks.splice(existingIndex, 1);
                            }
                            _this.totalItems -= 1;
                            _this.taskCounts.task -= 1;
                        });
                        this.toastStore.addToast(new ToastMessage("Task " + task.title + " deleted"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.deleteTaskWithConfirmation = function (task, onSuccess) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to delete task " + task.title + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deleteTask(task)];
                            case 1:
                                _a.sent();
                                if (onSuccess)
                                    onSuccess();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        // public unresolveTaskWithConfirmation = async (task: Task) => {
        //   this.confirmationModalStore.openModal(
        //     `Are you sure you want to unresolve task ${task.title}?`,
        //     () => this.unresolveTask(task)
        //   );
        // };
        this.updateTask = function (task) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, e_6;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).updateTask;
                        _c = [siteId, task.id, task];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _d.sent();
                        if (e_6.code === 'validation-exception') {
                            throw e_6;
                        }
                        this.errorModalStore.setError(e_6);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this.tasks.findIndex(function (ev) { return ev.id === resp.id; });
                            if (existingIndex >= 0) {
                                _this.tasks[existingIndex] = resp;
                            }
                        });
                        this.toastStore.addToast(new ToastMessage("Task " + resp.generatedTitle + " updated"));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.updateTaskBilling = function (task) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, e_7;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).updateTaskBilling;
                        _c = [siteId, task.id, task];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_7 = _d.sent();
                        if (e_7.code === 'validation-exception') {
                            throw e_7;
                        }
                        this.errorModalStore.setError(e_7);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this.tasks.findIndex(function (ev) { return ev.id === resp.id; });
                            if (existingIndex >= 0) {
                                _this.tasks[existingIndex].billingCodes = resp.billingCodes;
                                _this.tasks[existingIndex].icdCodes = resp.icdCodes;
                                _this.tasks[existingIndex].billingNote = resp.billingNote;
                            }
                        });
                        this.toastStore.addToast(new ToastMessage("Billing updated"));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.changeTaskStatus = function (task) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, e_8;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).changeTaskStatus;
                        _c = [siteId, task.id, task];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_8 = _d.sent();
                        if (e_8.code === 'validation-exception') {
                            throw e_8;
                        }
                        this.errorModalStore.setError(e_8);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this.tasks.findIndex(function (ev) { return ev.id === resp.id; });
                            if (existingIndex >= 0) {
                                _this.tasks[existingIndex].status = resp.status;
                                _this.tasks[existingIndex].resolvedAt = resp.resolvedAt;
                                _this.tasks[existingIndex].resolvedBy = resp.resolvedBy;
                                _this.tasks[existingIndex].resolvedById = resp.resolvedById;
                                _this.tasks[existingIndex].billingCodes = resp.billingCodes;
                                _this.tasks[existingIndex].icdCodes = resp.icdCodes;
                            }
                        });
                        this.toastStore.addToast(new ToastMessage("Task " + resp.generatedTitle + " updated"));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.addTask = function (task) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, e_9;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).addTask;
                        _c = [siteId, task];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_9 = _d.sent();
                        if (e_9.code === 'validation-exception') {
                            throw e_9;
                        }
                        this.errorModalStore.setError(e_9);
                        return [2 /*return*/, null];
                    case 5: return [4 /*yield*/, this.listTasks()];
                    case 6:
                        _d.sent();
                        if (task.type === TaskTypeEnum.ALARM) {
                            this.siteStore.siteInfo.counts.alarmsCount += 1;
                        }
                        else {
                            this.siteStore.siteInfo.counts.tasksCount += 1;
                        }
                        this.toastStore.addToast(new ToastMessage("New task " + resp.title + " added"));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.getAvailableTaskFilters = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, resp, _a, _b, _c, e_10;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (this.availableFilters) {
                            return [2 /*return*/];
                        }
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = TaskService).getAvailableTaskFilters;
                        _c = [siteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_10 = _d.sent();
                        this.errorModalStore.setError(e_10);
                        return [2 /*return*/];
                    case 5:
                        this.availableFilters = resp;
                        assignFilterLabels(this.filters, this.filterLabels, this.availableFilters);
                        return [2 /*return*/];
                }
            });
        }); };
        this.searchDebounce = debounce(this.listTasks, autoSaveConfig.autoSaveDelay);
        this.getExportTaskUrl = function (taskId) {
            var siteId = _this.siteStore.siteId;
            return getUrl(config, "/site/" + siteId + "/task/" + taskId + "/preview");
        };
        assignFiltersFromQueryString(searchString, this.filters);
    }
    Object.defineProperty(TaskStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TaskStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TaskStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TaskStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], TaskStore.prototype, "tasks", void 0);
    __decorate([
        observable
    ], TaskStore.prototype, "totalItems", void 0);
    __decorate([
        observable
    ], TaskStore.prototype, "taskCounts", void 0);
    __decorate([
        observable
    ], TaskStore.prototype, "filters", void 0);
    __decorate([
        observable
    ], TaskStore.prototype, "filterLabels", void 0);
    __decorate([
        observable
    ], TaskStore.prototype, "availableFilters", void 0);
    __decorate([
        observable
    ], TaskStore.prototype, "loading", void 0);
    return TaskStore;
}());
export { TaskStore };
export var injectTaskStore = inject('taskStore');
