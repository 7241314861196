var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Typography } from "@material-ui/core";
import React from "react";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
export var TypographyColorsEnum;
(function (TypographyColorsEnum) {
    TypographyColorsEnum["HeadlineColor"] = "HeadlineColor";
    TypographyColorsEnum["Black"] = "Black";
    TypographyColorsEnum["Error"] = "Error";
    TypographyColorsEnum["BlackMedium"] = "BlackMedium";
    TypographyColorsEnum["TrueBlack"] = "TrueBlack";
    TypographyColorsEnum["Primary"] = "Primary";
    TypographyColorsEnum["Success"] = "Success";
    TypographyColorsEnum["Placeholder"] = "Placeholder";
    TypographyColorsEnum["BlackSemiDark"] = "BlackSemiDark";
})(TypographyColorsEnum || (TypographyColorsEnum = {}));
var headlineColor = {
    color: ColorsEnum.BlueDark,
    opacity: 0.87
};
var blackColor = { color: "#000000" };
var errorColor = { color: ColorsEnum.Error, opacity: 0.6 };
var primaryColor = { color: ColorsEnum.Primary };
var blackMediumColor = {
    color: ColorsEnum.BlackMedium
};
var placeholderColor = {
    color: ColorsEnum.Placeholder
};
var successColor = {
    color: ColorsEnum.Success
};
var trueBlackColor = {
    color: "rgba(0, 0, 0, 0.8)",
    opacity: 0.87
};
var blackSemiDarkColor = {
    color: "rgba(0, 0, 0, 0.6)"
};
export var h7Style = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.25px",
    color: ColorsEnum.Black,
    opacity: 0.87
};
export var captionStyle = {
    paddingRight: "30px",
    alignItems: "flex-start",
    boxSizing: "border-box",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#7A7A7A"
};
var colorProvider = function (color) {
    switch (color) {
        case TypographyColorsEnum.HeadlineColor:
            return headlineColor;
        case TypographyColorsEnum.Black:
            return blackColor;
        case TypographyColorsEnum.Error:
            return errorColor;
        case TypographyColorsEnum.BlackMedium:
            return blackMediumColor;
        case TypographyColorsEnum.TrueBlack:
            return trueBlackColor;
        case TypographyColorsEnum.Primary:
            return primaryColor;
        case TypographyColorsEnum.Success:
            return successColor;
        case TypographyColorsEnum.Placeholder:
            return placeholderColor;
        case TypographyColorsEnum.BlackSemiDark:
            return blackSemiDarkColor;
        default:
            return {};
    }
};
var RdiTypography = function (props) {
    // const text = compile(getText(props.children)).tree;
    var text = props.children;
    return (React.createElement(Typography, { variant: props.variant, style: __assign(__assign(__assign(__assign(__assign({}, (props.bold ? { fontWeight: "bold" } : undefined)), (props.uppercase ? { textTransform: "uppercase" } : undefined)), (props.variant === "caption" ? captionStyle : undefined)), colorProvider(props.color)), props.style), className: props.className }, text));
};
export default RdiTypography;
