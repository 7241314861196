var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from "mobx";
import { SiteCountsDto } from "s~/types/dtos/SiteCountsDto";
import { Model } from "s~/types/models/Model";
import { User } from "s~/types/models/User";
var Site = /** @class */ (function (_super) {
    __extends(Site, _super);
    function Site(p) {
        var _this = _super.call(this, p) || this;
        if (!p) {
            return undefined;
        }
        _this.location = {};
        _this.contact = {};
        _this.id = p.id;
        _this.name = p.name;
        _this.counts = p.counts ? new SiteCountsDto(p.counts) : null;
        _this.timezone = p.timezone;
        _this.employeesOnCall = p.employeesOnCall
            ? p.employeesOnCall.map(function (e) { return new User(e); })
            : [];
        _this.employeesOnCallIds = p.employeesOnCallIds;
        _this.type = p.type;
        _this.contact = p.contact;
        _this.location = p.location;
        _this.email = p.email;
        return _this;
    }
    __decorate([
        observable
    ], Site.prototype, "counts", void 0);
    return Site;
}(Model));
export { Site };
