import { InputLabel, Tooltip } from "@material-ui/core";
import React from "react";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
import NoteQuestion from "s~/static/note-question.svg";
var RdiInputLabel = function (props) {
    var label = props.label, children = props.children, style = props.style, tooltip = props.tooltip, error = props.error;
    return (React.createElement(React.Fragment, null, (label || children) && (React.createElement(InputLabel, { error: !!error, style: style },
        React.createElement(FlexContainer, { margin: 10, style: { marginBottom: "8px" } },
            React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackSemiDark, style: style }, label || children),
            tooltip && (React.createElement(Tooltip, { title: tooltip },
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement("img", { src: NoteQuestion, alt: "img" }))))),
        error && (React.createElement(RdiTypography, { style: { marginBottom: "20px" }, variant: "caption", color: TypographyColorsEnum.Error }, error))))));
};
RdiInputLabel.defaultProps = {};
export default RdiInputLabel;
