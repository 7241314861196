export var PaymentStatusEnum;
(function (PaymentStatusEnum) {
    PaymentStatusEnum["PENDING"] = "pending";
    PaymentStatusEnum["IN_PROGRESS"] = "in-progress";
    PaymentStatusEnum["COMPLETE"] = "complete";
    PaymentStatusEnum["FAILED"] = "failed";
})(PaymentStatusEnum || (PaymentStatusEnum = {}));
export var PaymentStatusLabels;
(function (PaymentStatusLabels) {
    PaymentStatusLabels["pending"] = "Waiting for user";
    PaymentStatusLabels["in-progress"] = "In progress";
    PaymentStatusLabels["complete"] = "Complete";
    PaymentStatusLabels["failed"] = "Cancelled";
})(PaymentStatusLabels || (PaymentStatusLabels = {}));
