import React from "react";
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
var styles = function () {
    return createStyles({
        circle: {
            minWidth: function (props) { return props.radius; },
            height: function (props) { return props.radius; },
            backgroundColor: function (props) { return props.color; },
            borderRadius: function (props) { return props.radius / 2; },
            fontSize: "11px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 5px",
            color: function (props) { return props.fontColor; }
        }
    });
};
var RdiCircle = function (props) {
    return (React.createElement("div", { onClick: props.onClick, style: props.style, className: props.classes.circle + " circle", role: "button", tabIndex: 0 }, props.children));
};
export default withStyles(styles)(RdiCircle);
