var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { AppBar, Breadcrumbs, CircularProgress, createStyles, Tab, Tabs, withStyles } from '@material-ui/core';
import LocalPostOffice from '@material-ui/icons/LocalPostOffice';
import Phone from '@material-ui/icons/Phone';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { Link as RLink } from 'react-router-dom';
import EnrollmentPage from '~/pages/site/site-detail/trials/trial-detail/patient/EnrollmentPage';
import PatientDetailModal from '~/pages/site/site-detail/trials/trial-detail/patient/patient-detail/PatientDetailModal';
import { getAdverseEventsPatientRoute, getAuditRoute, getDemographicsRoute, getEnrollmentsRoute, getInsurancesRoute, getMedicalHistoryRoute, getMedicalRecordsRoute, getMedicationsRoute, getPatientRoute, getPatientsAllRoute, getPatientSharedFilesRoute, getTasksTaskRoute, getUserDetailsRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import PatientStore from '~/stores/PatientStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () {
    return createStyles({
        basicInfoContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        cardContainer: {
            marginBottom: '30px',
            display: 'grid',
            gridTemplateColumns: 'repeat( auto-fit, minmax(220px, 1fr) )',
            columnGap: '20px',
            '@media (max-width: 1280px)': {
                gridTemplateColumns: '1fr 1fr'
            },
            '@media (max-width: 900px)': {
                gridTemplateColumns: '1fr'
            }
        },
        trialTabsHeader: {
            zIndex: 100,
            position: 'relative',
            '&::after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '1px',
                backgroundColor: ColorsEnum.GreySuperlightLine,
                position: 'absolute',
                bottom: '0px',
                zIndex: '-1'
            }
        },
        trialTabs: {
            marginTop: '5px',
            borderBottomWidth: '0px',
            marginBottom: '0px',
            '& .MuiTab-root': {
                backgroundColor: ColorsEnum.GreyBG,
                border: "1px solid " + ColorsEnum.GreySuperlightLine,
                height: '60px',
                marginRight: '18px'
            },
            '& .MuiTab-wrapper': {
                alignItems: 'flex-start'
            },
            '& .MuiTabs-indicator': {
                top: 0,
                bottom: undefined
            },
            '& .Mui-selected': {
                backgroundColor: 'white',
                borderBottomColor: 'white'
            }
        }
    });
};
var PatientPage = /** @class */ (function (_super) {
    __extends(PatientPage, _super);
    function PatientPage(props) {
        var _this = _super.call(this, props) || this;
        _this.loading = true;
        _this.details = [
            {
                id: 'userdetails',
                label: 'Patient details',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getUserDetailsRoute(siteId, patientId, trialId, enrollmentId);
                },
                permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.USER
                    }
                ]
            },
            {
                id: 'insurances',
                label: 'Insurances',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getInsurancesRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'demographics',
                label: 'Demographics',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getDemographicsRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'medicalhistory',
                label: 'Medical history',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getMedicalHistoryRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'medicalrecords',
                label: 'Medical records',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getMedicalRecordsRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'medications',
                label: 'Medications',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getMedicationsRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'adverseevents',
                label: 'Adverse events',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getAdverseEventsPatientRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'trials',
                label: 'Trials',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getEnrollmentsRoute(siteId, patientId, trialId, enrollmentId);
                }
            },
            {
                id: 'tasks',
                label: 'Tasks',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getTasksTaskRoute(siteId) + "?patientId=" + patientId + "&trialId=" + trialId;
                }
            },
            {
                id: 'auditlog',
                label: 'Audit log',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return getAuditRoute(siteId) + "?patientId=" + patientId + "&trialId=" + trialId;
                }
            },
            {
                id: 'sharedfiles',
                label: 'Shared files',
                url: function (siteId, trialId, enrollmentId, patientId) {
                    return "" + getPatientSharedFilesRoute(siteId, patientId, trialId, enrollmentId);
                },
                permissions: [
                    {
                        aclOp: AclOpEnum.READ,
                        aclModule: AclModuleEnum.CLINIC_DOCUMENTS
                    }
                ]
            }
        ];
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var patientId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        patientId = this.props.match.params.patientId;
                        this.loading = true;
                        return [4 /*yield*/, this.patientStore.getPatient(patientId)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var siteStore = _this.props.siteStore;
        _this.patientStore = new PatientStore(siteStore);
        return _this;
    }
    PatientPage.prototype.render = function () {
        var _a = this.props, classes = _a.classes, _b = _a.match.params, siteId = _b.siteId, enrollmentId = _b.enrollmentId, trialId = _b.trialId, patientId = _b.patientId, siteStore = _a.siteStore, accountStore = _a.accountStore;
        var patient = this.patientStore.patient;
        return (React.createElement(Provider, { patientStore: this.patientStore },
            patient && !enrollmentId && (React.createElement(Route, { exact: true, path: SiteRoutes.PATIENT_ROUTE },
                React.createElement(Redirect, { to: getPatientRoute(siteId, patient === null || patient === void 0 ? void 0 : patient.id, patient === null || patient === void 0 ? void 0 : patient.enrollments[0].trialId, patient === null || patient === void 0 ? void 0 : patient.enrollments[0].id) }))),
            this.loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null))),
            !this.loading && (React.createElement("div", { style: { width: '100%' } },
                React.createElement(Breadcrumbs, { separator: "/", "aria-label": "breadcrumb" },
                    React.createElement(RdiLink, { to: getPatientsAllRoute(siteId), xs: true }, "Patients"),
                    React.createElement("span", { style: { color: 'black' } }, patient === null || patient === void 0 ? void 0 : patient.fullName)),
                React.createElement(FixedWidthContent, { white: true },
                    React.createElement(FlexContainer, { style: {
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap'
                        } },
                        React.createElement(RdiTypography, { style: { marginBottom: '0px' }, variant: "h1" }, patient === null || patient === void 0 ? void 0 : patient.fullName),
                        React.createElement("div", null,
                            React.createElement(FlexContainer, { margin: 20, alignItems: "center", flexWrap: "wrap" },
                                React.createElement(FlexContainer, { margin: 7 },
                                    React.createElement(LocalPostOffice, { style: {
                                            fontSize: '13px',
                                            color: ColorsEnum.BlackSemidark
                                        } }),
                                    React.createElement(RdiTypography, { variant: "body2" }, patient === null || patient === void 0 ? void 0 : patient.email)),
                                React.createElement(FlexContainer, { margin: 7 },
                                    React.createElement(Phone, { style: { fontSize: '13px' } }),
                                    React.createElement(RdiTypography, { variant: "body2", style: { color: ColorsEnum.BlackSemidark } }, patient === null || patient === void 0 ? void 0 :
                                        patient.phoneNumberPrefix, patient === null || patient === void 0 ? void 0 :
                                        patient.phoneNumber)),
                                React.createElement(FlexContainer, null,
                                    React.createElement(RdiTypography, { variant: "body2", style: { color: ColorsEnum.BlackSemidark } }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
                                React.createElement(FlexContainer, null,
                                    React.createElement(RdiTypography, { variant: "body2", style: { color: ColorsEnum.BlackSemidark } }, (patient === null || patient === void 0 ? void 0 : patient.genderDisplay) + ", " + (patient === null || patient === void 0 ? void 0 : patient.ageDisplay)))))),
                    React.createElement(RdiLine, { md: true }),
                    React.createElement(FlexContainer, { margin: 25, flexWrap: "wrap" }, this.details.map(function (item, index) { return (React.createElement(RdiLink, { id: item.id, key: index, to: "" + item.url(siteId, trialId, enrollmentId, patientId), aclCheck: function () { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(item.permissions, siteStore.siteId); }, aclHide: true }, item.label)); })),
                    React.createElement(RdiLine, { md: true }),
                    enrollmentId && (patient === null || patient === void 0 ? void 0 : patient.enrollments.find(function (e) { return e.id === enrollmentId; })) && (React.createElement(AppBar, { position: "static", color: "default", className: classes.trialTabsHeader },
                        React.createElement(Tabs, { value: enrollmentId, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", className: classes.trialTabs }, patient === null || patient === void 0 ? void 0 : patient.enrollments.map(function (tab) {
                            var _a, _b;
                            if (siteStore.trials.some(function (t) { return t.id === tab.trialId; })) {
                                return (React.createElement(Tab, { value: tab.id, component: RLink, to: getPatientRoute(siteId, tab.patientId, tab.trialId, tab.id), key: tab.id, icon: React.createElement("div", null,
                                        React.createElement("div", { style: {
                                                marginBottom: '3px',
                                                fontSize: '11px'
                                            } },
                                            React.createElement("b", null, (_a = siteStore.trials.find(function (t) { return t.id === tab.trialId; })) === null || _a === void 0 ? void 0 : _a.name)),
                                        React.createElement("div", { style: { textTransform: 'lowercase' } }, ((_b = siteStore.trials.find(function (t) { return t.id === tab.trialId; })) === null || _b === void 0 ? void 0 : _b.protocolId) ===
                                            'general' ? (React.createElement(RdiTypography, { variant: "subtitle2" },
                                            tab.numVisits,
                                            " visits")) : (React.createElement(RdiTypography, { variant: "subtitle2" },
                                            tab.progress,
                                            "% completed")))) }));
                            }
                            return null;
                        })))),
                    React.createElement(Route, { path: SiteRoutes.PATIENT_DETAIL_MODAL_ROUTE },
                        React.createElement(PatientDetailModal, null)),
                    React.createElement(Route, { path: SiteRoutes.PATIENT_TRIAL_ROUTE }, patient && React.createElement(EnrollmentPage, null)))))));
    };
    __decorate([
        observable
    ], PatientPage.prototype, "loading", void 0);
    PatientPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], PatientPage);
    return PatientPage;
}(Component));
export default withStyles(styles)(withRouter(PatientPage));
