var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable */
import React, { Component } from 'react';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import DateWithIcon from '@cros/shared/components/misc/DateWithIcon';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import GridContainer from '@cros/shared/components/misc/GridContainer';
import { RdiChart } from '@cros/shared/components/misc/RdiChart';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { EnrollmentStatusLabels } from '@cros/shared/constants/enums/EnrollmentStatusEnum';
import { VisitStatusEnum, VisitStatusLabelsEnum } from '@cros/shared/constants/enums/VisitStatusEnum';
import { CircularProgress, IconButton, Menu, TableBody, TableCell, TableHead, TableRow, createStyles, withStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import PatientCard from '~/components/patient/PatientCardContainer';
import { getPatientOverviewRoute, getTaskDetailRoute, getTasksDetailEditRoute, getTrialRoute } from '~/routing/Routes';
import CheckMarkIcon from '~/static/CheckMarkIcon.svg';
import InProgressSmallBlack from '~/static/InProgressSmallBlack.svg';
import InvitedArrow from '~/static/InvitedArrow.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import SiteStatisticStore from '~/stores/SiteStatisticStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { TaskStore } from '~/stores/TaskStore';
import { EnrollmentCountColorGradient } from '../../../../services/EnrollmentService';
var styles = function (theme) {
    var _a, _b, _c, _d, _e;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {},
            _a),
        grid: (_b = {
                gridGap: '10px',
                gridTemplateColumns: 'repeat(3, 1fr)'
            },
            _b[theme.breakpoints.down('md')] = {
                gridTemplateColumns: 'repeat(2, 1fr)'
            },
            _b[theme.breakpoints.down('xs')] = {
                gridTemplateColumns: 'repeat(1, 1fr)'
            },
            _b),
        patientText: (_c = {
                fontSize: '16px'
            },
            _c[theme.breakpoints.down('sm')] = {
                fontSize: '13px'
            },
            _c),
        patientTasks: (_d = {
                marginTop: '40px'
            },
            _d[theme.breakpoints.down('md')] = {
                width: '100%',
                flexDirection: 'column !important'
            },
            _d),
        header: (_e = {
                flexDirection: 'row'
            },
            _e[theme.breakpoints.down('sm')] = {
                flexDirection: 'column !important'
            },
            _e)
    });
};
var ClinicDashboardStatisticPage = /** @class */ (function (_super) {
    __extends(ClinicDashboardStatisticPage, _super);
    function ClinicDashboardStatisticPage(props) {
        var _this = _super.call(this, props) || this;
        _this.visitColumns = [
            {
                id: 'date',
                label: 'Date and time',
                sortable: true,
                sortName: 'enrollments__nextVisit__engagement',
                width: '25%'
            },
            {
                id: 'patient',
                label: 'Patient',
                sortable: false,
                width: '25%'
            },
            {
                id: 'trial',
                label: 'Trial',
                sortable: false,
                width: '25%'
            },
            {
                id: 'status',
                label: 'Status',
                width: '25%'
            }
        ];
        _this.menuRef = null;
        _this.handleMenuClick = function (event, task) {
            _this.menuRef = event.currentTarget;
            _this.taskInEdit = task;
        };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, siteId = _a.siteStore.siteId;
            _this.menuRef = null;
            history.push(getTasksDetailEditRoute(siteId, _this.taskInEdit.id, 'task'));
        };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.taskStore.deleteTaskWithConfirmation(this.taskInEdit, this.siteStatisticStore.listTasks)];
                    case 1:
                        _a.sent();
                        this.menuRef = null;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.formatStatus = function (patient) {
            if (!(patient === null || patient === void 0 ? void 0 : patient.nextVisit)) {
                return null;
            }
            var status = patient.nextVisit.status;
            return (React.createElement(FlexContainer, { alignItems: "center" },
                React.createElement("div", { style: { marginRight: '9px' } },
                    status === VisitStatusEnum.SCHEDULED && React.createElement("img", { alt: "img", src: InvitedArrow }),
                    status === VisitStatusEnum.COMPLETE && React.createElement("img", { alt: "checkmark", src: CheckMarkIcon }),
                    [
                        status === VisitStatusEnum.IN_PROGRESS,
                        VisitStatusEnum.FINALIZED,
                        VisitStatusEnum.UNSCHEDULED
                    ].includes(status) && React.createElement("img", { alt: "img", src: InProgressSmallBlack })),
                VisitStatusLabelsEnum[status]));
        };
        _this.hasPermissions = function (permissions) {
            var _a;
            var _b = _this.props, accountStore = _b.accountStore, siteStore = _b.siteStore;
            return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId);
        };
        var _a = _this.props, siteStore = _a.siteStore, location = _a.location, history = _a.history;
        _this.taskStore = new TaskStore(siteStore, location.search, history);
        _this.siteStatisticStore = new SiteStatisticStore(siteStore);
        return _this;
    }
    ClinicDashboardStatisticPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.siteStatisticStore.getPatients()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.siteStatisticStore.listTasks()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.siteStatisticStore.getTrialsEnrollmentsPatientCount()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClinicDashboardStatisticPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, accountStore = _a.accountStore, siteStore = _a.siteStore, classes = _a.classes;
        var siteId = siteStore.siteId;
        if (!this.hasPermissions([{ aclOp: AclOpEnum.READ, aclModule: AclModuleEnum.COMPANY_STATISTICS }])) {
            return null;
        }
        return (React.createElement(FixedWidthContent, { white: true, style: { maxWidth: '100%' } },
            React.createElement(FlexContainer, { justifyContent: "space-between", style: { flexWrap: 'wrap' }, className: classes.header },
                React.createElement(RdiTypography, { variant: "h1", className: classes.title, color: TypographyColorsEnum.Primary }, "Clinic Overview"),
                React.createElement(FlexContainer, null,
                    React.createElement(RdiInput, { type: "date", time: "startOfDay", label: "From", style: { width: '150px', minWidth: '150px' }, value: this.siteStatisticStore.filters.fromDate, onChange: function (e) {
                            return _this.siteStatisticStore.changeFilters({ fromDate: e.value });
                        } }),
                    React.createElement(RdiInput, { type: "date", time: "endOfDay", label: "To", value: this.siteStatisticStore.filters.toDate, onChange: function (e) {
                            return _this.siteStatisticStore.changeFilters({ toDate: e.value });
                        }, style: { width: '150px', minWidth: '150px' } }))),
            React.createElement(GridContainer, { className: classes.grid }, this.siteStatisticStore.trialsEnrollments.map(function (trial) {
                var _a;
                return (React.createElement(PatientCard, { key: trial.trialId }, trial.isLoading ? (React.createElement(CircularProgress, null)) : (React.createElement(React.Fragment, null,
                    React.createElement(FlexContainer, { justifyContent: "space-between" },
                        React.createElement(RdiTypography, { variant: "body1" }, trial.trialName),
                        React.createElement(RdiLink, { to: getTrialRoute(siteId, trial.trialId), style: { zIndex: 200 }, xs: true }, "View All")),
                    React.createElement(FlexContainer, { flexDirection: "row", alignItems: "flex-start", position: "relative", height: "215px", style: { marginTop: '-20px', marginLeft: '-10px', width: '360px' } },
                        React.createElement(RdiChart, { chartType: "pie", graphData: Object.entries(trial.count)
                                .filter(function (_a) {
                                var key = _a[0];
                                return key !== 'all';
                            })
                                .map(function (_a, index) {
                                var key = _a[0], value = _a[1];
                                return ({
                                    x: EnrollmentStatusLabels[key],
                                    y: value,
                                    fill: EnrollmentCountColorGradient[index]
                                });
                            }) }),
                        React.createElement(FlexContainer, { position: "absolute", flexDirection: "column", style: { top: '85px', left: '85px', fontSize: '16px' } },
                            React.createElement(RdiTypography, { variant: "subtitle1" }, ((_a = trial.count) === null || _a === void 0 ? void 0 : _a.all) || 0),
                            React.createElement(RdiTypography, { variant: "subtitle1" }, "Patients")),
                        React.createElement(FlexContainer, { flexDirection: "column", alignItems: "flex-start", style: { marginTop: '40px', marginLeft: '-15px' } }, Object.entries(trial.count)
                            .filter(function (_a) {
                            var key = _a[0];
                            return key !== 'all';
                        })
                            .map(function (_a, index) {
                            var key = _a[0], value = _a[1];
                            return (React.createElement(RdiTypography, { key: index, className: classes.patientText, style: { color: EnrollmentCountColorGradient[index] } }, EnrollmentStatusLabels[key] + ": " + value));
                        })))))));
            })),
            React.createElement(FlexContainer, { alignItems: "flex-start", className: classes.patientTasks },
                React.createElement(FlexContainer, { width: "100%", flexDirection: "column", alignItems: "flex-start", style: { marginRight: '24px' } },
                    React.createElement(RdiTypography, { variant: "h5", className: classes.title, color: TypographyColorsEnum.Primary, style: { margin: '0 12px 12px 12px' } }, "Upcoming Patient Visits"),
                    React.createElement(RdiTable, { wrapperStyle: { width: '100%' } },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, this.visitColumns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: _this.siteStatisticStore.changeVisitFilters, filters: _this.siteStatisticStore.visitFilters })); }))),
                        React.createElement(TableBody, null,
                            this.siteStatisticStore.patients.map(function (patient) {
                                var _a, _b, _c, _d, _e;
                                return (React.createElement(TableRow, { key: patient.id },
                                    React.createElement(TableCell, null,
                                        React.createElement(DateWithIcon, { displayType: "humanized date and time", value: patient.nextVisit ? patient.nextVisit.engagement : null })),
                                    React.createElement(TableCell, null,
                                        React.createElement(RdiLink, { to: getPatientOverviewRoute(siteId, patient.id), style: { fontWeight: 'bold' } }, patient.fullName)),
                                    React.createElement(TableCell, null,
                                        React.createElement(RdiLink, { to: getTrialRoute((_a = patient.nextVisit) === null || _a === void 0 ? void 0 : _a.siteId, (_b = patient.nextVisit) === null || _b === void 0 ? void 0 : _b.trialId) }, (_d = siteStore.getTrialById((_c = patient.nextVisit) === null || _c === void 0 ? void 0 : _c.trialId)) === null || _d === void 0 ? void 0 : _d.name)),
                                    React.createElement(TableCell, null, _this.formatStatus(patient.getEnrollmentByTrialId((_e = patient.nextVisit) === null || _e === void 0 ? void 0 : _e.trialId)))));
                            }),
                            this.siteStatisticStore.patients.length === 0 && (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 4 }, "No results"))))),
                    React.createElement(RdiTablePagination, { count: this.siteStatisticStore.visitsCount, rowsPerPage: this.siteStatisticStore.visitFilters.rowsPerPage, page: this.siteStatisticStore.visitFilters.page, onChangePage: function (e, newPage) {
                            return _this.siteStatisticStore.changeVisitFilters({ page: newPage + 1 });
                        }, onChangeRowsPerPage: function (e) {
                            return _this.siteStatisticStore.changeVisitFilters({
                                rowsPerPage: parseInt(e.target.value, 10),
                                page: 1
                            });
                        } })),
                React.createElement(PatientCard, { style: { marginTop: 0, minWidth: '380px' } },
                    React.createElement(FlexContainer, { justifyContent: "space-between" },
                        React.createElement(RdiTypography, { variant: "body2" }, "RECENT TASKS")),
                    React.createElement(FlexContainer, { flexDirection: "row", alignItems: "flex-start", position: "relative", minHeight: "220px", minWidth: "380px", maxWidth: "500px" },
                        React.createElement(RdiTable, { wrapperStyle: { width: '100%' } },
                            React.createElement(TableBody, null,
                                this.siteStatisticStore.recentTasks.map(function (task) {
                                    var _a, _b;
                                    return (React.createElement(TableRow, { key: task.id, style: { margin: 0, padding: 0 } },
                                        React.createElement(TableCell, { style: { margin: 0, padding: 0, minWidth: '250px' } },
                                            React.createElement(FlexContainer, { margin: 5, flexDirection: "column", alignItems: "flex-start" },
                                                React.createElement(RdiLink, { to: getTaskDetailRoute(siteId, task.id, 'task'), style: { margin: 0 }, xs: true }, task === null || task === void 0 ? void 0 : task.title),
                                                React.createElement(RdiTypography, { variant: "caption", style: { margin: 0 } }, (_a = task === null || task === void 0 ? void 0 : task.patient) === null || _a === void 0 ? void 0 : _a.fullName),
                                                React.createElement(RdiTypography, { variant: "caption" },
                                                    React.createElement("span", { style: { fontSize: '10px' } }, (_b = task === null || task === void 0 ? void 0 : task.patient) === null || _b === void 0 ? void 0 : _b.id)))),
                                        React.createElement(TableCell, { style: { margin: 0, padding: 0, width: '50px' } },
                                            React.createElement("div", null,
                                                React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, task); } },
                                                    React.createElement(MoreVertIcon, null))))));
                                }),
                                this.siteStatisticStore.recentTasks.length === 0 && (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { colSpan: 2 }, "No results")))))))),
            React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, onClose: function () {
                    _this.menuRef = null;
                }, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                        {
                            aclOp: AclOpEnum.UPDATE,
                            aclModule: AclModuleEnum.TASK
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Edit detail and patient")),
                React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                        {
                            aclOp: AclOpEnum.DELETE,
                            aclModule: AclModuleEnum.TASK
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Delete")))));
    };
    __decorate([
        observable
    ], ClinicDashboardStatisticPage.prototype, "menuRef", void 0);
    __decorate([
        observable
    ], ClinicDashboardStatisticPage.prototype, "taskInEdit", void 0);
    ClinicDashboardStatisticPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], ClinicDashboardStatisticPage);
    return ClinicDashboardStatisticPage;
}(Component));
export default withStyles(styles)(withRouter(ClinicDashboardStatisticPage));
