import React from 'react';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
var styles = function () {
    return createStyles({
        square: {
            minWidth: function (props) { return props.radius; },
            height: function (props) { return props.radius; },
            backgroundColor: function (props) { return props.color; },
            fontSize: '11px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 5px',
            color: function (props) { return props.fontColor; },
        },
    });
};
var RdiSquare = function (props) {
    return (React.createElement("div", { onClick: props.onClick, style: props.style, className: props.classes.square + " square", role: "button", tabIndex: 0 }, props.children));
};
export default withStyles(styles)(RdiSquare);
