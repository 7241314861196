import { createStyles, withStyles } from "@material-ui/core";
import React from "react";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
var styles = function (theme) {
    var _a;
    return createStyles({
        root: (_a = {
                flex: 1,
                maxWidth: function (props) {
                    return props.xs ? "800px" : "1180px";
                },
                width: "100%",
                margin: "0 auto",
                boxSizing: "border-box",
                padding: "0px 40px",
                display: "flex",
                flexDirection: "column"
            },
            _a[theme.breakpoints.down("xs")] = {
                padding: "0px 20px",
                paddingTop: "15px",
                "& >form, >div": {
                    justifyContent: function (props) {
                        return props.flex ? "space-between" : undefined;
                    },
                    height: function (props) {
                        return props.flex ? "100%" : undefined;
                    },
                    display: function (props) {
                        return props.flex ? "flex" : undefined;
                    },
                    flexDirection: function (props) {
                        return props.flex ? "column" : undefined;
                    }
                }
            },
            _a.backgroundColor = function (props) {
                return props.white ? ColorsEnum.White : undefined;
            },
            _a)
    });
};
var FixedWidthContent = function (props) {
    var classes = props.classes;
    return (React.createElement("div", { className: classes.root, style: props.style }, props.children));
};
export default withStyles(styles)(FixedWidthContent);
