var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { h7Style, TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { MedicationFrequencyEnum, MedicationFrequencyLabelsEnum } from '@cros/shared/constants/enums/MedicationFrequencyEnum';
import { MedicationRouteEnum, MedicationRouteLabelsEnum } from '@cros/shared/constants/enums/MedicationRouteEnum';
import { MedicationUnitEnum, MedicationUnitLabelsEnum } from '@cros/shared/constants/enums/MedicationUnitEnum';
import { getDateFromTimeAndDate } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectMedicationStore } from '~/stores/PatientStore/MedicationsStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () { return createStyles({}); };
var AddMedication = /** @class */ (function (_super) {
    __extends(AddMedication, _super);
    function AddMedication() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasErrors = false;
        _this.notDuringVisit = {
            id: 'NotDurringVisit',
            name: 'Not during visit'
        };
        _this.data = {
            title: {
                value: '',
                error: null,
                rule: 'required|min:2'
            },
            dose: {
                value: null,
                error: null,
                rule: 'required|integer'
            },
            unit: {
                value: null,
                error: null,
                label: '',
                rule: 'required'
            },
            route: {
                value: null,
                label: '',
                error: null,
                rule: 'required'
            },
            frequency: {
                value: null,
                label: '',
                error: null,
                rule: 'required'
            },
            startDate: {
                value: new Date(),
                error: null,
                rule: 'required|date'
            },
            stopDate: {
                value: null,
                error: null,
                rule: 'date'
            },
            indication: {
                value: '',
                error: null,
                rule: 'required'
            },
            visitId: {
                value: _this.notDuringVisit.id,
                label: 'Not during visit',
                error: null,
                rule: 'required'
            }
        };
        _this.loading = true;
        _this.saving = false;
        _this.errorMessage = '';
        _this.assignData = function (medication) {
            var _a, _b;
            _this.data.visitId.value = ((_a = medication.visit) === null || _a === void 0 ? void 0 : _a.id) || _this.notDuringVisit.id;
            _this.data.visitId.label = ((_b = medication.visit) === null || _b === void 0 ? void 0 : _b.name) || _this.notDuringVisit.name;
            _this.data.title.value = medication.title;
            _this.data.dose.value = medication.dose;
            _this.data.unit.value = medication.unit;
            _this.data.unit.label = MedicationUnitLabelsEnum[medication.unit];
            _this.data.route.value = medication.route;
            _this.data.route.label = MedicationRouteLabelsEnum[medication.route];
            _this.data.frequency.value = medication.frequency;
            _this.data.frequency.label = MedicationFrequencyLabelsEnum[medication.frequency];
            _this.data.startDate.value = medication.startDate;
            _this.data.stopDate.value = medication.stopDate ? medication.stopDate : undefined;
            _this.data.indication.value = medication.indication;
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, medicationStore, patientStore, medication;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, medicationStore = _a.medicationStore, patientStore = _a.patientStore;
                        return [4 /*yield*/, patientStore.listVisits()];
                    case 1:
                        _b.sent();
                        if (!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, medicationStore.getMedicationById(this.medicationId)];
                    case 2:
                        medication = _b.sent();
                        if (medication) {
                            this.assignData(medication);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        if (this.visitId) {
                            this.data.visitId.value = this.visitId;
                        }
                        _b.label = 4;
                    case 4:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            var medicationId = _this.medicationId || 'new';
            history.push("" + url.split((_this.isEdit ? '/edit' : '') + "/" + medicationId)[0]);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var medicationStore, medication, o, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        medicationStore = this.props.medicationStore;
                        e.preventDefault();
                        e.stopPropagation();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        o = this.getObjectToSave();
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!this.isEdit) return [3 /*break*/, 3];
                        return [4 /*yield*/, medicationStore.updateMedication(o)];
                    case 2:
                        medication = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, medicationStore.addMedication(o)];
                    case 4:
                        medication = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 7:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 8:
                        if (!medication) {
                            return [2 /*return*/];
                        }
                        this.onClose();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var midnight = new Date();
            midnight.setHours(0, 0, 0, 0);
            var o = {
                id: _this.medicationId,
                title: _this.data.title.value,
                unit: _this.data.unit.value,
                route: _this.data.route.value,
                frequency: _this.data.frequency.value,
                startDate: _this.data.startDate.value
                    ? getDateFromTimeAndDate(midnight, _this.data.startDate.value, true)
                    : undefined,
                stopDate: _this.data.frequency.value !== MedicationFrequencyEnum.ONCE && _this.data.stopDate.value
                    ? getDateFromTimeAndDate(midnight, _this.data.stopDate.value, true)
                    : undefined,
                indication: _this.data.indication.value,
                dose: _this.data.dose.value ? parseInt(_this.data.dose.value, 10) : undefined,
                visitId: _this.data.visitId.value === _this.notDuringVisit.id ? undefined : _this.data.visitId.value
            };
            return o;
        };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors);
        };
        return _this;
    }
    Object.defineProperty(AddMedication.prototype, "isEdit", {
        get: function () {
            var params = this.props.match.params;
            return !!params.medicationId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddMedication.prototype, "visitId", {
        get: function () {
            var params = this.props.match.params;
            return params.visitId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddMedication.prototype, "medicationId", {
        get: function () {
            var params = this.props.match.params;
            return params.medicationId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddMedication.prototype, "visits", {
        get: function () {
            var patientStore = this.props.patientStore;
            return __spreadArrays([this.notDuringVisit], (patientStore.visits || []));
        },
        enumerable: false,
        configurable: true
    });
    AddMedication.prototype.render = function () {
        var _this = this;
        var _a = this.props, patient = _a.patientStore.patient, accountStore = _a.accountStore, siteStore = _a.siteStore;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h5" }, (this.isEdit ? 'Edit' : 'New') + " medication change"),
            React.createElement(FlexContainer, { style: { marginTop: '10px', marginBottom: '15px' }, margin: 15 },
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, patient === null || patient === void 0 ? void 0 : patient.fullName),
                React.createElement(RdiTypography, { style: h7Style, variant: "h5" }, "Patient ID: " + (patient === null || patient === void 0 ? void 0 : patient.id))),
            React.createElement(RdiLine, null),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent },
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { label: "Drug name", type: "text", name: "title", value: this.data.title.value, errorText: this.data.title.error, onChange: function (e) { return _this.fieldChange(e, 'title'); }, autoFocus: true })),
                React.createElement(InputRow, null, !this.visitId && (React.createElement(React.Fragment, null,
                    React.createElement(RdiInput, { type: "select", label: "Visit", name: "visitId", value: this.data.visitId.value, selectedItemLabel: this.data.visitId.label, errorText: this.data.visitId.error, onChange: function (e) { return _this.fieldChange(e, 'visitId'); }, options: this.visits.map(function (visit) { return ({
                            label: visit.name,
                            value: visit.id
                        }); }), style: { width: 'calc(50% - 15px)' } }),
                    React.createElement("div", { style: { width: 'calc(50% - 15px)' } })))),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { label: "Dose", type: "number", name: "dose", value: this.data.dose.value, errorText: this.data.dose.error, onChange: function (e) { return _this.fieldChange(e, 'dose'); }, style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(RdiInput, { label: "Unit", type: "select", name: "unit", value: this.data.unit.value, selectedItemLabel: this.data.unit.label, errorText: this.data.unit.error, onChange: function (e) { return _this.fieldChange(e, 'unit'); }, options: Object.values(MedicationUnitEnum).map(function (enumType) { return ({
                            value: enumType,
                            label: MedicationUnitLabelsEnum[enumType]
                        }); }), style: { width: 'calc(50% - 15px)' } })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { label: "Route", type: "select", name: "route", value: this.data.route.value, selectedItemLabel: this.data.route.label, errorText: this.data.route.error, onChange: function (e) { return _this.fieldChange(e, 'route'); }, options: Object.values(MedicationRouteEnum).map(function (enumType) { return ({
                            value: enumType,
                            label: MedicationRouteLabelsEnum[enumType]
                        }); }), style: { width: 'calc(50% - 15px)' } }),
                    React.createElement(RdiInput, { label: "Frequency", type: "select", name: "frequency", value: this.data.frequency.value, selectedItemLabel: this.data.frequency.label, errorText: this.data.frequency.error, onChange: function (e) { return _this.fieldChange(e, 'frequency'); }, options: Object.values(MedicationFrequencyEnum).map(function (enumType) { return ({
                            value: enumType,
                            label: MedicationFrequencyLabelsEnum[enumType]
                        }); }), style: { width: 'calc(50% - 15px)' } })),
                React.createElement(InputRow, null,
                    React.createElement(RdiInput, { label: "Start date", type: "date", name: "startDate", value: this.data.startDate.value, errorText: this.data.startDate.error, onChange: function (e) { return _this.fieldChange(e, 'startDate'); }, style: { width: 'calc(50% - 15px)' } }),
                    this.data.frequency.value !== MedicationFrequencyEnum.ONCE ? (React.createElement(RdiInput, { label: "End date", type: "date", name: "stopDate", value: this.data.stopDate.value, errorText: this.data.stopDate.error, onChange: function (e) { return _this.fieldChange(e, 'stopDate'); }, style: { width: 'calc(50% - 15px)' } })) : (React.createElement("div", { style: { width: 'calc(50% - 15px)' } }))),
                React.createElement(RdiInput, { label: "Indication", type: "text_multiline", name: "indication", value: this.data.indication.value, errorText: this.data.indication.error, onChange: function (e) { return _this.fieldChange(e, 'indication'); } }),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, submit: true, permissions: [
                            {
                                aclOp: this.isEdit ? AclOpEnum.UPDATE : AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.MEDICATION
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, this.isEdit ? 'Update' : 'Add')))));
    };
    __decorate([
        observable
    ], AddMedication.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], AddMedication.prototype, "data", void 0);
    __decorate([
        observable
    ], AddMedication.prototype, "loading", void 0);
    __decorate([
        observable
    ], AddMedication.prototype, "saving", void 0);
    __decorate([
        observable
    ], AddMedication.prototype, "errorMessage", void 0);
    __decorate([
        computed
    ], AddMedication.prototype, "isEdit", null);
    __decorate([
        computed
    ], AddMedication.prototype, "visitId", null);
    __decorate([
        computed
    ], AddMedication.prototype, "medicationId", null);
    __decorate([
        computed
    ], AddMedication.prototype, "visits", null);
    AddMedication = __decorate([
        injectPatientStore,
        injectAccountStore,
        injectSiteStore,
        injectMedicationStore,
        observer
    ], AddMedication);
    return AddMedication;
}(Component));
var InputRow = function (props) { return (React.createElement(FlexContainer, { alignItems: "flex-start", margin: 30, flexWrap: "wrap" }, props.children)); };
export default withStyles(styles)(withRouter(AddMedication));
