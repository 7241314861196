var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import NavigationModal from '@cros/shared/components/modals/NavigationModal';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { CircularProgress } from '@material-ui/core';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import BilledItemCodes from '~/components/billing/BilledItemCodes';
import NotesList from '~/pages/site/site-detail/tasks/NotesList';
import NotesNavigation from '~/pages/site/site-detail/tasks/TaskModalNavigation';
import { getTasksDetailNotesRoute, SiteRoutes } from '~/routing/Routes';
import { NoteStore } from '~/stores/NoteStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTaskStore } from '~/stores/TaskStore';
import UserStore from '~/stores/UserStore';
var TaskModal = /** @class */ (function (_super) {
    __extends(TaskModal, _super);
    function TaskModal(props) {
        var _this = _super.call(this, props) || this;
        _this.task = null;
        _this.loading = true;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var taskStore, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        taskStore = this.props.taskStore;
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, taskStore.getTaskById(this.taskId)];
                    case 1:
                        _a.task = _b.sent();
                        if (!this.task) {
                            this.onClose();
                            return [2 /*return*/];
                        }
                        this.noteStore.task = this.task;
                        return [4 /*yield*/, this.noteStore.listNotes()];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.userStore.listUsers()];
                    case 3:
                        _b.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url, search = _a.location.search;
            history.push("" + url.split("/detail")[0] + search);
        };
        var siteStore = _this.props.siteStore;
        _this.noteStore = new NoteStore(siteStore);
        _this.userStore = new UserStore(siteStore);
        return _this;
    }
    Object.defineProperty(TaskModal.prototype, "taskId", {
        get: function () {
            var params = this.props.match.params;
            return params.taskId;
        },
        enumerable: false,
        configurable: true
    });
    TaskModal.prototype.render = function () {
        var _a = this.props, _b = _a.siteStore, siteId = _b.siteId, trials = _b.trials, params = _a.match.params, taskStore = _a.taskStore;
        var task = this.task;
        var trial = trials.find(function (t) { return t.id === (task === null || task === void 0 ? void 0 : task.trialId); });
        return (React.createElement(NavigationModal, { loading: this.loading, navigation: React.createElement(NotesNavigation, { task: task }), onClose: this.onClose, NavigationDrawer: NotesNavigation, drawerProps: {
                task: task
            } },
            React.createElement(Route, { exact: true, path: SiteRoutes.TASK_MODAL_ROUTE }, task && React.createElement(Redirect, { to: "" + getTasksDetailNotesRoute(siteId, params.tabId, task.id) })),
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: SiteRoutes.TASK_MODAL_NOTES_ROUTE },
                    React.createElement(NotesList, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.TASK_MODAL_CODES_ROUTE }, task && (React.createElement(BilledItemCodes, { disabled: task.isResolved, item: task, trial: trial, save: taskStore.updateTaskBilling, aclModule: AclModuleEnum.TASK })))),
            this.loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null)))));
    };
    __decorate([
        observable
    ], TaskModal.prototype, "task", void 0);
    __decorate([
        observable
    ], TaskModal.prototype, "loading", void 0);
    __decorate([
        computed
    ], TaskModal.prototype, "taskId", null);
    TaskModal = __decorate([
        injectSiteStore,
        injectTaskStore,
        observer
    ], TaskModal);
    return TaskModal;
}(Component));
export default withRouter(TaskModal);
