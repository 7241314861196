var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import NoteService from '~/services/NoteService';
var NoteStore = /** @class */ (function () {
    function NoteStore(siteStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.task = null;
        this.notes = [];
        this.totalItems = 0;
        this.filters = {
            orderBy: 'createdAt',
            orderDir: 'DESC',
            page: 1,
            rowsPerPage: 1000
        };
        this.loading = false;
        this.changeFilters = function (newFilters) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.filters = __assign(__assign({}, this.filters), newFilters);
                        return [4 /*yield*/, this.listNotes()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.listNotes = function () { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, resp, _a, _b, _c, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        this.loading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = NoteService).listNotes;
                        _c = [siteId, this.task.id, this.filters];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.notes = [];
                        this.totalItems = 0;
                        this.errorModalStore.setError(e_1);
                        this.loading = false;
                        return [2 /*return*/, false];
                    case 5:
                        runInAction(function () {
                            _this.notes = resp.items;
                            _this.totalItems = resp.count;
                            _this.loading = false;
                        });
                        return [2 /*return*/, true];
                }
            });
        }); };
        this.addNote = function (note, files) { return __awaiter(_this, void 0, Promise, function () {
            var resp, authToken, siteId, _a, _b, _c, uploads, _i, files_1, f, e_2;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, , 6]);
                        _b = (_a = NoteService).addNote;
                        _c = [siteId, this.task.id, note];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        resp = _d.sent();
                        uploads = [];
                        if (files === null || files === void 0 ? void 0 : files.length) {
                            for (_i = 0, files_1 = files; _i < files_1.length; _i++) {
                                f = files_1[_i];
                                if (!f.deleted) {
                                    uploads.push(this.uploadFile(f.file, resp.taskId, resp.id));
                                }
                            }
                        }
                        return [4 /*yield*/, Promise.all(uploads)];
                    case 4:
                        _d.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_2 = _d.sent();
                        if (e_2.code === 'validation-exception') {
                            throw e_2;
                        }
                        this.errorModalStore.setError(e_2);
                        return [2 /*return*/, null];
                    case 6: return [4 /*yield*/, this.listNotes()];
                    case 7:
                        _d.sent();
                        this.toastStore.addToast(new ToastMessage("Note added"));
                        return [2 /*return*/, resp];
                }
            });
        }); };
        this.deleteNote = function (note) { return __awaiter(_this, void 0, void 0, function () {
            var authToken, siteId, _a, _b, _c, e_3;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = NoteService).deleteNote;
                        _c = [siteId, this.task.id, note.id];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_3 = _d.sent();
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            var existingIndex = _this.notes.findIndex(function (ev) { return ev.id === note.id; });
                            if (existingIndex >= 0) {
                                _this.notes.splice(existingIndex, 1);
                            }
                        });
                        this.toastStore.addToast(new ToastMessage("Note removed"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.deleteNoteWithConfirmation = function (note) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.confirmationModalStore.openModal("Are you sure you want to delete your last note?", function () {
                    return _this.deleteNote(note);
                });
                return [2 /*return*/];
            });
        }); };
        this.uploadFile = function (file, taskId, noteId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, siteId, f, _a, _b, _c, e_4;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        siteId = this.siteStore.siteId;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = NoteService).uploadNoteAttachedFile;
                        _c = [file, siteId, taskId, noteId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        f = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _d.sent();
                        this.errorModalStore.setError(e_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, f];
                }
            });
        }); };
    }
    Object.defineProperty(NoteStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NoteStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NoteStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NoteStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], NoteStore.prototype, "task", void 0);
    __decorate([
        observable
    ], NoteStore.prototype, "notes", void 0);
    __decorate([
        observable
    ], NoteStore.prototype, "totalItems", void 0);
    __decorate([
        observable
    ], NoteStore.prototype, "filters", void 0);
    __decorate([
        observable
    ], NoteStore.prototype, "loading", void 0);
    return NoteStore;
}());
export { NoteStore };
export var injectNoteStore = inject('noteStore');
