var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from "mobx";
import { ICDCode } from "s~/types/models/ICDCode";
import { Model } from "s~/types/models/Model";
import { getDateFromString } from "s~/utils/dateUtils";
var MedicalHistory = /** @class */ (function (_super) {
    __extends(MedicalHistory, _super);
    function MedicalHistory(p) {
        var _this = _super.call(this, p) || this;
        _this.id = p.id;
        _this.title = p.title;
        _this.startDate = getDateFromString(p.startDate);
        _this.endDate = p.endDate ? getDateFromString(p.endDate) : undefined;
        _this.takingMedicine = p.takingMedicine;
        _this.icdCodes = p.icdCodes ? p.icdCodes.map(function (c) { return new ICDCode(c); }) : [];
        return _this;
    }
    __decorate([
        observable
    ], MedicalHistory.prototype, "icdCodes", void 0);
    return MedicalHistory;
}(Model));
export { MedicalHistory };
