export var UserStatusEnum;
(function (UserStatusEnum) {
    UserStatusEnum["ACTIVE"] = "active";
    UserStatusEnum["ARCHIVED"] = "archived";
})(UserStatusEnum || (UserStatusEnum = {}));
export var UserStatusLabelsEnum;
(function (UserStatusLabelsEnum) {
    UserStatusLabelsEnum["active"] = "Active";
    UserStatusLabelsEnum["archived"] = "Archived";
})(UserStatusLabelsEnum || (UserStatusLabelsEnum = {}));
