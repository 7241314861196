var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import GridContainer from '@cros/shared/components/misc/GridContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { BillingCodeTypeEnum, BillingCodeTypeLabelsEnum } from '@cros/shared/constants/enums/BillingCodeTypeEnum';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { Breadcrumbs, createStyles, withStyles } from '@material-ui/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getTrialRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTrialStore } from '~/stores/TrialStore';
var styles = function (theme) {
    var _a;
    return createStyles({
        grid: {
            marginTop: '20px',
            '& >*:nth-child(3n+1)': {
                paddingTop: '15px',
                display: 'flex',
                alignItems: 'flex-end'
            },
            '& >*:nth-child(3n+2)': (_a = {
                    minWidth: '300px'
                },
                _a[theme.breakpoints.down('xs')] = {
                    minWidth: '100%'
                },
                _a)
        }
    });
};
var BillingCodesPage = /** @class */ (function (_super) {
    __extends(BillingCodesPage, _super);
    function BillingCodesPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.saving = false;
        _this.errorMessage = '';
        _this.hasErrors = false;
        _this.data = {
            billingCodes: {
                value: [],
                error: null,
                rule: 'required'
            }
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var trialStore;
            return __generator(this, function (_a) {
                trialStore = this.props.trialStore;
                this.assignData(trialStore.trial);
                return [2 /*return*/];
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        _this.submit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var trialStore, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        e.stopPropagation();
                        this.errorMessage = '';
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        this.saving = true;
                        trialStore = this.props.trialStore;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, trialStore.updateTrial(this.getObjectToSave())];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 4:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.getObjectToSave = function () {
            var trialStore = _this.props.trialStore;
            return {
                id: trialStore.trial.id,
                billingCodes: _this.data.billingCodes.value.map(function (b) { return ({
                    code: b.code.value,
                    description: b.description.value,
                    type: b.type.value
                }); })
            };
        };
        _this.assignData = function (trial) {
            _this.data.billingCodes.value = trial.billingCodes.map(function (b) { return ({
                code: {
                    value: b.code
                },
                description: {
                    value: b.description
                },
                type: {
                    value: b.type,
                    label: BillingCodeTypeLabelsEnum[b.type]
                }
            }); });
            // if no billing codes, add one
            if (_this.data.billingCodes.value.length === 0) {
                _this.addBillingCode();
            }
        };
        _this.addBillingCode = function () {
            _this.data.billingCodes.value.push({
                code: { value: '' },
                description: { value: '' },
                type: { value: '' }
            });
        };
        _this.removeBillingCode = function (billingCode, index) {
            _this.data.billingCodes.value.splice(index, 1);
        };
        return _this;
    }
    BillingCodesPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, classes = _a.classes, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, trial = _a.trialStore.trial;
        return (React.createElement("div", { style: { width: '100%' } },
            React.createElement(Breadcrumbs, { separator: "/", "aria-label": "breadcrumb" },
                React.createElement(RdiLink, { xs: true, to: getTrialRoute(siteId, trialId) }, trial.name),
                React.createElement("span", null, "Billing codes")),
            React.createElement(FixedWidthContent, { xs: true, white: true },
                React.createElement(RdiTypography, { variant: "h1" }, "Billing codes"),
                React.createElement(RdiLine, { md: true }),
                React.createElement(RdiTypography, { variant: "body2" }, "Billing code configuration"),
                React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submit },
                    React.createElement(GridContainer, { className: classes.grid },
                        this.data.billingCodes.value.map(function (billingCode, index) { return (React.createElement(React.Fragment, { key: index },
                            React.createElement(FlexContainer, { flexDirection: "column", alignItems: "flex-end" },
                                React.createElement(RdiTypography, { variant: "caption", uppercase: true },
                                    "Billing code ",
                                    index + 1),
                                React.createElement(RdiLink, { xs: true, onClick: function () { return _this.removeBillingCode(billingCode, index); }, style: { paddingRight: '30px' } }, "Remove")),
                            React.createElement("div", null,
                                React.createElement(FlexContainer, { margin: 15, alignItems: "flex-start" },
                                    React.createElement(RdiInput, { type: "text", placeholder: "Enter billing code", value: billingCode.code.value, errorText: billingCode.code.error, onChange: function (e) {
                                            return _this.fieldChange(e, "billingCodes." + index + ".code");
                                        } }),
                                    React.createElement(RdiInput, { type: "select", placeholder: "Select type", value: billingCode.type.value, errorText: billingCode.type.error, selectedItemLabel: billingCode.type.label, onChange: function (e) {
                                            return _this.fieldChange(e, "billingCodes." + index + ".type");
                                        }, options: Object.values(BillingCodeTypeEnum).map(function (key) { return ({
                                            label: BillingCodeTypeLabelsEnum[key],
                                            value: key
                                        }); }) })),
                                React.createElement("div", null,
                                    React.createElement(RdiInput, { type: "text", placeholder: "Enter billing code description", value: billingCode.description.value, errorText: billingCode.description.error, onChange: function (e) {
                                            return _this.fieldChange(e, "billingCodes." + index + ".description");
                                        } }))),
                            React.createElement("div", null))); }),
                        React.createElement(React.Fragment, null,
                            React.createElement("div", null),
                            React.createElement(FlexContainer, { style: { justifyContent: 'flex-end' } },
                                React.createElement(RdiLink, { xs: true, onClick: this.addBillingCode }, "+ Add another billing code")),
                            React.createElement("div", null))),
                    React.createElement(RdiErrorBox, null, this.errorMessage),
                    React.createElement(RdiLine, null),
                    React.createElement(GridContainer, { className: classes.grid },
                        React.createElement("div", null),
                        React.createElement("div", null,
                            React.createElement(RdiButton, { fullWidth: true, submit: true, loading: this.saving }, "Save changes")),
                        React.createElement("div", null))))));
    };
    __decorate([
        observable
    ], BillingCodesPage.prototype, "saving", void 0);
    __decorate([
        observable
    ], BillingCodesPage.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], BillingCodesPage.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], BillingCodesPage.prototype, "data", void 0);
    BillingCodesPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectTrialStore,
        observer
    ], BillingCodesPage);
    return BillingCodesPage;
}(Component));
export default withStyles(styles)(withRouter(BillingCodesPage));
