var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { BillingCodeStatusEnum } from '@cros/shared/constants/enums/BillingCodeStatusEnum';
import { BillingTypeLabelsEnum } from '@cros/shared/constants/enums/BillingTypeEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { formatDatetime } from '@cros/shared/utils/dateUtils';
import { AppBar, CircularProgress, createStyles, IconButton, Menu, Tab, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, withStyles } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CheckmarkIcon from '@material-ui/icons/Check';
import NoteInfo from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WarningIcon from '@material-ui/icons/Warning';
import { observable } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import AddBillingCode from '~/pages/site/site-detail/billing-approval/AddBillingCode';
import { getBillingApprovalRoute, getBillingApprovedRoute, getBillingDeniedRoute, getBillingNeedsApprovalRoute, getBillingOpenRoute, getPatientOverviewRoute, getTaskDetailRoute, getVisitDetailRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import BilledItemStore from '~/stores/BilledItemStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        noBorder: {
            '& .MuiTableCell-root': {
                borderBottom: 0
            }
        },
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {},
            _a),
        menuContainer: (_b = {
                marginBottom: '20px',
                marginTop: '30px',
                flexWrap: 'wrap'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginTop: '0px'
            },
            _b)
    });
};
var BillingApprovalPage = /** @class */ (function (_super) {
    __extends(BillingApprovalPage, _super);
    function BillingApprovalPage(props) {
        var _this = _super.call(this, props) || this;
        _this.tabs = [
            {
                id: 'open',
                label: function (billingCodeCounts) { return "Open notes (" + ((billingCodeCounts === null || billingCodeCounts === void 0 ? void 0 : billingCodeCounts.open) || 0) + ")"; },
                url: function (siteId) { return getBillingOpenRoute(siteId); }
            },
            {
                id: 'needs-approval',
                label: function (billingCodeCounts) { return "Needs approval (" + (billingCodeCounts['needs-approval'] || 0) + ")"; },
                url: function (siteId) { return getBillingNeedsApprovalRoute(siteId); }
            },
            {
                id: 'approved',
                label: function (billingCodeCounts) { return "Approved (" + ((billingCodeCounts === null || billingCodeCounts === void 0 ? void 0 : billingCodeCounts.approved) || 0) + ")"; },
                url: function (siteId) { return getBillingApprovedRoute(siteId); }
            },
            {
                id: 'denied',
                label: function (billingCodeCounts) { return "Denied (" + ((billingCodeCounts === null || billingCodeCounts === void 0 ? void 0 : billingCodeCounts.denied) || 0) + ")"; },
                url: function (siteId) { return getBillingDeniedRoute(siteId); }
            }
        ];
        _this.columns = [
            {
                id: 'item',
                label: 'Item',
                sortable: false
            },
            {
                id: 'patient',
                label: 'Patient',
                sortable: false
            },
            {
                id: 'type',
                label: 'Type'
            },
            {
                id: 'code',
                label: 'Code',
                sortable: false
            },
            {
                id: 'amount',
                label: 'Amount',
                sortable: false
            },
            {
                id: 'createdAt',
                label: 'Added',
                sortable: true,
                sortName: 'createdAt'
            },
            {
                id: 'status',
                label: 'Status',
                sortable: false
            },
            {
                id: 'actions',
                label: 'Actions',
                sortable: false
            },
            {
                id: 'actions1',
                label: '',
                sortable: false
            }
        ];
        _this.menuRef = null;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var siteId, tab;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        siteId = this.props.siteStore.siteId;
                        return [4 /*yield*/, this.billingCodeStore.listBilledItemsFilters()];
                    case 1:
                        _a.sent();
                        tab = this.tabs.find(function (t) { return t.url(siteId) === _this.getTabUrl(); });
                        if (!tab) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.changeTab(tab)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.handleMenuClick = function (event, item, code) {
            _this.menuRef = event.currentTarget;
            _this.itemInEdit = item;
            _this.codeInEdit = code;
        };
        _this.getAddBillingCodeUrl = function () {
            var _a = _this.props.location, pathname = _a.pathname, search = _a.search;
            return pathname + "/new" + search;
        };
        _this.getTabUrl = function () {
            var pathname = _this.props.location.pathname;
            var regex = new RegExp(['/new', '/edit', '/assign', '/detail'].join('|'), 'g');
            var tab = pathname.split(regex)[0];
            return tab;
        };
        _this.changeTab = function (tab) { return __awaiter(_this, void 0, void 0, function () {
            var _a, history, siteId, billingCodes__status;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, history = _a.history, siteId = _a.siteStore.siteId;
                        billingCodes__status = Object.values(BillingCodeStatusEnum).find(function (b) { return b === tab.id; });
                        history.push({ pathname: "" + tab.url(siteId) });
                        return [4 /*yield*/, this.billingCodeStore.changeBillingCodeFilters({
                                billingCodes__status: billingCodes__status,
                                page: 1
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onEdit = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, history, pathname;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, pathname = _a.location.pathname;
                this.menuRef = null;
                history.push(pathname + "/edit/" + this.itemInEdit.id + "--" + this.itemInEdit.billingType + "--" + this.codeInEdit.id);
                return [2 /*return*/];
            });
        }); };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.billingCodeStore.deleteBillingCodeWithConfirmation(this.itemInEdit, this.codeInEdit, this.updateBillingItems)];
                    case 1:
                        _a.sent();
                        this.menuRef = null;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.updateBillingItems = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteStore, siteId;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, siteStore = _a.siteStore, siteId = _a.siteStore.siteId;
                        return [4 /*yield*/, this.billingCodeStore.listBilledItems()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, siteStore.getBillingItemsCount(siteId)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var _a = _this.props, siteStore = _a.siteStore, location = _a.location, history = _a.history;
        _this.billingCodeStore = new BilledItemStore(siteStore, location.search, history);
        return _this;
    }
    BillingApprovalPage.prototype.getTabId = function () {
        var _this = this;
        var siteId = this.props.siteStore.siteId;
        var tab = this.tabs.find(function (t) { return t.url(siteId) === _this.getTabUrl(); });
        return tab.id;
    };
    BillingApprovalPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, siteStore = _a.siteStore, siteId = _a.siteStore.siteId, pathname = _a.location.pathname, classes = _a.classes, accountStore = _a.accountStore;
        var _b = this.billingCodeStore, billingCodeFilters = _b.billingCodeFilters, billingCodeFilterLabels = _b.billingCodeFilterLabels, availableBilledItemsFilters = _b.availableBilledItemsFilters, changeBillingCodeFilters = _b.changeBillingCodeFilters, billedItemsCounts = _b.billedItemsCounts, billedItemsCount = _b.billedItemsCount, billedItemsLoading = _b.billedItemsLoading, billedItems = _b.billedItems, bulkRevertCodesWithConfirmation = _b.bulkRevertCodesWithConfirmation, bulkDenyCodesWithConfirmation = _b.bulkDenyCodesWithConfirmation, bulkApproveCodesWithConfirmation = _b.bulkApproveCodesWithConfirmation, approveCodeWithConfirmation = _b.approveCodeWithConfirmation, denyCodeWithConfirmation = _b.denyCodeWithConfirmation, revertWithConfirmation = _b.revertWithConfirmation;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(Provider, { billingCodeStore: this.billingCodeStore },
                React.createElement(Route, { exact: true, path: SiteRoutes.BILLING_APPROVAL_INDEX_ROUTE },
                    React.createElement(Redirect, { to: "" + getBillingNeedsApprovalRoute(siteId) })),
                React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "Billing code approval"),
                React.createElement(FlexContainer, { className: classes.menuContainer, alignItems: "flex-end", margin: 10 },
                    React.createElement(RdiInput, { style: { width: '200px' }, type: "search", name: "search", placeholder: "Search", value: billingCodeFilters.search, onChange: function (e) { return changeBillingCodeFilters({ search: e.value }); } }),
                    !!availableBilledItemsFilters && (React.createElement(React.Fragment, null,
                        React.createElement(RdiInput, { type: "select", label: "Patient", name: "filter-patientId", style: { width: '180px' }, value: billingCodeFilters.patientId, selectedItemLabel: billingCodeFilterLabels.patientId, onChange: function (e) {
                                return e.value
                                    ? changeBillingCodeFilters({ patientId: e.value }, { patientId: e.label })
                                    : changeBillingCodeFilters({ patientId: null }, { patientId: 'All' });
                            }, options: __spreadArrays([
                                { label: 'All', slug: null }
                            ], availableBilledItemsFilters.patientId).map(function (patientId) { return ({
                                value: patientId.slug,
                                label: patientId.label
                            }); }) }),
                        React.createElement(RdiInput, { type: "select", label: "Trial", name: "filter-trialId", style: { width: '180px' }, value: billingCodeFilters.trialId, selectedItemLabel: billingCodeFilterLabels.trialId, onChange: function (e) {
                                return e.value
                                    ? changeBillingCodeFilters({ trialId: e.value }, { trialId: e.label })
                                    : changeBillingCodeFilters({ trialId: null }, { trialId: 'All' });
                            }, options: __spreadArrays([
                                { label: 'All', slug: null }
                            ], availableBilledItemsFilters.trialId).map(function (trialId) { return ({
                                value: trialId.slug,
                                label: trialId.label
                            }); }) }))),
                    React.createElement("div", { style: { flex: 1 } }),
                    React.createElement(RdiButton, { name: "new-task", to: this.getAddBillingCodeUrl, permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.BILLING
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true }, "New code")),
                pathname !== getBillingApprovalRoute(siteId) && (React.createElement(AppBar, { position: "static", color: "default" },
                    React.createElement(Tabs, { value: this.getTabUrl(), indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" }, this.tabs.map(function (tab) { return (React.createElement(Tab, { key: tab.id, value: tab.url(siteId), 
                        // to={`${tab.url(siteId)}`}
                        label: "" + tab.label(billedItemsCounts), id: tab.id, onClick: function () { return _this.changeTab(tab); } })); })))),
                React.createElement(RdiTable, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeBillingCodeFilters, filters: billingCodeFilters })); }))),
                    !billedItemsLoading && (React.createElement(TableBody, null,
                        billedItems.map(function (item, index) {
                            var _a, _b, _c;
                            return (React.createElement(React.Fragment, { key: index },
                                React.createElement(TableRow, { className: classes.noBorder },
                                    React.createElement(TableCell, null,
                                        React.createElement(FlexContainer, { margin: 7 },
                                            React.createElement(RdiLink, { to: item.billingType === 'TaskModel'
                                                    ? getTaskDetailRoute(siteId, item.id, 'alarm')
                                                    : getVisitDetailRoute(siteId, item.patientId, item.trialId, item.enrollmentId, item.id) }, item.billingName),
                                            React.createElement(Tooltip, { title: (_a = item.trial) === null || _a === void 0 ? void 0 : _a.name },
                                                React.createElement(NoteInfo, { style: { color: ColorsEnum.BlackGrey } })))),
                                    React.createElement(TableCell, null,
                                        React.createElement(RdiLink, { to: getPatientOverviewRoute(siteId, item.patientId) }, (_b = item.patient) === null || _b === void 0 ? void 0 : _b.fullName)),
                                    React.createElement(TableCell, null, BillingTypeLabelsEnum[item.billingType]),
                                    React.createElement(TableCell, null,
                                        item.billingCodes.length,
                                        " code",
                                        item.billingCodes.length === 1 ? '' : 's'),
                                    React.createElement(TableCell, null),
                                    React.createElement(TableCell, null, formatDatetime(item.createdAt)),
                                    React.createElement(TableCell, null),
                                    React.createElement(TableCell, null,
                                        item.billingCodes[0].status === BillingCodeStatusEnum.NEEDS_APPROVAL && (React.createElement(FlexContainer, { margin: 20 },
                                            React.createElement(RdiButton, { onClick: function () { return bulkApproveCodesWithConfirmation(item); }, xs: true, permissions: [
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.PROVIDER
                                                    },
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.BILLING
                                                    }
                                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Approve all"),
                                            React.createElement(RdiLink, { onClick: function () { return bulkDenyCodesWithConfirmation(item); }, xs: true, permissions: [
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.PROVIDER
                                                    },
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.BILLING
                                                    }
                                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Deny all"))),
                                        (item.billingCodes[0].status === BillingCodeStatusEnum.APPROVED ||
                                            item.billingCodes[0].status === BillingCodeStatusEnum.DENIED) && (React.createElement(RdiLink, { onClick: function () { return bulkRevertCodesWithConfirmation(item, _this.getTabId()); }, xs: true, permissions: [
                                                {
                                                    aclOp: AclOpEnum.UPDATE,
                                                    aclModule: AclModuleEnum.PROVIDER
                                                },
                                                {
                                                    aclOp: AclOpEnum.UPDATE,
                                                    aclModule: AclModuleEnum.BILLING
                                                }
                                            ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Revert all"))),
                                    React.createElement(TableCell, null)), (_c = item.billingCodes) === null || _c === void 0 ? void 0 :
                                _c.map(function (b, i) {
                                    var _a, _b, _c, _d;
                                    return (React.createElement(TableRow, { style: { backgroundColor: ColorsEnum.BlackSuperlight }, className: i !== ((_a = item.billingCodes) === null || _a === void 0 ? void 0 : _a.length) - 1 ? classes.noBorder : undefined, key: i },
                                        React.createElement(TableCell, null),
                                        React.createElement(TableCell, null),
                                        React.createElement(TableCell, null),
                                        React.createElement(TableCell, null,
                                            React.createElement(Tooltip, { title: b.description || 'No description' },
                                                React.createElement("div", null, b.code))),
                                        React.createElement(TableCell, null,
                                            b.amount,
                                            " min"),
                                        React.createElement(TableCell, null, formatDatetime(b.createdAt)),
                                        React.createElement(TableCell, null,
                                            React.createElement(FlexContainer, { margin: 10 },
                                                b.status === BillingCodeStatusEnum.OPEN && (React.createElement(React.Fragment, null,
                                                    React.createElement(CachedIcon, null),
                                                    React.createElement(RdiTypography, { variant: "body2" }, "Open"))),
                                                b.status === BillingCodeStatusEnum.NEEDS_APPROVAL && (React.createElement(React.Fragment, null,
                                                    React.createElement(CachedIcon, null),
                                                    React.createElement(RdiTypography, { variant: "body2" }, "Needs approval"),
                                                    React.createElement(Tooltip, { title: "Submitted by " + ((_b = b.submittedBy) === null || _b === void 0 ? void 0 : _b.fullName) },
                                                        React.createElement("div", { style: { display: 'flex' } },
                                                            React.createElement(NoteInfo, { style: { color: ColorsEnum.BlackGrey } }))))),
                                                b.status === BillingCodeStatusEnum.APPROVED && (React.createElement(React.Fragment, null,
                                                    React.createElement(CheckmarkIcon, { style: { color: ColorsEnum.IconsGreen } }),
                                                    React.createElement(RdiTypography, { style: { color: ColorsEnum.IconsGreen }, variant: "body2" }, "Approved"),
                                                    React.createElement(Tooltip, { title: "Approved by " + ((_c = b.approvedBy) === null || _c === void 0 ? void 0 : _c.fullName) },
                                                        React.createElement("div", { style: { display: 'flex' } },
                                                            React.createElement(NoteInfo, { style: { color: ColorsEnum.BlackGrey } }))))),
                                                b.status === BillingCodeStatusEnum.DENIED && (React.createElement(React.Fragment, null,
                                                    React.createElement(WarningIcon, { style: { color: ColorsEnum.Error } }),
                                                    React.createElement(RdiTypography, { style: { color: ColorsEnum.Error }, variant: "body2" }, "Denied"),
                                                    React.createElement(Tooltip, { title: "Denied by " + ((_d = b.deniedBy) === null || _d === void 0 ? void 0 : _d.fullName) },
                                                        React.createElement("div", { style: { display: 'flex' } },
                                                            React.createElement(NoteInfo, { style: { color: ColorsEnum.BlackGrey } }))))))),
                                        React.createElement(TableCell, null,
                                            b.status === BillingCodeStatusEnum.NEEDS_APPROVAL && (React.createElement(FlexContainer, { margin: 20 },
                                                React.createElement(RdiButton, { onClick: function () { return approveCodeWithConfirmation(item, b); }, xs: true, permissions: [
                                                        {
                                                            aclOp: AclOpEnum.UPDATE,
                                                            aclModule: AclModuleEnum.PROVIDER
                                                        },
                                                        {
                                                            aclOp: AclOpEnum.UPDATE,
                                                            aclModule: AclModuleEnum.BILLING
                                                        }
                                                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Approve"),
                                                React.createElement(RdiLink, { onClick: function () { return denyCodeWithConfirmation(item, b); }, xs: true, permissions: [
                                                        {
                                                            aclOp: AclOpEnum.UPDATE,
                                                            aclModule: AclModuleEnum.PROVIDER
                                                        },
                                                        {
                                                            aclOp: AclOpEnum.UPDATE,
                                                            aclModule: AclModuleEnum.BILLING
                                                        }
                                                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Deny"))),
                                            (b.status === BillingCodeStatusEnum.APPROVED ||
                                                b.status === BillingCodeStatusEnum.DENIED) && (React.createElement(RdiLink, { onClick: function () { return revertWithConfirmation(item, b); }, xs: true, permissions: [
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.PROVIDER
                                                    },
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.BILLING
                                                    }
                                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Revert"))),
                                        React.createElement(TableCell, { style: { padding: '0px' } },
                                            React.createElement("div", { style: {
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end'
                                                } },
                                                React.createElement(React.Fragment, null,
                                                    React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, item, b); } },
                                                        React.createElement(MoreVertIcon, null)))))));
                                })));
                        }),
                        !billedItemsLoading && billedItemsCount === 0 && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 9 }, "No results")))))),
                !billedItemsLoading && (React.createElement(RdiTablePagination, { count: billedItemsCount, rowsPerPage: billingCodeFilters.rowsPerPage, page: billingCodeFilters.page, onChangePage: function (e, newPage) { return changeBillingCodeFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                        return changeBillingCodeFilters({
                            rowsPerPage: parseInt(e.target.value, 10),
                            page: 1
                        });
                    } })),
                React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, onClose: function () {
                        _this.menuRef = null;
                    }, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                    React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.BILLING
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Edit")),
                    React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.BILLING
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Delete"))),
                billedItemsLoading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                    React.createElement(CircularProgress, null))),
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: SiteRoutes.NEW_BILLING_CODE_ROUTE },
                        React.createElement(AddBillingCode, { onUpdate: function () { return _this.billingCodeStore.listBilledItems(); } })),
                    React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_BILLING_CODE_ROUTE },
                        React.createElement(AddBillingCode, { onUpdate: function () { return _this.billingCodeStore.listBilledItems(); } }))))));
    };
    __decorate([
        observable
    ], BillingApprovalPage.prototype, "codeInEdit", void 0);
    __decorate([
        observable
    ], BillingApprovalPage.prototype, "itemInEdit", void 0);
    __decorate([
        observable
    ], BillingApprovalPage.prototype, "menuRef", void 0);
    BillingApprovalPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], BillingApprovalPage);
    return BillingApprovalPage;
}(Component));
export default withStyles(styles)(withRouter(BillingApprovalPage));
