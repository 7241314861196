var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { CircularProgress, createStyles, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectUserStore } from '~/stores/UserStore';
var styles = function () { return createStyles({}); };
var UserGroupsPage = /** @class */ (function (_super) {
    __extends(UserGroupsPage, _super);
    function UserGroupsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.columns = [
            {
                id: 'name',
                label: 'Group name',
                sortable: false
            },
            {
                id: 'numUsers',
                label: 'Number of users',
                sortable: false
            },
            {
                id: 'actions',
                label: '',
                sortable: false
            }
        ];
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var userStore;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userStore = this.props.userStore;
                        return [4 /*yield*/, userStore.listRoles()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    UserGroupsPage.prototype.render = function () {
        var _a = this.props.userStore, roles = _a.roles, rolesLoading = _a.rolesLoading;
        return (React.createElement(React.Fragment, null,
            React.createElement(RdiTable, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column })); }))),
                !rolesLoading && (React.createElement(TableBody, null,
                    roles.map(function (role, index) { return (React.createElement(TableRow, { key: index },
                        React.createElement(TableCell, null, role.name),
                        React.createElement(TableCell, null, role.numUsers),
                        React.createElement(TableCell, null))); }),
                    !rolesLoading && roles.length === 0 && (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: 7 }, "No results")))))),
            rolesLoading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                React.createElement(CircularProgress, null)))));
    };
    UserGroupsPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectUserStore,
        observer
    ], UserGroupsPage);
    return UserGroupsPage;
}(Component));
export default withStyles(styles)(withRouter(UserGroupsPage));
