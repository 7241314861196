var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fade } from "@material-ui/core/styles";
import React from "react";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
var RdiErrorBox = function (props) {
    if (props.value || props.children) {
        return (React.createElement(FlexContainer, { style: __assign({ backgroundColor: fade("#FF3061", 0.2), minHeight: "50px", justifyContent: "center", marginTop: "10px", marginBottom: "10px", padding: "15px" }, props.style) },
            React.createElement(RdiTypography, { style: { textAlign: "center" }, variant: "body2", color: TypographyColorsEnum.Error }, "" + (props.value ? props.value : "") + props.children)));
    }
    return null;
};
export default RdiErrorBox;
