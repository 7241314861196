var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
var CenteredContent = function (props) {
    return (React.createElement("div", { style: __assign({ width: "100%", minHeight: "100%", display: "flex", justifyContent: "center", alignItems: "center" }, props.style) }, props.children));
};
export default CenteredContent;
