var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed } from 'mobx';
import { PaymentStatusEnum } from 's~/constants/enums/PaymentStatusEnum';
import { Claim } from 's~/types/models/Claim';
import { Enrollment } from 's~/types/models/Enrollment';
import { Patient } from 's~/types/models/Patient';
import { Transaction } from 's~/types/models/Transaction';
import { Trial } from 's~/types/models/Trial';
import { getDateFromString, getDaysAgo } from 's~/utils/dateUtils';
var Payment = /** @class */ (function (_super) {
    __extends(Payment, _super);
    function Payment(e) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var _this = _super.call(this, e) || this;
        _this.transferId = e.transferId;
        _this.transferStatus = e.transferStatus;
        _this.visit = e.visit;
        _this.visitId = e.visitId;
        if ((_a = _this.visit) === null || _a === void 0 ? void 0 : _a.engagement) {
            _this.visit.engagement = getDateFromString(_this.visit.engagement);
        }
        _this.paymentType = e.paymentType;
        _this.executionDate = e.executionDate ? getDateFromString(e.executionDate) : null;
        _this.description = e.description;
        _this.enrollmentId = e.enrollmentId;
        _this.enrollment = e.enrollment ? new Enrollment(e.enrollment) : null;
        _this.patientId = e.patientId;
        _this.patient = e.patient ? new Patient(e.patient) : null;
        _this.claim = e.claim ? new Claim(e.claim) : null;
        _this.externalStatus = (_b = _this.claim) === null || _b === void 0 ? void 0 : _b.externalStatus;
        _this.externalErrorMessage = (_c = _this.claim) === null || _c === void 0 ? void 0 : _c.externalErrorMessage;
        _this.comment = (_d = e.comment) !== null && _d !== void 0 ? _d : (_e = _this.claim) === null || _e === void 0 ? void 0 : _e.comment;
        _this.venmoIdentifier = (_f = e.venmoIdentifier) !== null && _f !== void 0 ? _f : (_g = _this.claim) === null || _g === void 0 ? void 0 : _g.venmoIdentifier;
        _this.venmoUsername = (_h = e.venmoUsername) !== null && _h !== void 0 ? _h : (_j = _this.claim) === null || _j === void 0 ? void 0 : _j.venmoUsername;
        _this.trialId = e.trialId;
        _this.trial = e.trial ? new Trial(e.trial) : null;
        return _this;
    }
    Object.defineProperty(Payment.prototype, "daysPending", {
        get: function () {
            if (this.status !== PaymentStatusEnum.PENDING || !this.visit || !this.visit.engagement) {
                return -1;
            }
            return getDaysAgo(this.visit.engagement);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        computed
    ], Payment.prototype, "daysPending", null);
    return Payment;
}(Transaction));
export { Payment };
