var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { RdiDateInput } from 's~/components/forms/RdiDateInput';
import { RdiDateSelectorInput } from 's~/components/forms/RdiDateSelectorInput';
import { RdiFileInput } from 's~/components/forms/RdiFileInput';
import RdiMentionsInput from 's~/components/forms/RdiMentionsInput';
import { RdiMultipleChoiceInput } from 's~/components/forms/RdiMultipleChoiceInput';
import { RdiSelectInput } from 's~/components/forms/RdiSelectInput';
import RdiTextInput from 's~/components/forms/RdiTextInput';
import { RdiTimeInput } from 's~/components/forms/RdiTimeInput';
var styles = function (theme) {
    var _a;
    return createStyles({
        root: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                minWidth: '100%',
            },
            _a),
    });
};
var Wrapper = function (props) {
    return (React.createElement("div", { style: __assign({ width: props.fullWidth ? '100%' : 'auto', marginBottom: '14px', display: 'flex', flexDirection: 'column' }, props.style), className: props.className + " " + props.classes.root }, props.children));
};
var RdiInputWrapper = withStyles(styles)(Wrapper);
export { RdiInputWrapper };
var RdiInput = function (props) {
    switch (props.type) {
        case 'select':
            return React.createElement(RdiSelectInput, __assign({}, props));
        case 'date':
            return React.createElement(RdiDateInput, __assign({}, props));
        case 'simple-date':
            return React.createElement(RdiDateSelectorInput, __assign({}, props));
        case 'time':
            return React.createElement(RdiTimeInput, __assign({}, props));
        case 'file':
            return React.createElement(RdiFileInput, __assign({}, props));
        case 'mentions':
            return React.createElement(RdiMentionsInput, __assign({}, props));
        case 'select-multiple':
            return React.createElement(RdiMultipleChoiceInput, __assign({}, props));
        default:
            return React.createElement(RdiTextInput, __assign({}, props));
    }
};
RdiInput.defaultProps = {
    type: 'text',
    fullWidth: true,
    isValid: true,
    borderWidth: '1px',
    placeholder: '',
};
export default RdiInput;
