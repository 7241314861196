var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import DateWithIcon from '@cros/shared/components/misc/DateWithIcon';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiTablePagination from '@cros/shared/components/misc/RdiTablePagination';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { CircularProgress, createStyles, IconButton, Menu, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getEnrollmentsRoute, getNewPatientRoute, getPatientOverviewRoute, getTrialRoute } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import PatientListStore from '~/stores/PatientListStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        clickCell: {
            color: ColorsEnum.Blue,
            cursor: 'pointer',
            fontWeight: 'bold'
        },
        menuContainer: (_a = {
                marginBottom: '20px',
                marginTop: '30px'
            },
            _a[theme.breakpoints.down('xs')] = {
                marginTop: '0px'
            },
            _a),
        title: (_b = {},
            _b[theme.breakpoints.down('xs')] = {},
            _b)
    });
};
var PatientListPage = /** @class */ (function (_super) {
    __extends(PatientListPage, _super);
    function PatientListPage(props) {
        var _this = _super.call(this, props) || this;
        _this.columns = [
            {
                id: 'fullName',
                label: 'Patient',
                sortable: true,
                sortName: 'firstName'
            },
            {
                id: 'trials',
                label: 'Active trials'
            },
            {
                id: 'visits',
                label: 'Visits',
                width: '100px'
            },
            {
                id: 'nextVisit',
                label: 'Next visit'
            },
            {
                id: 'actions',
                label: '',
                width: '50px'
            }
        ];
        _this.menuRef = null;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchTrialPatients()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.fetchTrialPatients = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, getPatients, getPatientFilters;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.patientListStore, getPatients = _a.getPatients, getPatientFilters = _a.getPatientFilters;
                        return [4 /*yield*/, getPatients()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, getPatientFilters()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.changeSearchString = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.patientListStore.changePatientFilters({
                    page: 1,
                    search: e.value
                });
                return [2 /*return*/];
            });
        }); };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.patientListStore.deletePatientWithConfirmation(this.patientInEdit)];
                    case 1:
                        _a.sent();
                        this.menuRef = null;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleMenuClick = function (event, patient) {
            _this.menuRef = event.currentTarget;
            _this.patientInEdit = patient;
        };
        _this.viewTrials = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, history, siteStore;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, siteStore = _a.siteStore;
                history.push(getEnrollmentsRoute(siteStore.siteId, this.patientInEdit.id, this.patientInEdit.enrollments[0].trialId, this.patientInEdit.enrollments[0].id));
                return [2 /*return*/];
            });
        }); };
        _this.resendPatientPasswordReset = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.patientListStore.resentPasswordSetEmail(this.patientInEdit)];
                    case 1:
                        _a.sent();
                        this.menuRef = null;
                        return [2 /*return*/];
                }
            });
        }); };
        var siteStore = props.siteStore;
        _this.patientListStore = new PatientListStore(siteStore);
        return _this;
    }
    PatientListPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.siteStore, siteId = _b.siteId, trials = _b.trials, trialsLoading = _b.trialsLoading, classes = _a.classes;
        var _c = this.patientListStore, patients = _c.patients, patientsCount = _c.patientsCount, changePatientFilters = _c.changePatientFilters, patientsLoading = _c.patientsLoading, patientFilters = _c.patientFilters, availableFilters = _c.availableFilters, patientFilterLabels = _c.patientFilterLabels;
        var _d = this.props, accountStore = _d.accountStore, siteStore = _d.siteStore;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "All patients"),
            React.createElement("div", null,
                React.createElement(FlexContainer, { className: classes.menuContainer, alignItems: "flex-end", style: { flexWrap: 'wrap' }, margin: 10 },
                    React.createElement(RdiInput, { style: { width: '200px' }, type: "search", placeholder: "Search", value: patientFilters.search, onChange: this.changeSearchString }),
                    availableFilters && (React.createElement(RdiInput, { type: "select", label: "Trial", name: "filter-trial", style: { width: '180px' }, value: patientFilters.trialId, selectedItemLabel: patientFilterLabels.trialId, onChange: function (e) {
                            return e.value
                                ? changePatientFilters({ trialId: e.value, page: 1 }, { trialId: e.label })
                                : changePatientFilters({ trialId: null, page: 1 }, { trialId: 'All' });
                        }, options: __spreadArrays([{ label: 'All', slug: null }], availableFilters.trialId).map(function (trialId) { return ({
                            value: trialId.slug,
                            label: trialId.label
                        }); }) })),
                    React.createElement("div", { style: { flex: '1' } }),
                    React.createElement(RdiButton, { name: "new-patient", fullWidth: false, to: "" + getNewPatientRoute(siteId), permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.PATIENT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "New patient")),
                React.createElement(RdiTable, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changePatientFilters, filters: patientFilters })); }))),
                    !patientsLoading && !trialsLoading && (React.createElement(TableBody, null,
                        patients.map(function (row, index) {
                            return (React.createElement(TableRow, { hover: true, tabIndex: -1, key: index },
                                React.createElement(TableCell, null,
                                    React.createElement(RdiLink, { to: getPatientOverviewRoute(siteId, row.id) }, row.fullName)),
                                React.createElement(TableCell, null, (trials === null || trials === void 0 ? void 0 : trials.length) > 0 &&
                                    row.enrollments.map(function (e, innerIndex) {
                                        var _a, _b, _c;
                                        return ((_b = (_a = trials.find(function (t) { return t.id === e.trialId; })) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.trim()) && (React.createElement(RdiLink, { key: innerIndex, to: getTrialRoute(e.siteId, e.trialId) }, (_c = trials.find(function (t) { return t.id === e.trialId; })) === null || _c === void 0 ? void 0 :
                                            _c.name,
                                            row.enrollments.length === innerIndex + 1 ? '' : ', '));
                                    })),
                                React.createElement(TableCell, null,
                                    row.numVisits,
                                    " visits"),
                                React.createElement(TableCell, null, row.nextVisit && (React.createElement(DateWithIcon, { displayType: "humanized date and time", value: row.nextVisit ? row.nextVisit.engagement : null }))),
                                React.createElement(TableCell, { style: { padding: '0px' } },
                                    React.createElement("div", { style: {
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        } },
                                        React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, row); } },
                                            React.createElement(MoreVertIcon, null))))));
                        }),
                        !patientsLoading && patientsCount === 0 && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 5 }, "No results")))))),
                patientsLoading && (React.createElement(FlexContainer, { style: { marginTop: '45px' }, alignItems: "center", justifyContent: "center" },
                    React.createElement(CircularProgress, null))),
                !patientsLoading && (React.createElement(RdiTablePagination, { count: patientsCount, rowsPerPage: patientFilters.rowsPerPage, page: patientFilters.page, onChangePage: function (e, newPage) { return changePatientFilters({ page: newPage + 1 }); }, onChangeRowsPerPage: function (e) {
                        return changePatientFilters({
                            rowsPerPage: parseInt(e.target.value, 10),
                            page: 1
                        });
                    } }))),
            React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, onClose: function () {
                    _this.menuRef = null;
                }, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                React.createElement(RdiMenuItem, { onClick: this.viewTrials, permissions: [
                        {
                            aclOp: AclOpEnum.READ,
                            aclModule: AclModuleEnum.PATIENT
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "View trials")),
                React.createElement(RdiMenuItem, { onClick: this.resendPatientPasswordReset, permissions: [
                        {
                            aclOp: AclOpEnum.UPDATE,
                            aclModule: AclModuleEnum.PATIENT
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Resend password set email")),
                React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                        {
                            aclOp: AclOpEnum.DELETE,
                            aclModule: AclModuleEnum.PATIENT
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(RdiTypography, { variant: "body2" }, "Delete")))));
    };
    __decorate([
        observable
    ], PatientListPage.prototype, "menuRef", void 0);
    __decorate([
        observable
    ], PatientListPage.prototype, "patientInEdit", void 0);
    PatientListPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], PatientListPage);
    return PatientListPage;
}(Component));
export default withStyles(styles)(withRouter(PatientListPage));
