export var TaskPriorityEnum;
(function (TaskPriorityEnum) {
    TaskPriorityEnum["LOW"] = "low";
    TaskPriorityEnum["MEDIUM"] = "medium";
    TaskPriorityEnum["HIGH"] = "high";
    TaskPriorityEnum["CRITICAL"] = "critical";
})(TaskPriorityEnum || (TaskPriorityEnum = {}));
export var TaskPriorityColorsEnum;
(function (TaskPriorityColorsEnum) {
    TaskPriorityColorsEnum["low"] = "green";
    TaskPriorityColorsEnum["medium"] = "blue";
    TaskPriorityColorsEnum["critical"] = "red";
    TaskPriorityColorsEnum["high"] = "red";
})(TaskPriorityColorsEnum || (TaskPriorityColorsEnum = {}));
export var TaskPriorityLabelsEnum;
(function (TaskPriorityLabelsEnum) {
    TaskPriorityLabelsEnum["low"] = "Low";
    TaskPriorityLabelsEnum["medium"] = "Medium";
    TaskPriorityLabelsEnum["high"] = "High";
    TaskPriorityLabelsEnum["critical"] = "Critical";
})(TaskPriorityLabelsEnum || (TaskPriorityLabelsEnum = {}));
