var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { CircularProgress, IconButton, Menu, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectSiteStore } from '~/stores/SiteStore';
import NewIpDispensingLog from './AddIpDispensingLog';
var IpDispensingLogPage = /** @class */ (function (_super) {
    __extends(IpDispensingLogPage, _super);
    function IpDispensingLogPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.columns = [
            {
                id: 'ipnumber',
                label: 'IP Number',
                sortable: false
            },
            {
                id: 'lotnumber',
                label: 'Lot number',
                sortable: false
            },
            {
                id: 'dispensed',
                label: 'Dispensed date',
                sortable: true,
                sortName: 'dispensedAt'
            },
            {
                id: 'tabsdispensed',
                label: 'Tabs dispensed',
                sortable: false
            },
            {
                id: 'staffdispensing',
                label: 'Staff dispensing',
                sortable: false
            },
            {
                id: 'returned',
                label: 'Returned date',
                sortable: false
            },
            {
                id: 'tabsreturned',
                label: 'Tabs returned',
                sortable: false
            },
            {
                id: 'compliance',
                label: 'Compliance',
                sortable: false
            },
            {
                id: 'action',
                label: '',
                sortable: false,
                width: '50px'
            }
        ];
        _this.menuRef = null;
        _this.dispensingLogInEdit = null;
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
            var ipDispensingStore;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ipDispensingStore = this.props.enrollmentStore.ipDispensingStore;
                        this.handleMenuClose();
                        this.menuRef = null;
                        return [4 /*yield*/, ipDispensingStore.deleteIpDispensingLogWithConfirmation(this.dispensingLogInEdit)];
                    case 1:
                        _a.sent();
                        this.dispensingLogInEdit = null;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleMenuClick = function (event, log) {
            _this.menuRef = event.currentTarget;
            _this.dispensingLogInEdit = log;
        };
        _this.handleMenuClose = function () {
            _this.menuRef = null;
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var ipDispensingStore;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ipDispensingStore = this.props.enrollmentStore.ipDispensingStore;
                        return [4 /*yield*/, ipDispensingStore.listIpDispensingLogs()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getNewRoute = function () {
            var url = _this.props.match.url;
            return url + "/newdi";
        };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            _this.handleMenuClose();
            history.push(url + "/editdi/" + _this.dispensingLogInEdit.id);
        };
        _this.onView = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            _this.handleMenuClose();
            history.push(url + "/details/" + _this.dispensingLogInEdit.id);
        };
        _this.export = function () {
            var ipDispensingStore = _this.props.enrollmentStore.ipDispensingStore;
            var url = ipDispensingStore.getExportUrl();
            window.open(url, '_blank');
        };
        return _this;
    }
    Object.defineProperty(IpDispensingLogPage.prototype, "rowMenuOpen", {
        get: function () {
            return !!this.menuRef;
        },
        enumerable: false,
        configurable: true
    });
    IpDispensingLogPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, ipDispensingStore = _a.enrollmentStore.ipDispensingStore, disabled = _a.disabled, siteStore = _a.siteStore, accountStore = _a.accountStore;
        var logs = ipDispensingStore.logs, logsCount = ipDispensingStore.logsCount, changeIpDispensingFilters = ipDispensingStore.changeIpDispensingFilters, ipDispensingLogsFilters = ipDispensingStore.ipDispensingLogsFilters, ipDispensingLoading = ipDispensingStore.ipDispensingLoading;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { width: '100%', marginBottom: '20px' } },
                React.createElement(FlexContainer, { justifyContent: "space-between", style: { marginBottom: '25px' } },
                    React.createElement(RdiTypography, { style: {
                            fontSize: '14px',
                            lineHeight: '18px',
                            margin: 0
                        }, variant: "h6" }, logsCount + " IP log entr" + (logsCount === 1 ? 'y' : 'ies')),
                    React.createElement(FlexContainer, null,
                        React.createElement(RdiButton, { disabled: disabled, onClick: this.export, name: "export", style: { marginBottom: '15px', marginRight: '12px' }, permissions: [
                                {
                                    aclOp: AclOpEnum.READ,
                                    aclModule: AclModuleEnum.IP_DISPENSING_LOG
                                }
                            ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Export IP"),
                        React.createElement(RdiButton, { disabled: disabled, to: this.getNewRoute, name: "addip", permissions: [
                                {
                                    aclOp: AclOpEnum.CREATE,
                                    aclModule: AclModuleEnum.IP_DISPENSING_LOG
                                }
                            ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Add IP"))),
                React.createElement(RdiTable, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, this.columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column, changeFilters: changeIpDispensingFilters, filters: ipDispensingLogsFilters })); }))),
                    !ipDispensingLoading && (React.createElement(TableBody, null,
                        logs.map(function (item) { return (React.createElement(TableRow, { key: item.id },
                            React.createElement(TableCell, null, item.ipNumber),
                            React.createElement(TableCell, null, item.lotNumber),
                            React.createElement(TableCell, null, formatDate(item.dispensedAt)),
                            React.createElement(TableCell, null, item.tabsDispensedCount),
                            React.createElement(TableCell, null, item.dispensingStaffName),
                            React.createElement(TableCell, null, item.returnedAt && item.returnedAt ? formatDate(item.returnedAt) : ''),
                            React.createElement(TableCell, null, item.tabsReturnedCount !== undefined ? item.tabsReturnedCount : ''),
                            React.createElement(TableCell, null, item.compliance !== undefined
                                ? item.compliance + "% (" + item.compliance + "/100)"
                                : ''),
                            React.createElement(TableCell, null,
                                React.createElement(IconButton, { name: "editAnchor", style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, item); } },
                                    React.createElement(MoreVertIcon, null))))); }),
                        !ipDispensingLoading && logsCount === 0 && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 9 }, "No results")))))),
                ipDispensingLoading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                    React.createElement(CircularProgress, null))),
                React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: this.rowMenuOpen, onClose: this.handleMenuClose, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                    React.createElement(RdiMenuItem, { onClick: this.onView, permissions: [
                            {
                                aclOp: AclOpEnum.READ,
                                aclModule: AclModuleEnum.IP_DISPENSING_LOG
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "View")),
                    React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.IP_DISPENSING_LOG
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Edit")),
                    React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.IP_DISPENSING_LOG
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Delete"))),
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: SiteRoutes.NEW_IP_DISPENSING_LOG_ROUTE },
                        React.createElement(NewIpDispensingLog, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.NEW_IP_DISPENSING_LOG_ROUTE_VISIT },
                        React.createElement(NewIpDispensingLog, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_IP_DISPENSING_LOG_ROUTE },
                        React.createElement(NewIpDispensingLog, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_IP_DISPENSING_LOG_ROUTE_VISIT },
                        React.createElement(NewIpDispensingLog, null)),
                    React.createElement(Route, { exact: true, path: SiteRoutes.IP_DISPENSING_LOG_DETAILS_ROUTE },
                        React.createElement(NewIpDispensingLog, { viewOnly: true }))))));
    };
    __decorate([
        observable
    ], IpDispensingLogPage.prototype, "menuRef", void 0);
    __decorate([
        observable
    ], IpDispensingLogPage.prototype, "dispensingLogInEdit", void 0);
    __decorate([
        computed
    ], IpDispensingLogPage.prototype, "rowMenuOpen", null);
    IpDispensingLogPage = __decorate([
        injectEnrollmentStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], IpDispensingLogPage);
    return IpDispensingLogPage;
}(Component));
export default withRouter(IpDispensingLogPage);
