import { MenuItem } from "@material-ui/core";
import React, { forwardRef, useEffect, useState } from "react";
var RdiMenuItem = forwardRef(function (props, ref) {
    var aclCheck = props.aclCheck, disabled = props.disabled, permissions = props.permissions;
    var _a = useState(false), internalDisabled = _a[0], setInternalDisabled = _a[1];
    useEffect(function () {
        if ((aclCheck && !aclCheck(permissions)) || disabled) {
            setInternalDisabled(true);
        }
        else {
            setInternalDisabled(false);
        }
    }, [disabled, permissions]);
    return (React.createElement(MenuItem, { disabled: internalDisabled, onClick: props.onClick, ref: ref, style: props.style }, props.children));
});
export default RdiMenuItem;
