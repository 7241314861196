var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Model } from "s~/types/models/Model";
import { getDateFromString } from "s~/utils/dateUtils";
var IpDispensingLog = /** @class */ (function (_super) {
    __extends(IpDispensingLog, _super);
    function IpDispensingLog(p) {
        var _this = _super.call(this, p) || this;
        _this.ipNumber = p.ipNumber;
        _this.lotNumber = p.lotNumber;
        _this.dispensedAt = getDateFromString(p.dispensedAt);
        _this.tabsDispensedCount = p.tabsDispensedCount;
        _this.dispensingStaffName = p.dispensingStaffName;
        _this.returnedAt = p.returnedAt
            ? getDateFromString(p.returnedAt)
            : undefined;
        _this.tabsReturnedCount = p.tabsReturnedCount
            ? p.tabsReturnedCount
            : undefined;
        _this.compliance = p.compliance ? p.compliance : undefined;
        _this.receivingStaffName = p.receivingStaffName
            ? p.receivingStaffName
            : undefined;
        _this.id = p.id;
        return _this;
    }
    return IpDispensingLog;
}(Model));
export { IpDispensingLog };
