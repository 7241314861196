var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Model } from "s~/types/models/Model";
var Permission = /** @class */ (function (_super) {
    __extends(Permission, _super);
    function Permission(q) {
        var _this = _super.call(this, q) || this;
        _this.id = q.id;
        _this.namespace = q.namespace;
        _this.namespaceId = q.namespaceId;
        _this.source = q.source;
        _this.operation = q.operation;
        _this.module = q.module;
        return _this;
    }
    Object.defineProperty(Permission.prototype, "toString", {
        get: function () {
            return this.namespace + "-" + this.namespaceId + "__" + this.module + "__" + this.operation;
        },
        enumerable: false,
        configurable: true
    });
    return Permission;
}(Model));
export { Permission };
