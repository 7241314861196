import { useEffect } from 'react';
var useScript = function (url, onLoad, onUnload) {
    useEffect(function () {
        var script = document.createElement('script');
        script.src = url;
        script.async = true;
        if (onLoad) {
            script.onload = function () {
                onLoad();
            };
        }
        document.body.appendChild(script);
        return function () {
            document.body.removeChild(script);
        };
    }, [url]);
    useEffect(function () {
        return function () {
            if (onUnload) {
                onUnload();
            }
        };
    }, []);
};
export default useScript;
