var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import { Breadcrumbs, CircularProgress, createStyles, Link, withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTreeView from '@cros/shared/components/misc/RdiTreeView';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { DropzoneArea } from 'material-ui-dropzone';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import CustomGptWidget from '~/components/widgets/customGptWidget';
import config from '~/config';
import { getProtocolRoute, getTrialRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
import { injectTrialStore } from '~/stores/TrialStore';
import AddTrialContactModal from './AddTrialContactModal';
import WeblinkAddModal from './WeblinkAddModal';
var styles = function () {
    return createStyles({
        rowContainer: {
            borderBottom: "1px solid " + ColorsEnum.BlackLight,
            marginBottom: '20px'
        },
        row: {
            borderTop: "1px solid " + ColorsEnum.BlackLight,
            display: 'flex',
            flexDirection: 'row',
            padding: '10px 0px',
            alignItems: 'center',
            cursor: 'pointer',
            minHeight: '45px',
            outline: 'none !important'
        },
        expandedCell: {
            flex: '1'
        },
        heightContainer: {
            transition: 'max-height .5s',
            overflow: 'hidden',
            boxSizing: 'border-box'
        },
        expandedContainer: {
            padding: '5px 10px 30px 50px'
        },
        expandedItem: {
            minHeight: '45px',
            display: 'flex',
            alignItems: 'center'
        },
        visible: {
            maxHeight: '500px'
        },
        hidden: {
            maxHeight: '0px'
        },
        menu: {
            position: 'absolute',
            backgroundColor: '#fff',
            borderRadius: '2px',
            padding: '0.5rem 0',
            width: '150px',
            height: 'auto',
            margin: 0,
            listStyle: 'none',
            boxShadow: '0 0 20px 0 #ccc'
        },
        menuItem: {
            padding: '0.5rem',
            color: '#000',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: '#46aac855',
                borderLeft: '4px solid #46aac8',
                textDecoration: 'none'
            }
        }
    });
};
var ProtocolPage = /** @class */ (function (_super) {
    __extends(ProtocolPage, _super);
    function ProtocolPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.uploadFile = function (files) {
            var uploadFiles = _this.props.trialStore.uploadFiles;
            if (files === null || files === void 0 ? void 0 : files.length) {
                uploadFiles(files.map(function (f) {
                    return new UploadedFile({
                        originalname: f.path.charAt(0) === '/' ? f.path.substring(1) : f.path,
                        mimetype: f.type,
                        size: f.size,
                        url: null,
                        file: f
                    });
                }));
            }
        };
        _this.getModalLink = function (type, index) {
            var _a = _this.props.match.params, siteId = _a.siteId, trialId = _a.trialId;
            return getProtocolRoute(siteId, trialId) + "/" + type + "/" + (index >= 0 ? index : '');
        };
        _this.getContactModalLink = function (index) {
            var _a = _this.props.match.params, siteId = _a.siteId, trialId = _a.trialId;
            return getProtocolRoute(siteId, trialId) + "/contact/" + (index || index === 0 ? "edit/" + index : 'add');
        };
        _this.handleCreateFolder = function (folderName, parentFolder) {
            var _a;
            var addEmptyFolder = _this.props.trialStore.addEmptyFolder;
            var newFile = new UploadedFile(__assign(__assign({}, parentFolder === null || parentFolder === void 0 ? void 0 : parentFolder.file), { url: 'folder' }));
            var targetFileNameSplit = ((_a = newFile.originalname) === null || _a === void 0 ? void 0 : _a.split('/')) || [''];
            if (parentFolder.key === '0') {
                newFile.originalname = folderName;
            }
            else if (parentFolder.isFolder && parentFolder.file.url === 'folder') {
                targetFileNameSplit[targetFileNameSplit.length] = folderName;
            }
            else {
                targetFileNameSplit[targetFileNameSplit.length - 1] = folderName;
            }
            newFile.originalname = newFile.originalname || targetFileNameSplit.join('/');
            addEmptyFolder(newFile);
        };
        _this.handleRename = function (nodes) { return __awaiter(_this, void 0, void 0, function () {
            var updateSiteRoleTrial;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateSiteRoleTrial = this.props.trialStore.updateSiteRoleTrial;
                        return [4 /*yield*/, updateSiteRoleTrial({ files: nodes.map(function (n) { return n.file; }) })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleDelete = function (node) {
            var deleteFileWithConfirmation = _this.props.trialStore.deleteFileWithConfirmation;
            deleteFileWithConfirmation(node);
        };
        _this.handleUploadError = function (msg) {
            var setError = _this.props.trialStore.errorModalStore.setError;
            if (msg.startsWith('Maximum allowed number of files exceeded')) {
                setError({ message: msg });
            }
        };
        _this.hasPermissions = function (permissions) {
            var _a;
            var _b = _this.props, accountStore = _b.accountStore, siteStore = _b.siteStore;
            return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId);
        };
        return _this;
    }
    ProtocolPage.prototype.componentDidMount = function () {
        var getSiteRoleTrial = this.props.trialStore.getSiteRoleTrial;
        getSiteRoleTrial();
    };
    ProtocolPage.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var _d = this.props, classes = _d.classes, _e = _d.match.params, siteId = _e.siteId, trialId = _e.trialId, _f = _d.trialStore, trial = _f.trial, siteRoleTrial = _f.siteRoleTrial, deleteTrialSiteLinkWithConfirmation = _f.deleteTrialSiteLinkWithConfirmation, fileUploadLoading = _f.fileUploadLoading, accountStore = _d.accountStore, siteStore = _d.siteStore;
        return (React.createElement("div", { style: { width: '100%' } },
            React.createElement(Breadcrumbs, { separator: "/", "aria-label": "breadcrumb" },
                React.createElement(RdiLink, { xs: true, to: getTrialRoute(siteId, trialId) }, trial.name),
                React.createElement("span", null, "Protocol")),
            React.createElement(FixedWidthContent, { white: true, xs: true },
                React.createElement(RdiTypography, { variant: "h1" }, "Protocol"),
                React.createElement(RdiTypography, { variant: "body1", style: { marginBottom: '30px' } }, "Here you can review the workflow, logs, forms and documents of the trial."),
                React.createElement(FlexContainer, { alignItems: "center", flexDirection: "column", margin: 30, padding: "5px", permissions: [
                        {
                            aclOp: AclOpEnum.CREATE,
                            aclModule: AclModuleEnum.TRIAL
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                    React.createElement(DropzoneArea, { onDrop: this.uploadFile, showPreviewsInDropzone: false, maxFileSize: 52428800, filesLimit: 200, showAlerts: false, onAlert: function (msg) { return _this.handleUploadError(msg); } }),
                    fileUploadLoading && React.createElement(CircularProgress, null)),
                React.createElement(RdiTypography, { variant: "h6", style: { marginBottom: '20px', marginTop: '20px' } }, "Documents"),
                !!((_a = siteRoleTrial === null || siteRoleTrial === void 0 ? void 0 : siteRoleTrial.files) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(RdiTreeView, { apiUrl: config.apiUrl, files: siteRoleTrial === null || siteRoleTrial === void 0 ? void 0 : siteRoleTrial.files, showCreateFolder: this.hasPermissions([
                        { aclOp: AclOpEnum.CREATE, aclModule: AclModuleEnum.TRIAL }
                    ]), showDelete: this.hasPermissions([
                        { aclOp: AclOpEnum.DELETE, aclModule: AclModuleEnum.TRIAL }
                    ]), showRename: this.hasPermissions([
                        { aclOp: AclOpEnum.UPDATE, aclModule: AclModuleEnum.TRIAL }
                    ]), createFolder: this.handleCreateFolder, renameFile: this.handleRename, deleteFile: this.handleDelete })),
                React.createElement(RdiTypography, { variant: "h6", style: { marginBottom: '30px', marginTop: '20px' } }, "Website links"),
                React.createElement("div", { className: classes.rowContainer }, (_b = siteRoleTrial === null || siteRoleTrial === void 0 ? void 0 : siteRoleTrial.websiteLinks) === null || _b === void 0 ? void 0 : _b.map(function (item, index) { return (React.createElement("div", { key: index, className: classes.row },
                    React.createElement("div", { className: classes.expandedCell },
                        React.createElement(Link, { href: item.href, target: "_blank", rel: "noreferrer" }, item.label)),
                    React.createElement(RdiLink, { xs: true, aclHide: true, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.TRIAL
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, to: _this.getModalLink('edit-link', index) },
                        React.createElement(Edit, { style: { fontSize: '18px' } })),
                    React.createElement(RdiLink, { xs: true, aclHide: true, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.TRIAL
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(Delete, { style: { fontSize: '18px', marginLeft: '8px', marginRight: '8px' }, onClick: function () { return deleteTrialSiteLinkWithConfirmation(index); } })))); })),
                React.createElement(RdiLink, { xs: true, aclHide: true, permissions: [
                        {
                            aclOp: AclOpEnum.UPDATE,
                            aclModule: AclModuleEnum.TRIAL
                        }
                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, to: this.getModalLink('add-link') },
                    React.createElement(Add, null)),
                React.createElement(RdiLine, null),
                React.createElement(RdiTypography, { variant: "h6", style: { marginBottom: '30px', marginTop: '20px' } },
                    "Contact details",
                    React.createElement(FlexContainer, { display: "inline-flex", permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.TRIAL
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RouterLink, { to: this.getContactModalLink(), style: {
                                color: '#46AAC8',
                                fontSize: '12px',
                                marginLeft: '10px',
                                cursor: 'pointer'
                            } }, "Add"))), (_c = siteRoleTrial === null || siteRoleTrial === void 0 ? void 0 : siteRoleTrial.contact) === null || _c === void 0 ? void 0 :
                _c.map(function (contact, index) { return (React.createElement("div", { className: classes.rowContainer },
                    React.createElement(RdiLink, { to: _this.getContactModalLink(index), style: {
                            color: '#46AAC8',
                            fontSize: '12px',
                            margin: '0 0 8px 0',
                            cursor: 'pointer'
                        }, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.TRIAL
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, aclHide: true }, "Edit"),
                    React.createElement(FlexContainer, { margin: 8, style: { marginBottom: '8px', marginTop: '8px' } },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "Name:"),
                        React.createElement("div", null, contact === null || contact === void 0 ? void 0 : contact.name)),
                    React.createElement(FlexContainer, { margin: 8, style: { marginBottom: '8px' } },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "Title:"),
                        React.createElement("div", null, contact === null || contact === void 0 ? void 0 : contact.title)),
                    React.createElement(FlexContainer, { margin: 8, style: { marginBottom: '8px' } },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "Email address:"),
                        React.createElement("div", null, contact === null || contact === void 0 ? void 0 : contact.email)),
                    React.createElement(FlexContainer, { margin: 8, style: { marginBottom: '8px' } },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "Phone no.:"),
                        React.createElement("div", null, contact === null || contact === void 0 ? void 0 : contact.phoneNumber)),
                    React.createElement(FlexContainer, { margin: 8, style: { marginBottom: '8px' } },
                        React.createElement(RdiTypography, { variant: "h6", color: TypographyColorsEnum.Primary }, "CRO / Sponsor:"),
                        React.createElement("div", null, contact === null || contact === void 0 ? void 0 : contact.sponsor)),
                    React.createElement(CustomGptWidget, { protocolId: trial.protocolId }))); })),
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: SiteRoutes.ADD_SITE_TRIAL_LINK },
                    React.createElement(WeblinkAddModal, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_SITE_TRIAL_LINK },
                    React.createElement(WeblinkAddModal, { isEdit: true })),
                React.createElement(Route, { exact: true, path: SiteRoutes.ADD_SITE_CONTACT },
                    React.createElement(AddTrialContactModal, null)),
                React.createElement(Route, { exact: true, path: SiteRoutes.EDIT_SITE_CONTACT },
                    React.createElement(AddTrialContactModal, null)))));
    };
    ProtocolPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectTrialStore,
        observer
    ], ProtocolPage);
    return ProtocolPage;
}(Component));
export default withStyles(styles)(withRouter(ProtocolPage));
