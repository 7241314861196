var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiInputLabel from '@cros/shared/components/forms/RdiInputLabel';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import RdiFileDescription from '@cros/shared/components/misc/RdiFileDescription';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import UploadedFile from '@cros/shared/types/models/UploadedFile';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import config from '~/config';
import { getPatientOverviewRoute, getTrialRoute } from '~/routing/Routes';
import CalendarIconSmall from '~/static/CalendarIconSmall.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectBillStore } from '~/stores/BillStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () { return createStyles({}); };
var BillFileList = /** @class */ (function (_super) {
    __extends(BillFileList, _super);
    function BillFileList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hasErrors = false;
        _this.data = {
            records: []
        };
        _this.errors = {};
        _this.loading = true;
        _this.saving = false;
        _this.errorMessage = '';
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var billStore, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        billStore = this.props.billStore;
                        _a = this;
                        return [4 /*yield*/, billStore.getBillById(this.billingReportId)];
                    case 1:
                        _a.bill = _b.sent();
                        this.assignData(this.bill);
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.assignData = function (billingReport) {
            _this.data.records = Object.assign([], billingReport.records);
        };
        _this.onClose = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url, search = _a.location.search;
            history.push("" + url.split('/files')[0] + search);
        };
        _this.removeFile = function (item) {
            _this.data.records.find(function (x) { return x.originalname === item.originalname; }).deleted = true;
        };
        _this.uploadFile = function (event) {
            if (!event.files) {
                return;
            }
            for (var _i = 0, _a = event.files; _i < _a.length; _i++) {
                var f = _a[_i];
                _this.data.records.push(new UploadedFile({
                    originalname: f.name,
                    mimetype: f.type,
                    size: f.size,
                    url: null,
                    file: f
                }));
            }
        };
        _this.submitEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var billStore, success, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        e.stopPropagation();
                        billStore = this.props.billStore;
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        this.saving = true;
                        success = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, billStore.updateFiles(this.bill, this.data.records)];
                    case 2:
                        success = _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 4:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 5:
                        if (success) {
                            this.onClose();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        return _this;
    }
    Object.defineProperty(BillFileList.prototype, "billingReportId", {
        get: function () {
            var params = this.props.match.params;
            return params.billingReportId;
        },
        enumerable: false,
        configurable: true
    });
    BillFileList.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var _j = this.props, accountStore = _j.accountStore, siteStore = _j.siteStore;
        return (React.createElement(SmallModal, { onClose: this.onClose, loading: this.loading },
            React.createElement(RdiTypography, { color: TypographyColorsEnum.HeadlineColor, variant: "h1", style: { marginBottom: '15px' } }, "Manage files"),
            React.createElement(FlexContainer, { margin: 50 },
                React.createElement("div", null,
                    React.createElement(RdiLink, { to: getPatientOverviewRoute((_a = this.bill) === null || _a === void 0 ? void 0 : _a.siteId, (_b = this.bill) === null || _b === void 0 ? void 0 : _b.patientId) }, (_d = (_c = this.bill) === null || _c === void 0 ? void 0 : _c.patient) === null || _d === void 0 ? void 0 : _d.fullName)),
                React.createElement("div", null,
                    React.createElement(RdiLink, { to: getTrialRoute((_e = this.bill) === null || _e === void 0 ? void 0 : _e.siteId, (_f = this.bill) === null || _f === void 0 ? void 0 : _f.trialId) }, (_h = (_g = this.bill) === null || _g === void 0 ? void 0 : _g.trial) === null || _h === void 0 ? void 0 : _h.name)),
                React.createElement("div", null,
                    React.createElement(FlexContainer, { margin: 7 },
                        React.createElement("img", { alt: "img", src: CalendarIconSmall }),
                        React.createElement("div", null, "June 2019")))),
            React.createElement(RdiLine, { md: true }),
            React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: this.submitEvent },
                React.createElement(RdiInputLabel, null, "Files"),
                this.data.records
                    .filter(function (x) { return !x.deleted; })
                    .map(function (item, index) { return (React.createElement(RdiFileDescription, { key: index, file: item, onRemove: function () { return _this.removeFile(item); }, config: config })); }),
                React.createElement(RdiInput, { type: "file", label: "Upload file", name: "file", value: null, errorText: this.errors.records, onChange: this.uploadFile }),
                React.createElement(RdiLine, { style: { marginTop: '0px', marginBottom: '20px' } }),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(FlexContainer, { margin: 30, justifyContent: "center" },
                    React.createElement(RdiButton, { loading: this.saving, style: { minWidth: '220px' }, fullWidth: false, submit: true, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.BILLING
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Save")))));
    };
    __decorate([
        observable
    ], BillFileList.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], BillFileList.prototype, "data", void 0);
    __decorate([
        observable
    ], BillFileList.prototype, "errors", void 0);
    __decorate([
        observable
    ], BillFileList.prototype, "loading", void 0);
    __decorate([
        observable
    ], BillFileList.prototype, "saving", void 0);
    __decorate([
        observable
    ], BillFileList.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], BillFileList.prototype, "bill", void 0);
    __decorate([
        computed
    ], BillFileList.prototype, "billingReportId", null);
    BillFileList = __decorate([
        injectAccountStore,
        injectSiteStore,
        injectBillStore,
        observer
    ], BillFileList);
    return BillFileList;
}(Component));
export default withStyles(styles)(withRouter(BillFileList));
