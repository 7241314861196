var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import { AppBar, createStyles, Tab, Tabs, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { Link as RLink } from 'react-router-dom';
import UserGroupsPage from '~/pages/site/site-detail/users/UserGroupsPage';
import UserListPage from '~/pages/site/site-detail/users/UserListPage';
import UserPermissionsPage from '~/pages/site/site-detail/users/UserPermissionsPage';
import { getUsersAllRoute, getUsersGroupsRoute, getUsersPermissionsRoute, getUsersRoute, SiteRoutes } from '~/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () { return createStyles({}); };
var UsersIndexPage = /** @class */ (function (_super) {
    __extends(UsersIndexPage, _super);
    function UsersIndexPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UsersIndexPage.prototype.render = function () {
        var _a = this.props, siteId = _a.siteStore.siteId, pathname = _a.location.pathname;
        return (React.createElement(FixedWidthContent, { white: true },
            React.createElement(Route, { exact: true, path: SiteRoutes.USERS_ROUTE },
                React.createElement(Redirect, { to: "" + getUsersAllRoute(siteId) })),
            React.createElement(RdiTypography, { variant: "h1" }, "User management"),
            pathname !== getUsersRoute(siteId) && (React.createElement(AppBar, { position: "static", color: "default" },
                React.createElement(Tabs, { value: pathname, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" },
                    React.createElement(Tab, { value: getUsersAllRoute(siteId), component: RLink, to: getUsersAllRoute(siteId), label: "Users" }),
                    React.createElement(Tab, { value: getUsersGroupsRoute(siteId), component: RLink, to: getUsersGroupsRoute(siteId), label: "Groups" }),
                    React.createElement(Tab, { value: getUsersPermissionsRoute(siteId), component: RLink, to: getUsersPermissionsRoute(siteId), label: "Permissions" })))),
            React.createElement(Switch, null,
                React.createElement(Route, { path: SiteRoutes.USERS_ALL_ROUTE },
                    React.createElement(UserListPage, null)),
                React.createElement(Route, { path: SiteRoutes.USERS_GROUPS_ROUTE },
                    React.createElement(UserGroupsPage, null)),
                React.createElement(Route, { path: SiteRoutes.USERS_PERMISSIONS_ROUTE },
                    React.createElement(UserPermissionsPage, null)))));
    };
    UsersIndexPage = __decorate([
        injectAccountStore,
        injectSiteStore,
        observer
    ], UsersIndexPage);
    return UsersIndexPage;
}(Component));
export default withStyles(styles)(withRouter(UsersIndexPage));
