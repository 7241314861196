var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import DateWithIcon from '@cros/shared/components/misc/DateWithIcon';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import ProgressWithStatus from '@cros/shared/components/misc/ProgressWithStatus';
import RdiMenuItem from '@cros/shared/components/misc/RdiMenuItem';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import { VisitStatusEnum } from '@cros/shared/constants/enums/VisitStatusEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { CircularProgress, createStyles, IconButton, Menu, TableBody, TableCell, TableHead, TableRow, Tooltip, withStyles } from '@material-ui/core';
import NoteInfo from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getNewUnscheduledVisitRoute, getScheduleRoute, getVisitDetailRoute } from '~/routing/Routes';
import CheckMarkIcon from '~/static/CheckMarkIcon.svg';
import InProgressIcon from '~/static/InProgressIcon.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function (theme) {
    return createStyles({
        clickCell: {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    });
};
var VisitListPage = /** @class */ (function (_super) {
    __extends(VisitListPage, _super);
    function VisitListPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tabs = [
            {
                id: 'all',
                label: 'Visit',
                sortable: false,
                width: '25%'
            },
            {
                id: 'invited',
                label: 'Week',
                sortable: false,
                width: '10%'
            },
            {
                id: 'inprogress',
                label: 'Visit progress',
                sortable: false,
                width: '15%'
            },
            {
                id: 'completed',
                label: 'Scheduled',
                sortable: false,
                width: '25%'
            },
            {
                id: 'completed',
                label: 'Actions',
                sortable: false,
                width: '35%'
            },
            {
                id: 'actions',
                label: '',
                sortable: false,
                width: '50px'
            }
        ];
        _this.loading = true;
        _this.menuRef = null;
        _this.visitInEdit = null;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var visitListStore;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        visitListStore = this.props.enrollmentStore.visitListStore;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        this.loading = true;
                        return [4 /*yield*/, visitListStore.listVisits()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.goToVisitDetail = function (visitId) {
            var _a = _this.props, _b = _a.match.params, enrollmentId = _b.enrollmentId, siteId = _b.siteId, trialId = _b.trialId, patientId = _b.patientId, history = _a.history;
            history.push(getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, visitId));
        };
        _this.checkInPatient = function (visit) { return __awaiter(_this, void 0, Promise, function () {
            var _a, history, visitListStore, _b, enrollmentId, siteId, trialId, patientId;
            return __generator(this, function (_c) {
                _a = this.props, history = _a.history, visitListStore = _a.enrollmentStore.visitListStore, _b = _a.match.params, enrollmentId = _b.enrollmentId, siteId = _b.siteId, trialId = _b.trialId, patientId = _b.patientId;
                visitListStore.checkInVisitWithConfirmation(visit, function () {
                    history.push(getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, visit.id));
                });
                return [2 /*return*/];
            });
        }); };
        _this.goToNewUnscheduledVisit = function () {
            var _a = _this.props, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId, history = _a.history;
            history.push(getNewUnscheduledVisitRoute(siteId, patientId, trialId, enrollmentId));
        };
        _this.handleMenuClick = function (event, visit) {
            _this.menuRef = event.currentTarget;
            _this.visitInEdit = visit;
        };
        _this.onEdit = function () {
            var _a = _this.props, history = _a.history, url = _a.match.url;
            _this.menuRef = null;
            history.push(url + "/visits/edit/" + _this.visitInEdit.id);
        };
        _this.onShowDeleteConfirmation = function () { return __awaiter(_this, void 0, Promise, function () {
            var visitListStore;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        visitListStore = this.props.enrollmentStore.visitListStore;
                        this.menuRef = null;
                        return [4 /*yield*/, visitListStore.deleteVisitWithConfirmation(this.visitInEdit, function () {
                                _this.visitInEdit = null;
                            }, function () {
                                _this.visitInEdit = null;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    VisitListPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, classes = _a.classes, _b = _a.enrollmentStore.visitListStore, totalItems = _b.totalItems, visitsDisplay = _b.visitsDisplay, _c = _a.match.params, siteId = _c.siteId, trialId = _c.trialId, enrollmentId = _c.enrollmentId, patientId = _c.patientId, siteStore = _a.siteStore, accountStore = _a.accountStore;
        return (React.createElement(React.Fragment, null,
            this.loading && (React.createElement(FlexContainer, { height: "85px", justifyContent: "center", alignItems: "center" },
                React.createElement(CircularProgress, null))),
            !this.loading && (React.createElement(React.Fragment, null,
                React.createElement(FlexContainer, { justifyContent: "space-between", style: { marginBottom: '25px' } },
                    React.createElement(RdiTypography, { variant: "h6", style: {
                            fontSize: '14px',
                            lineHeight: '18px',
                            margin: 0
                        } }, totalItems + " visits"),
                    React.createElement(RdiButton, { name: "add-visit", fullWidth: false, onClick: this.goToNewUnscheduledVisit, permissions: [
                            {
                                aclOp: AclOpEnum.CREATE,
                                aclModule: AclModuleEnum.VISIT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); }, margin: "none" }, "Add a visit")),
                React.createElement(RdiTable, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, this.tabs.map(function (col, index) {
                            return (React.createElement(TableCell, { style: { width: col.width }, key: index }, col.label));
                        }))),
                    React.createElement(TableBody, null,
                        visitsDisplay.map(function (_a, i) {
                            var stage = _a.stage, visits = _a.visits;
                            return (React.createElement(React.Fragment, { key: i },
                                React.createElement(TableRow, { style: {
                                        backgroundColor: ColorsEnum.BlackSuperlight,
                                        height: '42px'
                                    } },
                                    React.createElement(TableCell, { colSpan: 6 },
                                        React.createElement(RdiTypography, { variant: "subtitle1" }, stage))),
                                visits.map(function (visit) { return (React.createElement(TableRow, { key: visit.id, hover: true, tabIndex: -1, style: visit.status === VisitStatusEnum.SCHEDULED ||
                                        visit.status === VisitStatusEnum.IN_PROGRESS
                                        ? {
                                            backgroundColor: 'rgba(70,170,200,0.1)'
                                        }
                                        : {} },
                                    React.createElement(TableCell, { onClick: function () { return _this.goToVisitDetail(visit.id); }, className: classes.clickCell },
                                        React.createElement(FlexContainer, { margin: 7 },
                                            React.createElement(RdiLink, { to: "" }, visit.name),
                                            !!visit.description && (React.createElement(Tooltip, { title: visit.description },
                                                React.createElement("div", { style: { display: 'flex' } },
                                                    React.createElement(NoteInfo, { style: { color: ColorsEnum.BlackGrey } })))))),
                                    React.createElement(TableCell, null, visit.week),
                                    React.createElement(TableCell, null,
                                        React.createElement(ProgressWithStatus, { value: visit.progress })),
                                    React.createElement(TableCell, null, visit.engagement ? (React.createElement(DateWithIcon, { displayType: "humanized date and time", value: visit.engagement })) : (React.createElement(DateWithIcon, { displayType: "humanized date and time", stringValue: "Not yet scheduled" }))),
                                    React.createElement(TableCell, null,
                                        React.createElement(FlexContainer, { margin: 10, flexWrap: "wrap" },
                                            visit.isDoneAtList && (React.createElement(React.Fragment, null,
                                                React.createElement("img", { alt: "checkmark", src: CheckMarkIcon }),
                                                React.createElement(RdiTypography, { color: TypographyColorsEnum.Success, variant: "body2" }, "Visit completed"))),
                                            visit.isInProgressAtList && (React.createElement(React.Fragment, null,
                                                React.createElement("img", { alt: "in-progress", src: InProgressIcon }),
                                                React.createElement(RdiTypography, { variant: "body2" }, "Visit in progress"),
                                                React.createElement(RdiButton, { fullWidth: false, to: getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, visit.id), style: {
                                                        width: '90px',
                                                        height: '26px',
                                                        fontSize: '10px'
                                                    }, xs: true }, "VIEW"))),
                                            visit.isDoneAtList && !visit.isCheckedOut && (React.createElement(RdiButton, { fullWidth: false, to: getVisitDetailRoute(siteId, patientId, trialId, enrollmentId, visit.id), xs: true, permissions: [
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.VISIT
                                                    }
                                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Check out")),
                                            !visit.isCheckedIn && visit.engagement && (React.createElement(React.Fragment, null,
                                                React.createElement("span", null, "Upcoming"),
                                                React.createElement(RdiButton, { xs: true, onClick: function () { return _this.checkInPatient(visit); }, permissions: [
                                                        {
                                                            aclOp: AclOpEnum.UPDATE,
                                                            aclModule: AclModuleEnum.VISIT
                                                        }
                                                    ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Check in"))),
                                            !visit.isCheckedIn && (React.createElement(RdiLink, { to: getScheduleRoute(siteId, patientId, trialId, enrollmentId, visit.id), permissions: [
                                                    {
                                                        aclOp: AclOpEnum.UPDATE,
                                                        aclModule: AclModuleEnum.VISIT
                                                    }
                                                ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, visit.engagement ? 'Reschedule' : 'Schedule')))),
                                    React.createElement(TableCell, null,
                                        React.createElement(IconButton, { style: { padding: 'unset' }, "aria-label": "more", "aria-controls": "long-menu", "aria-haspopup": "true", onClick: function (event) { return _this.handleMenuClick(event, visit); }, name: "editAnchor" },
                                            React.createElement(MoreVertIcon, null))))); })));
                        }),
                        !this.loading && totalItems === 0 && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 6 }, "No results"))))),
                React.createElement(Menu, { className: "editMenuDropdown", anchorEl: this.menuRef, keepMounted: true, open: !!this.menuRef, onClose: function () {
                        _this.menuRef = null;
                    }, getContentAnchorEl: null, transformOrigin: { vertical: 'top', horizontal: 'right' }, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                    React.createElement(RdiMenuItem, { onClick: this.onEdit, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.VISIT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Edit")),
                    React.createElement(RdiMenuItem, { onClick: this.onShowDeleteConfirmation, permissions: [
                            {
                                aclOp: AclOpEnum.DELETE,
                                aclModule: AclModuleEnum.VISIT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } },
                        React.createElement(RdiTypography, { variant: "body2" }, "Delete")))))));
    };
    __decorate([
        observable
    ], VisitListPage.prototype, "loading", void 0);
    __decorate([
        observable
    ], VisitListPage.prototype, "menuRef", void 0);
    __decorate([
        observable
    ], VisitListPage.prototype, "visitInEdit", void 0);
    VisitListPage = __decorate([
        injectEnrollmentStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], VisitListPage);
    return VisitListPage;
}(Component));
export default withStyles(styles)(withRouter(VisitListPage));
