import { createStyles, withStyles } from "@material-ui/core";
import React from "react";
import RdiButton from "s~/components/forms/RdiButton";
import ModalContent from "s~/components/layout/content/ModalContent";
import RdiTypography from "s~/components/layout/RdiTypography";
import FlexContainer from "s~/components/misc/FlexContainer";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import RoundCheckMark from "s~/static/RoundCheckMark.svg";
var styles = function () {
    return createStyles({
        root: {
            maxWidth: "490px",
            alignItems: "center",
            backgroundColor: ColorsEnum.White
        }
    });
};
var RdiListItem = function (props) {
    var to = props.to, text = props.text, classes = props.classes;
    return (React.createElement(ModalContent, { className: classes.root },
        React.createElement(FlexContainer, { flexDirection: "column", alignItems: "center", style: { flex: "1", justifyContent: "center" } },
            React.createElement(FlexContainer, { style: { marginBottom: "30px" } },
                React.createElement("img", { src: RoundCheckMark, alt: "checkMark" })),
            React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: "25px" } }, props.title ? props.title : "Success"),
            React.createElement(RdiTypography, { variant: "body2", style: { marginBottom: "35px" } }, text)),
        React.createElement(RdiButton, { fullWidth: true, to: to }, "Continue")));
};
export default withStyles(styles)(RdiListItem);
