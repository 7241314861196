export var AdverseEventOutcomeEnum;
(function (AdverseEventOutcomeEnum) {
    AdverseEventOutcomeEnum["RECOVERED"] = "recovered";
    AdverseEventOutcomeEnum["NOT_RECOVERED"] = "not-recovered";
    AdverseEventOutcomeEnum["RECOVERED_WITH_SEQUELAE"] = "recovered-with-sequalae";
})(AdverseEventOutcomeEnum || (AdverseEventOutcomeEnum = {}));
export var AdverseEventOutcomeLabelsEnum;
(function (AdverseEventOutcomeLabelsEnum) {
    AdverseEventOutcomeLabelsEnum["recovered"] = "Recovered";
    AdverseEventOutcomeLabelsEnum["not-recovered"] = "Not recovered";
    AdverseEventOutcomeLabelsEnum["recovered-with-sequalae"] = "Recovered with sequelae";
})(AdverseEventOutcomeLabelsEnum || (AdverseEventOutcomeLabelsEnum = {}));
