var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ChangePasswordComponent from '@cros/shared/components/auth/ChangePasswordComponent';
import { createStyles, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
var styles = function (theme) {
    var _a;
    return createStyles({
        inputFlex: {
            flex: '0 0 33%',
            display: 'flex',
            boxSizing: 'border-box',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            '&:nth-of-type(2)': (_a = {
                    minWidth: '300px'
                },
                _a[theme.breakpoints.down('xs')] = {
                    minWidth: '100%'
                },
                _a)
        }
    });
};
var ChangePasswordPage = /** @class */ (function (_super) {
    __extends(ChangePasswordPage, _super);
    function ChangePasswordPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChangePasswordPage.prototype.render = function () {
        var changePassword = this.props.accountStore.changePassword;
        return React.createElement(ChangePasswordComponent, { changePassword: changePassword });
    };
    ChangePasswordPage = __decorate([
        injectAccountStore,
        observer
    ], ChangePasswordPage);
    return ChangePasswordPage;
}(Component));
export default withStyles(styles)(withRouter(ChangePasswordPage));
