var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from "mobx";
import { inject } from "mobx-react";
var ToastStore = /** @class */ (function () {
    function ToastStore() {
        var _this = this;
        this._toastMessages = [];
        this.addToast = function (toastMessage) {
            _this._toastMessages.push(toastMessage);
            setTimeout(function () {
                _this.removeToastMessage(toastMessage.id);
            }, toastMessage.toastTimeout);
        };
        this.removeToastMessage = function (toastMessageId) {
            _this._toastMessages = _this._toastMessages.filter(function (toast) { return toast.id !== toastMessageId; });
        };
    }
    Object.defineProperty(ToastStore.prototype, "toastMessages", {
        get: function () {
            return this._toastMessages;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], ToastStore.prototype, "_toastMessages", void 0);
    __decorate([
        computed
    ], ToastStore.prototype, "toastMessages", null);
    return ToastStore;
}());
export default ToastStore;
export var injectToastStore = inject("toastStore");
