var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from 'mobx';
import { ProcedureStatusEnum } from 's~/constants/enums/ProcedureStatusEnum';
import { BillingCode } from 's~/types/models/BillingCode';
import { ICDCode } from 's~/types/models/ICDCode';
import { Model } from 's~/types/models/Model';
import { Patient } from 's~/types/models/Patient';
import { Trial } from 's~/types/models/Trial';
import { User } from 's~/types/models/User';
import { getDateFromString } from 's~/utils/dateUtils';
import { Procedure } from './Procedure';
var Visit = /** @class */ (function (_super) {
    __extends(Visit, _super);
    function Visit(v) {
        var _this = _super.call(this, v) || this;
        _this.procedures = [];
        _this.id = v.id;
        _this.slug = v.slug;
        _this.week = v.week;
        _this.name = v.name;
        _this.progress = v.progress ? parseFloat(v.progress) : 0;
        _this.engagement = v.engagement ? getDateFromString(v.engagement) : null;
        _this.engagementEnd = v.engagementEnd
            ? getDateFromString(v.engagementEnd)
            : null;
        _this.status = v.status;
        _this.location = v.location;
        _this.stage = v.stage;
        _this.order = v.order;
        _this.description = v.description;
        _this.type = v.type;
        _this.checkedInAt = v.checkedInAt ? getDateFromString(v.checkedInAt) : null;
        _this.checkedOutAt = v.checkedOutAt
            ? getDateFromString(v.checkedOutAt)
            : null;
        _this.enrollmentId = v.enrollmentId;
        _this.patientId = v.patientId;
        _this.trialId = v.trialId;
        _this.siteId = v.siteId;
        _this.billingCodes = v.billingCodes
            ? v.billingCodes.map(function (b) { return new BillingCode(b); })
            : [];
        _this.icdCodes = v.icdCodes ? v.icdCodes.map(function (b) { return new ICDCode(b); }) : [];
        _this.billingNote = v.billingNote;
        _this.patientId = v.patientId;
        _this.patient = v.patient ? new Patient(v.patient) : undefined;
        _this.trialId = v.trialId;
        _this.trial = v.trial ? new Trial(v.trial) : undefined;
        _this.createdAt = v.createdAt ? getDateFromString(v.createdAt) : undefined;
        _this.billingType = v.billingType;
        _this.billingName = v.billingName;
        _this.initialEmailReminder = v.initialEmailReminder;
        _this.initialSmsReminder = v.initialSmsReminder;
        _this.secondEmailReminder = v.secondEmailReminder;
        _this.secondSmsReminder = v.secondSmsReminder;
        if (v.feedback) {
            var _a = v.feedback, rating = _a.rating, description = _a.description, changed = _a.changed;
            _this.feedback = {
                rating: rating,
                description: description,
                changed: changed ? new Date(changed) : undefined,
            };
        }
        _this.procedures = [];
        if (v.procedures) {
            for (var _i = 0, _b = v.procedures; _i < _b.length; _i++) {
                var p = _b[_i];
                _this.procedures.push(new Procedure(p));
            }
        }
        _this.visitLocation = v.visitLocation;
        _this.responsiblePeople = v.responsiblePeople
            ? v.responsiblePeople.map(function (e) { return new User(e); })
            : [];
        _this.responsiblePeopleIds = v.responsiblePeopleIds;
        return _this;
    }
    Object.defineProperty(Visit.prototype, "progressDetail", {
        get: function () {
            if (!this.procedures || this.procedures.length === 0) {
                return 100;
            }
            return Math.floor((this.procedures.filter(function (x) {
                return x.status === ProcedureStatusEnum.FINALIZED ||
                    x.status === ProcedureStatusEnum.COMPLETE;
            }).length /
                this.procedures.length) *
                100);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Visit.prototype, "isDone", {
        get: function () {
            return this.progressDetail === 100 && this.engagement && this.isCheckedIn;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Visit.prototype, "isDoneAtList", {
        get: function () {
            return this.progress === 100 && this.engagement && this.isCheckedIn;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Visit.prototype, "isInProgress", {
        get: function () {
            return this.isCheckedIn && this.progressDetail !== 100;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Visit.prototype, "isInProgressAtList", {
        get: function () {
            return this.isCheckedIn && this.progress !== 100;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Visit.prototype, "isCheckedOut", {
        get: function () {
            return !!this.checkedOutAt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Visit.prototype, "isCheckedIn", {
        get: function () {
            return !!this.checkedInAt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Visit.prototype, "isNotScheduled", {
        get: function () {
            return !this.engagement;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], Visit.prototype, "id", void 0);
    __decorate([
        observable
    ], Visit.prototype, "progress", void 0);
    __decorate([
        observable
    ], Visit.prototype, "status", void 0);
    __decorate([
        observable
    ], Visit.prototype, "procedures", void 0);
    __decorate([
        observable
    ], Visit.prototype, "checkedInAt", void 0);
    __decorate([
        observable
    ], Visit.prototype, "checkedOutAt", void 0);
    __decorate([
        observable
    ], Visit.prototype, "checkedOutWithPayment", void 0);
    __decorate([
        observable
    ], Visit.prototype, "billingCodes", void 0);
    __decorate([
        observable
    ], Visit.prototype, "icdCodes", void 0);
    __decorate([
        computed
    ], Visit.prototype, "progressDetail", null);
    __decorate([
        computed
    ], Visit.prototype, "isDone", null);
    __decorate([
        computed
    ], Visit.prototype, "isDoneAtList", null);
    __decorate([
        computed
    ], Visit.prototype, "isInProgress", null);
    __decorate([
        computed
    ], Visit.prototype, "isInProgressAtList", null);
    __decorate([
        computed
    ], Visit.prototype, "isCheckedOut", null);
    __decorate([
        computed
    ], Visit.prototype, "isCheckedIn", null);
    __decorate([
        computed
    ], Visit.prototype, "isNotScheduled", null);
    return Visit;
}(Model));
export { Visit };
