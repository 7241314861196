var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ToastMessage } from '@cros/shared/types/ToastMessage';
import { observable, runInAction } from 'mobx';
import { inject } from 'mobx-react';
import PatientService from '~/services/PatientService';
import VisitService from '~/services/VisitService';
var PatientStore = /** @class */ (function () {
    function PatientStore(siteStore) {
        var _this = this;
        this.siteStore = siteStore;
        this.authToken = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.accountStore.authToken()];
            });
        }); };
        this.getPatient = function (patientId) { return __awaiter(_this, void 0, Promise, function () {
            var authToken, patient, _a, _b, _c, e_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        patient = null;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        _b = (_a = PatientService).getPatient;
                        _c = [this.siteId, patientId];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 3:
                        patient = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        this.errorModalStore.setError(e_1);
                        return [2 /*return*/, null];
                    case 5:
                        runInAction(function () {
                            _this.patient = patient;
                        });
                        return [2 /*return*/, patient];
                }
            });
        }); };
        this.updatePatient = function (o) { return __awaiter(_this, void 0, void 0, function () {
            var authToken, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        authToken = this.accountStore.authToken;
                        _b = (_a = PatientService).updatePatient;
                        _c = [o, this.siteId, this.patient.id];
                        return [4 /*yield*/, authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 2:
                        _d.sent();
                        this.toastStore.addToast(new ToastMessage("Patient " + this.patient.fullName + "'s data updated"));
                        this.getPatient(this.patient.id);
                        return [2 /*return*/];
                }
            });
        }); };
        this.listVisits = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, siteId, authToken, errorModalStore, result, _b, _c, _d, e_2;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.siteStore, siteId = _a.siteId, authToken = _a.authToken, errorModalStore = _a.errorModalStore;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 4, , 5]);
                        _c = (_b = VisitService).listAllVisits;
                        _d = [siteId, this.patient.id, {}];
                        return [4 /*yield*/, authToken()];
                    case 2: return [4 /*yield*/, _c.apply(_b, _d.concat([_e.sent()]))];
                    case 3:
                        result = _e.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _e.sent();
                        errorModalStore.setError(e_2);
                        return [2 /*return*/];
                    case 5:
                        runInAction(function () {
                            _this.visits = result.items;
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        this.deleteEnrollmentWithConfirmation = function (enrollment, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                this.confirmationModalStore.openModal("Are you sure you want to remove trial " + ((_a = enrollment.trial) === null || _a === void 0 ? void 0 : _a.name) + " from patient " + this.patient.fullName + "?", function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.deleteEnrollment(enrollment)];
                            case 1:
                                _a.sent();
                                if (onSuccess) {
                                    onSuccess();
                                }
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        }); };
        this.enrollPatientToTrials = function (obj) { return __awaiter(_this, void 0, void 0, function () {
            var p, _a, _b, _c, e_3;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        _b = (_a = PatientService).enrollPatientToTrials;
                        _c = [this.siteStore.siteId,
                            this.patient.id,
                            obj];
                        return [4 /*yield*/, this.authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 2:
                        p = _d.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _d.sent();
                        this.errorModalStore.setError(e_3);
                        return [2 /*return*/];
                    case 4:
                        runInAction(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.patient = p;
                                return [2 /*return*/];
                            });
                        }); });
                        this.toastStore.addToast(new ToastMessage("Patient enrolled to trials"));
                        return [2 /*return*/];
                }
            });
        }); };
        this.deleteEnrollment = function (enrollment) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c, e_4;
            var _this = this;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 3, , 4]);
                        _b = (_a = PatientService).deleteEnrollment;
                        _c = [enrollment.siteId,
                            this.patient.id,
                            enrollment.id];
                        return [4 /*yield*/, this.authToken()];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_e.sent()]))];
                    case 2:
                        _e.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _e.sent();
                        this.errorModalStore.setError(e_4);
                        return [2 /*return*/];
                    case 4:
                        runInAction(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.patient.enrollments = this.patient.enrollments.filter(function (e) { return e.id !== enrollment.id; });
                                return [2 /*return*/];
                            });
                        }); });
                        this.toastStore.addToast(new ToastMessage(((_d = enrollment.trial) === null || _d === void 0 ? void 0 : _d.name) + " trial removed from patient " + this.patient.fullName));
                        return [2 /*return*/];
                }
            });
        }); };
    }
    Object.defineProperty(PatientStore.prototype, "confirmationModalStore", {
        get: function () {
            return this.siteStore.confirmationModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientStore.prototype, "toastStore", {
        get: function () {
            return this.siteStore.toastStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientStore.prototype, "errorModalStore", {
        get: function () {
            return this.siteStore.errorModalStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientStore.prototype, "accountStore", {
        get: function () {
            return this.siteStore.accountStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientStore.prototype, "siteId", {
        get: function () {
            return this.siteStore.siteId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PatientStore.prototype, "patientId", {
        get: function () {
            return this.patient.id;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], PatientStore.prototype, "patient", void 0);
    __decorate([
        observable
    ], PatientStore.prototype, "visits", void 0);
    return PatientStore;
}());
export default PatientStore;
export var injectPatientStore = inject('patientStore');
