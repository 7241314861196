var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
var styles = function () {
    return createStyles({
        marginizedDiv: {
            '& > :not(:last-child)': {
                marginRight: function (props) { return (props.margin && props.flexDirection === 'row' ? props.margin : 0) + "px"; },
                marginBottom: function (props) { return (props.margin && props.flexDirection === 'column' ? props.margin : 0) + "px"; },
            },
        },
    });
};
var FlexContainer = function (props) {
    var flexDirection = props.flexDirection, alignItems = props.alignItems, justifyContent = props.justifyContent, alignSelf = props.alignSelf, bottom = props.bottom, boxSizing = props.boxSizing, display = props.display, flex = props.flex, flexWrap = props.flexWrap, height = props.height, maxHeight = props.maxHeight, minHeight = props.minHeight, minWidth = props.minWidth, order = props.order, maxWidth = props.maxWidth, overflow = props.overflow, overflowX = props.overflowX, overflowY = props.overflowY, padding = props.padding, position = props.position, textAlign = props.textAlign, top = props.top, width = props.width, zIndex = props.zIndex, className = props.className, aclCheck = props.aclCheck, permissions = props.permissions;
    return aclCheck && !aclCheck(permissions) ? null : (React.createElement("div", { className: props.classes.marginizedDiv + " " + (className || ''), style: __assign({ display: display,
            flexDirection: flexDirection,
            alignItems: alignItems,
            justifyContent: justifyContent,
            alignSelf: alignSelf,
            bottom: bottom,
            boxSizing: boxSizing,
            flex: flex,
            flexWrap: flexWrap,
            height: height,
            maxHeight: maxHeight,
            minHeight: minHeight,
            minWidth: minWidth,
            order: order,
            maxWidth: maxWidth,
            overflow: overflow,
            overflowX: overflowX,
            overflowY: overflowY,
            padding: padding,
            position: position,
            textAlign: textAlign,
            top: top,
            width: width,
            zIndex: zIndex }, props.style) }, props.children));
};
FlexContainer.defaultProps = {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
};
export default withStyles(styles)(FlexContainer);
