var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeStyles } from "@material-ui/core";
import Tree from "rc-tree";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import AddFolderModal from "s~/components/modals/AddFolderModal";
import RenameFileModal from "s~/components/modals/RenameFileModal";
import RdiLink from "s~/components/routing/RdiLink";
import { generateTreeViewFromFiles, } from "s~/utils/treeViewUtils";
import "rc-tree/assets/index.css";
var useStyles = makeStyles(function (theme) { return ({
    menu: {
        zIndex: 1000,
        position: "absolute",
        backgroundColor: "#fff",
        borderRadius: "2px",
        padding: "0.5rem 0",
        width: "150px",
        height: "auto",
        margin: 0,
        listStyle: "none",
        boxShadow: "0 0 20px 0 #ccc",
    },
    menuItem: {
        padding: "0.5rem",
        color: "#000",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "#46aac855",
            borderLeft: "4px solid #46aac8",
            textDecoration: "none",
        },
    },
}); });
var allowDrop = function (_a) {
    var dropNode = _a.dropNode, dropPosition = _a.dropPosition;
    if (!dropNode.children) {
        if (dropPosition === 0)
            return false;
    }
    return true;
};
var RdiTreeView = function (_a) {
    var apiUrl = _a.apiUrl, files = _a.files, showCreateFolder = _a.showCreateFolder, showRename = _a.showRename, showDelete = _a.showDelete, showShare = _a.showShare, deleteFile = _a.deleteFile, renameFile = _a.renameFile, createFolder = _a.createFolder, shareFile = _a.shareFile;
    var location = useLocation();
    var history = useHistory();
    var _b = useState(false), isRightMenuOpen = _b[0], setIsRightMenuOpen = _b[1];
    var _c = useState({
        x: 0,
        y: 0,
    }), anchorPoint = _c[0], setAnchorPoint = _c[1];
    var _d = useState(undefined), selectedNode = _d[0], setSelectedNode = _d[1];
    var _e = useState(false), isCreateFolderModalOpen = _e[0], setIsCreateFolderModalOpen = _e[1];
    var _f = useState(false), isEditFileNameModalOpen = _f[0], setIsEditFileNameModalOpen = _f[1];
    var onDrop = function (info) {
        var _a, _b;
        setSelectedNode(info.dragNode);
        var targetFileNameSplit = ((_b = (_a = info.node) === null || _a === void 0 ? void 0 : _a.fullname) === null || _b === void 0 ? void 0 : _b.split("/")) || [""];
        if (info.node.isFolder && info.node.key !== "0") {
            targetFileNameSplit.push(info.dragNode.title);
        }
        else {
            targetFileNameSplit[targetFileNameSplit.length - 1] = info.dragNode.title;
        }
        if (info.dragNode.isFolder) {
            var fullname_1 = info.dragNode.fullname;
            var updateFiles_1 = [];
            files.forEach(function (f) {
                if (f.originalname.includes(fullname_1)) {
                    var fileName = f.originalname.split(fullname_1)[1];
                    var targetFileNameArr = targetFileNameSplit.slice();
                    targetFileNameArr.push(fileName.charAt(0) === '/' ? fileName.substring(1) : fileName);
                    f.originalname = targetFileNameArr.join("/");
                    updateFiles_1.push({ file: f });
                }
            });
            renameFile(updateFiles_1);
        }
        else {
            info.dragNode.file.originalname = targetFileNameSplit.join("/");
            renameFile([info.dragNode]);
        }
    };
    useEffect(function () {
        var handleClick = function () {
            setIsRightMenuOpen(false);
        };
        document.addEventListener("click", handleClick);
        return function () {
            document.removeEventListener("click", handleClick);
        };
    }, []);
    var onRightClick = function (info) {
        var _a, _b, _c, _d;
        if (((_a = info === null || info === void 0 ? void 0 : info.node) === null || _a === void 0 ? void 0 : _a.file) || ((_b = info === null || info === void 0 ? void 0 : info.node) === null || _b === void 0 ? void 0 : _b.isFolder)) {
            if (showCreateFolder || showRename || showDelete || showShare) {
                setIsRightMenuOpen(true);
                setAnchorPoint({ x: (_c = info === null || info === void 0 ? void 0 : info.event) === null || _c === void 0 ? void 0 : _c.pageX, y: (_d = info === null || info === void 0 ? void 0 : info.event) === null || _d === void 0 ? void 0 : _d.pageY });
                setSelectedNode(info.node);
            }
        }
    };
    var onSelect = function (info) {
        var url = info[0];
        if (url === null || url === void 0 ? void 0 : url.includes("-url:")) {
            var fileUrl = url === null || url === void 0 ? void 0 : url.split("-url:").pop();
            if (fileUrl !== "folder") {
                window.location.href = "" + apiUrl + fileUrl;
            }
        }
    };
    var openCreateFolderModal = function () {
        setIsCreateFolderModalOpen(true);
        history.replace(location.pathname + "#create-folder");
    };
    var openFileRenameModal = function () {
        setIsEditFileNameModalOpen(true);
        return location.pathname + "#rename-file";
    };
    var openFileShareModal = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, shareFile(selectedNode)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleRename = function (fileName) {
        selectedNode.file.originalname = fileName;
        renameFile([selectedNode]);
    };
    var handleDownload = function () {
        var _a;
        window.location.href = "" + apiUrl + ((_a = selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.file) === null || _a === void 0 ? void 0 : _a.url);
    };
    var handleDelete = function () {
        deleteFile(selectedNode);
    };
    var handleCreateFolder = function (folderName) {
        createFolder(folderName, selectedNode);
    };
    var classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Tree, { draggable: !!renameFile, allowDrop: allowDrop, defaultExpandAll: true, defaultExpandParent: true, autoExpandParent: true, expandAction: "click", onSelect: onSelect, onDrop: onDrop, onRightClick: onRightClick, treeData: generateTreeViewFromFiles(files) }),
        isRightMenuOpen && (React.createElement("ul", { className: classes.menu, style: {
                position: "absolute",
                top: anchorPoint.y,
                left: anchorPoint.x,
            } },
            !(selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.isFolder) && (React.createElement(RdiLink, { xs: true, className: classes.menuItem, onClick: handleDownload }, "Download")),
            showCreateFolder && (React.createElement(RdiLink, { xs: true, className: classes.menuItem, onClick: openCreateFolderModal }, "Create folder")),
            showRename && !(selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.isFolder) && (React.createElement(RdiLink, { xs: true, className: classes.menuItem, onClick: openFileRenameModal }, "Rename")),
            showShare && !(selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.isFolder) && (React.createElement(RdiLink, { xs: true, className: classes.menuItem, onClick: openFileShareModal }, "Share")),
            showDelete && (React.createElement(RdiLink, { xs: true, className: classes.menuItem, onClick: handleDelete }, "Delete")))),
        isCreateFolderModalOpen && (React.createElement(AddFolderModal, { onClose: function () { return setIsCreateFolderModalOpen(false); }, createFolder: handleCreateFolder })),
        isEditFileNameModalOpen && (React.createElement(RenameFileModal, { onClose: function () { return setIsEditFileNameModalOpen(false); }, renameFile: handleRename, fileName: selectedNode.file.originalname }))));
};
export default RdiTreeView;
