export var VisitStatusEnum;
(function (VisitStatusEnum) {
    VisitStatusEnum["UNSCHEDULED"] = "unscheduled";
    VisitStatusEnum["SCHEDULED"] = "scheduled";
    VisitStatusEnum["IN_PROGRESS"] = "in-progress";
    VisitStatusEnum["COMPLETE"] = "complete";
    VisitStatusEnum["FINALIZED"] = "finalized";
})(VisitStatusEnum || (VisitStatusEnum = {}));
export var VisitStatusLabelsEnum;
(function (VisitStatusLabelsEnum) {
    VisitStatusLabelsEnum["unscheduled"] = "Unscheduled";
    VisitStatusLabelsEnum["scheduled"] = "Scheduled";
    VisitStatusLabelsEnum["in-progress"] = "In progress";
    VisitStatusLabelsEnum["complete"] = "Complete";
    VisitStatusLabelsEnum["finalized"] = "Finalized";
})(VisitStatusLabelsEnum || (VisitStatusLabelsEnum = {}));
