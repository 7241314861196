/* eslint max-classes-per-file: 0 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { add_operation, apply } from "json-logic-js";
import { computed, observable } from "mobx";
import { DatapointEnum } from "s~/constants/enums/DatapointEnum";
import { DatapointTemplateEnum } from "s~/constants/enums/DatapointTemplateEnum";
import { Model } from "s~/types/models/Model";
import { mathRoundTwoPlaces, timeDiff } from "s~/utils/jsonLogicUtils";
var Data = /** @class */ (function () {
    function Data(d) {
        this.value = d.value;
        this.unit = d.unit;
    }
    __decorate([
        observable
    ], Data.prototype, "value", void 0);
    return Data;
}());
export { Data };
var Datapoint = /** @class */ (function (_super) {
    __extends(Datapoint, _super);
    function Datapoint(p, procedure) {
        var _this = _super.call(this, p) || this;
        _this.id = p.id;
        _this.type = p.type;
        _this.label = p.label;
        _this.slug = p.slug;
        _this.template_name = p.template_name;
        _this.errors = p.errors;
        _this.visible = p.visible;
        _this.data = p.data ? new Data(p.data) : null;
        _this.properties = p.properties;
        _this.touched = p.touched;
        _this.template_meta = p.template_meta;
        _this.procedure = procedure;
        if (p.template_name === "radio") {
            _this.properties = __assign(__assign({}, (p.properties ? p.properties : {})), { options: [
                    {
                        value: true,
                        label: p.template_meta.label_true || "Yes"
                    },
                    {
                        value: false,
                        label: p.template_meta.label_false || "No"
                    }
                ] });
        }
        if (p.type === DatapointEnum.error) {
            _this.template_name = DatapointTemplateEnum.error;
        }
        return _this;
    }
    Object.defineProperty(Datapoint.prototype, "errorMessage", {
        get: function () {
            var _a, _b;
            return this.touched && ((_b = (_a = this.errors) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.message);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Datapoint.prototype, "calculatedType", {
        get: function () {
            if (this.type !== DatapointEnum.computed) {
                return this.type;
            }
            return this.template_name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Datapoint.prototype, "calculatedFormula", {
        get: function () {
            var _this = this;
            if (!this.properties || !this.properties.formula) {
                return null;
            }
            add_operation("time_diff", function (a, b) {
                return timeDiff(a, b, {
                    $procedure: _this.procedure.jsonLogicContext,
                    $self: _this.data
                });
            });
            add_operation("math_round", function (a) {
                return mathRoundTwoPlaces(a, {
                    $procedure: _this.procedure.jsonLogicContext,
                    $self: _this.data
                });
            });
            var r = apply(this.properties.formula, {
                $procedure: this.procedure.jsonLogicContext,
                $self: this.data
            });
            return r;
        },
        enumerable: false,
        configurable: true
    });
    Datapoint.prototype.toJSON = function () {
        return {
            data: this.data,
            id: this.id,
            slug: this.slug
        };
    };
    __decorate([
        observable
    ], Datapoint.prototype, "touched", void 0);
    __decorate([
        observable
    ], Datapoint.prototype, "errors", void 0);
    __decorate([
        observable
    ], Datapoint.prototype, "procedure", void 0);
    __decorate([
        observable
    ], Datapoint.prototype, "data", void 0);
    __decorate([
        computed
    ], Datapoint.prototype, "errorMessage", null);
    __decorate([
        computed
    ], Datapoint.prototype, "calculatedType", null);
    __decorate([
        computed
    ], Datapoint.prototype, "calculatedFormula", null);
    return Datapoint;
}(Model));
export { Datapoint };
