export var VisitTypeEnum;
(function (VisitTypeEnum) {
    VisitTypeEnum["CLINIC"] = "clinic-visit";
    VisitTypeEnum["PHONE"] = "phone-visit";
    VisitTypeEnum["STUDY"] = "study-visit";
})(VisitTypeEnum || (VisitTypeEnum = {}));
export var VisitTypeLabelsEnum;
(function (VisitTypeLabelsEnum) {
    VisitTypeLabelsEnum["clinic-visit"] = "Clinic visit";
    VisitTypeLabelsEnum["phone-visit"] = "Phone visit";
    VisitTypeLabelsEnum["study-visit"] = "Study visit";
    VisitTypeLabelsEnum["screening"] = "Screening";
})(VisitTypeLabelsEnum || (VisitTypeLabelsEnum = {}));
