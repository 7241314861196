var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TableCell, TableSortLabel } from "@material-ui/core";
import React from "react";
import RdiTypography, { TypographyColorsEnum } from "s~/components/layout/RdiTypography";
import SortIcon from "s~/static/SortIcon.svg";
import { invertOrderDir } from "s~/types/dtos/OrderQueryParamsDto";
var CustomSortIcon = function () {
    return React.createElement("img", { alt: "img", style: { marginLeft: "10px" }, src: SortIcon });
};
var TableHeaderCell = function (props) {
    var filters = props.filters, column = props.column, changeFilters = props.changeFilters;
    return (React.createElement(TableCell, { style: __assign({ width: column.width }, props.style) },
        React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium, bold: !!column.sortable },
            React.createElement(TableSortLabel, { active: filters ? filters.orderBy === column.sortName : false, IconComponent: CustomSortIcon, direction: filters === null || filters === void 0 ? void 0 : filters.orderDir.toLowerCase(), onClick: function () {
                    return column.sortable
                        ? changeFilters({
                            orderDir: invertOrderDir(filters.orderDir),
                            orderBy: column.sortName
                        })
                        : {};
                }, hideSortIcon: !column.sortable, style: { cursor: column.sortable ? "pointer" : "default" } }, column.label))));
};
export default TableHeaderCell;
