var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import BaseModal from '@cros/shared/components/modals/BaseModal';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { invertOrderDir } from '@cros/shared/types/dtos/OrderQueryParamsDto';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { observable, runInAction } from 'mobx';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getPatientRoute } from '~/routing/Routes';
import TableColapsed from '~/static/TableColapsed.svg';
import TableExpanded from '~/static/TableExpanded.svg';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { FeedbackStore } from '~/stores/PatientStore/FeedbackStore';
var StyledRating = withStyles({
    iconFilled: {
        color: ColorsEnum.Primary
    }
})(Rating);
var filledTableCell = {
    backgroundColor: ColorsEnum.White
};
var col1Stye = {
    width: '30px'
};
var colStyle = {
    flex: 1
};
var PatientFeedbackHistory = /** @class */ (function (_super) {
    __extends(PatientFeedbackHistory, _super);
    function PatientFeedbackHistory(props) {
        var _this = _super.call(this, props) || this;
        _this.loading = true;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.feedbackStore.getFeedback()];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function () {
            var _a = _this.props, _b = _a.match.params, siteId = _b.siteId, trialId = _b.trialId, enrollmentId = _b.enrollmentId, patientId = _b.patientId, history = _a.history;
            history.push(getPatientRoute(siteId, patientId, trialId, enrollmentId));
        };
        _this.changeSort = function () {
            _this.feedbackStore.sortDirection = invertOrderDir(_this.feedbackStore.sortDirection);
        };
        _this.toggleCell = function (visitId) {
            runInAction(function () {
                var feedbackToToggle = _this.feedbackStore.patientFeedback.find(function (fedb) { return fedb.visitId === visitId; });
                feedbackToToggle.expanded = !feedbackToToggle.expanded;
                _this.feedbackStore.patientFeedback = __spreadArrays(_this.feedbackStore.patientFeedback);
            });
        };
        var enrollmentStore = _this.props.enrollmentStore;
        _this.feedbackStore = new FeedbackStore(enrollmentStore);
        return _this;
    }
    PatientFeedbackHistory.prototype.render = function () {
        var _this = this;
        var patient = this.props.patientStore.patient;
        var _a = this.feedbackStore, patientFeedbackCount = _a.patientFeedbackCount, sortedFeedback = _a.sortedFeedback, sortDirection = _a.sortDirection;
        return (React.createElement(Provider, { feedbackStore: this.feedbackStore },
            React.createElement(BaseModal, { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto'
                }, loading: this.loading, onClose: this.onClose },
                React.createElement(RdiTypography, { variant: "h1" }, "Patient report"),
                React.createElement(FlexContainer, { style: { marginBottom: '30px' }, margin: 15 },
                    React.createElement(RdiTypography, { variant: "body2", bold: true }, patient.fullName),
                    React.createElement("div", { style: { width: '45px' } }),
                    React.createElement(RdiTypography, { variant: "body2" }, "Patient ID: " + patient.id)),
                React.createElement(RdiTypography, { style: { marginBottom: '5px' }, variant: "subtitle1", color: TypographyColorsEnum.Black }, "History"),
                React.createElement("div", { style: {
                        flex: 1,
                        overflowY: 'auto',
                        paddingRight: '25px',
                        position: 'relative'
                    } },
                    React.createElement(RdiTable, { stickyHeader: true },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { style: __assign(__assign({}, filledTableCell), col1Stye) }, "\u00A0"),
                                React.createElement(TableCell, { style: filledTableCell },
                                    React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium }, "Visit")),
                                React.createElement(TableCell, { style: filledTableCell },
                                    React.createElement(TableSortLabel, { active: true, direction: sortDirection === 'DESC' ? 'asc' : 'desc', onClick: this.changeSort },
                                        React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium, bold: true }, "Date added"))),
                                React.createElement(TableCell, { style: filledTableCell },
                                    React.createElement(RdiTypography, { variant: "caption", color: TypographyColorsEnum.BlackMedium }, "Score")))),
                        React.createElement(TableBody, null,
                            sortedFeedback.map(function (feedback, index) {
                                return (React.createElement(React.Fragment, { key: index },
                                    React.createElement(TableRow, { key: index },
                                        React.createElement(TableCell, { style: __assign(__assign({}, filledTableCell), col1Stye), onClick: function () { return _this.toggleCell(feedback.visitId); } }, feedback.expanded ? (React.createElement("img", { alt: "table-expanded", src: TableExpanded })) : (React.createElement("img", { alt: "table-collapsed", src: TableColapsed }))),
                                        React.createElement(TableCell, { style: colStyle },
                                            React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Primary }, feedback.visitName)),
                                        React.createElement(TableCell, { style: colStyle },
                                            React.createElement(RdiTypography, { variant: "body2" }, formatDate(feedback.feedbackAdded))),
                                        React.createElement(TableCell, { style: colStyle },
                                            React.createElement(StyledRating, { value: feedback.score, readOnly: true }))),
                                    feedback.expanded && (React.createElement(TableRow, null,
                                        React.createElement(TableCell, { colSpan: 4, style: { padding: '20px 35px' } },
                                            React.createElement(RdiTypography, { variant: "subtitle1", style: { marginBottom: '15px' }, color: TypographyColorsEnum.TrueBlack }, "Explanation"),
                                            React.createElement(RdiTypography, { variant: "body2" }, feedback.feedbackDetail))))));
                            }),
                            !this.loading && patientFeedbackCount === 0 && (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: 4 }, "No results")))))))));
    };
    __decorate([
        observable
    ], PatientFeedbackHistory.prototype, "loading", void 0);
    PatientFeedbackHistory = __decorate([
        injectPatientStore,
        injectEnrollmentStore,
        injectAccountStore,
        observer
    ], PatientFeedbackHistory);
    return PatientFeedbackHistory;
}(Component));
export default withRouter(PatientFeedbackHistory);
