var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStyles, ListItem, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ColorsEnum from 's~/constants/styling/ColorsEnum';
var styles = function () {
    return createStyles({
        activeLink: {
            backgroundColor: "" + ColorsEnum.Primary,
            '&:hover': {
                backgroundColor: "rgba(70,170,200,0.85)",
            },
            '&.inverted:hover': {
                color: ColorsEnum.BlueDark + "!important",
                backgroundColor: "white!important",
                border: "2px solid " + ColorsEnum.BlueDark + "!important",
            },
            '& span': {
                color: ColorsEnum.White + " !important",
            },
        },
    });
};
var RdiListItem = function (props) {
    var permissions = props.permissions, classes = props.classes, aclCheck = props.aclCheck, aclHide = props.aclHide;
    var _a = useState(false), internalDisabled = _a[0], setInternalDisabled = _a[1];
    useEffect(function () {
        if (aclCheck && !aclCheck(permissions)) {
            setInternalDisabled(true);
        }
    }, [permissions]);
    if (aclHide && aclCheck && !aclCheck(permissions)) {
        return null;
    }
    return (React.createElement(ListItem, __assign({ onClick: props.onClick, disabled: internalDisabled, component: props.component, button: props.button, id: props.id, to: props.to, exact: props.exact, style: props.style }, (props.component ? { activeClassName: props.activeClassName || classes.activeLink } : undefined)), props.children));
};
export default withStyles(styles)(RdiListItem);
