var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Model } from "s~/types/models/Model";
import { Permission } from "s~/types/models/Permission";
var Role = /** @class */ (function (_super) {
    __extends(Role, _super);
    function Role(q) {
        var _this = _super.call(this, q) || this;
        _this.id = q.id;
        _this.name = q.name;
        _this.slug = q.slug;
        _this.numUsers = q.numUsers;
        _this.permissions = q.permissions.map(function (p) { return new Permission(p); });
        return _this;
    }
    return Role;
}(Model));
export { Role };
