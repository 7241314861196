var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed, observable } from "mobx";
import { Model } from "s~/types/models/Model";
import { Questionnaire } from "s~/types/models/Questionnaire";
import { getDateFromString, getDaysAgo } from "s~/utils/dateUtils";
import { Device } from "./Device";
var DeviceData = /** @class */ (function (_super) {
    __extends(DeviceData, _super);
    function DeviceData(d) {
        var _this = _super.call(this, d) || this;
        _this.getCategory = function (device) {
            return device.categories.find(function (x) { return x.slug === _this.fieldsAll.category; });
        };
        _this.id = d.id;
        _this.data = d.data;
        _this.computedFields = d.computedFields;
        _this.deviceId = d.deviceId;
        _this.device = d.device ? new Device(d.device) : null;
        _this.timestamp = d.timestamp ? getDateFromString(d.timestamp) : null;
        _this.deviceType = d.deviceType;
        _this.questionnaire = d.questionnaire
            ? new Questionnaire(d.questionnaire)
            : null;
        _this.categories = d.categories;
        _this.isManualEntry = d.isManualEntry;
        return _this;
    }
    Object.defineProperty(DeviceData.prototype, "daysAgo", {
        get: function () {
            return getDaysAgo(this.timestamp);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeviceData.prototype, "categoryLabel", {
        get: function () {
            return this.category.label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeviceData.prototype, "category", {
        get: function () {
            var _this = this;
            return this.categories.find(function (x) { return x.slug === _this.fieldsAll.category; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeviceData.prototype, "fieldsAll", {
        get: function () {
            return __assign(__assign({}, this.data), this.computedFields);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], DeviceData.prototype, "data", void 0);
    __decorate([
        observable
    ], DeviceData.prototype, "deviceType", void 0);
    __decorate([
        observable
    ], DeviceData.prototype, "computedFields", void 0);
    __decorate([
        computed
    ], DeviceData.prototype, "daysAgo", null);
    __decorate([
        computed
    ], DeviceData.prototype, "categoryLabel", null);
    __decorate([
        computed
    ], DeviceData.prototype, "category", null);
    __decorate([
        computed
    ], DeviceData.prototype, "fieldsAll", null);
    return DeviceData;
}(Model));
export { DeviceData };
