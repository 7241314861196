export var MedicationUnitEnum;
(function (MedicationUnitEnum) {
    MedicationUnitEnum["MG"] = "mg";
    MedicationUnitEnum["UG"] = "ug";
    MedicationUnitEnum["ML"] = "ml";
    MedicationUnitEnum["G"] = "g";
    MedicationUnitEnum["IU"] = "iu";
    MedicationUnitEnum["PUFF"] = "puff";
    MedicationUnitEnum["MG_ML"] = "mg-ml";
    MedicationUnitEnum["OTHER"] = "other";
})(MedicationUnitEnum || (MedicationUnitEnum = {}));
export var MedicationUnitLabelsEnum;
(function (MedicationUnitLabelsEnum) {
    MedicationUnitLabelsEnum["mg"] = "mg";
    MedicationUnitLabelsEnum["ug"] = "Ug";
    MedicationUnitLabelsEnum["ml"] = "mL";
    MedicationUnitLabelsEnum["g"] = "G";
    MedicationUnitLabelsEnum["iu"] = "IU";
    MedicationUnitLabelsEnum["puff"] = "Puff";
    MedicationUnitLabelsEnum["mg-ml"] = "mg/mL";
    MedicationUnitLabelsEnum["other"] = "other";
})(MedicationUnitLabelsEnum || (MedicationUnitLabelsEnum = {}));
