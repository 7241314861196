import React from "react";
import ColorsEnum from "s~/constants/styling/ColorsEnum";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryPie, VictoryScatter, VictoryTooltip, } from "victory";
export var RdiChart = function (props) {
    var graphData = props.graphData, domain = props.domain, tickValues = props.tickValues, tickFormat = props.tickFormat, chartType = props.chartType;
    return (React.createElement("div", null,
        chartType === "pie" && (React.createElement(VictoryPie, { width: 230, height: 230, data: graphData, innerRadius: 38, padAngle: 1, labelComponent: React.createElement(VictoryTooltip, { renderInPortal: true, constrainToVisibleArea: true }), labels: function (_a) {
                var datum = _a.datum;
                return datum.x + " (" + datum.y + ")";
            }, style: {
                data: {
                    fill: function (_a) {
                        var datum = _a.datum;
                        return datum.fill;
                    },
                },
            } })
        // </svg>
        ),
        chartType !== "pie" && (React.createElement(VictoryChart, { maxDomain: { x: domain.xMax, y: domain.yMax }, minDomain: { x: domain.xMin, y: domain.yMin }, style: {
                parent: {
                    border: "0px solid #ccc",
                    margin: "0 auto",
                    maxWidth: "80%",
                },
            }, theme: {}, height: 220, padding: { top: 10, bottom: 25, left: 25, right: 25 } },
            React.createElement(VictoryAxis, { crossAxis: false, tickValues: tickValues.x, tickFormat: tickFormat.x, padding: 50, style: {
                    axis: {
                        stroke: ColorsEnum.BlackLight,
                    },
                    grid: {
                        strokeWidth: 0,
                    },
                    tickLabels: {
                        fill: ColorsEnum.BlackMedium,
                        fontWeight: "normal",
                        fontFamily: "Lato",
                        fontSize: 7,
                        padding: 10,
                    },
                } }),
            React.createElement(VictoryAxis, { dependentAxis: true, tickValues: tickValues.y, padding: 50, style: {
                    axis: {
                        strokeWidth: 0,
                    },
                    grid: {
                        strokeWidth: 1,
                        stroke: ColorsEnum.BlackLight,
                    },
                    tickLabels: {
                        fill: ColorsEnum.BlackMedium,
                        fontWeight: "normal",
                        fontFamily: "Lato",
                        fontSize: 7,
                        padding: 10,
                    },
                } }),
            chartType === "line" &&
                graphData.map(function (line, index) { return (React.createElement(VictoryLine, { key: index, data: line.data, interpolation: "cardinal", style: {
                        data: {
                            stroke: line.color,
                            strokeWidth: 2,
                        },
                    } })); }),
            chartType === "line" &&
                graphData.map(function (line, index) { return (React.createElement(VictoryScatter, { key: index, data: line.data, size: 4, style: {
                        data: {
                            fill: function (x) { return (x.datum ? x.datum.color : "red"); },
                        },
                    } })); }),
            chartType === "bar" &&
                graphData.map(function (line, index) { return (React.createElement(VictoryBar, { data: line.data, key: index, 
                    // labels={(d) => d.x}
                    style: {
                        data: {
                            fill: function (x) { return (x.datum ? x.datum.color : "red"); },
                            strokeWidth: 0,
                            width: 5,
                        },
                    } })); })))));
};
