var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import DateFnsUtils from "@date-io/date-fns";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { RdiInputWrapper } from "s~/components/forms/RdiInput";
import RdiInputLabel from "./RdiInputLabel";
export var RdiTimeInput = function (props) {
    var label = props.label, isValid = props.isValid, tooltip = props.tooltip, placeholder = props.placeholder, value = props.value, onChange = props.onChange, disabled = props.disabled, name = props.name, errorText = props.errorText;
    return (React.createElement(RdiInputWrapper, __assign({}, props),
        React.createElement(RdiInputLabel, { tooltip: tooltip }, label),
        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
            React.createElement(KeyboardTimePicker, __assign({}, (!!errorText && { helperText: errorText }), ((!isValid || !!errorText) && { error: true }), { keyboardIcon: React.createElement(ArrowDropDownIcon, null), margin: "normal", label: "", placeholder: placeholder, value: value, onChange: function (e) { return onChange({ value: e }); }, KeyboardButtonProps: {
                    "aria-label": "change time"
                }, invalidDateMessage: "Invalid time format", invalidLabel: "Invalid time format", inputVariant: "outlined", disabled: disabled || false, style: { marginBottom: "0px" }, name: name })))));
};
