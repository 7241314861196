var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiErrorBox from '@cros/shared/components/misc/RdiErrorBox';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import { AclModuleEnum } from '@cros/shared/constants/enums/AclModuleEnum';
import { AclOpEnum } from '@cros/shared/constants/enums/AclOpEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { getDateFromTimeAndDate } from '@cros/shared/utils/dateUtils';
import { fieldChange } from '@cros/shared/utils/utils';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectEnrollmentStore } from '~/stores/EnrollmentStore';
import { injectPatientStore } from '~/stores/PatientStore';
import { injectVisitStore } from '~/stores/PatientStore/VisitStore';
import { injectSiteStore } from '~/stores/SiteStore';
var styles = function () { return createStyles({}); };
var CheckoutVisitModal = /** @class */ (function (_super) {
    __extends(CheckoutVisitModal, _super);
    function CheckoutVisitModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.saving = false;
        _this.hasErrors = false;
        _this.errorMessage = '';
        _this.shouldCheckoutWithoutDate = false;
        _this.data = {
            nextVisitEngagement: {
                value: null,
                error: null,
                rule: 'required_with:nextVisitIdent|date'
            },
            time: {
                value: null,
                error: null,
                rule: 'required_with:nextVisitIdent'
            },
            nextVisitIdent: {
                value: null,
                error: null,
                label: '',
                rule: 'required_with:nextVisitEngagement'
            }
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var shouldCheckoutWithoutDate;
            return __generator(this, function (_a) {
                shouldCheckoutWithoutDate = this.props.visitStore.shouldCheckoutWithoutDate;
                this.shouldCheckoutWithoutDate = shouldCheckoutWithoutDate();
                return [2 /*return*/];
            });
        }); };
        _this.submit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var nextVisitDate, visitStore, updatedVisit, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        this.hasErrors = this.validateForm();
                        if (this.hasErrors) {
                            return [2 /*return*/];
                        }
                        if (!this.shouldCheckoutWithoutDate) {
                            nextVisitDate = getDateFromTimeAndDate(this.data.nextVisitEngagement.value, this.data.nextVisitEngagement.value);
                        }
                        visitStore = this.props.visitStore;
                        updatedVisit = null;
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, visitStore.checkoutVisit({
                                nextVisitEngagement: nextVisitDate,
                                nextVisitIdent: this.data.nextVisitIdent.value
                            })];
                    case 2:
                        updatedVisit = _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [3 /*break*/, 5];
                    case 4:
                        this.saving = false;
                        if (!updatedVisit) {
                            return [2 /*return*/];
                        }
                        return [7 /*endfinally*/];
                    case 5:
                        this.onClose(null, true);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onClose = function (e, completed) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onClose, history, match;
            return __generator(this, function (_b) {
                _a = this.props, onClose = _a.onClose, history = _a.history, match = _a.match;
                if (onClose) {
                    onClose();
                }
                if (completed) {
                    history.push(match.url.split('/checkout')[0] + "/completed");
                }
                else {
                    history.push("" + match.url.split('/checkout')[0]);
                }
                return [2 /*return*/];
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return utilValidate(_this.data, null, validationErrors, true);
        };
        _this.fieldChange = function (e, field) {
            fieldChange(e, field, _this.data);
        };
        return _this;
    }
    CheckoutVisitModal.prototype.render = function () {
        var _this = this;
        var _a = this.props, visits = _a.enrollmentStore.visitListStore.visits, patient = _a.patientStore.patient, accountStore = _a.accountStore, siteStore = _a.siteStore;
        return (React.createElement(SmallModal, { onClose: this.onClose },
            React.createElement("form", { noValidate: true, onSubmit: this.submit },
                React.createElement(RdiTypography, { variant: "h1", style: { marginBottom: '20px' } }, "Checkout the patient"),
                React.createElement(FlexContainer, { margin: 30 },
                    React.createElement(RdiTypography, { variant: "caption" }, patient.fullName),
                    React.createElement(RdiTypography, { variant: "caption" }, "Patient ID: " + patient.id)),
                React.createElement(React.Fragment, null,
                    React.createElement(RdiLine, null),
                    React.createElement(RdiTypography, { variant: "body2" }, 'Checkout the patient. To create a payment go to Accounting -> Payments')),
                !this.shouldCheckoutWithoutDate && (React.createElement(React.Fragment, null,
                    React.createElement(RdiLine, null),
                    React.createElement(RdiTypography, { style: { marginBottom: '30px' }, variant: "body2" }, "Schedule next visit"),
                    React.createElement(RdiInput, { type: "select", label: "Visit", value: this.data.nextVisitIdent.value, selectedItemLabel: this.data.nextVisitIdent.label, errorText: this.data.nextVisitIdent.error, onChange: function (e) { return _this.fieldChange(e, 'nextVisitIdent'); }, options: visits
                            .filter(function (v) { return !v.engagement; })
                            .map(function (item) { return ({
                            label: item.name,
                            value: item.id
                        }); }) }),
                    React.createElement(FlexContainer, { margin: 50, alignItems: "flex-start" },
                        React.createElement(RdiInput, { type: "date", label: "Date", value: this.data.nextVisitEngagement.value, errorText: this.data.nextVisitEngagement.error, onChange: function (e) { return _this.fieldChange(e, 'nextVisitEngagement'); } }),
                        React.createElement(RdiInput, { type: "time", label: "Time", value: this.data.time.value, errorText: this.data.time.error, onChange: function (e) { return _this.fieldChange(e, 'time'); } })))),
                React.createElement(RdiErrorBox, null, this.errorMessage),
                React.createElement(RdiLine, null),
                React.createElement("div", null,
                    React.createElement(RdiButton, { submit: true, style: {
                            height: '56px',
                            backgroundColor: ColorsEnum.IconsGreen,
                            margin: '0 auto'
                        }, loading: this.saving, permissions: [
                            {
                                aclOp: AclOpEnum.UPDATE,
                                aclModule: AclModuleEnum.VISIT
                            }
                        ], aclCheck: function (permissions) { var _a; return (_a = accountStore.accountDetail) === null || _a === void 0 ? void 0 : _a.aclCheck(permissions, siteStore.siteId); } }, "Check out patient")))));
    };
    __decorate([
        observable
    ], CheckoutVisitModal.prototype, "saving", void 0);
    __decorate([
        observable
    ], CheckoutVisitModal.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], CheckoutVisitModal.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], CheckoutVisitModal.prototype, "shouldCheckoutWithoutDate", void 0);
    __decorate([
        observable
    ], CheckoutVisitModal.prototype, "data", void 0);
    CheckoutVisitModal = __decorate([
        injectPatientStore,
        injectEnrollmentStore,
        injectVisitStore,
        injectAccountStore,
        injectSiteStore,
        observer
    ], CheckoutVisitModal);
    return CheckoutVisitModal;
}(Component));
export default withStyles(styles)(withRouter(CheckoutVisitModal));
