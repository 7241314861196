import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiTable from '@cros/shared/components/misc/RdiTable';
import TableHeaderCell from '@cros/shared/components/misc/TableHeaderCell';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { BillingCodeStatusEnum } from '@cros/shared/constants/enums/BillingCodeStatusEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CheckmarkIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { getTaskDetailRoute, getVisitDetailRoute } from '~/routing/Routes';
var BillingTable = function (props) {
    var columns = [
        {
            id: 'code',
            label: 'Billing code',
            sortable: false
        },
        {
            id: 'amount',
            label: 'Amount',
            sortable: false
        },
        {
            id: 'billedItem',
            label: 'Visit/task',
            sortable: false
        },
        {
            id: 'createdAt',
            label: 'Date',
            sortable: true,
            sortName: 'createdAt'
        },
        {
            id: 'status',
            label: 'Status',
            sortable: false
        }
    ];
    var billingCodes = props.billingCodes;
    return (React.createElement(FlexContainer, { flexDirection: "column", alignItems: "stretch", margin: 10, style: { width: '100%' } },
        React.createElement(FlexContainer, { margin: 7 },
            React.createElement(RdiTypography, { variant: "caption", uppercase: true }, "Billing codes"),
            billingCodes.find(function (b) { return b.status === BillingCodeStatusEnum.OPEN; }) && (React.createElement(RdiTypography, { style: { opacity: 0.6 }, variant: "caption" }, "This billing cycle has unfinished notes"))),
        React.createElement(RdiTable, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, columns.map(function (column, index) { return (React.createElement(TableHeaderCell, { key: index, column: column })); }))),
            React.createElement(TableBody, null,
                billingCodes.map(function (code, index) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                    return (React.createElement(TableRow, { key: index, style: {
                            opacity: code.status === BillingCodeStatusEnum.OPEN ? 0.6 : 1
                        } },
                        React.createElement(TableCell, null, code.code),
                        React.createElement(TableCell, null,
                            code.amount,
                            " min"),
                        React.createElement(TableCell, null,
                            React.createElement(RdiLink, { to: ((_a = code.billedItem) === null || _a === void 0 ? void 0 : _a.billingType) === 'TaskModel'
                                    ? getTaskDetailRoute((_b = code.billedItem) === null || _b === void 0 ? void 0 : _b.siteId, code.billedItemId, 'alarm')
                                    : getVisitDetailRoute((_c = code.billedItem) === null || _c === void 0 ? void 0 : _c.siteId, (_d = code.billedItem) === null || _d === void 0 ? void 0 : _d.patientId, (_e = code.billedItem) === null || _e === void 0 ? void 0 : _e.trialId, (_f = code.billedItem) === null || _f === void 0 ? void 0 : _f.enrollmentId, code.billedItemId) }, code.billedItemName)),
                        React.createElement(TableCell, null, formatDate(code.createdAt)),
                        React.createElement(TableCell, null,
                            code.status === BillingCodeStatusEnum.APPROVED && (React.createElement(Tooltip, { title: "Approved by " + ((_g = code.approvedBy) === null || _g === void 0 ? void 0 : _g.fullName) },
                                React.createElement(CheckmarkIcon, { style: { color: ColorsEnum.IconsGreen, fontSize: '19px' } }))),
                            code.status === BillingCodeStatusEnum.DENIED && (React.createElement(Tooltip, { title: "Denied by " + ((_h = code.deniedBy) === null || _h === void 0 ? void 0 : _h.fullName) },
                                React.createElement(WarningIcon, { style: { color: ColorsEnum.Error, fontSize: '19px' } }))),
                            code.status === BillingCodeStatusEnum.NEEDS_APPROVAL && (React.createElement(Tooltip, { title: "Submitted by " + ((_j = code.submittedBy) === null || _j === void 0 ? void 0 : _j.fullName) },
                                React.createElement(CachedIcon, { style: { fontSize: '19px' } }))))));
                }),
                billingCodes.length === 0 && (React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: 5 }, "No results")))))));
};
BillingTable.defaultProps = {};
export default BillingTable;
