export var AdverseEventIpActionEnum;
(function (AdverseEventIpActionEnum) {
    AdverseEventIpActionEnum["IP_WITHDRAWN"] = "ip-withdrawn";
    AdverseEventIpActionEnum["DOSE_REDUCED"] = "dose-reduced";
    AdverseEventIpActionEnum["DOSE_INCREASED"] = "dose-increased";
    AdverseEventIpActionEnum["DOSE_INTERRUPTION"] = "dose-interruption";
    AdverseEventIpActionEnum["NO_CHANGE_IN_DOSE"] = "no-change-in-dose";
    AdverseEventIpActionEnum["NOT_APPLICABLE"] = "not-applicable";
})(AdverseEventIpActionEnum || (AdverseEventIpActionEnum = {}));
export var AdverseEventIpActionLabelsEnum;
(function (AdverseEventIpActionLabelsEnum) {
    AdverseEventIpActionLabelsEnum["ip-withdrawn"] = "IP withdrawn";
    AdverseEventIpActionLabelsEnum["dose-reduced"] = "Dose reduced";
    AdverseEventIpActionLabelsEnum["dose-increased"] = "Dose increased";
    AdverseEventIpActionLabelsEnum["dose-interruption"] = "Dose interruption";
    AdverseEventIpActionLabelsEnum["no-change-in-dose"] = "No change in dose";
    AdverseEventIpActionLabelsEnum["not-applicable"] = "Not applicable ";
})(AdverseEventIpActionLabelsEnum || (AdverseEventIpActionLabelsEnum = {}));
