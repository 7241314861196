export var PaymentTypeEnum;
(function (PaymentTypeEnum) {
    PaymentTypeEnum["CHEQUE"] = "cheque";
    PaymentTypeEnum["COUPON"] = "coupon";
    PaymentTypeEnum["VENMO"] = "venmo";
    PaymentTypeEnum["OTHER"] = "other";
})(PaymentTypeEnum || (PaymentTypeEnum = {}));
export var PaymentTypeLabels;
(function (PaymentTypeLabels) {
    PaymentTypeLabels["cheque"] = "Check";
    PaymentTypeLabels["coupon"] = "Amazon gift card";
    PaymentTypeLabels["venmo"] = "Venmo";
    PaymentTypeLabels["other"] = "Other";
})(PaymentTypeLabels || (PaymentTypeLabels = {}));
export var VenmoPaymentIdentifier;
(function (VenmoPaymentIdentifier) {
    VenmoPaymentIdentifier["PHONE"] = "PHONE";
    VenmoPaymentIdentifier["EMAIL"] = "EMAIL";
    VenmoPaymentIdentifier["VENMO"] = "USER_HANDLE";
})(VenmoPaymentIdentifier || (VenmoPaymentIdentifier = {}));
export var VenmoPaymentIdentifierLabels;
(function (VenmoPaymentIdentifierLabels) {
    VenmoPaymentIdentifierLabels["PHONE"] = "Phone";
    VenmoPaymentIdentifierLabels["EMAIL"] = "Email";
    VenmoPaymentIdentifierLabels["USER_HANDLE"] = "Venmo username";
})(VenmoPaymentIdentifierLabels || (VenmoPaymentIdentifierLabels = {}));
