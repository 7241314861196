var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { isBefore } from "date-fns";
import { computed, observable } from "mobx";
import { GenderEnum } from "s~/constants/enums/GenderEnum";
import { Enrollment } from "s~/types/models/Enrollment";
import { Model } from "s~/types/models/Model";
import { Payment } from "s~/types/models/Payment";
import { getYearsAgo } from "s~/utils/dateUtils";
var Patient = /** @class */ (function (_super) {
    __extends(Patient, _super);
    function Patient(p) {
        var _this = _super.call(this, p) || this;
        _this.getEnrollmentById = function (enrollmentId) {
            var _a;
            var enrollment = (_a = _this.enrollments) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.id === enrollmentId; });
            return enrollment;
        };
        _this.getEnrollmentByTrialId = function (trialId) {
            var _a;
            var enrollment = (_a = _this.enrollments) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.trialId === trialId; });
            return enrollment;
        };
        _this.getFirstEnrollment = function () {
            var _a;
            return ((_a = _this.enrollments) === null || _a === void 0 ? void 0 : _a.length) ? _this.enrollments[0] : undefined;
        };
        _this.id = p.id;
        _this.firstName = p.firstName;
        _this.lastName = p.lastName;
        _this.gender = p.gender;
        _this.dateOfBirth = p.dateOfBirth;
        _this.ethnicity = p.ethnicity;
        _this.race = p.race;
        _this.childbearingPotential = p.childbearingPotential;
        _this.phoneNumber = p.phoneNumber;
        _this.phoneNumberPrefix = p.phoneNumberPrefix;
        _this.email = p.email;
        _this.emergencyContactName = p.emergencyContactName;
        _this.emergencyContactPhonePrefix = p.emergencyContactPhonePrefix;
        _this.emergencyContactPhoneNumber = p.emergencyContactPhoneNumber;
        _this.hasOwnDevice = p.hasOwnDevice;
        _this.hasSmsNotificationsEnabled = p.hasSmsNotificationsEnabled;
        _this.hasDailyPushNotificationsEnabled = p.hasDailyPushNotificationsEnabled;
        _this.zip = p.zip;
        _this.state = p.state;
        _this.addressLine1 = p.addressLine1;
        _this.addressLine2 = p.addressLine2;
        _this.country = p.country;
        _this.city = p.city;
        _this.payments = p.payments ? p.payments.map(function (x) { return new Payment(x); }) : [];
        _this.enrollments = p.enrollments
            ? p.enrollments.map(function (x) { var _a; return new Enrollment(__assign(__assign({}, x), { siteId: x.siteId || ((_a = x.site) === null || _a === void 0 ? void 0 : _a.id) })); })
            : [];
        _this.phonePlatform = p.phonePlatform;
        _this.phoneOsVersion = p.phoneOsVersion;
        _this.phoneNotificationsEnabled = p.phoneNotificationsEnabled;
        _this.phoneAppVersion = p.phoneAppVersion;
        _this.patientNumber = p.patientNumber;
        _this.venmoUsername = p.venmoUsername;
        return _this;
    }
    Object.defineProperty(Patient.prototype, "fullName", {
        get: function () {
            return this.firstName + " " + this.lastName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Patient.prototype, "age", {
        get: function () {
            if (this.dateOfBirth) {
                return -1 * getYearsAgo(new Date(this.dateOfBirth));
            }
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Patient.prototype, "ageDisplay", {
        get: function () {
            var age = this.age;
            if (age) {
                return age + " years";
            }
            return "age unknown";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Patient.prototype, "genderDisplay", {
        get: function () {
            if (this.gender) {
                switch (this.gender) {
                    case GenderEnum.MALE:
                        return "Male";
                    case GenderEnum.FEMALE:
                        return "Female";
                    default:
                        return "Other";
                }
            }
            return "gender unknown";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Patient.prototype, "numVisits", {
        get: function () {
            if (!this.enrollments) {
                return -1;
            }
            var visitSum = 0;
            for (var _i = 0, _a = this.enrollments; _i < _a.length; _i++) {
                var enrollment = _a[_i];
                visitSum += enrollment.numVisits;
            }
            return visitSum;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Patient.prototype, "nextVisit", {
        get: function () {
            var _a;
            if (!this.enrollments) {
                return null;
            }
            return (_a = this.enrollments
                .filter(function (e) { return !!e.nextVisit; })
                .slice()
                .sort(function (a, b) { var _a, _b; return isBefore((_a = a.nextVisit) === null || _a === void 0 ? void 0 : _a.engagement, (_b = b.nextVisit) === null || _b === void 0 ? void 0 : _b.engagement) ? 1 : -1; })[0]) === null || _a === void 0 ? void 0 : _a.nextVisit;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], Patient.prototype, "dateOfBirth", void 0);
    __decorate([
        observable
    ], Patient.prototype, "enrollments", void 0);
    __decorate([
        computed
    ], Patient.prototype, "fullName", null);
    __decorate([
        computed
    ], Patient.prototype, "age", null);
    __decorate([
        computed
    ], Patient.prototype, "ageDisplay", null);
    __decorate([
        computed
    ], Patient.prototype, "genderDisplay", null);
    __decorate([
        computed
    ], Patient.prototype, "numVisits", null);
    __decorate([
        computed
    ], Patient.prototype, "nextVisit", null);
    return Patient;
}(Model));
export { Patient };
