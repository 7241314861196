import { CircularProgress, createStyles, Modal, withStyles, } from '@material-ui/core';
import React from 'react';
import CenteredContent from 's~/components/layout/content/CenteredContent';
import ModalContent from 's~/components/layout/content/ModalContent';
import NavigationDrawerWrapper from 's~/components/layout/NavigationDrawerWrapper';
import ModalCloseIcon from 's~/components/misc/ModalCloseIcon';
import ColorsEnum from 's~/constants/styling/ColorsEnum';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        modal: {
            width: '100%',
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalContent: (_a = {
                maxWidth: '90%',
                width: '1100px',
                height: '85vh',
                position: 'relative',
                background: ColorsEnum.White,
                padding: 0,
                display: 'flex',
                flexDirection: 'row'
            },
            _a[theme.breakpoints.down('xs')] = {
                height: '100%',
                width: '100%',
                maxWidth: '100%',
            },
            _a),
        navigationDrawer: {
            background: '#FAFAFA',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        content: (_b = {
                position: 'relative',
                width: 'calc(100% - 287px)',
                height: '100%',
                padding: '25px 30px',
                overflow: 'auto'
            },
            _b[theme.breakpoints.down('xs')] = {
                width: '100%',
                padding: '20px 15px 15px 70px',
            },
            _b),
    });
};
var NavigationModal = function (props) {
    var classes = props.classes, onClose = props.onClose, loading = props.loading, NavigationDrawer = props.NavigationDrawer, drawerProps = props.drawerProps;
    return (React.createElement(Modal, { open: true, onClose: onClose, className: classes.modal },
        React.createElement(React.Fragment, null,
            loading && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null))),
            !loading && (React.createElement(ModalContent, { className: classes.modalContent },
                React.createElement(ModalCloseIcon, { onClick: onClose }),
                !!NavigationDrawer && (React.createElement(NavigationDrawerWrapper, { NavigationDrawer: NavigationDrawer, anchor: "left", detached: true, width: "287px", appBarAnchor: "left", drawerProps: drawerProps })),
                React.createElement("div", { className: "hideScrollbar " + classes.content }, props.children))))));
};
export default withStyles(styles)(NavigationModal);
